import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { createDriver } from '../../models/drivers';

import '../../styles/spinner.css';

export default class AddDriver extends Component {
  constructor() {
    super();
    this.state = {
      loadingData: false,
      loadingSuccessFull: true,
    };
  }

  createDriver(e) {
    e.preventDefault();
    if (!this.fname.value || !this.lname.value || !this.phone.value || !this.password.value) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    const data = {
      fname: this.fname.value,
      lname: this.lname.value,
      email: this.email.value,
      phone: this.phone.value,
      password: this.password.value,
    };

    this.setState({ loadingData: true });
    createDriver(data).then(() => {
      this.props.setDashboardMessage('success', 'Driver created succesfully');
      this.setState({
        loadingData: false,
        loadingSuccessFull: true,
      });
      this.createForm.reset();
    }).catch((err) => {
      console.error('[Create Driver] Error creating driver', data, err.stack);
      this.props.setDashboardMessage('error', 'Sorry an error occured while creating the driver');
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }


  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/drivers/add',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-container">
        <div className="row">
          <div className="user-dash-wrapper">
            <div className="sub-page-header col-sm-12">
              <h2>Add Driver</h2>
            </div>

            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="module">
                    <div className="module-header">
                      <h3>Driver Information</h3>
                    </div>
                    <div className="module-wrapper">
                      <div className="module-content module-form-wrapper">
                        <div className="form-wrapper">
                          <form
                            onSubmit={e => this.createDriver(e)}
                            ref={(form) => { this.createForm = form; }}
                          >
                            <div className="row">
                              <div className="form-element col-sm-6">
                                <span className="label">First Name</span>
                                <input
                                  ref={(input) => { this.fname = input; }}
                                  type="text"
                                  required
                                  className="form-text"
                                />
                              </div>
                              <div className="form-element col-sm-6">
                                <span className="label">Last Name</span>
                                <input
                                  ref={(input) => { this.lname = input; }}
                                  type="text"
                                  required
                                  className="form-text"
                                />
                              </div>
                            </div>
                            <div className="form-element">
                              <span className="label">E-mail Address</span>
                              <input
                                ref={(input) => { this.email = input; }}
                                type="email"
                                className="form-text"
                              />
                              <span className="hint">
                                An email will be sent to this address
                                with instructions to set a password.
                              </span>
                            </div>

                            <div className="form-element phone">
                              <span className="label">Phone Number</span>
                              <input
                                ref={(input) => { this.phone = input; }}
                                type="phone"
                                required
                                className="form-text"
                              />
                            </div>

                            <div className="form-element phone">
                              <span className="label">password</span>
                              <input
                                ref={(input) => { this.password = input; }}
                                type="password"
                                required
                                className="form-text"
                              />
                            </div>
                            <div className="form-element">
                              <input
                                type="submit"
                                className="bvtton"
                                value={this.state.loadingData ?
                                  'Creating Driver...' : 'Create Driver '
                                }
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="module-footer" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


AddDriver.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AddDriver.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AddDriver.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
