/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import { getAllUsers, searchUsers } from "../models/users";
import { getAllDrivers, searchDrivers } from "../models/drivers";
import { getAllAdmins, searchAdmins } from "../models/admin";

import "../styles/spinner.css";

const DRIVER_TYPE = "drivers";
const ADMIN_TYPE = "admins";

export default class UserList extends Component {
  constructor() {
    super();
    this.config = {
      page_size: 20,
      length_menu: [10, 20, 50],
      show_first: false,
      show_last: false,
      show_length_menu: false,
      button: {
        excel: true,
        print: true
      }
    };
    this.state = {
      users: [],
      searchTerm: "",
      loadingData: true,
      loadingSuccessFull: false,
      searchDone: false
    };

    this.history = createHistory();
    this.fetchAllUsers = this.fetchAllUsers.bind(this);
    this.fetchAllDrivers = this.fetchAllDrivers.bind(this);
    this.fetchAllAdmins = this.fetchAllAdmins.bind(this);
    this.executeSearch = this.executeSearch.bind(this);
  }

  componentDidMount() {
    const query = this.props.query || "";
    if (query.length >= 3) return this.executeSearch(query);

    if (this.props.type === DRIVER_TYPE) return this.fetchAllDrivers();
    if (this.props.type === ADMIN_TYPE) return this.fetchAllAdmins();
    this.fetchAllUsers();
  }

  componentWillReceiveProps(nextProps) {
    const query = nextProps.query || "";
    if (this.state.searchTerm === query) return false;
    if (query === "") {
      if (this.props.type === DRIVER_TYPE) {
        return this.fetchAllDrivers();
      }
      if (this.props.type === ADMIN_TYPE) {
        return this.fetchAllAdmins();
      }
      return this.fetchAllUsers();
    }
    if (query.length >= 3) {
      this.executeSearch(query);
    }
  }

  /**
   * Gets all users when no search query is issued.
   */
  fetchAllUsers() {
    getAllUsers()
      .then(data => {
        this.setState({
          users: this.props.summary ? data.slice(0, 6) : data,
          loadingSuccessFull: true,
          searchTerm: ""
        });
      })
      .catch(e => {
        console.error("[User List] Error fetching user data", e.stack);
        this.setState({ loadingSuccessFull: false });
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }

  /**
   * Gets all drivers when no search query is issued.
   */
  fetchAllDrivers() {
    getAllDrivers()
      .then(data => {
        this.setState({
          users: this.props.summary ? data.slice(0, 6) : data,
          loadingSuccessFull: true,
          searchTerm: ""
        });
      })
      .catch(e => {
        console.error(
          "[Driver List] Error fetching driver data",
          e.stack
        );
        this.setState({ loadingSuccessFull: false });
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }

  /**
   * Gets all admins when no search query is issued.
   */
  fetchAllAdmins() {
    getAllAdmins()
      .then(data => {
        let admins = data;
        if (data && data.total) {
          // paginated
          admins = data.data;
        }
        this.setState({
          users: this.props.summary ? admins.slice(0, 6) : admins,
          loadingSuccessFull: true,
          searchTerm: ""
        });
      })
      .catch(e => {
        console.error(
          "[Admin List] Error fetching admin data",
          e.stack
        );
        this.setState({ loadingSuccessFull: false });
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }

  /**
   * Process the search based on the type of content in view
   * @param {String} query
   */
  executeSearch(query) {
    this.setState({
      searchTerm: query,
      loadingData: true,
      loadingSuccessFull: false
    });

    this.props.setDashboardMessage(); // reset the timeout and dashboard message if there exists any.

    if (this.props.type === DRIVER_TYPE) {
      this.executeDriverSearch(query);
    } else if (this.props.type === ADMIN_TYPE) {
      this.executeAdminSearch(query);
    } else {
      this.executeUserSearch(query);
    }
  }

  /**
   * Search for users
   * @param {String} query
   */
  executeUserSearch(query) {
    searchUsers(query)
      .then(users => {
        // If no users reset state
        if (!Array.isArray(users) || users.length < 1) {
          return this.setState({ loadingSuccessFull: false });
        }

        this.setState({
          users,
          loadingSuccessFull: true,
          searchDone: true
        });
      })
      .catch(err => {
        this.setState({
          loadingSuccessFull: false
        });
        let message = "Sorry we could not find that user";
        if (err.body && err.body.code === "BadRequest") {
          message = err.body.message;
        }
        this.props.setDashboardMessage("error", message);
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }

  /**
   * Search for drivers
   * @param {String} query
   */
  executeDriverSearch(query) {
    searchDrivers(query)
      .then(drivers => {
        // If no driver reset state
        if (!Array.isArray(drivers) || drivers.length < 1) {
          return this.setState({ loadingSuccessFull: false });
        }

        this.setState({
          users: drivers,
          loadingSuccessFull: true,
          searchDone: true
        });
      })
      .catch(err => {
        this.setState({
          loadingSuccessFull: false
        });
        let message = "Sorry we could not find that driver";
        if (err.body && err.body.code === "BadRequest") {
          message = err.body.message;
        }
        this.props.setDashboardMessage("error", message);
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }

  /**
   * Search for admins
   * @param {String} query
   */
  executeAdminSearch(query) {
    searchAdmins(query)
      .then(admins => {
        // If no driver reset state
        if (!Array.isArray(admins) || admins.length < 1) {
          return this.setState({ loadingSuccessFull: false });
        }

        this.setState({
          users: admins,
          loadingSuccessFull: true,
          searchDone: true
        });
      })
      .catch(err => {
        this.setState({
          loadingSuccessFull: false
        });
        let message = "Sorry we could not find that admin";
        if (err.body && err.body.code === "BadRequest") {
          message = err.body.message;
        }
        this.props.setDashboardMessage("error", message);
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }

  render() {
    const isAdmin = this.props.type === "admins";
    const isSummary = this.props.summary;
    const nameOnly = this.props.nameOnly;

    return (
      <div
        className={`module-wrapper ${this.state.loadingData &&
          "loading"}`}
      >
        <div className="module-content has-table has-users">
          <div className="module-table">
            <table>
              {!nameOnly && (
                <thead>
                  <tr>
                    <th
                      width="40%"
                      style={{
                        textTransform: "capitalize"
                      }}
                    >
                      {this.props.type}
                    </th>
                    <th>E-mail</th>
                    {!isSummary && <th>Phone</th>}
                    {!isSummary && <th>Rides</th>}
                    <th className="align-right">Status</th>
                  </tr>
                </thead>
              )}
              {this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <tbody>
                    <tr
                      className="ride-data"
                      style={{ display: "table-row" }}
                    >
                      <td colSpan="5">
                        <div className="ride-data-row">
                          <div>
                            Loading
                            {this.props.type}
                            data...
                                                        <div className="spinner" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              {!this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <tbody>
                    <tr
                      className="ride-data"
                      style={{ display: "table-row" }}
                    >
                      <td colSpan="5">
                        <div className="ride-data-row">
                          <div>
                            Sorry we could not load
                                                        any {this.props.type}{" "}
                            data.
                                                    </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              {!this.state.loadingData &&
                this.state.loadingSuccessFull && (
                  <tbody>
                    {this.state.users.map(user => (
                      <tr
                        key={user.id}
                        className="clickable"
                        onClick={() =>
                          this.context.router.history.push(
                            `/${
                            this.props.type
                            }/details`,
                            isAdmin
                              ? {
                                adminDetails: user
                              }
                              : {
                                userDetails: user
                              }
                          )
                        }
                      >
                        <td>
                          <div className="user-table-display">
                            <div
                              className="image css-img-object"
                              style={{
                                backgroundImage: `url(${user.avatar ||
                                  "/assets/img/default.png"})`
                              }} // eslint-disable-line
                            />
                            <h3>
                              {user.fname}{" "}
                              {user.lname}
                            </h3>
                          </div>
                        </td>
                        {!nameOnly && (
                          <td>{user.email}</td>
                        )}
                        {!nameOnly && !isSummary && (
                          <td>{user.phone}</td>
                        )}
                        {!nameOnly && !isSummary && (
                          <td>Rides</td>
                        )}
                        {!nameOnly && (
                          <td className="small">
                            {parseInt(
                              user.active,
                              10
                            ) === 1
                              ? "Active"
                              : "Inactive"}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}
            </table>
          </div>
        </div>
        {isSummary && (
          <div className="module-footer">
            <Link
              to={`/${this.props.type}/manage`}
              className="module-footer-action"
              style={{ textTransform: "capitalize" }}
            >
              All {this.props.type}
              <i className="fa fa-long-arrow-right" />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

UserList.propTypes = {
  type: PropTypes.string,
  query: PropTypes.string,
  nameOnly: PropTypes.bool,
  summary: PropTypes.bool,
  setDashboardMessage: PropTypes.func
};

UserList.defaultProps = {
  type: "users",
  query: "",
  nameOnly: false,
  summary: false,
  setDashboardMessage: () => { }
};

UserList.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};
