import ManageRoutes from './manage';
import RouteDetails from './Details';
import PickUpDetails from './Pickup';
import DestinationDetails from './Destination';
import RouteBookings from './bookings';
import EditRoute from './Edit';
import AddRoute from './New';
import SuggestedRoutes from './suggestions';
import FutureRoute from './future_routes'

export {
  RouteBookings,
  AddRoute,
  EditRoute,
  RouteDetails,
  ManageRoutes,
  SuggestedRoutes,
  PickUpDetails,
  DestinationDetails,
  FutureRoute
};

export default ManageRoutes;
