import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Datatable from '../../components/datatable'
import { getAllDrivers } from "../../models/drivers";

import '../../styles/dashboard.css';

export default class ManageDrivers extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      success: false,
      error: false
    };
  }

  componentDidMount() {
    getAllDrivers()
      .then(data => {
        this.setState({ data, success: true });
      })
      .catch(e => {
        console.error("[Driver List] Error fetching driver data", e);
        this.setState({ error: true });
      })
  }

  handleRowClick = (e, record) => {
    const isAdmin = this.props.type === "admins";
    const isSummary = this.props.summary;
    const nameOnly = this.props.nameOnly;


    this.props.history.push('/drivers/details',
      isAdmin
        ? {
          adminDetails: record
        }
        : {
          userDetails: record
        }
    );
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: `/${this.props.type}/manage`,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    const { data } = this.state;
    const columns = [
      {
        key: "fname",
        text: "First Name",
        sortable: true,
      },
      {
        key: "lname",
        text: "Last Name",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        sortable: true,
      },
      {
        key: "phone",
        text: "Phone",
        sortable: true,
      },
      {
        key: "avatar",
        text: "Avatar",
        width: 70,
        sortable: false,
        cell: record => {
          return (
            <img
              className="image css-img-object"
              src={
                record.avatar
                  ? record.avatar
                  : "/assets/img/default.png"
              }
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "10px"
              }}
            />
          );
        }
      },
      {
        key: "active",
        width: 70,
        text: "Status",
        // className: "align-right",
        sortable: true,
        align: "left",
        cell: record => {
          return (
            <Fragment>
              {record.active == 1 ?
                "Active" : "InActive"
              }
            </Fragment>
          );
        }
      },
      {
        key: 'action',
        text: 'Action',
        width: 50,
        align: 'left',
        sortable: false,
        cell: record => {
          return (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => this.handleRowClick(e, record)}
              style={{ textAlign: 'center' }}
            >
              <i className="fa fa-edit" />
            </button>
          );
        },
      }
    ]

    return (
      <div className="user-dash-wrapper">

        <div className="sub-page-header col-sm-12">
          <h2>Manage Drivers</h2>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />

            <Datatable
              title="Drivers"
              data={data}
              columns={columns}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

ManageDrivers.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

ManageDrivers.defaultProps = {
  user: null,
  type: 'drivers',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
