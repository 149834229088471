/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import {getAllPromotions} from "../models/promotions";

import "../styles/spinner.css";
const moment = require("moment");

const DRIVER_TYPE = "drivers";
const ADMIN_TYPE = "admins";

export default class PromotionsList extends Component {
  constructor() {
    super();
    this.config = {
      page_size: 20,
      length_menu: [10, 20, 50],
      show_first: false,
      show_last: false,
      show_length_menu: false,
      button: {
        excel: true,
        print: true
      }
    };
    this.state = {
        promotions: {meta: {},models: []},
      searchTerm: "",
      loadingData: true,
      loadingSuccessFull: false,
      searchDone: false
    };

    this.history = createHistory();
    this.fetchAllPromotions = this.fetchAllPromotions.bind(this);
  }

  componentDidMount() {
    const query = this.props.query || "";
    if (query.length >= 3) return this.executeSearch(query);
    this.fetchAllPromotions();
  }

  componentWillReceiveProps(nextProps) {
    const query = nextProps.query || "";
    if (this.state.searchTerm === query) return false;
    if (query === "") {
      return this.fetchAllPromotions();
    }
    if (query.length >= 3) {
      this.executeSearch(query);
    }
  }

  /**
   * Gets all users when no search query is issued.
   */
  fetchAllPromotions() {
    getAllPromotions()
      .then(data => {
        this.setState({
          promotions: this.props.summary ? data.slice(0, 6) : data,
          loadingSuccessFull: true,
          searchTerm: ""
        });
      })
      .catch(e => {
        console.error("[User List] Error fetching promotions data", e.stack);
        this.setState({ loadingSuccessFull: false });
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }


  render() {
    const isSummary = this.props.summary;
    const nameOnly = this.props.nameOnly;

    return (
      <div
        className={`module-wrapper ${this.state.loadingData &&
          "loading"}`}
      >
        <div className="module-content has-table has-users">
          <div className="module-table">
            <table>
              {!nameOnly && (
                <thead>
                  <tr>
                    <th
                      width="25%"
                      style={{
                        textTransform: "capitalize"
                      }}
                    >
                      Promo Code
                    </th>
                    <th>Owner</th>
                    <th>Expires At</th>
                    <th>Max Number of uses</th>
                    <th>Number of uses</th>
                    <th className="align-right">Status</th>
                  </tr>
                </thead>
              )}
              {this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <tbody>
                    <tr
                      className="ride-data"
                      style={{ display: "table-row" }}
                    >
                      <td colSpan="5">
                        <div className="ride-data-row">
                          <div>
                            Loading
                            {this.props.type}
                            data...
                                                        <div className="spinner" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              {!this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <tbody>
                    <tr
                      className="ride-data"
                      style={{ display: "table-row" }}
                    >
                      <td colSpan="5">
                        <div className="ride-data-row">
                          <div>
                            Sorry we could not load
                                                        any {this.props.type}{" "}
                            data.
                                                    </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              {!this.state.loadingData &&
                this.state.loadingSuccessFull && (
                  <tbody>
                    {this.state.promotions.models.map(user => (
                      <tr
                        key={user.id}
                        className="clickable"
                        onClick={() =>
                          this.context.router.history.push(
                            `/promotions/details`,
                             {
                                promotion: user
                              }
                          )
                        }
                      >
                       
                       <td>
                       <strong>{user.promo_code}</strong>
                       {user.name ? (<div><small>{user.name}</small></div>) : ""}
                       </td>
                       <td>{user.owner_type}</td>
                       <td>{user.expires_at ? moment(user.expires_at).format('YYYY-MM-DD HH:mm') : 'Never'}</td>
                       <td>{user.max_number_of_uses ? user.max_number_of_uses : 'Unlimited'}</td>
                       <td>{user.number_of_usages ? user.number_of_usages : 0}</td>
                       <td className="small">
                            { !user.has_promotion_expired
                              ? "Active"
                              : "Inactive"
                              }
                          </td>
                      </tr>
                    ))}
                  </tbody>
                )}
            </table>
          </div>
        </div>
        {isSummary && (
          <div className="module-footer">
            <Link
              to={`/${this.props.type}/manage`}
              className="module-footer-action"
              style={{ textTransform: "capitalize" }}
            >
              All {this.props.type}
              <i className="fa fa-long-arrow-right" />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

PromotionsList.propTypes = {
  type: PropTypes.string,
  query: PropTypes.string,
  nameOnly: PropTypes.bool,
  summary: PropTypes.bool,
  setDashboardMessage: PropTypes.func
};

PromotionsList.defaultProps = {
  type: "users",
  query: "",
  nameOnly: false,
  summary: false,
  setDashboardMessage: () => { }
};

PromotionsList.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};
