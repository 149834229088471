import React, { useState, useEffect, Fragment, Component } from "react";
import ClickAway from "react-click-away-listener";
import papa from "papaparse";

import { getAllUsers, BulkDebitUsers, BulkCreditUsers } from "../../models/users";
import "./transfers.css";

const CREDIT = "credit", DEBIT = "debit";

const SelectButton = ({ addUser, removeUser, user, allSelectedUsers }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (allSelectedUsers.find(each => each.email === user.email)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [allSelectedUsers]);

  const handleClick = e => {
    e.preventDefault();
    if (selected) {
      removeUser(user);
    } else {
      addUser(user);
    }
    setSelected(!selected);
  };

  return (
    <button
      className={"select-bvtton--" + (selected ? "passive" : "active")}
      onClick={handleClick}
    >
      {selected ? "Selected" : "Select"}
    </button>
  );
};

const Modal = ({ active, closeModal, performAction, selectedUsers, creditting, debitting }) => {
  const [title, setTitle] = useState("");
  const [amount, setAmount] = useState("");

  if (active) {
    return (
      <div className="modal-container">
        <ClickAway onClickAway={closeModal}>
          <div className="modal">
            <div className="modal-action">
              <div className="form-element">
                <input
                  className="form-text"
                  type="text"
                  name=""
                  defaultValue={title}
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                  placeholder="title"
                  id=""
                />
              </div>
              <div className="form-element">
                <input
                  type="number"
                  className="form-text"
                  defaultValue={amount}
                  onChange={e => {
                    setAmount(e.target.value);
                  }}
                  name=""
                  placeholder="amount"
                  id=""
                />
              </div>
            </div>
            <h3>Confirm</h3>
            <div className="modal-body">
              {selectedUsers.length > 0 ? (
                <ul>
                  {selectedUsers.map((each, i) => (
                    <li>{each.email}</li>
                  ))}
                </ul>
              ) : (
                  <h3>No User Selected</h3>
                )}
            </div>
            <div className="modal-bottom">
              <button
                className=" select-bvtton--active"
                onClick={() => {
                  performAction(CREDIT, title, parseInt(amount)).then(() => {
                    closeModal();
                  });
                }}
                disabled={!title || !amount || creditting}
              >
                {" "}
                Credit
              </button>
              <button
                className=" select-bvtton--active"
                onClick={() => {
                  performAction(DEBIT, title, parseInt(amount)).then(() => {
                    closeModal();
                  });
                }}
                disabled={!title || !amount || debitting}
              >
                {" "}
                {debitting ? "Debitting..." : "Debit"}
              </button>
            </div>
          </div>
        </ClickAway>
      </div>
    );
  } else {
    return <div />;
  }
};

export default class Transfers extends Component {
  constructor() {
    super();
    this.state = {
      csvUploaded: false,
      FileName: null,
      amount: null,
      modalActive: false,
      selectedUsers: [],
      users: [],
      loading: true,
      creditError: null,
      debitError: null,
      title: "",
      debitting: false,
      creditting: false
    };
    this.input = React.createRef();
    this.file = React.createRef();
    this.amount = React.createRef();
    this.title = React.createRef();
  }

  componentDidMount() {
    getAllUsers()
      .then(users => {
        this.setState({ ...this.state, users, loading: false, userDup: users });
      })
      .catch(e => {
        throw e;
      });
  }

  addUser(userObject) {
    this.setState({
      ...this.state,
      selectedUsers: [...this.state.selectedUsers, userObject]
    });
  }

  closeModal() {
    this.setState({ ...this.state, modalActive: false });
  }

  removeUser(userObject) {
    this.setState({
      ...this.state,
      selectedUsers: this.state.selectedUsers.filter(
        each => each.id != userObject.id
      )
    });
  }

  filterSearch() {
    this.setState({
      ...this.state,
      users: this.state.userDup.filter(each => {
        const value = new RegExp(this.search.value);
        return value.test(each.email);
      })
    });
  }

  handleClick(type) {
    this.performAction(type, this.state.title);
  }

  csvLogic(event) {
    this.setState({ ...this.state, selectedUsers: [] });
    papa.parse(event.target.files[0], {
      complete: (result, _) => {
        result.data.map(each => {
          if (each[0]) {
            this.addUser({
              email: each[0]
            });
          }
        });
      }
    });
    this.setState({
      ...this.state,
      csvUploaded: true,
      FileName: event.target.files[0].name
    });
  }

  async performAction(action, title, amount) {
    if (this.state.selectedUsers.length === 0) return;
    try {
      if (action === CREDIT) {
        const userData = this.state.selectedUsers.map(each => {
          return {
            amount: amount,
            type: CREDIT,
            title,
            email: each.email
          };
        });
        this.setState({ ...this.state, creditting: true });
        const response = await BulkCreditUsers({ items: userData });
        const errors = response.filter(each => each.code === 500);
        if (errors.length != 0) {
          this.setState({ ...this.state, creditError: errors });
          this.props.setDashboardMessage(
            "error",
            `failed to credit ${errors.length} users`
          );
        } else {
          this.setState({ ...this.state, selectedUsers: [] });
          this.props.setDashboardMessage(
            "success",
            "All users were successfully creditted"
          );
        }
        this.setState({ ...this.state, creditting: false });
      } else {
        const userData = this.state.selectedUsers.map(each => {
          return {
            amount: amount,
            type: DEBIT,
            title,
            email: each.email
          };
        });
        this.setState({ ...this.state, debitting: true });

        const response = await BulkDebitUsers({ items: userData });
        const errors = response.filter(each => each.code === 500);
        if (errors.length != 0) {
          // this.setState({ ...this.state, debitError: errors });/
          this.props.setDashboardMessage(
            "error",
            `failed to debit ${errors.length} users`
          );
        } else {
          this.setState({ ...this.state, selectedUsers: [] });
          this.props.setDashboardMessage(
            "success",
            "All users were successfully Debitted"
          );
        }
        this.setState({ ...this.state, debitting: false });
      }
      this.file.current.value = "";
      this.setState({
        ...this.state,
        FileName: "",
        title: "",
        amount: "",
        csvUploaded: false
      });
    } catch (e) {
      if (e.response) {
        this.setState({ ...this.state, error: e.response.data });
      } else {
        console.warn(JSON.stringify(e, null, 2));
      }
    }
  }

  errorComponent() {
    return (
      <div className="col-md-12 col-lg-12">
        <div className="module">
          <div className="module-header">
            <div className="module-wrapper">
              {this.state.debitError && (
                <div className="module-content">
                  <h3 style={{ color: "red" }}>
                    Debit action failed for {this.state.debitError.length} users
                  </h3>
                </div>
              )}
              {this.state.creditError && (
                <div className="module-content">
                  <h3 style={{ color: "red" }}>
                    Credit action failed for {this.state.debitError.length}{" "}
                    users
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  clear() {
    this.file.current.value = "";
    this.setState({
      ...this.state,
      FileName: "",
      csvUploaded: false
    });
  }

  render() {
    return (
      <Fragment>
        <div className="user-dash-wrapper">
          <div className="sub-page-header col-sm-12">
            <h2>Bulk Transactions</h2>
          </div>

          <label className="upload-csv">
            {this.state.csvUploaded ? this.state.FileName : "Upload Email CSV"}
            <div className="tooltip">title Fill in amount &</div>
            <input
              type="file"
              key={this.state.FileName}
              ref={this.file}
              accept=".csv"
              onChange={this.csvLogic.bind(this)}
            />
          </label>
          <button
            className="select-bvtton--active"
            disabled={this.state.selectedUsers.length == 0}
            onClick={() => {
              this.setState({ ...this.state, modalActive: true });
            }}
          >
            {" "}
            Proceed
          </button>

          {(this.state.creditError || this.state.debitError) &&
            this.errorComponent()}
          <div className="col-md-12 col-lg-12">
            <div className="module-pre-header-filter">
              <div className="form-element">
                <input
                  ref={input => {
                    this.search = input;
                  }}
                  onChange={this.filterSearch.bind(this)}
                  type="text"
                  className="form-text"
                  placeholder="Search for Users"
                />
              </div>
            </div>
            <div className="module focus green">
              <div className="module-header">
                <div className="module-wrapper">
                  <div className="module-content has-table has-users">
                    <div className="module-table">
                      <table>
                        <thead>
                          <tr>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Phone No</td>
                            <td />
                          </tr>
                        </thead>
                        <tbody>
                          {!this.loading &&
                            this.state.users.map(user => (
                              <tr key={user.id} className="clickable">
                                <td>
                                  <div className="user-table-display">
                                    <div
                                      className="image css-img-object"
                                      style={{
                                        backgroundImage: `url(${user.avatar ||
                                          "/assets/img/default.png"})`
                                      }} // eslint-disable-line
                                    />
                                    <span>
                                      {user.fname} {user.lname}
                                    </span>
                                  </div>
                                </td>
                                <td>{user.email}</td>
                                <td>{user.phone}</td>
                                <td>
                                  <SelectButton
                                    user={user}
                                    addUser={this.addUser.bind(this)}
                                    removeUser={this.removeUser.bind(this)}
                                    allSelectedUsers={this.state.selectedUsers}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          active={this.state.modalActive}
          closeModal={this.closeModal.bind(this)}
          selectedUsers={this.state.selectedUsers}
          performAction={this.performAction.bind(this)}
          creditting={this.state.creditting}
          debitting={this.state.debitting}
          clear={this.clear.bind(this)}
        />
      </Fragment>
    );
  }
}
