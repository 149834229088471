import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddVehicle from '../../components/addVehicle';
import RideHistory from '../../components/rideHistory';
import { updateDriver, getAllDrivers } from '../../models/drivers';
import { deleteVehicle,  } from '../../models/fleet';

import '../../styles/spinner.css';

export default function VehicleDetails({ location, history, setDashboardMessage, user, trips }) {
  const [meta, setMeta] = useState({ loading: false, error: false, success: false });
  const [dropDown, setDropdown] = useState(false);
  const [driversArray, setDriversArray] = useState([]);
  const [preferredDriver, setPreferredDriver] = useState({});
  const vehicle = location.state.vehicle;

  useEffect(() => {
    async function fetchAllVehicles() {
      getAllDrivers().then((data) => {
        setDriversArray(data);
      }).catch((e) => {
        console.error('[Driver List] Error fetching driver data', e.stack);
      })
    }
    fetchAllVehicles();
  }, []);


  const removeVehicle = (e) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to delete this vehicle?')) return false; //eslint-disable-line

    setMeta({ loading: true });

    deleteVehicle(this.state.vehicle.id).then(() => {
      history.goBack();
      setDashboardMessage('success', 'Vehicle deleted');
    }).catch((err) => {
      setMeta({ loading: true });
      setDashboardMessage('error', 'Sorry an error occurred while deleting vehicle');
      console.error('[Vehicle Details] Error deleting vehicle', this.state.vehicle.id, err.stack);
    });
  }

  const handleDropdownChange = (e) => {
    const driver_id = e.target.value;
    let data;
    driversArray.map(each => {
      if(each.id == driver_id) {
        data = each;
      }
    })
    setPreferredDriver(data)
  }

   const submitForm = async (e) => {
    e.preventDefault();

    if(vehicle.driver && vehicle.driver.hasOwnProperty('id')) {
      await ChangeDriver(vehicle.driver.id, vehicle.driver, "unassign");
    }

    let res = await ChangeDriver(preferredDriver.id, preferredDriver);
    if(res.status == 400) {
      return setDashboardMessage('error', 'Sorry an error occurred while changing driver');
    }
    window.alert("Driver unassigment successful")
    return window.location.assign("/fleet");
  }

  const ChangeDriver = async(id, data, action) => {
    if(action == "unassign") {
      data.vehicle_id = null;
    }
    else {
      data.vehicle_id = vehicle.id
    }
    
    return await updateDriver(id, data)
      .then(async(res) => {
        return await res;
      })
      .catch((error) => {
        console.log(error)
        return error;
      })
  }


  // if we have a user session we redirect away from sign up
  if (!user || !user.id) {
    return (
      <Redirect to={{
        push: true,
        rtURL: '/fleet/details',
        rtProps: location.state,
        pathname: '/login',
      }}
      />
    );
  }

const driver_list = [];
driversArray.map((each, key) => {
  if(each.hasOwnProperty('fname') && each.hasOwnProperty('lname') && each.active && !each.vehicle_id) {
    let name = each.fname + " " + each.lname;
    driver_list.push(
      <option value={each.id} key={key}>{name}</option>
    )
  }
})


  if (!vehicle) {
    return (
      <Redirect to={{
        pathname: '/fleet',
      }}
      />
    );
  }

  return (
    <div className="user-dash-wrapper">
      <div className="sub-page-header col-sm-12">
        <h2>{`${vehicle.brand} ${vehicle.name}'s Details`}</h2>
      </div>

      <div className="page-usability-back col-sm-12">
        <button className="bvtton small grey" onClick={() => history.goBack()}>
          Go Back
        </button>
      </div>

      <div className="clearfix" />
      <div className="clearfix" />

      <div className="col-md-4">
        <div className={`admin-user-summary ${meta.loading && 'loading'}`}>
          {meta.loading && <div className="spinner" />}

          <div className="user-picture">
            <div className="image css-img-object"
              style={{ backgroundImage: `url(${user.avatar || '/assets/img/default-bus.png'})` }}
            />
          </div>
          <h3>{vehicle.brand} {vehicle.name}</h3>
          <div className="user-data flex flex-row">
            <div>
              {vehicle.registration_number}
              <span>PLATE NUMBER</span>
            </div>
            <div>
              {vehicle.seats}-seats
                                <span>CAPACITY</span>
            </div>
            <div>
              {vehicle.amenities}
              <span>Ammenities</span>
            </div>
            <div>
              {vehicle.driver && vehicle.driver.id ?
                `${vehicle.driver.fname} ${vehicle.driver.lname}` : 'N/A'}
              <span>DRIVER ASSIGNED</span>
            </div>
          </div>
          <div className="bi-stat">
            {/* <div className="user-rides-count">
              {this.state.trips ? `${this.state.trips.trips.length}` : '0'}
              <span>rides completed</span>
            </div> */}
            <div className="user-account-balance">
              <Moment format="h:mm A">{vehicle.created_at}</Moment>
              <br /><Moment format="MMM Do YY">{vehicle.created_at}</Moment>
              <span>COMMISIONED SINCE</span>
            </div>
          </div>
        </div>

        <div className={`admin-account-actions ${meta.loading && 'loading'}`}>
          {meta.loading && <div className="spinner" />}
          <div className="_header">
            <h3>Vehicle Actions</h3>
          </div>
          <ul>
              <li>
                <form
                  onSubmit={(event) => submitForm(event)}    
                >
                  <div className="dropdown__">
                    <a href="" onClick={(e) => {
                        e.preventDefault();
                        setDropdown(true);
                    }}>
                      {vehicle.driver ? "Change Driver" : "Assign Driver"}
                    </a>
                    <select 
                      name="cars" 
                      id="cars" 
                      className={
                        (!dropDown) ? "selectDriver" 
                        : "dp_btn"
                      }
                      onChange={handleDropdownChange}
                    >
                      <option value="" key="">Select Driver</option>
                      { driver_list }
                    </select>
                  </div>
                  <button 
                    className={
                      (!dropDown) ? "selectDriver" 
                      : "bvtton small success" 
                    }
                  >
                    {vehicle.driver ? "Update Driver" : "Assign Driver"}
                  </button>
                </form>
              </li>
            <li>
              <a href="" onClick={e => removeVehicle(e)}>
                De-commision Vehicle <i className="fa fa-angle-right" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="col-sm-7">
        <div className="module">
          <div className="module-header" />
          <AddVehicle
            vehicle={vehicle}
            onUpdate={v => { }}
            setDashboardMessage={setDashboardMessage}
          />
        </div>
      </div>

      <div className="col-sm-7">
        <div className="module">
          <div className="module-header" />
          {/* assign route */}
          {/* <Formik
            initialValues={{ pickup: '' }}
            // validationSchema={schema}
            onSubmit={(values, actions) => {
              setMeta({ loading: true });
            }}
          >
            {props => {
              const { handleSubmit, values, errors, touched, handleBlur, setFieldValue } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-sub-header">
                      <h4>Assign Route</h4>
                      <span>Assign this fleet to ply a partivular route.</span>
                    </div>

                    <div className="form-element col-sm-12">
                      <span className="label">Routes</span>
                      <Select
                        value={{ label: values.pickup, value: values.pickup }}
                        onChange={val => setFieldValue('pickup', val.value)}
                        // options={busStops.map(item => ({ label: item.location, value: item.location }))}
                        required
                        name="pickup"
                        onBlur={handleBlur}
                      />
                      {errors['pickup'] && touched['pickup'] && <p style={{ color: 'red' }}>{errors['pickup']}</p>}
                    </div>
                    <div className="clearfix" />
                  </div>

                  <div className="form-element">
                    <input
                      type="submit"
                      className="bvtton"
                      value={meta.loading ? 'Assigning Route...' : 'Assign Route'}
                    />
                  </div>
                </form>
              )
            }}
          </Formik> */}
        </div>
      </div>

      <div className="col-sm-7">
        <div className="module">
          <div className="module-header" />
          {/* assign driver */}
        </div>
      </div>

      <div className="col-sm-8">
        <div className="module">
          <div className="module-header">
            <h3>Ride History</h3>
          </div>
          <RideHistory vehcile={vehicle} />
        </div>
      </div>
    </div>
  );
}


VehicleDetails.propTypes = {
  user: PropTypes.object,
  vehicle: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

VehicleDetails.defaultProps = {
  user: null,
  vehicle: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

VehicleDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
