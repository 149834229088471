import React from 'react'
import { BrowserRouter, Route, Link, NavLink } from 'react-router-dom'
import storage from 'localforage'

import './styles/dashboard.css'
import './styles/login.css'
import './styles/style.css'

import Dashboard from './pages/index'
import LoginPage from './pages/auth/login'
import ForgotPassword from './pages/auth/forgotPassword'
import ChangePassword from './pages/auth/changePassword'
import ResetPassword from './pages/auth/resetPassword'
import {
  UserInsights,
  UserDetails,
  ManageUsers,
  AddUser,
  Transfers,
  Notify,
  UserRouteBookings,
  ExportData,
} from './pages/users'
import { ManageDrivers } from './pages/drivers/'
import { TripDetails, TripInsights, ActiveTrips } from './pages/trips'
import NFC from './pages/nfc'
import {
  AddRoute,
  EditRoute,
  ManageRoutes,
  RouteDetails,
  SuggestedRoutes,
  PickUpDetails,
  DestinationDetails,
  FutureRoute,
  RouteBookings,
} from './pages/routes'
import { AddDriver, DriverDetails } from './pages/drivers'
import NotFound from './pages/404'
import { AddStaff, ManageStaff } from './pages/staff'
import { CharterDetails, CharterRequests, CharterStatus } from './pages/charter'
import { AdminDetails, ManageAdmin, AddAdmin } from './pages/admin'
import {
  PromoCodeDetails,
  ManagePromoCodes,
  AddPromoCode,
  AddPromoCodeForUsers,
} from './pages/promoCodes'
import { TransactionsInsights, TransactionDetails } from './pages/transactions'
import {
  BusinessInsights,
  AddBusiness,
  BusinessDetails,
  CorporateInterest,
} from './pages/business'
import {
  FleetInsights,
  VehicleDetails,
  VehicleRegistration,
  TrackFleet,
} from './pages/fleet'
import {
  OntripServicesInsights,
  OntripServiceDetails,
  AddOntripService,
} from './pages/ontrip'
import { OntripOrdersInsights, OntripOrdersDetails } from './pages/orders'
import { BusStops, AddBusStop, UpdateBusStops } from './pages/busStops'
import Burger from './components/burger'
import SideBar from './components/sideBar'
import Survey from './pages/survey'
import ManagePartners from './pages/partner'

import { scrollToEl } from './libs/utils'
import { getBookings } from './models/routes'
import UpcomingTrips from './pages/trips/upcoming'

let mountComplete = false

class Root extends React.Component {
  constructor() {
    super()

    this.state = {
      user: null,
      layout: 'default',
      mounted: false,
      dashboardMessages: null,
      recommendedRoutes: [],
    }

    this.setLayout = this.setLayout.bind(this)
    this.setUserSession = this.setUserSession.bind(this)
    this.clearUserSession = this.clearUserSession.bind(this)
    this.setDashboardMessage = this.setDashboardMessage.bind(this)
    this.showDashboardMessage = this.showDashboardMessage.bind(this)
  }

  componentDidMount() {
    // add a logged in user to session on component mount
    storage.getItem('session').then((session) => {
      this.setUserSession(session)
    })

    if (!mountComplete) {
      this.forceUpdate()
    }
    window.addEventListener('resize', this.updateNavHeight)
  }

  componentDidUpdate() {
    mountComplete = true
    window.jQuery(document).ready(() => {
      this.updateNavHeight()
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNavHeight)
  }

  /**
   * Updates the base layout for our views
   * @param layout
   */
  setLayout(layout) {
    this.setState({
      layout,
    })
  }

  /**
   * Puts a user in session
   * @param user
   */
  setUserSession(user) {
    storage.setItem('session', user).then(() => {
      this.setState({
        user,
        mounted: true,
      })
    })
  }

  /**
   * Sets a dashboard message
   * @param type
   * @param message
   */
  setDashboardMessage(type, message) {
    if (type && message) {
      const key = `${type}-${Date.now()}`
      const dashboardMessages = { key, type, message }
      this.setState({ dashboardMessages })
      scrollToEl('dashboard-message')
    } else {
      this.setState({ dashboardMessages: null })
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
    }
  }

  /**
   * Shows a dashboard message for 10 secs
   * @returns {JSX}
   */
  showDashboardMessage() {
    const message = this.state.dashboardMessages
    if (message) {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      // Remove message after 10 seconds
      this.timeout = window.setTimeout(() => {
        this.setState({ dashboardMessages: null })
      }, 10000)

      return (
        <div
          id="dashboard-message"
          className={`alert ${message.type}`}
          data-key={message.key}
        >
          <p>{`${message.message}`}</p>
        </div>
      )
    }
  }

  /**
   * Resize the left navigation pane to fit with the windows's height - navbar height
   */
  updateNavHeight() {
    const documentHeight = window.jQuery(window).innerHeight()
    const headerHeight = window.jQuery('#user-dashboard-admin').outerHeight()
    const rightHeight = documentHeight - headerHeight

    if (headerHeight > 0) {
      // has [header] so we set
      window.jQuery('.right').css('min-height', `${rightHeight}px`)
      window.jQuery('.user-dashboard .left').css('height', `${rightHeight}px`)
      window
        .jQuery('section.user-dashboard')
        .css('padding-top', `${headerHeight}px`)
      window
        .jQuery('.user-dashboard .user-dash-container')
        .css('min-height', `${rightHeight}px`)
    } else {
      // Page doesn't have [header] i.e login/forgot-password/reset-password page
      window.jQuery('.right').css('min-height', `${documentHeight}px`)
    }
  }

  /**
   * Removes a user from session ie logout
   */
  clearUserSession() {
    storage.removeItem('session').then(this.setUserSession)
  }

  layoutHeader(layout) {
    if (layout === 'login') return null
    return (
      <header id="user-dashboard-admin" className="user-dashboard admin">
        <div className="container-fluid">
          <div className="row">
            <div className="nav-anchor">
              <i className="fa fa-bars" />
            </div>
            <div className="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <div className="logo">
                <Link to="/">
                  <img src="/assets/img/logo-light.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="col-xs-6 col-sm-8 col-md-9 col-lg-9">
              <div className="navigation flex flex-row space-between">
                <ul />
                <ul>
                  <li>
                    <Link to="/admins/details">My Profile</Link>
                  </li>
                  <li>
                    <Link to="" onClick={() => this.clearUserSession()}>
                      Log Out
                    </Link>
                  </li>
                </ul>
                <Burger />
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }

  render() {
    const layout = this.state.layout

    if (!this.state.mounted) {
      return false
    }

    const api_regex = /^\/v2\/.*/
    // if using "/api/" in the pathname, don't use React Router
    if (api_regex.test(window.location.pathname)) {
      return <div /> // must return at least an empty div
    } else {
      return (
        <BrowserRouter>
          <div>
            {this.layoutHeader(layout)}
            <Route
              exact
              path="/login"
              render={(routeProps) => (
                <LoginPage
                  {...routeProps}
                  setLayout={this.setLayout}
                  user={this.state.user}
                  setUserSession={this.setUserSession}
                />
              )}
            />

            <Route
              exact
              path="/forgot-password"
              render={(routeProps) => (
                <ForgotPassword
                  {...routeProps}
                  setLayout={this.setLayout}
                  user={this.state.user}
                />
              )}
            />

            <Route
              exact
              path="/change-password/:token"
              render={(routeProps) => (
                <ChangePassword {...routeProps} setLayout={this.setLayout} />
              )}
            />

            <Route
              exact
              path="/password/:token"
              render={(routeProps) => (
                <ResetPassword {...routeProps} setLayout={this.setLayout} />
              )}
            />

            <section className="nopadding user-dashboard">
              <div className="container-fluid full-height">
                <div className="row">
                  <div className="col-sm-12 col-md-3  col-lg-2 left">
                    <div className="admin-ui-nav row">
                      <SideBar />
                    </div>
                  </div>

                  <div
                    className={`col-sm-12
                    col-md-9 col-lg-10 col-md-offset-3 col-lg-offset-2 user-dash-container`}
                  >
                    {this.showDashboardMessage()}
                    <div className="row">
                      <Route
                        exact
                        path="/"
                        render={(routeProps) => (
                          <Dashboard
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users"
                        render={(routeProps) => (
                          <UserInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/user-bookings"
                        render={(routeProps) => (
                          <UserRouteBookings
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/data-export"
                        render={(routeProps) => (
                          <ExportData
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/manage"
                        render={(routeProps) => (
                          <ManageUsers
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/new"
                        render={(routeProps) => (
                          <AddUser
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/details"
                        render={(routeProps) => (
                          <UserDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/transfers"
                        render={(routeProps) => (
                          <Transfers
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/users/notify"
                        render={(routeProps) => (
                          <Notify
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips"
                        render={(routeProps) => (
                          <TripInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/active-trips"
                        render={(routeProps) => (
                          <ActiveTrips
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/upcoming"
                        render={(routeProps) => (
                          <UpcomingTrips
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/details"
                        render={(routeProps) => (
                          <TripDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/nfc"
                        render={(routeProps) => (
                          <NFC
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes"
                        render={(routeProps) => (
                          <ManageRoutes
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes/bookings"
                        render={(routeProps) => (
                          <RouteBookings
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes/details"
                        render={(routeProps) => (
                          <RouteDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes/pickup/details"
                        render={(routeProps) => (
                          <PickUpDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes/pickup/destination/details"
                        render={(routeProps) => (
                          <DestinationDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes/edit"
                        render={(routeProps) => (
                          <EditRoute
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/routes/new"
                        render={(routeProps) => (
                          <AddRoute
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/bus-stops"
                        render={(routeProps) => (
                          <BusStops
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/bus-stops/new"
                        render={(routeProps) => (
                          <AddBusStop
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/bus-stops/update"
                        render={(routeProps) => (
                          <UpdateBusStops
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/drivers"
                        render={(routeProps) => (
                          <ManageDrivers
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/drivers/details"
                        render={(routeProps) => (
                          <DriverDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/drivers/add"
                        render={(routeProps) => (
                          <AddDriver
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/charters"
                        render={(routeProps) => (
                          <CharterRequests
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/charters/pending"
                        render={(routeProps) => (
                          <CharterStatus
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            status="New"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/charters/complete"
                        render={(routeProps) => (
                          <CharterStatus
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            status="Completed"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/charters/details"
                        render={(routeProps) => (
                          <CharterDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            type="drivers"
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/promotions"
                        render={(routeProps) => (
                          <ManagePromoCodes
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/promotions/new"
                        render={(routeProps) => (
                          <AddPromoCode
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/promotions/new-users"
                        render={(routeProps) => (
                          <AddPromoCodeForUsers
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/promotions/details"
                        render={(routeProps) => (
                          <PromoCodeDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/admins"
                        render={(routeProps) => (
                          <ManageAdmin
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/admins/new"
                        render={(routeProps) => (
                          <AddAdmin
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/staff"
                        render={(routeProps) => (
                          <ManageStaff
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/staff/new"
                        render={(routeProps) => (
                          <AddStaff
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/admins/details"
                        render={(routeProps) => (
                          <AdminDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            adminDetails={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/transactions"
                        render={(routeProps) => (
                          <TransactionsInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/transactions/details"
                        render={(routeProps) => (
                          <TransactionDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/business"
                        render={(routeProps) => (
                          <BusinessInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/business/add"
                        render={(routeProps) => (
                          <AddBusiness
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/business/details"
                        render={(routeProps) => (
                          <BusinessDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/business/corporate_interest"
                        render={(routeProps) => (
                          <CorporateInterest
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/fleet"
                        render={(routeProps) => (
                          <FleetInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/fleet/details"
                        render={(routeProps) => (
                          <VehicleDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/fleet/add"
                        render={(routeProps) => (
                          <VehicleRegistration
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trip-services"
                        render={(routeProps) => (
                          <OntripServicesInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/trip-services/details"
                        render={(routeProps) => (
                          <OntripServiceDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/trip-services/add"
                        render={(routeProps) => (
                          <AddOntripService
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trip-orders"
                        render={(routeProps) => (
                          <OntripOrdersInsights
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/trip-orders/details"
                        render={(routeProps) => (
                          <OntripOrdersDetails
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/fleet/tracking"
                        render={(routeProps) => (
                          <TrackFleet
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/trips/suggestion"
                        render={(routeProps) => (
                          <SuggestedRoutes
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/trips/future_routes"
                        render={(routeProps) => (
                          <FutureRoute
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/survey"
                        render={(routeProps) => (
                          <Survey
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      <Route
                        exact
                        path="/partners"
                        render={(routeProps) => (
                          <ManagePartners
                            {...routeProps}
                            setLayout={this.setLayout}
                            user={this.state.user}
                            setDashboardMessage={this.setDashboardMessage}
                          />
                        )}
                      />

                      {/* catch all other unknown routes */}
                      {/* <Route path="*" render={routeProps => (
                      <NotFound
                        {...routeProps}
                        setLayout={this.setLayout}
                        user={this.state.user}
                        setDashboardMessage={this.setDashboardMessage}
                      />
                    )} /> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </BrowserRouter>
      )
    }
  }
}

export default Root
