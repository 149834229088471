/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { getCharters } from '../models/charter';

import '../styles/spinner.css';

export default class ChaterList extends Component {
    constructor() {
        super();
        this.state = {
            charterData: [],
            loadingData: true,
            loadingSuccessFull: false,
        };
    }

    componentDidMount() {
        getCharters('0', '5').then((data) => {
            this.setState({
                charterData: data,
                loadingData: false,
                loadingSuccessFull: true,
            });
        }).catch((e) => {
            console.error('[Charter List] Error fetching charter summary', e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    render() {
        const isSummary = this.props.summary;
        return (
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                <div className="module-content has-table has-users">
                    <div className="module-table">
                        <table className="responsive">
                            <thead>
                                <tr>
                                    <th>Pickup Date</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th className="align-right">Trip Type</th>
                                </tr>
                            </thead>
                            {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                                <tbody>
                                    <tr className="ride-data" style={{ display: 'table-row' }}>
                                        <td colSpan="4">
                                            <div className="ride-data-row">
                                                <div>
                                                    Loading charter data...
                                                    <div className="spinner" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                            {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                                <tbody>
                                    <tr className="ride-data" style={{ display: 'table-row' }}>
                                        <td colSpan="4">
                                            <div className="ride-data-row">
                                                <div>
                                                    Sorry we could not load any charter data.
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                            {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                                <tbody>
                                    {this.state.charterData.data.map(request => (
                                        <tr key={request.id} className="clickable" href="admin-charter-single.html">
                                            <td><Moment format="DD/MM/YYYY">{request.pickup_date}</Moment></td>
                                            <td>{request.pickup_address}</td>
                                            <td>{request.dropoff_address}</td>
                                            <td className="align-right">
                                                {request.return_address ? 'Round Trip' : 'One Way'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            }

                        </table>
                    </div>
                </div>
                {isSummary &&
                <div className="module-footer">
                    <Link to="/charters" className="module-footer-action">
                        All Charter Requests<i className="fa fa-long-arrow-right" />
                    </Link>
                </div>
                }
            </div>
        );
    }
}


ChaterList.propTypes = {
    summary: PropTypes.bool,
};

ChaterList.defaultProps = {
    summary: false,
};
