import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { updateAdmins, deleteAdmin } from '../../models/admin';
import '../../styles/spinner.css';

export default class AdminDetails extends Component {
  constructor() {
    super();
    this.state = {
      admin: null,
      changePassword: false,
      loadingData: false,
      loadingSuccessful: true,
    };
  }

  componentWillMount() {
    const locationAdmin = this.props.location.state ? this.props.location.state.adminDetails : null;
    const admin = locationAdmin || this.props.adminDetails;
    this.setState({ admin });
  }

  suspendAdmin() {
    const admin = this.state.admin;
    const data = { active: 0 };

    this.setState({
      loading: true,
    });
    updateAdmins(admin.id, data).then((adminData) => {
      this.setState({
        admin: adminData,
      });
      this.props.setDashboardMessage('success', 'Admin account suspended');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while suspending driver');
      console.error('[Admin Details] Error suspending admin ', admin.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  updateAdmin(e) {
    e.preventDefault();
    if (!this.fname.value || !this.lname.value || !this.email.value || !this.phone.value) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    const data = {
      fname: this.fname.value,
      lname: this.lname.value,
      email: this.email.value,
      phone: this.phone.value,
    };

    this.setState({ loadingData: true, loadingSuccessFull: false });
    updateAdmins(this.state.admin.id, data).then((admin) => {
      this.props.setDashboardMessage('success', 'Admin updated successfully');
      this.setState({
        admin,
        loadingSuccessFull: true,
      });
    }).catch((err) => {
      console.error('[Admin Details] Error updating admin', data, err.stack);
      this.props.setDashboardMessage('error', 'Sorry an error occurred while updating the admin');
      this.setState({ loadingSuccessFull: false });
    }).finally(() => {
      this.setState({
        loadingData: false,
      });
    });
  }

  unsuspendAdmin() {
    const admin = this.state.admin;
    const data = { active: 1 };

    this.setState({
      loading: true,
    });
    updateAdmins(admin.id, data).then((adminData) => {
      this.setState({
        admin: adminData,
      });
      this.props.setDashboardMessage('success', 'Admin account unsuspended');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while unsuspending admin');
      console.error('[Admin Details] Error unsuspending admin ', admin.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  changePassword(evt) {
    evt.preventDefault();
    const admin = this.state.admin;
    const password = this.password.value;
    const password2 = this.password2.value;

    if ((password && !password2) || (!password && password2)) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    } else if ((password && password2) && (password.length < 4)) {
      return this.props.setDashboardMessage('error', 'Password length should be at least 4 characters long');
    } else if (password && (password !== password2)) {
      return this.props.setDashboardMessage('error', 'Password confirmation does not match');
    }

    const data = { password };

    this.setState({
      loading: true,
    });
    updateAdmins(admin.id, data).then((adminDetails) => {
      this.setState({
        changePassword: false,
        admin: adminDetails,
      });
      this.props.setDashboardMessage('success', 'Admin account updated');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while changing admin password');
      console.error('[Admin Details] Error changing Admin password', admin.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  deleteAdmin() {
    const admin = this.state.admin;

    if (!window.confirm('Are you sure you want to delete this Admin?')) return false; //eslint-disable-line

    this.setState({
      loading: true,
    });

    deleteAdmin(admin.id).then(() => {
      this.context.router.history.goBack();
      this.props.setDashboardMessage('success', 'Admin account deleted');
    }).catch((e) => {
      this.setState({
        loading: false,
      });
      this.props.setDashboardMessage('error', 'Sorry an error occured while deleting admin');
      console.error('[Admin Details] Error deleting admin ', admin.id, e.stack);
    });
  }

  render() {
    const user = this.props.user;
    const adminDetails = this.state.admin;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/admins/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!adminDetails) {
      return (
        <Redirect to={{
          pathname: '/admins',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{`${adminDetails.fname}'s Details`}</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>
        <div className="clearfix" />

        <div className="col-md-4">
          <div className={`admin-user-summary ${this.state.loading && 'loading'}`}>
            {this.state.loading && <div className="spinner" />}
            <div className="user-picture">
              <div className="image css-img-object"
                style={{ backgroundImage: `url(${adminDetails.avatar || '/assets/img/default.png'})` }}
              />
            </div>
            <h3>{adminDetails.fname} {adminDetails.lname}</h3>
            <div className="user-data flex flex-row">
              <div>
                {adminDetails.phone}
                <span>Phone Number</span>
              </div>
              <div>
                {adminDetails.email}
                <span>E-mail Address</span>
              </div>
            </div>
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Account Actions</h3>
            </div>
            <ul>
              {adminDetails.active === '0' ?
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.unsuspendAdmin(); }}>
                    Unuspend Admin <i className="fa fa-angle-right" />
                  </a>
                </li>
                :
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.suspendAdmin(); }}>
                    Suspend Admin <i className="fa fa-angle-right" />
                  </a>
                </li>
              }
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({ changePassword: true });
                }}
                >
                  Change Password <i className="fa fa-angle-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {this.state.changePassword &&
          <div className="col-md-8">
            <div className="module">
              <div className="module-header">
                <h3>Change Admin Password</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.changePassword(e)}>
                      <div className="row">
                        <div className="form-element col-sm-6">
                          <span className="label">New Password</span>
                          <input
                            ref={(input) => { this.password = input; }}
                            type="password"
                            required
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-sm-6">
                          <span className="label">Password Confirmation</span>
                          <input
                            ref={(input) => { this.password2 = input; }}
                            type="password"
                            required
                            className="form-text"
                          />
                        </div>
                      </div>

                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Change Password" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {!this.state.changePassword &&
          <div className="col-md-12 col-lg-6">
            <div className="module">
              <div className="module-header">
                <h3>Edit Admin User</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form
                      onSubmit={e => this.updateAdmin(e)}
                      ref={(form) => { this.updateForm = form; }}
                    >
                      <div className="row">
                        <div className="form-element col-sm-6">
                          <span className="label">First Name</span>
                          <input
                            ref={(input) => { this.fname = input; }}
                            type="text"
                            required
                            defaultValue={adminDetails.fname}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-sm-6">
                          <span className="label">Last Name</span>
                          <input
                            ref={(input) => { this.lname = input; }}
                            type="text"
                            required
                            defaultValue={adminDetails.lname}
                            className="form-text"
                          />
                        </div>
                      </div>
                      <div className="form-element">
                        <span className="label">E-mail Address</span>
                        <input
                          ref={(input) => { this.email = input; }}
                          type="email"
                          required
                          defaultValue={adminDetails.email}
                          className="form-text"
                        />
                      </div>

                      <div className="form-element phone">
                        <span className="label">Phone Number</span>
                        <input
                          ref={(input) => { this.phone = input; }}
                          type="number"
                          required
                          defaultValue={adminDetails.phone}
                          className="form-text"
                        />
                      </div>

                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Update Admin" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

AdminDetails.propTypes = {
  user: PropTypes.object,
  adminDetails: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AdminDetails.defaultProps = {
  user: null,
  adminDetails: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AdminDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
