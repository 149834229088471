/**
 * Created by tewshi on 10/4/2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import createHistory from 'history/createBrowserHistory';

import '../styles/spinner.css';
import Pagination from './pagination';

export default class DriverTrips extends Component {
	constructor() {
		super();
		this.state = {
			trips: null,
			loadingData: true,
			loadingSuccessful: false,
		};

		this.history = createHistory();
		this.fetchDriverTrips = this.fetchDriverTrips.bind(this);
		this.onPaginateTrips = this.onPaginateTrips.bind(this);
	}

	/**
	 * Component lifecycle method, called by React when
	 */
	componentDidMount() {
		const driver = this.props.driver;

		if (driver) return this.fetchDriverTrips();
	}

	/**
	 * Callback to call when the pagination component fetches the required data.
	 * @param {object} err
	 * @param {object} data
	 * @returns an err object if any error occurred, and a data object that holds the fetched data.
	 */
	onPaginateTrips(err, data) {
		if (data) {
			this.setState({ trips: data, loadingData: false, loadingSuccessful: true });
		} else {
			console.error('[Ride History] Error fetching trips for driver');
			this.setState(err);
		}
	}

	/**
	 * Method used in initializing the state on component mount.
	 */
	fetchDriverTrips() {
		this.setState({
			loadingData: true,
			loadingSuccessful: false,
		});
	}

	render() {
		const options = { limit: 10, offset: 0 };
		const driver = this.props.driver || null;
		const trips = this.state.trips;
		if (driver && driver.id) {
			options.url = `/v1/drivers/${driver.id}/trips`;
		}

		return (
			<div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
				<div className="module-content has-table has-users">
					<div className="module-table">
						<table className="">
							<thead>
								<tr>
									<th>Date</th>
									<th>from</th>
									<th>To</th>
									<th>Distance</th>
									<th>Duration</th>
									<th className="align-right">Fare</th>
								</tr>
							</thead>
							{(this.state.loadingData && !this.state.loadingSuccessful) &&
							<tbody>
								<tr className="ride-data" style={{ display: 'table-row' }}>
									<td colSpan="6">
										<div className="ride-data-row">
											<div>
											Loading ride history...
												<div className="spinner" />
											</div>
										</div>
									</td>
								</tr>
							</tbody>
							}
							{(!this.state.loadingData && !this.state.loadingSuccessful) &&
							<tbody>
								<tr className="ride-data" style={{ display: 'table-row' }}>
									<td colSpan="6">
										<div className="ride-data-row">
											<div>
											Sorry you do not have any trip.
											</div>
										</div>
									</td>
								</tr>
							</tbody>
							}
							{(!this.state.loadingData && trips.total === 0) &&
							<tbody>
								<tr className="ride-data" style={{ display: 'table-row' }}>
									<td colSpan="6">
										<div className="ride-data-row">
											<div>
											Sorry you do not have any trip.
											</div>
										</div>
									</td>
								</tr>
							</tbody>
							}

							{(!this.state.loadingData && this.state.loadingSuccessful && trips.total > 0) &&
							<tbody>
								{trips.data.map(trip => (
									<tr key={trip.id} className="clickable"
										onClick={() => this.context.router.history.push('/trips/details', { trip })}
									>
										<td><Moment format="h:mm A">{trip.created_at}</Moment>
											<br /><Moment format="MMM Do YY">{trip.created_at}</Moment>
										</td>
										<td>{trip.route.pickup}</td>
										<td>{trip.route.destination}</td>
										<td>{trip.route.distance}</td>
										<td>{trip.route.duration}</td>
										<td className="align-right money">Multiple Drop Off</td>
									</tr>
								))}
							</tbody>
							}
						</table>
					</div>
				</div>
				<div className="module-footer">
					<Pagination callback={this.onPaginateTrips} options={options} />
				</div>
			</div>
		);
	}
}

DriverTrips.propTypes = {
	driver: PropTypes.shape({
		id: PropTypes.number,
	}),
};

DriverTrips.defaultProps = {
	driver: null,
};
DriverTrips.contextTypes = {
	router: PropTypes.shape({
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
			replace: PropTypes.func.isRequired,
		}).isRequired,
		staticContext: PropTypes.object,
	}).isRequired,
};
