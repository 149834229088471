import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { findCharter, getAllCharters } from '../../models/charter';
import DatePicker from '../../components/datepicker';
import '../../styles/spinner.css';

export default class CharterRequests extends Component {
  constructor() {
    super();
    this.state = {
      chaters: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    this.fetchAllCharter();
  }

  componentWillReceiveProps() {
    this.query.value = null;
    this.fetchAllCharter();
  }


  fetchAllCharter(range) {
    getAllCharters(null, null, range).then((data) => {
      // If no charter reset state
      if (range && (!Array.isArray(data) || range.length < 1)) {
        return this.setState({ loadingData: false, loadingSuccessFull: false });
      }

      this.setState({
        chaters: Array.isArray(data) ? { data } : data,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((e) => {
      console.error('[Charter List] Error fetching charter data', e.stack);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;
    if (from && to) this.fetchAllCharter({ from, to });
  }

  executeSearch(e) {
    e.preventDefault();

    const query = this.query.value;

    if (!query) return this.fetchAllCharter();
    this.setState({
      loadingData: true,
      loadingSuccessFull: false,
    });

    findCharter(query)
      .then((chaters) => {
        // If no charter reset state
        if (!Array.isArray(chaters) || chaters.length < 1) {
          return this.setState({ loadingData: false, loadingSuccessFull: false });
        }

        this.setState({
          chaters: { data: chaters },
          loadingData: false,
          loadingSuccessFull: true,
        });
      })
      .catch((err) => {
        console.error('[Charter List] Error searching for user', query, err.stack);
        this.setState({
          loadingData: false,
          loadingSuccessFull: false,
        });
        this.props.setDashboardMessage('error', 'Sorry we could not find that charter');
      });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/chaters',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Charter Requests</h2>
          <div className="right-options">
            <div className="search">
              <form onSubmit={e => this.executeSearch(e)}>
                <div className="form-element">
                  <input
                    ref={(input) => { this.query = input; }}
                    type="text"
                    className="form-text"
                    placeholder="Enter search term"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">

            <div className="module-pre-header-filter">
              <form onSubmit={e => this.applyFilters(e)}>
                <div className="flex flex-wrap inline-form-wrapper">
                  <div className="title">
                    <h3>Filter by Date</h3>
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.startDate = data; }}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.endDate = data; }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="form-element">
                    <button type="submit">Filter</button>
                  </div>
                </div>
              </form>
            </div>
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`} >
              <div className="module-content has-table">
                <div className="module-table">
                  <table className="responsive">
                    <thead>
                      <tr>
                        <th>Pickup</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Vehicle Type</th>
                        <th>Customer Name</th>
                        <th>Customer Phone</th>
                        <th>Status</th>
                        <th className="align-right">Trip Type</th>
                      </tr>
                    </thead>
                    {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="8">
                            <div className="ride-data-row">
                              <div>
                                Loading charter data...
                                                                <div className="spinner" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="8">
                            <div className="ride-data-row">
                              <div>
                                Sorry we could not load any charter data.
                                                            </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                      <tbody>
                        {this.state.chaters.data.map(req => (
                          <tr key={req.id} className="clickable"
                            onClick={() =>
                              this.context.router.history.push('/charters/details',
                                { charter: req })}
                          >
                            <td>{req.pickup_time} <br />{req.pickup_date}.</td>
                            <td>{req.pickup_address}</td>
                            <td>{req.dropoff_address}</td>
                            <td>{req.vehicles}</td>
                            <td>{req.fname} {req.lname}</td>
                            <td>{req.phone}</td>
                            <td>{req.status}</td>
                            <td className="align-right">
                              {req.return_address ? 'One Way' : 'Return'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


CharterRequests.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

CharterRequests.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

CharterRequests.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
