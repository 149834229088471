import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { getAllTrips } from '../models/trips';
import { getAllUsers, usersGraph } from '../models/users';
import { getAllDrivers } from '../models/drivers';
import { getAllTransactions } from '../models/transactions';

import UserList from '../components/userList';
import CharterList from '../components/charterList';
import RoutesList from '../components/routeList';
import TransactionHistory from '../components/transactionHistory';

import { initBarChart } from '../libs/utils';

import '../styles/dashboard.css';

export default class Dashboard extends Component {
  constructor() {
    super();

    this.state = {
      users: 0,
      trips: 0,
      drivers: 0,
      transactions: 0,
      loadingData: true,
    };
  }

  componentDidMount() {
    const dashbordPromises = [getAllTrips(), getAllUsers(), getAllDrivers(), getAllTransactions(null, null, null, true)];

    Promise.all(dashbordPromises).then((data) => {
      this.setState({
        users: data[1].length,
        trips: data[0].total,
        drivers: data[2].length,
        transactions: data[3].length,
        loadingData: false,
      });
    }).catch((e) => {
      console.error('[User Dashboard] Error fetching data for dashboard', e);
      this.props.setDashboardMessage('error', 'Error fetching dashboard summary, Please try after some time');
      this.setState({ loadingData: false });
    });

    usersGraph().then((data) => {
      const userData = [];

      data.forEach((datum) => {
        userData.push([datum.month, datum.total]);
      });

      const Users30Day = [{
        data: userData,
        label: 'Users',
      }];

      initBarChart('.linechart.dashboard-user-rides', Users30Day);
    });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">

        <div className="col-sm-6 col-lg-3">
          <div className={`module admin-focus green ${this.state.loadingData && 'dashboard-loading'}`}>
            <div className="focus-number">
              {this.state.trips}
              <span>Total Rides</span>
              {this.state.loadingData && <div className="spinner" />}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className={`module admin-focus blue ${this.state.loadingData && 'dashboard-loading'}`}>
            <div className="focus-number">
              {this.state.transactions}
              <span>Total Transactions</span>
              {this.state.loadingData && <div className="spinner" />}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className={`module admin-focus red ${this.state.loadingData && 'dashboard-loading'}`}>
            <div className="focus-number">
              {this.state.users}
              <span>Total Users</span>
              {this.state.loadingData && <div className="spinner" />}
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className={`module admin-focus orange ${this.state.loadingData && 'dashboard-loading'}`}>
            <div className="focus-number">
              {this.state.drivers}
              <span>Total Drivers</span>
              {this.state.loadingData && <div className="spinner" />}
            </div>
          </div>
        </div>

        <div className="clearfix" />

        <div className="col-md-12">
          <div className="chart-wrapper">
            <h3>Rides by Users <span>Rides by new and existing users</span></h3>
            <div className="chart linechart dashboard-user-rides" />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="module focus green">
            <div className="module-header">
              <h3>Last 5 Transactions</h3>
            </div>
            <TransactionHistory summary />
          </div>
        </div>

        <div className="col-md-12 col-lg-6">
          <div className="module focus green">
            <div className="module-header">
              <h3>Last 5 User Signups</h3>
            </div>
            <UserList summary />
          </div>
        </div>

        <div className="clearfix" />

        <div className="col-sm-6 col-md-6 col-lg-6">
          <div className="module focus green">
            <div className="module-header">
              <h3>Our Routes</h3>
            </div>
            <RoutesList summary />
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6">
          <div className="module focus green">
            <div className="module-header">
              <h3>Recent Charter Requests</h3>
            </div>
            <CharterList summary />
          </div>
        </div>

        <div className="clearfix" />
      </div>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

Dashboard.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
