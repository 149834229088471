import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { getTransactionGraphData } from '../../models/transactions';
import { initLineChart, initPieChart } from '../../libs/utils';
import TransactionHistory from '../../components/transactionHistory';
import DatePicker from '../../components/datepicker';

import '../../styles/dashboard.css';

export default class TransactionsInsights extends Component {
  constructor() {
    super();
    this.state = {
      users: 0,
      filter: null,
    };
  }

  componentDidMount() {
    getTransactionGraphData().then((data) => {
      const transactionDebits = [];
      const transactionCredits = [];
      let totalDebits = 0;
      let totalCredits = 0;

      data.forEach((datum) => {
        const amount = datum.amount;
        const date = parseInt(moment(datum.created_at).format('X'), 10);

        if (datum.type === 'credit') {
          totalCredits += amount;
          transactionCredits.push([date, amount]);
        } else {
          totalDebits += amount;
          transactionDebits.push([date, amount]);
        }
      });

      const lineTransaction30Day = [
        {
          data: transactionDebits,
          label: 'Wallet Spends',
        },
        {
          data: transactionCredits,
          label: 'Wallet Funding',
        },
      ];

      const piedata = [
        { label: 'Credits', data: totalCredits },
        { label: 'Debit', data: totalDebits },
      ];

      initLineChart('.linechart.30day-transaction', lineTransaction30Day, '&#x20A6;');
      initPieChart('.piechart.user-transit-time', piedata);
    });
  }

  componentWillReceiveProps() {
    this.setState({ filter: null });
  }

  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;

    if (from && to) this.setState({ filter: { from, to } });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/users',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="col-md-8">
          <div className="chart-wrapper">
            <h3>Transactions (30 Days) <span>User transactions over the last 30 days</span></h3>
            <div className="chart linechart 30day-transaction" />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="chart-wrapper">
            <h3>Transaction Distribution
                            <span>Type of users transactions (Credits, Debits)</span>
            </h3>
            <div className="chart piechart user-transit-time" />
          </div>
        </div>
        <div className="clearfix" />

        <div className="sub-page-header col-sm-12">
          <h2>Transactions</h2>
        </div>

        <div className="col-lg-12">
          <div className="module focus green">
            <div className="module-pre-header-filter">
              <form onSubmit={e => this.applyFilters(e)}>
                <div className="flex flex-wrap inline-form-wrapper">
                  <div className="title">
                    <h3>Filter by Date</h3>
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.startDate = data; }}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.endDate = data; }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="form-element">
                    <button type="submit">Filter</button>
                  </div>
                </div>
              </form>
            </div>

            <TransactionHistory filter={this.state.filter} />
          </div>
        </div>

      </div>
    );
  }
}


TransactionsInsights.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

TransactionsInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
