import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/dashboard.css';
import '../styles/login.css';
import '../styles/style.css';

const SideBar = () => {

    return (
        <>
            <div className="col-sm-12 col-md-3  col-lg-2 left">
                <div className="admin-ui-nav row">
                    <nav>
                        <li>
                            <NavLink activeClassName="active" exact to="/">
                                <i className="fa fa-dashboard" /> Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/transactions">
                                <i className="fa fa-exchange" /> Transactions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/users">
                                <i className="fa fa-user-circle" /> Users
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" exact to="/users">
                                        Insights
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/users/manage">
                                        Manage Users
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/users/new">
                                        Add Users
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="active"
                                        exact
                                        to="/users/transfers"
                                    >
                                        Bulk Transactions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="active"
                                        exact
                                        to="/users/notify"
                                    >
                                        Notify
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="active"
                                        exact
                                        to="/users/data-export"
                                    >
                                        Export Data
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/trips">
                                <i className="fa fa-map-marker" /> Trips/Routes
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" exact to="/trips">
                                        All Trips
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/active-trips">
                                        Active Trips
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/trips/upcoming">
                                        Upcoming Trips
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/trips/routes">
                                        Manage Routes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/trips/routes/new">
                                        Add New Route
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/trips/suggestion">
                                        Suggested Routes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/trips/future_routes">
                                        Future Routes
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/bus-stops">
                                        Bus Stops
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/bus-stops/new">
                                        Add Bustop
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/nfc">
                                <i className="fa fa-credit-card" /> NFC Verification
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/drivers">
                                <i className="fa fa-id-badge" /> Drivers
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" to="/drivers">
                                        Manage Drivers
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/drivers/add">
                                        Add Driver
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/business">
                                <i className="fa fa-briefcase" /> Businesses
                            </NavLink>
                            <ul>

                                <li>
                                    <NavLink activeClassName="active"
                                        to="/business"
                                    >
                                        Manage Businesses
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active"
                                        to="/business/add"
                                    >
                                        Add New
                                    </NavLink>
                                    <NavLink activeClassName="active"
                                        to="/business/corporate_interest"
                                    >
                                        Corporate Interest
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink activeClassName="active" to="/charters">
                                <i className="fa fa-calendar-check-o" /> Charter
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" to="/charters">
                                        All Requests
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active"
                                        to="/charters/pending"
                                    >
                                        Pending Requests
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active"
                                        to="/charters/complete"
                                    >
                                        Completed Requests
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/fleet">
                                <i className="fa fa-bus" /> Fleet
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" exact to="/fleet">
                                        Manage Fleet
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" to="/fleet/add">
                                        Add New
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/trip-services">
                                <i className="fa fa-list" /> On-trip Services
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" exact
                                        to="/trip-services"
                                    >
                                        All Products/Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName="active" exact
                                        to="/trip-services/add"
                                    >
                                        New Product/Service
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/trip-orders">
                                <i className="fa fa-shopping-cart" /> On-trip Orders
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink
                                        activeClassName="active" exact
                                        to="/trip-orders"
                                    >
                                        All Orders
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink activeClassName="active" to="/promotions">
                                <i className="fa fa-ticket" />Promotions
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" exact to="/promotions">
                                        Manage Promotions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/promotions/new">
                                        Add Promotion
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/promotions/new-users">
                                        Add Promotion For Users
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="/v2/#/promo-codes/" target="_blank">
                                <i className="fa fa-ticket" />Promotions
                            </a>
                            <ul>
                                <li>
                                    <a href="/v2/#/promo-codes/" target="_blank">
                                        Manage Promotions
                                    </a>
                                </li>
                                <li>
                                    <a href="/v2/#/promo-codes/new/" target="_blank">
                                        Create Promotion
                                    </a>
                                </li>
                                <li>
                                    <a href="/v2/#/referral-codes/new/" target="_blank">
                                        Create Referral Code
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink activeClassName="active" to="/admins">
                                <i className="fa fa-user-secret" />Admins
                            </NavLink>
                            <ul>
                                <li>
                                    <NavLink activeClassName="active" exact to="/admins">
                                        Manage Admins
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/admins/new">
                                        Add Admin
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/staff">
                                        Manage Staff
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName="active" exact to="/staff/new">
                                        Add Staff
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="/v2/" target="_blank">
                                <i className="fa fa-tag" />Credit System
                            </a>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/survey">
                                <i className="fa fa-rss"></i> Survey
                            </NavLink>
                        </li>
                        <li>
                            <NavLink activeClassName="active" to="/partners">
                                <i className="fa fa-handshake-o"></i> Partners
                            </NavLink>
                        </li>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default SideBar;
