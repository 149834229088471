import { get } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getAllTrips = (offset, limit, range) => {
  let url = (offset && limit) ? `/v1/trips?offset=${offset}&limit=${limit}&` : '/v1/trips?';
  if (range) url += `from=${range.from}&to=${range.to}`;
  const fetchTrips = get(url)
    .then((trips) => {
      cacheClient.set(url, trips);
      return trips;
    });

  return cacheClient.get(url)
    .catch((err) => {
      console.error('[Trips model] Error fetching trips from cache', err.stack);
      return fetchTrips;
    })
    .then((data) => {
      if (data) return data;
      return fetchTrips;
    });
};

export const getActiveTrips = () => {
  const fetchTrips = get('/v1/trips/active')
    .then((trips) => {
      cacheClient.set('/v1/trips/active', trips);
      return trips;
    });

  return cacheClient.get('/v1/trips/active')
    .catch((err) => {
      console.error('[Trips model] Error fetching active trips from cache', err.stack);
      return fetchTrips;
    })
    .then((data) => {
      if (data) return data;
      return fetchTrips;
    });
};

export const getUpcomingTrips = () => {
    const fetchTrips = get('/v1/trips/upcoming')
      .then((trips) => {
        cacheClient.set('/v1/trips/upcoming', trips);
        return trips;
      });
  
    return cacheClient.get('/v1/trips/upcoming')
      .catch((err) => {
        console.error('[Trips model] Error fetching upcoming trips from cache', err.stack);
        return fetchTrips;
      })
      .then((data) => {
        if (data) return data;
        return fetchTrips;
      });
  };

export const getTrip = (id) => {
  const fetchTrip = get(`/v1/trips/${id}`)
    .then((trip) => {
      cacheClient.set(`/v1/trips/${id}`, trip);
      return trip;
    });

  return cacheClient.get(`/v1/trips/${id}`)
    .catch((err) => {
      console.error('[Trips model] Error fetching trip from cache', id, err.stack);
      return fetchTrip;
    })
    .then((data) => {
      if (data) return data;
      return fetchTrip;
    });
};

export const tripGraph = () => {
  const fetchTrip = get('/v1/trips/graph')
    .then((trip) => {
      cacheClient.set('/v1/trips/graph', trip);
      return trip;
    });

  return cacheClient.get('/v1/trips/graph')
    .catch((err) => {
      console.error('[Trips model] Error fetching trip  graph from cache', err.stack);
      return fetchTrip;
    })
    .then((data) => {
      if (data) return data;
      return fetchTrip;
    });
};
