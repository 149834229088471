import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import Datatable from '../../components/datatable'
import {fetchAllSurvey } from '../../models/survey'

import '../../styles/dashboard.css';

export default class Survey extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      success: false,
      error: false
    };
  }

  componentDidMount() {
    fetchAllSurvey()
      .then(data => {
        let arr = []
        for(let i = 0; i< data.length; i++) {
          let payload = JSON.parse(data[i].payload);
          if(payload.carDetail === "") {
            payload.carDetail = 'NO'
          } else {
            payload.carDetail = 'YES'
          }
          delete data[i].payload;
          payload.survey_type = data[i].survey_type
          let entry = data[i];
          arr.push({entry, ...payload})
        }
       this.setState({ data: arr, success: true });
      })
      .catch(e => {
        console.error("[Business List] Error Corporate data", e);
        this.setState({ error: true });
      })
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: `/${this.props.type}/manage`,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    const { data } = this.state;
    console.log(data)
    const columns = [
      {
        key: "fname",
        text: "First Name",
        sortable: true,
      },
      {
        key: "lname",
        text: "Last Name",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        sortable: true,
      },
      {
        key: "industry",
        text: "Industry",
        sortable: true,
      },
      {
        key: "phone",
        text: "Phone",
        sortable: true,
      },
      {
        key: "survey_type",
        text: "Survey Category",
        sortable: true,
      },
      {
        key: "carDetail",
        text: "Is Driver",
        sortable: true
      },
      {
        key: "subscriptionOption",
        text: "Subscription Option",
        sortable: true,
      },
      {
        key: "pickUp",
        text: "Pick Up",
        sortable: true
      },
      {
        key: "dropOff",
        text: "Drop Off",
        sortable: true
      },
      {
        key: "gender",
        text: "Gender",
        sortable: true,
      },
    ]

    return (
      <div className="user-dash-wrapper">

        <div className="sub-page-header col-sm-12">
          <h2>Manage Survey</h2>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />
            <Datatable
              title="Users"
              data={data}
              columns={columns}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}


Survey.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

Survey.defaultProps = {
  user: null,
  type: 'users',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
