import { patch, get, post } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const assignBus = (id, vehicle) =>
  patch(`/v1/drivers/${id}/`, { vehicle_id: vehicle });

export const createVehicle = data => post('/v1/vehicles/', data);

export const getVehicle = id => get(`/v1/vehicles/${id}`);

export const getAllVehicles = () => {
  const fetchAllVehicles = get('/v1/vehicles')
    .then((trips) => {
      cacheClient.set('/v1/vehicles', trips);
      return trips;
    });

  return cacheClient.get('/v1/vehicles')
    .catch((err) => {
      console.error('[Vehicles model] Error fetching vehicles from cache', err.stack);
      return fetchAllVehicles;
    })
    .then((data) => {
      if (data) return data;
      return fetchAllVehicles;
    });
};
