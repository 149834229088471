import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link, NavLink } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import {groupBy, uniq} from 'lodash'

import RoutesMap from '../../components/routeMap';
import {
  deleteRoute, getPickUpPoints, getRoutePassengers, createRoutePickup, createRouteDropOff,
  updateRouteSeats, updateRoute, createRoute, getRouteDestination, getRouteDays, getBookings, getBookingReport,
  getCorporateBookingReport
} from '../../models/routes';
import {getRoutes, getDriverDetail} from "../../models/drivers";
import { getAllBusStops } from "../../models/busStops";
import {getAllBusinesses} from '../../models/business';
import { NotifyUsers } from '../../models/users';
import { scrollToEl } from '../../libs/utils';
import { get } from '../../libs/requestClient';
import '../../styles/spinner.css';

const schema = Yup.object().shape({
  location: Yup.string().required('Pickup location is required'),
});

const days = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];
export default class RouteDetails extends Component {
  constructor(props) {
    super(props);
    this.formChangeHandler = this.formChangeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleCounter = this.handleCounter.bind(this);
    this.state = {
      dayFormat: "YYYY-MM-DD",
      total_seats: null,
      route: null,
      pickups: null,
      pickup: null,
      passengers: [],
      loadingData: false,
      loadingSuccessFull: true,
      showIncreaseSeat: false,
      showDecreaseSeat: false,
      users: [],
      busStops: [],
      routeDays: [],
      loading: false,
      error: false,
      success: false,
      routeDrivers: null,
      duplicateRoute: false,
      bookingsArray: [],
      dateWeek: [],
      dataWeek: [],
      input: {
        location: '',
        location_description: '',
      },  
      duplicateCounter: '',
      form_inputs: {},
      data: [],
      bookingData: [],
      businesses: [],
      currentMonthDates: [],
      dateColumns: null,
      corporateFilteredBooking: [],
      currentOrgID: null,
      month_id: 0,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    };
  }

  handleDownload(params) {
    let csvData = this.state.data.map(x => {
      return {
        amount: x.amount,
        pickup: x.pickup.location,
        destination: x.destination.location,
        email: x.user.email,
        name: x.user.fname + " " + x.user.lname,
        start_date: moment(x.start_date).format("YYYY-MM-DD"),
        end_date: moment(x.end_date).format("YYYY-MM-DD")
      }
    });

    const csvParams = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: `Bookings for ${this.state.route.pickup} between ${params.start_date} and ${params.end_date}`,
      useTextFile: false,
      useBom: true,
      headers: ['Amount', 'Pickup', 'Destination', 'Email', 'Name', 'Start Date', 'End Date']
    };

    const csvExporter = new ExportToCsv(csvParams);
    csvExporter.generateCsv(csvData);
    window.alert('Download complete');  
  }

  componentWillMount() {
    const route = this.state.route ||
      this.props.route || (this.props.location.state && this.props.location.state.route) || null;
    this.setState({ route });
  }
  fetchRoute() {
    let routeId = this.state.route.id
    get(`/v1/routes/${routeId}`).then(route => {
      this.setState({ users: route.users, total_seats: route.total_seats})
    }).catch();

    getRoutes({id:routeId})
      .then(async(res) => {
        let dataObject = {};
        for(let i = 0; i < res.length; i++) {
          let response = await getDriverDetail(res[i].driver_id);
          dataObject[i] = response;
        }
        let newData = Object.values(dataObject);
        this.setState({ routeDrivers: newData})
      })
      .catch((error) => {
        console.log("Could not fetch driver routes: ", error)
      });

      getRouteDays(routeId)
        .then(async(res) => {
          console.log(res)
          this.setState({ routeDays: res})
        })      
        .catch((error) => {
          console.log("Could not fetch route days: ", error)
        });
  }

  async getCurrentWeekDateRange(route_id, corporate_id) {
    if(corporate_id) {
      corporate_id = parseInt(corporate_id);
    }
    let today = new Date() 
    let dateWeek = [];
    
    for (let i = 1; i <= 7; i++) {
      let first = today.getDate() - (today.getDay()) + i 
      let day = new Date(today.setDate(first)).toISOString().slice(0, 10)
      dateWeek.push(day)
    }
    this.setState({ dateWeek: dateWeek});
    return await getBookings(route_id, {booking_days: dateWeek}, corporate_id);
  }

  getFirstAndLastDateOfMonth() {
    let firstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let lastDay = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    return [firstDay, lastDay];
  }

  componentDidMount() {
    if (this.state.route) {
      this.fetchRoute();
    }

    getPickUpPoints(this.state.route.id, true)
      .then((pickups) => {
        this.setState({
          pickups: pickups.pickups,
        });
      }).catch((err) => {
        this.setState({
          loadingData: false,
        });
        console.error('[Route details]', err.stack);
      });

    getRoutePassengers(this.state.route.id, true)
      .then((passengers) => { this.setState({ passengers }) })
      .catch((err) => {
        this.setState({
          loadingData: false,
        });
        console.error('[Route details]', err.stack);
      });

    getAllBusStops()
      .then(data => { this.setState({ busStops: data }) })
      .catch(e => {
        console.error("[Bus stop List] Error fetching bus stop data", e);
      })
    
    getAllBusinesses()
      .then((res) => {
        console.log(res.data)
        res = res.data.filter((x) => x.active !== "0")
        this.setState({
          businesses: res,
        });
      })
    .catch(e => {
        console.error("[Business List] Error fetching business data", e);
      })
    
    this.getCurrentWeekDateRange(this.state.route.id, null)
      .then(async(res) => {
        let data = await res.data;
        this.setState({bookingsArray: data})
      })
      .catch((err) => {
        console.error("[Fetch Bookings] Error while fetching bookings", err);
      })
  }
  
  handleDropdownChange = (e) => {
    const organization_id = e.target.value;
    this.setState({
      currentOrgID: organization_id
    })
  }

  applyCompanyFilters = async (route_id, corporate_id) => {
    return await this.getCurrentWeekDateRange(route_id, corporate_id) 
      .then(async (res) => {
        let data = await res.data;
        this.setState({bookingsArray: data})
      })
      .catch((err) => {
        console.log(err);
      })
  } 

  handleMonthsDropdownChange = (e) => {
    const month_id = e.target.value;
    this.setState({
      month_id: month_id
    })
  }

  getCorporateNameByID = (data, corporate_id) => {
    let corporate_name;
    data.map((each) => {
      if(each.id == corporate_id) {
        corporate_name = each.corporate_name
      }
    })
    return corporate_name;
  }


	/**
	 * Updates the number of seats for the selected route
	 * @param {Event} e - the event that triggered the action.
	 * @param {'debit'|'credit'} type - the type of update to perform, credit to increase seats, debit to decrease seats.
	 */
  updateSeats(e, type) {
    e.preventDefault();

    let routeDayId;
    let seat_update_number;

    if (!this.state.form_inputs || this.state.form_inputs.length < 1) {
      return this.props.setDashboardMessage('error', 'Number of seats to increase is ' +
        'required and should be a positive number.');
    }

    for (const [key, value] of Object.entries(this.state.form_inputs)) {
        if(key.includes(`_`)) {
          routeDayId = key.split(`_`)[1];
          seat_update_number = value;
        }
    }

    const data = { seat_update_number, routeDayId, type };
    this.setState({ loadingData: true });
    updateRouteSeats(this.state.route.id, data).then((res) => {
      this.props.setDashboardMessage('success', 'Route\'s number of seats updated successfully.');
      this.setState({ loadingSuccessFull: true, showIncreaseSeat: false, showDecreaseSeat: false});
    }).catch((err) => {
      let message = 'Sorry an error occurred while updating route\'s seats.';
      if (err.body && (err.body.code === 'ItemError')) {
        message = err.body.message;
      }
      this.props.setDashboardMessage('error', message);
      this.setState({ loadingSuccessFull: false });
    }).finally(() => {
      this.setState({ loadingData: false });
    });
  }

	/**
	 * Deactivates a route
	 */
  suspendRoute() {
    const routeDetails = this.state.route;
    const data = { status: 0 };

    this.setState({ loadingData: true });
    updateRoute(routeDetails.id, data).then((route) => {
      this.setState({
        loadingSuccessFull: true,
        route,
      });
      this.props.setDashboardMessage('success', 'Route suspended');
    }).catch((err) => {
      const message = (err.body && err.body.message) || 'Sorry an error occurred while suspending route.';
      this.props.setDashboardMessage('error', message);
      this.setState({ loadingSuccessFull: false });
    }).finally(() => {
      this.setState({
        loadingData: false,
      });
    });
  }

	/**
	 * Activates a route
	 */
  unSuspendRoute() {
    const routeDetails = this.state.route;
    const data = { status: 1 };

    this.setState({ loadingData: true });
    updateRoute(routeDetails.id, data).then((route) => {
      this.setState({
        loadingSuccessFull: true,
        route,
      });
      this.props.setDashboardMessage('success', 'Route unsuspended');
    }).catch((err) => {
      const message = (err.body && err.body.message) || 'Sorry an error occurred while unsuspending route.';
      this.props.setDashboardMessage('error', message);
      this.setState({ loadingSuccessFull: false });
    }).finally(() => {
      this.setState({
        loadingData: false,
      });
    });
  }

	/**
	 * Deletes the selected route
	 * @param {Event} e - the event that triggered the action.
	 */
  deleteRoute(e) {
    e.preventDefault();
    const route = this.state.route;

    if (!window.confirm('Are you sure you want to delete this route?')) return false; //eslint-disable-line

    deleteRoute(route.id).then(() => {
      this.context.router.history.goBack();
      this.props.setDashboardMessage('success', 'Route has been been deleted');
    }).catch((err) => {
      console.error('[RouteDetails] Error deleteing route', route.id, err.stack);
      this.props.setDashboardMessage('error', 'Sorry we could not remmove this Route');
    });
  }

  async getWeekBookingReport (e) {
    e.preventDefault();
    let data = {
      start_date: this.state.dateWeek[0],
      end_date: this.state.dateWeek[this.state.dateWeek.length - 1]
    }

    await getBookingReport(this.state.route.id, data)
      .then((res) => {
        this.setState({data: res.data});
        this.handleDownload(data);
      })
      .catch((e) => {
        console.log(e, "An error occured while getting bookings report")
      }) 
  }

  getDatesForDayIDs(dayids, startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);
    dayids = JSON.parse(dayids);
    let date = [];
  
    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
      let _date = m.format(this.state.dayFormat);
      let day = new Date(_date).getDay() + 1;
        if(dayids.includes(day)) {
          date.push(m.format(this.state.dayFormat));
        }
    }

    return date;
  }


  async processCorporateBookingData() {
    let data = this.state.bookingData || null;
    if(!data) {
     return window.alert("An error occured while fetching report, please try again");
    }

    const getDaysInMonth = (month, year) => {
      month = parseInt(month);
      var date = new Date(year, month, 1);

      var days_ = [];
      while (date.getMonth() === month) {
        days_.push(moment(new Date(date)).format(this.state.dayFormat));
        date.setDate(date.getDate() + 1);
      }
      return days_;
    }

    const currentMonthDates = getDaysInMonth(this.state.month_id, new Date().getFullYear())
        
    for(let i =0; i < data.length; i++) {
      let sd = moment(data[i].start_date).format(this.state.dayFormat);
      let ld = moment(data[i].end_date).format(this.state.dayFormat);
      const _weeks = this.getDatesForDayIDs(data[i].day_ids, sd, ld);
      data[i].valid_days = currentMonthDates.filter((x) => _weeks.includes(x));
    }

    this.setState({
      corporateFilteredBooking: data,
      currentMonthDates: currentMonthDates
    })

    return this.handleCorporateBookingDataDownload(data);
  }


  async getCorporateBookingReport(e) {
    e.preventDefault();
    if(!this.state.currentOrgID) {
      return window.alert('Please select an organization')
    }
    let month = this.state.month_id;
    
    await getCorporateBookingReport(this.state.currentOrgID, month, this.state.route.id)
      .then((res) => {
        this.setState({bookingData: res});
        this.processCorporateBookingData()
      })
      .catch((e) => {
        console.log(e, "An error occured while getting bookings report")
      }) 
  }

  handleCorporateBookingDataDownload() {
    let _data = groupBy(this.state.corporateFilteredBooking, (x) => {
      return x.email;
    });

    let arr = [];
    let userDates = [];
    for (const [key, value] of Object.entries(_data)) {
      arr.push({
        user: key, 
        data: value.map((x) => x.valid_days), 
        staff_id: value[0].staff_id,
        fname: value[0].fname,
        lname: value[0].lname,
        email: value[0].email
      });
    }

    for(let i =0; i< arr.length; i++) {
      let merged = [].concat.apply([], arr[i].data);
      userDates.push({user_email: arr[i].user, dates: uniq(merged)});
    }
    arr = arr.sort((a, b) => a.user.localeCompare(b.user));

    let csvData = arr.map(x => {
      let currentUserDates = userDates.filter((user) => user.user_email === x.user)
      let dataObj = {};
      let dataSchema = {
        staff_id: x.staff_id || 'N/A',
        email: x.email,
        route: this.state.route.pickup,
        name: x.fname + ' ' + x.lname
      };

      for(let i =0; i<this.state.currentMonthDates.length; i++) {
        for(let j = 0; j < currentUserDates[0].dates.length; j++) {
          if(this.state.currentMonthDates[i] === currentUserDates[0].dates[j]) {
            dataObj[this.state.currentMonthDates[i]] = 1;
          }
        }
        dataObj[this.state.currentMonthDates[i]] = (dataObj[this.state.currentMonthDates[i]] === 1) ? dataObj[this.state.currentMonthDates[i]] : 0;
      }
      this.setState({dateColumns: Object.keys({...dataSchema, ...dataObj})})
      return {...dataSchema, ...dataObj} 
    });

    const csvParams = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: `Booking report for ${this.state.route.pickup} the month of ${this.state.months[this.state.month_id]}`,
      useTextFile: false,
      useBom: true,
      headers: this.state.dateColumns
    };

    const csvExporter = new ExportToCsv(csvParams);
    csvExporter.generateCsv(csvData);
    window.alert('Download complete');  
  }


  async getMonthBookingReport (e) {
    e.preventDefault();
    let params = this.getFirstAndLastDateOfMonth();
    let data = {
      start_date: params[0].toJSON().split("T")[0],
      end_date: params[1].toJSON().split("T")[0]
    };

    await getBookingReport(this.state.route.id, data)
      .then((res) => {
        this.setState({data: res.data});
        this.handleDownload(data);
      })
      .catch((e) => {
        console.log(e, "An error occured while getting bookings report")
      })   
    }

  duplicateRoute(e) {
    e.preventDefault();
    const route = this.state.route;
    this.setState({ duplicateRoute: true }, () => {
      scrollToEl('target_table');
    })
  }

  formChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    this.setState(state => ({
        form_inputs: {
            ...state.form_inputs,
            [name]: value,
        }
    }))
  } 



  handleRowClick = (e, data) => {
    e.preventDefault();
    this.props.history.push('/drivers/details',
        {
          userDetails: data
        }
    );
  }


  handleCounter(event) {
    this.setState({duplicateCounter: event.target.value})  
  }


  async submitForm(e) {
    e.preventDefault();
    const current_pick_up_data = await getPickUpPoints(this.state.route.id, true);
    let arr = [];
    let incrementCounter = parseInt(this.state.duplicateCounter) + 1;

    for(let i =0; i <this.state.duplicateCounter; i++) {
      arr.push({});
    }

    for (const [key, value] of Object.entries(this.state.form_inputs)) {
      for(let j = 1; j < incrementCounter; j++) {
        if(key.includes(`_${j}`)) {
          let name = key.split(`_${j}`)[0];
          arr[j-1][name] = value;
        }
      }
    }

    arr.map(async(data) => {
      const routeCallback = await createRoute(data);
      current_pick_up_data.pickups.map(async(value, key) => {
        const payload = {
          location_description: value.location_description, 
          location: value.location
        };

        const pickUpData = await createRoutePickup(routeCallback.id, payload);

        const routeDestination = await getRouteDestination(this.state.route.id, value.pick_up_id, true);
        await routeDestination.destinations.map(async (each) => {  
          let routeData = {
            location: each.location,
              location_description: each.location_description,
              fare:each.fare,
            itinerary: [{
              departure_time: routeDestination.itineraries[0].departure_time,
                time_of_day: routeDestination.itineraries[0].time_of_day
            }]
          };
          await createRouteDropOff(routeCallback.id, pickUpData.pick_up_id, routeData);
        })
      })
    })
    window.alert("Route ducpliaction successful");
  }


  render() {
    let { passengers } = this.state;
   let corporateArray = [];
   let monthsArray = [];
   this.state.businesses.map((each, key) => {
        corporateArray.push(
          <option value={each.id} key={key}>{each.corporate_name}</option>
        )    
    })

    this.state.months.map((each, key) => {
      if(key !==0) {
        monthsArray.push(
          <option value={key} key={key}>{each}</option>
        )  
      }
    })
    const user = this.props.user;
    const route = this.state.route ||
      this.props.route || (this.props.location.state && this.props.location.state.route);
    const corporateName = this.getCorporateNameByID(this.state.businesses, route.corporate_id);
    // if we have a user session we redirect away from sign up
    let items = [];
    if(this.state.duplicateCounter > 0) {
      for(let i = 1; i <= this.state.duplicateCounter; i++) {
          items.push(
              <div 
                className="col-md-5 tab__"
                key={i}
              >
                <div className="admin-user-summary">
                  <div className="user-data flex flex-row">
                      <div className="">
                        Route
                        <input
                          type="text"
                          className="dup_input_tag"
                          placeholder={route.pickup}
                          onChange={this.formChangeHandler}
                          key={i}
                          name={`pickup_${i}`}
                        />                            
                      </div>
                      <div className="">
                        Day of Week
                        <input
                          type="text"
                          className="dup_input_tag"
                          onChange={this.formChangeHandler}
                          placeholder={route.day_of_week}
                          key={i}
                          name={`day_of_week_${i}`}
                        />
                      </div>
                      <div className="">
                        Destination
                        <input
                            type="text"
                            className="dup_input_tag"
                            onChange={this.formChangeHandler}
                            placeholder={route.destination}
                            key={i}
                            name={`destination_${i}`}
                        />                      
                      </div>
                      <div className="">
                        Total Seats
                        <input
                          type="text"
                          className="dup_input_tag"
                          onChange={this.formChangeHandler}
                          placeholder={route.total_seats}
                          key={i}
                          name={`total_seats_${i}`}
                        />                                    
                      </div>
                  </div> 
                </div>
              </div> 
          )
        }
    }

    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!route) {
      return (
        <Redirect to={{
          pathname: '/trips/routes/',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Manage Route</h2>
          <div className="right-options" />
          <div className="page-usability-back col-sm-12 row">
            <button className="bvtton small grey"
              onClick={() => this.context.router.history.goBack()}
            >
              Go Back
						</button>
          </div>
        </div>
        <div className="col-md-5">
          <div className="admin-user-summary">
            <div className="form-sub-header">
              <h4>Route Details</h4>
            </div>
            <div className="user-data flex flex-row">
              <div className="">
                {route.pickup}
                <span>From</span>
              </div>
              <div className="">
                {route.destination}
                <span>To</span>
              </div>
              <div className="">
                {this.state.total_seats}
                <span>Total seats</span>
              </div>
            </div>

            <div className="form-sub-header">
              <h4>Add Pickup Point</h4>
            </div>

            <div className="form-wrapper">
              <Formik
                initialValues={{ location: '' }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  const res = this.state.busStops.find(item => item.location == values.location);

                  const routeData = {
                    location: res.location,
                    location_description: res.location_description,
                  };

                  this.setState({ loadingData: true });

                  createRoutePickup(this.state.route.id, routeData).then(() => {
                    this.props.setDashboardMessage('success', 'Pickup bustop added successfully');
                    this.setState({
                      loadingSuccessFull: true,
                    });
                    setTimeout(() => window.location.reload(1), 1000);
                  }).catch((err) => {
                    console.error('[Create Route Pickup] Error creating route', routeData, err.stack);
                    this.props.setDashboardMessage('error', 'Sorry an error occurred while adding the pickup');
                    this.setState({ loadingSuccessFull: false });
                  }).finally(() => {
                    this.setState({ loadingData: false });
                    actions.resetForm();
                  });
                }}
              >
                {props => {
                  const { handleSubmit, values, errors, touched, handleBlur, setFieldValue } = props;

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-element col-sm-12">
                          <span className="label">Location</span>
                          <Select
                            value={{ label: values.location, value: values.location }}
                            onChange={val => setFieldValue('location', val.value)}
                            options={this.state.busStops.map(item => ({ label: item.location, value: item.location }))}
                            required
                            name="location"
                            onBlur={handleBlur}
                          />
                          {errors['location'] && touched['location'] && <p style={{ color: 'red' }}>{errors['location']}</p>}
                        </div>
                      </div>
                      <div className="row"><br />
                        <div className="form-element col-sm-12">
                          <input
                            type="submit"
                            className="bvtton"
                            value={
                              this.state.loadingData ? 'Adding Pickup...' : 'Add Pickup'
                            }
                          />
                        </div><br />
                      </div>
                    </form>
                  )
                }}
              </Formik>

            </div><br /><br />

            <div className="form-sub-header">
              <h4>Pickup Points</h4>
            </div>

            {(this.state.pickups && this.state.pickups.length > 0 &&
              <ul style={{
                listStyle: 'none',
                margin: 0,
                padding: 0,
              }}
              >
                {this.state.pickups.map(pickup => (
                  <li className="single-route" key={pickup.id}>
                    <Link to={{
                      state: { pickup, route },
                      pathname: '/trips/routes/pickup/details',
                    }}
                    >
                      {pickup.location}
                      <span className="route-description">({pickup.location_description})</span>
                      <i className="fa fa-angle-right" />
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Route Actions</h3>
            </div>
            <ul>
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showIncreaseSeat: true, showDecreaseSeat: false }, () => {
                    scrollToEl('increase-seats');
                  });
                }}
                >Increase seats
									<i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showIncreaseSeat: false, showDecreaseSeat: true }, () => {
                    scrollToEl('decrease-seats');
                  });
                }}
                >Decrease seats
									<i className="fa fa-angle-right" />
                </a>
              </li>
              {route.status === 0 ?
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.unSuspendRoute(); }}>
                    Unsuspend Route <i className="fa fa-angle-right" />
                  </a>
                </li>
                :
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.suspendRoute(); }}>
                    Suspend Route <i className="fa fa-angle-right" />
                  </a>
                </li>
              }
              <li>
                <Link to={{ pathname: '/trips/routes/edit', state: { route } }}>Edit Route
									<i className="fa fa-angle-right" />
                </Link>
              </li>
              <li>
                <Link to="" onClick={e => this.deleteRoute(e)}>Delete Route
									<i className="fa fa-angle-right" />
                </Link>
              </li>
              <li>
                <Link to="" onClick={(e) =>
                  {
                    this.duplicateRoute(e)
                  }
                }>Duplicate Route
									<i className="fa fa-angle-right" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Drivers on this Route</h3>
            </div>
            <ul>
              {
                Array.isArray(this.state.routeDrivers) 
                  && this.state.routeDrivers.map((info, key)=> (
                <li
                  key={key}
                >
                  <Link 
                    to=""
                    onClick={(e) => (this.handleRowClick(e, info))}
                  >
                    {info.fname + " " + info.lname} 
                  </Link>
                </li>
                ))
              }
            </ul>
          </div>
          <div className="map-image" style={{ border: 'none' }}>
            <RoutesMap
              polyline={route.overview_polyline.points}
              pickup_coordinate={route.pickup_coordinate}
              destination_coordinate={route.destination_coordinate}
              size="413x340"
            />
          </div>
        </div>
        <div className="col-md-7">
          {this.state.showIncreaseSeat &&
            <div className="module" id="increase-seats">
              <div className="module-header">
                <h3>Increase route seats</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.updateSeats(e, 'credit')}>
                      <div className="form-element">
                        <span className="label_rd">Route Days</span>
                        {
                          Array.isArray(this.state.routeDays) && this.state.routeDays.map((info, key) => (

                            <div className="route-days">
                              <div className="route_names"><b>{days[key]}</b></div>
                              <p className="route_label sn_">Available seat number </p>
                              <input 
                                className="route_label" 
                                type="number"
                                key={key}
                                placeholder={info.seats_available}
                                onChange={this.formChangeHandler}
                                name={`availableSeats_${info.day_id}`}
                                min="1"
                                />
                              <button 
                                type="submit" 
                                class="btn btn-success update_seats_btn"
                                onClick={(e) => {
                                  if (window.confirm("Do you really want to update seat available for this day?")) { 
                                    this.updateSeats(e, 'credit');
                                  }
                                }}
                              >
                                Add
                              </button>
                            </div>
                          ))
                        }
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          }
          {this.state.showDecreaseSeat &&
            <div className="module" id="decrease-seats">
              <div className="module-header">
                <h3>Decrease route seats</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form>
                      <div className="form-element">
                        <span className="label_rd">Route Days</span>
                        {
                          Array.isArray(this.state.routeDays) && this.state.routeDays.map((info, key) => (
                            <div className="route-days">
                              <div className="route_names"><b>{days[key]}</b></div>
                              <p className="route_label sn_">Available seat number </p>
                              <input 
                                className="route_label" 
                                type="number"
                                key={key}
                                placeholder={info.seats_available}
                                onChange={this.formChangeHandler}
                                name={`availableSeats_${info.day_id}`}
                                min="1"
                                />
                              <button 
                                type="submit" 
                                class="btn btn-success update_seats_btn"
                                onClick={(e) => {
                                  if (window.confirm("Do you really want to update seat available for this day?")) { 
                                    this.updateSeats(e, 'debit');
                                  }
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          ))
                        }
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          }

          <Formik
            initialValues={{ title: '', description: '' }}
            onSubmit={(values, actions) => {
              this.setState({ loading: true });

              const user_ids = this.state.users.map(item => item.user_id);
              
              NotifyUsers({
                user_ids,
                title: values.title,
                body: values.description,
              }).then(res => {
                this.setState({ loading: false, success: true });
                this.props.setDashboardMessage("success", "All users on this trip were successfully notified");

              }).catch(err => {
                console.log(err);
                this.props.setDashboardMessage("error", 'Could not notify users, please try again');

                this.setState({ loading: false, error: true });
              });
            }}
          >
            {props => {
              const { values, handleChange, handleSubmit } = props;

              return (
                <div className="module" id="increase-seats">
                  <div className="module-header">
                    <h3>Notify Users (In App Push Notifications)</h3>
                  </div>
                  <div className="module-wrapper">
                    <div className="module-content module-form-wrapper">
                      <div className="form-wrapper">
                        <form onSubmit={handleSubmit}>
                          <div className="form-element">
                            <span className="label">Message Title</span>
                            <input
                              required
                              value={values.title}
                              name="title"
                              className="form-text"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="form-element">
                            <span className="label">Message Description</span>
                            <textarea
                              required
                              value={values.description}
                              name="description"
                              className="form-text"
                              onChange={handleChange}

                            />
                          </div>

                          <div className="form-element">
                            <input type="submit" className="bvtton" value={this.state.loading ? 'Sending' : 'Send'} />
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="module-footer" />
                  </div>
                </div>
              )
            }}
          </Formik>
          <div>
            <div className="module passenger-list" id="target_table">
              <div className="module-wrapper false">
                <div className="module-content has-table has-users">
                <div className="form-sub-header">
                  <h4>Passengers</h4>
                </div> 
                <div className="_row_div">
                  <p className="filters__">Filter By Company :</p>
                  <div className="corporates_div">
                    <select 
                      name="corporates" 
                      className="select_corporate"
                      onChange={this.handleDropdownChange}
                    >
                      <option value="" key=""> All </option>
                      {corporateArray}
                    </select>                                  
                  </div>
                  <button className="filter_btn"
                    onClick={(e) => {
                      e.preventDefault();
                       this.applyCompanyFilters(this.state.route.id, this.state.currentOrgID);
                    }}
                  >Apply</button>
                </div>
                  <div className="module-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Route Day</th>
                          <th>Total Passengers</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.bookingsArray.map((info, key) => (
                            <tr key={key}>
                              <td key={key}>
                              <NavLink to={{pathname:`/trips/routes/bookings`, state: {data: this.state.dateWeek[key] !== new Date().toISOString().split('T')[0] ? info.data : passengers, date: this.state.dateWeek[key]}}} key={key}>
                                <li
                                  key={key}
                                >
                                    {`${days[key]}`} 
                                </li>
                              </NavLink>
                              </td>
                              <td>{this.state.dateWeek[key] !== new Date().toISOString().split('T')[0] ? info.passengers : passengers.length}</td>
                              <td>{this.state.dateWeek[key]}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="module passenger-list" id="target_table">
              <div className="module-wrapper false">
                <div className="module-content has-table has-users">
                  <div className="module-table">
                    <div className="admin-user-summary">
                      <h4>Reports</h4>
                      <ul className="_top_ul">
                        <li>
                          <a 
                            href="#" onClick={(e) => this.getWeekBookingReport(e)}
                          >download this week's report</a>
                        </li>
                        <li>
                          <a href="#" onClick={(e) => this.getMonthBookingReport(e)}> download this month's report</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="module passenger-list" id="target_table">
              <div className="module-wrapper false">
                <div className="module-content has-table has-users">
                  <div className="module-table">
                    <div className="admin-user-summary">
                      <h4>Business Booking Report</h4>
                      <div className="corporates_div">
                        <label htmlFor="corporates" name="total_seats">Select Organization</label>
                        <select 
                          name="corporates" 
                          className="select_corporate"
                          onChange={this.handleDropdownChange}
                        >
                          <option value="" key=""> Select Organization </option>
                          {corporateArray}
                        </select>                                  
                      </div>
                      <div className="_row_div">
                        <b><span>Select Month</span></b>
                        <select 
                            name="months" 
                            className="select_corporate"
                            onChange={this.handleMonthsDropdownChange}
                        >
                          <option value="0" key="0"> {this.state.months[0]}</option>
                          {monthsArray}
                        </select>
                      </div>
                      <br/>
                      <input 
                        type="submit" 
                        className="bvtton generate_" 
                        value="generate" 
                        style={{ height: '30px', width: '90px'}}
                        onClick={(e) => {
                          this.getCorporateBookingReport(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              this.state.duplicateRoute && (
                <div className="duplicate_field" id="duplicate_field">
                  <div className="dup_div">
                    <p className="dup_header">
                      Duplicate Routes : 
                    </p>
                    <span className="details_">How many fields?</span>
                    <form>
                      <input 
                        type="number" 
                        className="num__" 
                        name="counter"
                        min="1" 
                        max="3"
                        onChange={this.handleCounter}
                      />
                    </form>    
                  </div>
                </div>
              )
            }

            {
              this.state.duplicateCounter > 0 && 
              <div className="form_div">
                <form 
                  className="dup_form"
                  onSubmit={(event) => this.submitForm(event)}    
                >
                  {items}
                  <button
                        type="submit"
                        className="btn btn-success btn__" 
                        name="submit" 
                  >
                      Create
                  </button>
                </form>
              </div>
            }
          </div>
        </div>
      </div >
    );
  }
}


RouteDetails.propTypes = {
  user: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

RouteDetails.defaultProps = {
  route: null,
  user: null,
  pickup: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

RouteDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
