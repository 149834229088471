/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import {getPromoCodeUsages} from "../models/promotions";

import "../styles/spinner.css";
const moment = require("moment");

export default class PromotionsList extends Component {
  constructor() {
    super();
    this.config = {
      page_size: 20,
      length_menu: [10, 20, 50],
      show_first: false,
      show_last: false,
      show_length_menu: false,
      button: {
        excel: true,
        print: true
      }
    };
    this.state = {
        promotions: {meta: {}, models: []},
      searchTerm: "",
      loadingData: true,
      loadingSuccessFull: false,
      searchDone: false
    };

    this.history = createHistory();
    this.fetchAllPromotions = this.fetchAllPromotions.bind(this);
  }

  componentDidMount() {
    const query = this.props.query || "";
    if (query.length >= 3) return this.executeSearch(query);
    this.fetchAllPromotions();
  }

  componentWillReceiveProps(nextProps) {
    const query = nextProps.query || "";
    if (this.state.searchTerm === query) return false;
    return this.fetchAllPromotions();
  }

  /**
   * Gets all users when no search query is issued.
   */
  fetchAllPromotions() {
    getPromoCodeUsages(this.props.promotionId)
      .then(data => {
        this.setState({
          promotions: data,
          loadingSuccessFull: true,
          searchTerm: ""
        });
      })
      .catch(e => {
        console.error("[User List] Error fetching promotions data", e.stack);
        this.setState({ loadingSuccessFull: false });
      })
      .finally(() => {
        this.setState({ loadingData: false });
      });
  }


  render() {
    const isSummary = this.props.summary;
    const nameOnly = this.props.nameOnly;

    return (
      <div
        className={`module-wrapper ${this.state.loadingData &&
          "loading"}`}
      >
        <div className="module-content has-table has-users">
          <div className="module-table">
            <table>
              {!nameOnly && (
                <thead>
                  <tr>
                  <th>User Type</th>
                    <th
                      width="25%"
                      style={{
                        textTransform: "capitalize"
                      }}
                    >
                      User
                    </th>
                    <th>Applied At</th>
                    <th className="align-right">Status</th>
                  </tr>
                </thead>
              )}
              {this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <tbody>
                    <tr
                      className="ride-data"
                      style={{ display: "table-row" }}
                    >
                      <td colSpan="3">
                        <div className="ride-data-row">
                          <div>
                            Loading
                            {this.props.type}
                            data...
                                                        <div className="spinner" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              {!this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <tbody>
                    <tr
                      className="ride-data"
                      style={{ display: "table-row" }}
                    >
                      <td colSpan="3">
                        <div className="ride-data-row">
                          <div>
                            Sorry we could not load
                                                        any {this.props.type}{" "}
                            data.
                                                    </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              {!this.state.loadingData &&
                this.state.loadingSuccessFull && (
                  <tbody>
                    {this.state.promotions.models.map(usage => (
                      <tr
                        key={usage.id}
                      >
                       <td>{usage.user_type}</td>
                       <td>
                       {usage[usage.user_type].lname + ' '+usage[usage.user_type].fname}
                       </td>
                       
                       <td>{usage.created_at ? moment(usage.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                       <td className="small">
                            { usage.is_consumed === 1
                              ? "Completed"
                              : "Pending"
                              }
                          </td>
                      </tr>
                    ))}
                  </tbody>
                )}
            </table>
          </div>
        </div>
    
      </div>
    );
  }
}

PromotionsList.propTypes = {
  promotionId: PropTypes.number,
  type: PropTypes.string,
  query: PropTypes.string,
  nameOnly: PropTypes.bool,
  summary: PropTypes.bool,
  setDashboardMessage: PropTypes.func
};

PromotionsList.defaultProps = {
    promotionId: 0,
  type: "users",
  query: "",
  nameOnly: false,
  summary: false,
  setDashboardMessage: () => { }
};

PromotionsList.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};
