import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { getAllRoutes, searchRoutes } from "../models/routes";

import "../styles/spinner.css";

export default class RouteList extends Component {
  constructor() {
    super();
    this.state = {
      routes: [],
      routesDup: [],
      searchTerm: "",
      loadingData: true,
      loadingSuccessFull: false
    };
  }

  componentDidMount() {
    console.log(this.props);

    const query = this.props.query || "";
    if (query.length > 1) return this.executeSearch(query);
    this.fetchAllRoutes();
  }

  componentWillReceiveProps(nextProps) {
    const query = nextProps.query || "";
    const filter = nextProps.filter || "";
    const { routesDup, } = this.state;

    if (filter != "") {
      let filteredRoutes = routesDup.filter(
        each => each.status == parseInt(filter)
      );
      this.setState({ routes: filteredRoutes });
    } else {
      this.setState({ routes: routesDup });
    }

    if (this.state.searchTerm === query) {
      return false;
    }

    if (query === "") return this.fetchAllRoutes();

    if (query.length >= 1) {
      this.props.reset();
      this.executeSearch(query);
    }
  }

  fetchAllRoutes() {
    getAllRoutes()
      .then(data => {
        if(data && data.length) {
          data = data.map(
            ($) => {
              return {
                ...$,
                pickup: ($.route_code) ? `${$.pickup} ${$.route_code}` : $.pickup
              } 
            }
          )
        }

        this.setState({
          routes: this.props.summary ? data.slice(0, 6) : data,
          routesDup: this.props.summary ? data.slice(0, 6) : data,
          loadingData: false,
          loadingSuccessFull: true
        });
      })
      .catch(e => {
        console.error(
          "[Route List] Error fetching routes data",
          e.stack
        );
        this.setState({
          loadingData: false,
          loadingSuccessFull: false
        });
      });
  }

  executeSearch(query) {
    this.setState({
      searchTerm: query,
      loadingData: true,
      loadingSuccessFull: false
    });

    return searchRoutes(query, true)
      .then(data => {
        // If no users reset state
        if (!Array.isArray(data) || data.length < 1) {
          return this.setState({
            loadingData: false,
            loadingSuccessFull: false
          });
        }

        this.setState({
          routes: data,
          routesDup: data,
          loadingData: false,
          loadingSuccessFull: true
        });
      })
      .catch(err => {
        console.error(
          "[Route List] Error searching for route",
          query,
          err.stack
        );
        this.setState({
          loadingData: false,
          loadingSuccessFull: false
        });
        this.props.setDashboardMessage(
          "error",
          "Sorry we could not find that route"
        );
      });
  }

  render() {
    let statusActive = {
      backgroundColor: "green",
      color: "white",
      borderRadius: "5px",
      fontSize: ".7em",
      padding: ".5em"
    };

    let statusInactive = {
      backgroundColor: "grey",
      color: "white",
      borderRadius: "5px",
      fontSize: ".7em",
      padding: ".5em"
    };

    return (
      <div>
        <div
          className={`module-wrapper ${this.state.loadingData &&
            "loading"}`}
        >
          <div className="module-content no-padding">
            <div className="">
              {this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <div className="ride-data-row">
                    Loading routes...
                                        <div className="spinner" />
                  </div>
                )}
              {!this.state.loadingData &&
                !this.state.loadingSuccessFull && (
                  <div className="ride-data-row">
                    Sorry we could not load any data for
                    routes.
                                    </div>
                )}
              {!this.state.loadingData &&
                this.state.loadingSuccessFull &&
                this.state.routes.map(route => (
                  <div
                    className="single-route"
                    key={route.id}
                  >
                    <Link
                      to={{
                        state: { route },
                        pathname:
                          "/trips/routes/details/"
                      }}
                    >
                      <div className="direction">
                        <div>
                          <span className="indicator from" />{" "}
                          {route.pickup}
                        </div>
                        <div>
                          <span className="indicator to" />{" "}
                          {route.destination}
                        </div>
                      </div>
                      <div className="meta">
                        {route.route_code && (
                          <div
                            className="time pre-icon sh-bus"
                            title="Route Code"
                          >
                            {route.route_code}
                          </div>
                        )}
                        <div
                          className="time pre-icon sh-clock"
                          title="Duration"
                        >
                          {route.duration}
                        </div>
                        <div
                          className="pre-icon sh-stops"
                          title="Route Distance"
                        >
                          {route.distance}
                        </div>
                        {route.status == 1 ? (
                          <span style={statusActive}>
                            Active
                          </span>
                        ) : (
                            <span style={statusInactive}>
                              Inactive
                            </span>
                          )}
                      </div>

                      <i className="fa fa-angle-right" />
                    </Link>
                  </div>
                ))}
            </div>
          </div>
          <div className="module-footer" />
        </div>
      </div>
    );
  }
}

RouteList.propTypes = {
  query: PropTypes.string,
  summary: PropTypes.bool,
  setDashboardMessage: PropTypes.func
};

RouteList.defaultProps = {
  query: null,
  summary: false,
  setDashboardMessage: () => { }
};
