import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { deleteService, updateService } from '../../models/ontrip';
import { formatMoney } from '../../libs/utils';

import '../../styles/spinner.css';

export default class OntripServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      service: null,
      loading: false,
    };
  }

  componentWillMount() {
    const service = this.props.location.state.service || this.props.service;
    this.setState({ service });
  }

  /**
   * Updates an on-trip service
   * @param {Event} e - the event that triggered the update
   */
  updateService(e) {
    e.preventDefault();
    const serviceData = {
      name: this.name.value,
      price: this.price.value,
      status: parseInt(this.status.value, 10),
      quantity: this.quantity.value,
      image: this.image.value ? this.image.value : 'NULL',
    };
    const requiredFields = ['name', 'price', 'status', 'quantity'];

    this.setState({ loading: true });

    requiredFields.forEach((field) => {
      if (!serviceData[field]) {
        this.props.setDashboardMessage('error', 'Some required fields are missing');
        return false;
      }
    });

    updateService(this.state.service.id, serviceData)
      .then((service) => {
        this.setState({
          service,
          loading: false,
        });
        this.props.setDashboardMessage('success', 'Service information updated.');
        this.image.value = '';
      }).catch((err) => {
        this.setState({ loading: false });
        this.props.setDashboardMessage('error', 'Sorry we could not update service information');
        console.error('[Edit OnTripService]', 'Error updating service', serviceData, err, err.stack);
      });
  }

  /**
   * Deactivates a service
   */
  suspendService() {
    const serviceData = this.state.service || this.props.location.state.service;
    const data = { status: 0 };

    this.setState({
      loading: true,
    });
    updateService(serviceData.id, data).then((service) => {
      this.setState({
        service,
      });
      this.props.setDashboardMessage('success', 'Service suspended');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while suspending service');
      console.error('[Service Details] Error suspending service', e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  /**
   * Activates a user
   */
  unSuspendService() {
    const serviceData = this.state.service || this.props.location.state.service;
    const data = { status: 1 };

    this.setState({
      loading: true,
    });
    updateService(serviceData.id, data).then((service) => {
      this.setState({
        service,
      });
      this.props.setDashboardMessage('success', 'Service unsuspended');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while unsuspending service');
      console.error('[Service Details] Error unsuspending service', e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  /**
   * Deletes a service
   * @param {Number} id - the id of the service to be deleted.
   */
  deleteService(id) {
    const accept = confirm('Are you sure you want to delete this service?'); // eslint-disable-line
    if (accept) {
      this.setState({
        loading: true,
      });

      deleteService(id).then(() => {
        this.context.router.history.goBack();
        this.props.setDashboardMessage('success', 'Service deleted');
      }).catch((e) => {
        this.setState({
          loading: false,
        });
        this.props.setDashboardMessage('error', 'Sorry an error occurred while deleting service');
        console.error('[Service Details] Error deleting service', id, e.stack);
      });
    }
  }

  render() {
    const user = this.props.user;
    const service = this.state.service || this.props.location.state.service;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trip-services/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!service) {
      return (
        <Redirect to={{
          pathname: '/trip-services',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{`${service.name}'s Details`}</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>

        <div className="col-md-4">
          <div className={`admin-user-summary ${this.state.loading && 'loading'}`}>
            {this.state.loading && <div className="spinner" />}
            <div className="user-picture">
              <div className="image css-img-object" style={{ backgroundImage: `url(${service.image})` }} />
            </div>
            <h3>{service.name}</h3>
            <div className="user-data flex flex-row">
              <div>
                {(parseInt(service.status, 10) === 1) ? 'Active' : 'Non-active'}
                <span>Status</span>
              </div>
            </div>
            <div className="bi-stat">
              <div className="user-rides-count">
                {formatMoney(service.quantity)}
                <span>Quantity</span>
              </div>
              <div className="user-account-balance">
                &#x20A6;{formatMoney(service.price)}
                <span>Price</span>
              </div>
            </div>
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Service Actions</h3>
            </div>
            <ul>
              <li>
                <a
                  href=""
                  onClick={(e) => { e.preventDefault(); this.deleteService(service.id); }}
                  style={{ color: 'red', fontWeight: 'bold' }}
                >
                  Delete Service<i className="fa fa-angle-right" />
                </a>
              </li>
              {service.status === '0' ?
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.unSuspendService(); }}>
                    Unsuspend Service <i className="fa fa-angle-right" />
                  </a>
                </li>
                :
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.suspendService(); }}>
                    Suspend Service <i className="fa fa-angle-right" />
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>

        <div className="col-sm-8">
          <div className="module">
            <div className="module-header" />
            <div className="module-wrapper">
              <div className="module-content module-form-wrapper">
                <div className="form-wrapper">
                  <form onSubmit={e => this.updateService(e)}>
                    <div className="row">
                      <div className="form-element col-sm-12">
                        <span className="label">Name</span>
                        <input
                          ref={(input) => { this.name = input; }}
                          type="text"
                          className="form-text"
                          defaultValue={service.name}
                          required
                        />
                      </div>
                      <div className="form-element col-sm-6">
                        <span className="label">Price</span>
                        <input
                          ref={(input) => { this.price = input; }}
                          type="text"
                          className="form-text"
                          defaultValue={service.price}
                          required
                        />
                      </div>
                      <div className="form-element col-md-6">
                        <span className="label">Status</span>
                        <div className="form-select">
                          <select required ref={(input) => { this.status = input; }}>
                            <option value="1">Active</option>
                            <option value="0">Disabled</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-element col-sm-6">
                        <span className="label">Quantity</span>
                        <input
                          ref={(input) => { this.quantity = input; }}
                          type="text"
                          className="form-text"
                          defaultValue={service.quantity}
                          required
                        />
                      </div>
                      <div className="form-element col-sm-6">
                        <span className="label">Add Product/Service image</span>
                        <input
                          type="file"
                          accept=".png, .jpeg, .jpg"
                          className="form-text"
                          ref={(input) => { this.image = input; }}
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="form-element">
                      <input type="submit" className="bvtton"
                        value={`${this.state.loading ? 'Updating' : 'Update'} Product/Service`}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="module-footer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OntripServiceDetails.propTypes = {
  user: PropTypes.object,
  service: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

OntripServiceDetails.defaultProps = {
  user: null,
  service: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

OntripServiceDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
