import { del, get, post, patch } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getAllAdmins = (noCache) => {
  const fetchAllAdmins = get('/v1/admins');

  if (noCache) return fetchAllAdmins;

  return cacheClient.get('/v1/admins')
    .catch((err) => {
      console.error('[admins model] Error fetching trips from cache', err.stack);
      return fetchAllAdmins;
    })
    .then((data) => {
      if (data) return data;
      return fetchAllAdmins;
    });
};

export const searchAdmins = name => post('/v1/admins/search', { name });

export const createAdmin = admin => post('/v1/admins', admin);

export const updateAdmins = (id, data) => patch(`/v1/admins/${id}`, data);

export const deleteAdmin = id => del(`/v1/admins/${id}`);
