/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import createHistory from 'history/createBrowserHistory';

import { getUserTrips } from '../models/users';
import { getActiveTrips } from '../models/trips';
import { endDriverTrip, getDriverTrips } from '../models/drivers';

import '../styles/spinner.css';
import moment from 'moment';

const dayFormat = 'YYYY-MM-DD'

export default class ActiveTripsTable extends Component {
    constructor() {
        super();
        this.state = {
            allTrips: null,
            loadingData: true,
            loadingSuccessFull: false,
            loadingAll: false,
        };

        this.history = createHistory();
        this.fetchActiveTrips = this.fetchActiveTrips.bind(this);
    }

    componentDidMount() {
        return this.fetchActiveTrips();
    }

    fetchActiveTrips() {
        this.setState({
            loadingData: true,
            loadingSuccessFull: false,
        });
        getActiveTrips().then((trips) => {
            const today = moment().format(dayFormat);
            trips = trips.data || trips;
            trips = trips.filter(trip => moment(moment(trip.start_trip).format(dayFormat)).isSameOrAfter(today))

            this.setState({
                allTrips: trips,
                loadingData: false,
                loadingSuccessFull: true,
                loadingAll: true,
            });
        }).catch((e) => {
            console.error('[Active Trips] Error fetching data', e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    endActiveTrip = async (trip) => {
        const { setDashboardMessage } = this.props;
        
        if(window.confirm('Are you sure you want to end this trip')) {
            try {
                const res = await endDriverTrip({
                    route_id: trip.route_id,
                    destination_coordinate: trip.destination_coordinate ? trip.destination_coordinate : '6.5135466666661115, 3.3668908333333337'
                }, trip.id);
    
                if(res) {
                    const activeTrips = this.state.allTrips;
    
                    const endedTrip = activeTrips.find((t, index) => {
                        if(t.id === trip.id) {
                            activeTrips.splice(index, 1);
                            return t;
                        }
                        return false;
                    }); 
    
                    if(endedTrip) {
                        setDashboardMessage('success', 'Trip ended successfully');
                        this.setState({allTrips: activeTrips});
                    }
                }
                
            } catch (err) {
                console.log('Error::', err);
                setDashboardMessage('error', 'Error ending trip');
            }
        }
    }

    render() {
        let trips = this.state.allTrips || null;
        if (this.state.loadingAll) {
            trips = this.state.allTrips || null;
        }

        return (
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                <div className="module-content has-table has-users">
                    <div className="module-table">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Duration</th>
                                    <th>Driver's Name</th>
                                    <th className="align-right">Action</th>
                                </tr>
                            </thead>
                            {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                                <tbody>
                                    <tr className="ride-data" style={{ display: 'table-row' }}>
                                        <td colSpan="6">
                                            <div className="ride-data-row">
                                                <div>
                                                    Loading Active Trip...
                                                <div className="spinner" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                            {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                                <tbody>
                                    <tr className="ride-data" style={{ display: 'table-row' }}>
                                        <td colSpan="6">
                                            <div className="ride-data-row">
                                                <div>
                                                    Sorry you do not have any trip.
                                            </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }

                            {(!this.state.loadingData && this.state.loadingAll) &&
                                <tbody>
                                    {(trips && trips.length > 0) && trips.map(trip => (
                                        <tr key={trip.id}>
                                            <td>
                                                <Moment format="h:mm A">{trip.created_at}</Moment>
                                                <br /><Moment format="MMM Do YY">{trip.created_at}</Moment>
                                            </td>
                                            <td>{trip.route.pickup}</td>
                                            <td>{trip.route.destination}</td>
                                            <td>{trip.route.duration}</td>
                                            <td>{trip.driver.fname}{" "}{trip.driver.lname}</td>
                                            <td className="align-right money">
                                                <button className="btn btn-danger" onClick={() => this.endActiveTrip(trip)}>
                                                    End Trip <i className="fa fa-ban" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            }

                        </table>
                    </div>
                </div>
            </div>
        );
    }
}


ActiveTripsTable.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
    }),
    setDashboardMessage: PropTypes.func,
};

ActiveTripsTable.defaultProps = {
    user: null,
    setDashboardMessage: () => { },
};
ActiveTripsTable.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired,
        }).isRequired,
        staticContext: PropTypes.object,
    }).isRequired,
};
