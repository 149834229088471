import { del, get, post, patch } from '../libs/requestClient';

export const getAllServices = () => get('/v1/services?status=all');

export const getService = id => get(`/v1/services/${id}`);

export const searchService = query => post('/v1/services/search', { search_term: query });

export const createService = data => post('/v1/services/', data);

export const updateService = (id, data) => patch(`/v1/services/${id}`, data);

export const deleteService = id => del(`/v1/services/${id}`);

