import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import RideHistory from '../../components/rideHistory';
import TopUpWallet from '../../components/topUpWallet';
import TransactionHistory from '../../components/transactionHistory';

import { getUserTrips, getUserWallet } from '../../models/users';
import { getBusinessTripsGraph, updateBusiness, getBusinessStaff, getAllBusinesses, uploadFile } from '../../models/business';
import { formatMoney, initBarChart, scrollToEl } from '../../libs/utils';

import '../../styles/spinner.css';

export default class BusinessDetails extends Component {
  constructor() {
    super();
    this.state = {
      upload_btn_clicked: false,
      upload_action: null,
      trips: null,
      upload: false,
      file: null,
      filename: null,
      file_sent: false,
      error_rows: null,
      success_rows: null,
      loading: true,
      percentage: 0,
      wallet: null,
      fundWallet: false,
      businessDetails: null,
      industries: ['Government', 'Telecommunication', 'Legal services', 'Construction', 'Food processing',
        'Consulting', 'Gambling', 'Retail sales', 'Franchising', 'Real estate', 'Education', 'Pharmaceuticals',
        'Hospitality/Tourism', 'Mass media', 'Healthcare/hospitals', 'Public health', 'Information technology',
        'Waste disposal', 'Banking', 'Insurance', 'Financial services', 'FMCG'],
    };

    this.updateBusiness = this.updateBusiness.bind(this);
  }

  componentDidMount() {
    const { businessDetails, refetchBusiness } = this.props.location.state;

    const userTips = getUserTrips(businessDetails.id);
    const userWallet = getUserWallet(businessDetails.id);
    const tripsGraph = getBusinessTripsGraph(businessDetails.id);
    const businessStaff = getBusinessStaff(businessDetails.id);

    businessStaff
      .then(res => {

        this.setState({
          businessStaff: res.data
        });
      })
      .catch(err => {
        console.error('[Business Staff] Error fetching business staff',
          businessDetails.id, err);
      })

    Promise.all([userWallet, userTips, tripsGraph])
      .then((data) => {
        this.setState({
          loading: false,
          trips: data[1],
          wallet: data[0].wallet,
          businessDetails,
        });

        this.plotGraph(data[2]);
      }).catch((err) => {
        this.setState({
          businessDetails,
          loading: false,
        });

        console.error('[Business Details] Error fetching user wallet for business',
          businessDetails.id, err);
      });
  }

  fetchAllBusinesses() {
    getAllBusinesses()
      .then((businesses) => {
        // this.setState({ businesses });
      }).catch((err) => {
        console.error('[Business Insights] Error fetching business data', err, err.stack);
        this.props.setDashboardMessage('error', 'Sorry could not fetch businesses information');
      });
  }

  updateBusiness(e) {
    e.preventDefault();

    // return console.log(this.fname.value);
    if (!this.fname.value ||
      !this.lname.value ||
      !this.person_email.value ||
      !this.person_phone.value ||
      !this.email.value ||
      !this.corporate_name.value ||
      !this.corporate_phone.value ||
      !this.corporate_address.value ||
      !this.industry.value) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    const data = {
      fname: this.fname.value,
      lname: this.lname.value,
      person_email: this.person_email.value,
      person_phone: this.person_phone.value,
      email: this.email.value,
      corporate_name: this.corporate_name.value,
      corporate_phone: this.corporate_phone.value,
      corporate_address: this.corporate_address.value,
      industry: this.industry.value,
    };

    if (this.password.value) {
      Object.assign(data, { password: this.password.value })
    }

    this.setState({ loading: true });

    updateBusiness(this.state.businessDetails.id, data).then((res) => {
      this.props.setDashboardMessage('success', 'Business updated succesfully');

      this.setState({
        businessDetails: Object.assign(this.state.businessDetails, res),
        loading: false,
        updateBusiness: false,
      });
      alert('Update successful')
      window.location.href = '/business';
    }).catch((err) => {
      console.error('[Update Business] Error updating business', data, err.stack);
      this.props.setDashboardMessage('error', 'Sorry an error occured while updating the business');
      this.setState({ loading: false });
    });
  }

  plotGraph(data) {
    const userData = [];
    data.forEach((datum) => {
      userData.push([datum.month, datum.total]);
    });

    const Users30Day = [{
      data: userData,
      label: 'Users',
    }];

    initBarChart('.linechart.30day-transaction', Users30Day);
  }


  setUploadVisible() {
    this.setState({
      upload: true,
    });
    scrollToEl('file_sec');
  }

  handleSubmit(e) {
    let data = new FormData();
    let file = this.state.file;
    data.append("file", file);
    data.append("corporate_id", this.state.businessDetails.id);
    data.append("action", this.state.upload_action);

    const options = {
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        this.setState({percentage: percent})
      }
    }

    this.setState({
      loading: true,
    });

    uploadFile(data, options)
      .then((res) => {
        this.setState({ 
          loading: false, 
          success_rows: res.success_rows, 
          error_rows: res.error_rows, 
          file_sent: true
        });
        this.props.setDashboardMessage('success', "Successfully updated Company user data");
      })
      .catch((err) => {
        let message;
        if(err['error'].code === "CSVLimitPassed") {
          message = "Maximum rows exceeded. Please ensure record is not more than 1000"
        }
        else {
          message = 'Sorry an error occured while uploading file';
        }
        this.props.setDashboardMessage('error', message);
        this.setState({ loading: false });
      })
  }

  fileHandler(e) {    
    let file = e.target.files[0];
    if(e.target.files[0].type !== "text/csv") {
      scrollToEl('main');
      this.props.setDashboardMessage('error', 'Only CSV documents are allowed');
      return false
    }
    this.setState({
      file: file,
      filename: e.target.files[0].name
    });
  }

  suspendUser() {
    const user = this.props.location.state.businessDetails;
    const data = { active: 0 };

    this.setState({
      loading: true,
    });

    updateBusiness(user.id, data).then((businessDetails) => {
      this.setState({
        loading: false,
        businessDetails,
      });
      this.props.setDashboardMessage('success', 'User account suspended');
    }).catch((e) => {
      this.setState({
        loading: false,
      });
      this.props.setDashboardMessage('error', 'Sorry an error occured while suspending user');
      console.error('[User Details] Error suspending user ', user.id, e.stack);
    });
  }

  unsuspendUser() {
    const user = this.props.location.state.businessDetails;
    const data = { active: 1 };

    this.setState({
      loading: true,
    });

    updateBusiness(user.id, data).then((businessDetails) => {
      this.setState({
        loading: false,
        businessDetails,
      });
      this.props.setDashboardMessage('success', 'User account unsuspended');
    }).catch((e) => {
      this.setState({
        loading: false,
      });
      this.props.setDashboardMessage('error', 'Sorry an error occured while unsuspending user');
      console.error('[User Details] Error unsuspending user ', user.id, e.stack);
    });
  }

  render() {
    const user = this.props.user;
    const businessDetails = this.state.businessDetails || this.props.location.state.businessDetails;

    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/business/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!businessDetails) {
      return (
        <Redirect to={{
          pathname: '/users/manage',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper" id="main">
        <div className="sub-page-header col-sm-12">
          <h2 id="corporate_details">{`${businessDetails.corporate_name}'s Details`}</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>
        <div className="clearfix" />

        <div className="col-md-4">
          <div className={`admin-user-summary ${this.state.loading && 'loading'}`}>
            {this.state.loading && <div className="spinner" />}
            <div className="user-picture">
              <div className="image css-img-object"
                style={{ backgroundImage: `url(${user.avatar || '/assets/img/default-business.png'})` }}
              />
            </div>
            <h3>{businessDetails.corporate_name}</h3>
            <div className="user-data flex flex-row">
              <div>
                {businessDetails.corporate_phone}
                <span>Phone Number</span>
              </div>
              <div>
                {businessDetails.email}
                <span>E-mail Address</span>
              </div>
              <div>
                {businessDetails.corporate_address}
                <span>Office Address</span>
              </div>
            </div>
            <div className="bi-stat">
              <div className="user-rides-count">
                {this.state.trips ? `${this.state.trips.trips.length}` : '0'}
                <span>rides completed</span>
              </div>
              <div className="user-account-balance">
                {this.state.wallet ?
                  <div>&#x20A6;{formatMoney(this.state.wallet.amount)}</div> :
                  'Unavaliable'
                }
                <span>account balance</span>
              </div>
            </div>
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Account Actions</h3>
            </div>
            <ul>
              <li>
                <a href="" onClick={(e) => { e.preventDefault(); this.setState({ fundWallet: true }); }}>
                  Top-up Business Wallet <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href=""
                  onClick={(e) => { e.preventDefault(); this.setState({ updateBusiness: true }); }}
                >
                  Update Business Information <i className="fa fa-angle-right" />
                </a>
              </li>
              {businessDetails.active === '0' ?
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.unsuspendUser(); }}>
                    Unsuspend Business <i className="fa fa-angle-right" />
                  </a>
                </li>
                :
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.suspendUser(); }}>
                    Suspend Business <i className="fa fa-angle-right" />
                  </a>
                </li>
              }
              <li>
                <a 
                  href="" 
                  onClick={(e) => {
                    this.setState({upload_btn_clicked: !this.state.upload_btn_clicked}); e.preventDefault();}
                  }
                  style={{display: this.state.upload_btn_clicked ? "none" : "block"}}
                >
                  Upload User data <i className="fa fa-angle-right" />
                </a>
                <div style={{display: this.state.upload_btn_clicked ? "block" : "none"}}>
                  <h3>Action</h3>
                  <a  href="" onClick={
                    (e) => {
                      e.preventDefault(); this.setUploadVisible();
                      this.setState({upload_action: 'add new users'})
                    }
                  }>Add users</a>
                  <a  href="" onClick={
                    (e) => {
                      e.preventDefault(); this.setUploadVisible();
                      this.setState({upload_action: 'remove users'})
                    }
                  }>Remove users</a>
                  <a  href="" onClick={
                    (e) => {
                      e.preventDefault(); this.setUploadVisible();
                      this.setState({upload_action: 'update users'})
                    }
                  }>Update users</a>
                </div>
              </li>
            </ul>
          </div>
        </div>

        {(this.state.wallet && this.state.fundWallet) &&
          <div className="col-md-6 pull-right">
            {
              (this.props.user.role !== 'admin' && this.props.user.role !== 'admin') ?
                <div className="bg_white">
                  <p className="bg_text">You're not allowed to perform this operation</p>
                  <p style={{textAlign: "center"}}>Kindly speak with the admin</p>
                </div>         
                :
              <TopUpWallet
                wallet={this.state.wallet.id}
                onWalletFunded={() => this.setState({ fundWallet: false })}
                setDashboardMessage={this.props.setDashboardMessage}
                user={user}
              />
            }
          </div>
        }

        {(this.state.businessDetails && this.state.updateBusiness) &&
          <div className="col-sm-7">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form
                      onSubmit={e => this.updateBusiness(e)}
                      ref={(form) => { this.createForm = form; }}
                    >
                      <div className="row">

                        <div className="form-sub-header">
                          <h4>About the Business</h4>
                          <span>Information about the organization.</span>
                        </div>

                        <div className="form-element col-sm-12">
                          <span className="label">Business Name</span>
                          <input
                            ref={(input) => { this.corporate_name = input; }}
                            type="text"
                            required
                            defaultValue={this.state.businessDetails.corporate_name}
                            className="form-text"
                          />
                        </div>

                        <div className="form-element col-md-6">
                          <span className="label">Official Phone</span>
                          <input
                            ref={(input) => { this.corporate_phone = input; }}
                            type="text"
                            required
                            defaultValue={this.state.businessDetails.corporate_phone}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">Official E-mail Address</span>
                          <input
                            ref={(input) => { this.email = input; }}
                            type="email"
                            required
                            defaultValue={this.state.businessDetails.email}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">Sector/Industry</span>
                          <div className="form-select">
                            <select
                              name=""
                              defaultValue={this.state.businessDetails.industry}
                              id=""
                              ref={(input) => { this.industry = input; }}
                            >
                              {this.state.industries.map(industry => (
                                <option value={industry} key={industry}>
                                  {industry}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-element col-md-12">
                          <span className="label">Official Address</span>
                          <textarea
                            ref={(input) => { this.corporate_address = input; }}
                            type="text"
                            required
                            defaultValue={this.state.businessDetails.corporate_address}
                            className="form-text"
                          />
                        </div>

                        <div className="clearfix" />
                        <div className="form-sub-header">
                          <h4>About the Administrator</h4>
                          <span>
                            Information about the key personnel that
                                                        manages the company account.</span>
                        </div>

                        <div className="form-element col-md-6">
                          <span className="label">First Name</span>
                          <input
                            type="text"
                            required
                            defaultValue={this.state.businessDetails.fname}
                            ref={(input) => { this.fname = input; }}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">Last Name</span>
                          <input
                            ref={(input) => { this.lname = input; }}
                            type="text"
                            required
                            defaultValue={this.state.businessDetails.lname}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">Phone Number</span>
                          <input
                            ref={(input) => { this.person_phone = input; }}
                            type="text"
                            required
                            defaultValue={this.state.businessDetails.person_phone}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">E-mail Address</span>
                          <input
                            ref={(input) => { this.person_email = input; }}
                            type="email"
                            required
                            defaultValue={this.state.businessDetails.person_email}
                            className="form-text"
                          />
                          <span className="hint">
                            An email will be sent to this address
                            with instructions to set a password.
                          </span>
                        </div>

                        <div className="form-element col-md-6">
                          <span className="label">Password</span>
                          <input
                            ref={(input) => { this.password = input; }}
                            type="password"
                            required
                            defaultValue={this.state.businessDetails.password}
                            className="form-text"
                          />

                        </div>

                      </div>

                      <div className="form-element">
                        <input
                          type="submit"
                          className="bvtton"
                          value={this.state.loading ?
                            'Updating Business...' : 'Update Business '
                          }
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {(!this.state.fundWallet && !this.state.updateBusiness) &&
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <div className="chart-wrapper no-margin-top">
                  <h3>Rides By Staff <span>Rides by users over the last 30 days</span></h3>
                  <div className="chart linechart 30day-transaction" />
                </div>
              </div>
            </div>
            {
              this.state.upload && 
              <div className="col-md-12 file_sec">
                {this.state.filename && this.state.loading && <div className="spinner" />}
                <h4 className="upload_h4">
                  Upload file ({this.state.upload_action})
                </h4>
                <input 
                  type="file" 
                  id="my_file"
                  style={{ display: "none" }} 
                  name="upload"
                  onChange={(e) => this.fileHandler(e)}
                />
                <label                     
                  className={(this.state.filename) ? "no_show" : "edit_file"} 
                  htmlFor="my_file"
                >
                  <h5>
                    {this.state.filename ? "Upload" : "Select a file" }
                  </h5>
                </label>
                {
                  this.state.filename && 
                  <>
                    <p className="file_label">Filename: <span className="file_name">"{this.state.filename}"</span></p>
                    {
                      this.state.percentage == 0 &&                     
                        <input
                          type="submit"
                          value="submit"
                          className="btn btn-success submit_file"
                          onClick={(e) => this.handleSubmit(e)}
                        />
                    }
                    {
                      this.state.percentage > 0 &&                     
                        <div class="progress">
                          <div 
                            className="progress-bar" 
                            role="progressbar" 
                            style={{width: `${this.state.percentage}%`}}
                            aria-valuenow={this.state.percentage} 
                            aria-valuemin="0" 
                            aria-valuemax="100"
                          >
                            {this.state.percentage}%
                          </div>
                        </div>
                    }
                    {
                      this.state.file_sent && 
                        <>
                          <p className="upload_details">Details:</p>
                          <ul>
                            <li className="upload_list upload_list_success">Successful records updated: <label>{this.state.success_rows}</label></li>
                            <li className="upload_list upload_list_error">Error count: <label>{this.state.error_rows}</label></li>
                          </ul>
                        </>
                    }
                  </>
                }
              </div>
            }

            <div className="clearfix" />

            <div className="module focus green">
              <div className="module-header">
                <h3>{`${businessDetails.corporate_name}'s`} Transactions</h3>
              </div>
              <TransactionHistory summary user={businessDetails} />
            </div>
          </div>
        }

        <div className="col-sm-12">
          <div className="module">
            <div className="module-header">
              <h3>Recent Ride History</h3>
            </div>
            <RideHistory user={businessDetails} scope="business" />
          </div>
        </div>

      </div>
    );
  }
}


BusinessDetails.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

BusinessDetails.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

BusinessDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
