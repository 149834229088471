/**
 * Created by constance.okoghenun on 14/09/2017.
 */
import React, { Component } from "react";
import "../../styles/dashboard.css";
import { Redirect } from "react-router-dom";
import {getUserRoutes,getUserTrips, } from '../../models/users';

export default class UserRouteBookings extends Component {
  constructor(props) {
    super(props);
    console.log(props.location.state.data.userId, "locationlocationlocation")
    this.state = {
        _date: props.location.state.date,
        user_id: props.location.state.data.userId,
        userRoute: [],
        userTrips: [],
        data: [],
        bookingType: props.location.state.data.bookingType
    };
  }

  componentDidMount() {
    if(this.state.bookingType === 'Active') {
        getUserRoutes(this.state.user_id, false).then((route) => {
            this.setState({
                data: route
            });
        }).catch((e) => {
            console.error('[Booking Details], Error fetching user route data', e.stack);
        });
    }
    else {
        getUserTrips(this.state.user_id).then((res) => {
            this.setState({
                data: res
            });
        })
        .catch((e) => {
            this.setState({
                userTrips: []
            });
            console.error('[Booking Details], Error fetching user route data', e.stack);
        });
    }
  }
  

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: "/users/manage",
            rtProps: this.props.location.state,
            pathname: "/login"
          }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{this.state.bookingType} Bookings</h2>
        </div>
        {this.state.data.length ?
            <div className="module passenger-list" id="target_table">
            <div className="module-wrapper false">
                <div className="module-content has-table has-users">
                    <div className="module-table">
                    <table>
                        <thead>
                        <tr>
                            <th>Pickups</th>
                            <th>Destinations</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                            { this.state.data.map((info, key) => (
                                <tr key={key}>
                                    <td key={key}>{(this.state.bookingType === 'Active') ? info.pickup.location : info.route.pickup}</td>
                                    <td key={key}> {info.destination.location} </td>
                                    <td key={key}>{(this.state.bookingType === 'Active') ? JSON.stringify(new Date(info.start_date)).split('T')[0] : JSON.stringify(info.created_at).split(' ')[0]}</td>
                                    <td key={key}>{(this.state.bookingType === 'Active') ? JSON.stringify(info.end_date) : JSON.stringify(info.created_at).split(' ')[0]}</td>
                                    <td key={key}> {(this.state.bookingType === 'Active') ? info.cost : info.destination.fare} </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        :   
        <div className="no_user_route">
            <center><h4>There are no active bookings for this user currently...</h4></center>  
        </div>
        }
      </div>
    );
  }
}

  