import { del, get, post, patch } from '../libs/requestClient';

export const getAllVehicles = () => get('/v1/vehicles');

export const getVehicle = id => get(`/v1/vehicles/${id}`);

export const searchVehicles = registration_number => post('/v1/vehicles/search', { registration_number });

export const createVehicle = data => post('/v1/vehicles/', data);

export const updateVehicle = (id, data) => patch(`/v1/vehicles/${id}`, data);

export const deleteVehicle = id => del(`/v1/vehicles/${id}`);

