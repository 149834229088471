import VehicleRegistration from './add';
import VehicleDetails from './Details';
import FleetInsights from './insights';
import TrackFleet from './tracking';

export {
  VehicleRegistration,
  VehicleDetails,
  FleetInsights,
  TrackFleet,
};

export default FleetInsights;
