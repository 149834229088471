import ManagePromoCodes from './manage';
import PromoCodeDetails from './details';
import AddPromoCode from './new';
import AddPromoCodeForUsers from './new-user';

export {
  ManagePromoCodes,
  PromoCodeDetails,
  AddPromoCode,
  AddPromoCodeForUsers,
};

export default ManagePromoCodes;
