/**
 * Created by constance.okoghenun on 14/09/2017.
 */
import React, { Component } from "react";
import "../../styles/dashboard.css";
import '../../styles/spinner.css';
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ExportToCsv } from 'export-to-csv';
import { fetchAllStaff} from '../../models/staff'

export default class ManageStaffs extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
        data: [],
    };
  }

  getRole(role) {
    let roles = {
      super_admin: "Super Admin",
      user: "User",
      admin: "Admin"
    }
    return roles[role];
  }

  componentDidMount() {
    fetchAllStaff().then((staff) => {
      console.log(staff)
      this.setState({data: staff})
    }).catch((e) => {
        console.error('[Staff Details], Error fetching route data', e.stack);
    });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: "/users/manage",
            rtProps: this.props.location.state,
            pathname: "/login"
          }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Staff Listings</h2>
        </div>
        <div className="module passenger-list" id="target_table">
            <div className="module-wrapper false">
                <div className="module-content has-table has-users">
                    <div className="module-table">
                    <table>
                        <thead>
                        <tr>
                            <th>Staff</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                              (user.role !== 'admin' && user.role !== 'super_admin') ?
                                <div className="bg_white">
                                  <p className="bg_text">You're not allowed to view this page</p>
                                  <p style={{textAlign: "center"}}>Kindly speak with the admin</p>
                                </div>
                              :      
                              Array.isArray(this.state.data) && this.state.data.map((info, key) => (
                                <tr>
                                <td>
                                    <div className="user-table-display">
                                    <div className="image css-img-object"
                                        style={{ backgroundImage: `url(${null || '/assets/img/default.png'})` }} // eslint-disable-line
                                    />
                                    <h3>{`${info.fname} ${info.lname}`}</h3>
                                    </div>
                                </td>
                                  <td>{info.phone}</td>
                                  <td>{info.email}</td>
                                  <td>{this.getRole(info.role)}</td>
                                  <td>{<Moment format="MMM Do YY">{info.created_at}</Moment>}</td>
                                  <td>{<Moment format="MMM Do YY">{info.updated_at}</Moment>}</td>
                                </tr> 
                              ))          
                            } 
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

ManageStaffs.propTypes = {
    user: PropTypes.object,
    route: PropTypes.object,
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
    setDashboardMessage: PropTypes.func,
};
  