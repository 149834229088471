import React from 'react';
import PropTypes from 'prop-types';

const RoutesMap = props =>
    (
        <div className="map-image">
            <img
                src={
                    `https://maps.googleapis.com/maps/api/staticmap?&maptype=roadmap&markers=color:green%7Clabel:A%7C${props.pickup_coordinate}&markers=color:red%7Clabel:B%7C${props.destination_coordinate}&&path=weight:3%7Ccolor:blue%7Cenc:${props.polyline}&size=${props.size}&key=AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE`} // eslint-disable-line
                alt="map"
            />
        </div>
    );


RoutesMap.propTypes = {
    pickup_coordinate: PropTypes.string,
    destination_coordinate: PropTypes.string,
    size: PropTypes.string,
};

RoutesMap.defaultProps = {
    pickup_coordinate: '',
    destination_coordinate: '',
    size: '',
};

export default RoutesMap;
