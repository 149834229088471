import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { ExportToCsv } from 'export-to-csv';

import { getAllUsers } from "../../models/users";
import DatePicker from '../../components/datepicker'
import '../../styles/dashboard.css';
import {getAllBusinesses} from '../../models/business';


export default class ExportData extends Component {
  constructor() {
    super();

    this.state = {
      success: false,
      error: false,
      userStatus: null,
      data: [],
      dayFormat: "YYYY-MM-DD",
      businesses: []
    };
  }

  filterByDate(start, end, data) {
    start = moment(start).format(this.state.dayFormat);
    end = moment(end).format(this.state.dayFormat);
    return data.filter((_) => {
      const createdAt = moment(_.created_at).format(this.state.dayFormat);
      return moment(createdAt).isBetween(start, end);
    })
  }

  filterByStatus(data, status) {
    if(status && status !== 'all') {
      return data.filter((_) => _.active === status);
    }
    return data;
  }

  getCorporateName(id){
    if(id) {
      let data = this.state.businesses.filter((x) => x.id === id);
      if(data && data.length) {
        return data[0].corporate_name;
      }
    }
    return null;
  }

  handleDownload(e) {
    let date = false;
    let data = this.state.data;
    if(!this.endDate && !this.startDate) {
      date = true;
    }

    if(date) {
      data = this.filterByDate(this.startDate, this.endDate, data);
    }

    if(this.state.userStatus) {
      data = this.filterByStatus(data, this.state.userStatus);
    }

    let csvData = data.map(x => {
      return {
        active: x.active,
        corporate_id: this.getCorporateName(x.corporate_id) || 'N/A',
        fname: x.fname,
        lname: x.lname,
        email: x.email,
        phone: x.phone,
        staff_id: x.staff_id || 'N/A',
        personal_wallet_balance: x.wallet.amount,
        company_balance: x.wallet.credit_amount || 'N/A'
      }
    });

    const csvParams = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: `User data Export`,
      useTextFile: false,
      useBom: true,
      headers: ['Status', 'Company', 'First Name', 'Last Name', 'Email', 'Phone', 'Staff ID', 'Personal Wallet', 'Company Wallet']
    };
    const csvExporter = new ExportToCsv(csvParams);
    csvExporter.generateCsv(csvData);
    window.alert('Download complete');
  }


  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;
    if (from && to) this.setState({ filter: { from, to } }) 
    else {
      window.alert("Please indicate start and end date in the query");
      return false;
    }

    const filteredData = this.state.data.filter(data => 
        data.created_at.split(" ")[0] >= from && data.created_at.split(" ")[0] <= to
    );

    this.setState({
      data: filteredData,
      count: filteredData.length
    })
  }


  componentDidMount() {
    getAllUsers()
      .then(data => {
        console.log("USers ", data)
        this.setState({ data, success: true });
      })
      .catch(e => {
        console.error("[User List] Error fetching user data", e);
        this.setState({ error: true });
      })

    getAllBusinesses()
      .then((res) => {
        res = res.data.filter((x) => x.active !== "0")
        this.setState({
          businesses: res,
        });
      })
      .catch(e => {
        console.error("[Business List] Error fetching business data", e);
      })
  }


  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: `/${this.props.type}/manage`,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="module-pre-header-filter">
          <h2>Export User Data</h2>
        </div>
        <div className="container__">
          <p className="bold_text">
            Set Filters:
          </p>
          <div className="module-pre-header-filter formBody_">
            <form onSubmit={e => this.applyFilters(e)}>
              <div className="flex flex-wrap inline-form-wrapper formBody_">
                <div className="title">
                  <h3>Filter Data by Date</h3>
                </div>
                <div className="form-element dateSec_">
                  <DatePicker
                    type="date"
                    onUpdate={(data) => { this.startDate = data; }}
                    placeholder="Start Date"
                  />
                </div>
                <div className="form-element dateSec_">
                  <DatePicker
                    type="date"
                    onUpdate={(data) => { this.endDate = data; }}
                    placeholder="End Date"
                  />
                </div>
              </div>
            </form>
            <div className="checkbox_section__">
              <span className="radioLabel">Filter by status</span>
              <div className="form-check statusRadio">
                <label
                  className="form-check-label"
                  htmlFor="weeks"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="weeks"
                    onClick={(e) => this.setState({userStatus: '1'})}
                    value="1"
                  />
                  Active
                </label>
              </div>
              <div className="form-check statusRadio">
                <label
                  className="form-check-label"
                  htmlFor="weeks"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="weeks"
                    onClick={(e) => this.setState({userStatus: '0'})}
                    value="2"
                  />
                  Inactive
                </label>
              </div>
              <div className="form-check statusRadio">
                <label
                  className="form-check-label"
                  htmlFor="weeks"
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="weeks"
                    onClick={(e) => this.setState({userStatus: 'all'})}
                    value="1"
                  />
                  All
                </label>
              </div>
            </div>
            <div className="form-element">
              <button className="mainBtn__" type="submit" onClick={e => this.handleDownload(e)}
              >
                Export
              </button>
            </div>          
          </div>
        </div>
      </div>
    );
  }
}


ExportData.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

ExportData.defaultProps = {
  user: null,
  type: 'users',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
