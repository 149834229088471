import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { createUser } from "../../models/users";
import { getAllBusinesses } from "../../models/business";

import "../../styles/spinner.css";
import "./new.css"

export default class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      loadingData: false,
      loadingSuccessFull: true,
      selectedDate: null,
      dateError: false,
      allBusinesses: null,
      selectedBusiness: null
    };
  }

  componentDidMount() {
    getAllBusinesses()
      .then(resp => {
        this.setState({ ...this.state, allBusinesses: resp.data });
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Couldnt get all busniesses"
        );
      });
  }

  handleSelectChange(e) {
    this.setState({ ...this.state, selectedBusiness: e.target.value });
  }

  createUser(e) {
    e.preventDefault();

    if (
      !this.fname.value ||
      !this.lname.value ||
      !this.phone.value ||
      !this.email.value ||
      !this.password.value
      // this.createForm.gender.value == "" ||
      // !this.state.selectedDate
    ) {
      window.scrollTo(0, -30);
      return this.props.setDashboardMessage(
        "error",
        "Some fields are missing"
      );
    }

    let userData = {
      fname: this.fname.value,
      lname: this.lname.value,
      phone: this.phone.value,
      email: this.email.value,
      password: this.password.value,
      gender: this.createForm.gender.value,
      dob: new Date(this.state.selectedDate).toISOString()
    };

    if (
      this.state.selectedBusiness &&
      this.state.selectedBusiness != "none"
    ) {
      let corporate_id = this.state.allBusinesses.find(
        each => each.corporate_name == this.state.selectedBusiness
      ).id;

      userData = Object.assign({}, userData, { corporate_id })
    }

    this.setState({ loadingData: true });

    createUser(userData)
      .then(() => {
        // this.props.setDashboardMessage(
        //   "success",
        //   "User created successfully"
        // );

        alert('User created successfully');

        this.setState({
          loadingData: false,
          loadingSuccessFull: true
        });

        this.createForm.reset();
      })
      .catch(err => {
        let message = "Sorry an error occurred while creating the user";

        if (
          err.error &&
          (err.error.code === "BadRequest" ||
            err.error.code === "ItemAlreadyExist")
        ) {
          message = err.error.message;
        }

        this.props.setDashboardMessage("error", message);

        window.scrollTo(0, 0);

        this.setState({
          loadingData: false,
          loadingSuccessFull: false
        });
      });
  }

  onDateChange(e) {
    let date = new RegExp(
      "^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]((19|20)\\d\\d)$"
    );

    if (date.test(e.target.value)) {
      console.log("right");
      this.setState({
        ...this.state,
        selectedDate: e.target.value,
        dateError: false
      });
    } else {
      this.setState({
        ...this.state,
        selectedDate: null,
        dateError: true
      });
    }
  }

  render() {
    const user = this.props.user;
    const { loadingData, selectedDate, dateError, allBusinesses } = this.state;

    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: "/users/new",
            rtProps: this.props.location.state,
            pathname: "/login"
          }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Add a new user</h2>
          <div className="right-options" />
        </div>

        <div className="clearfix" />

        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="module">
                <div className="module-header" />
                <div className="module-wrapper">
                  <div className="module-content module-form-wrapper">
                    <div className="form-wrapper">
                      <form onSubmit={e => this.createUser(e)}
                        ref={form => {
                          this.createForm = form;
                        }}
                      >
                        <div className="row">
                          <div className="form-element col-sm-6">
                            <span className="label">First Name</span>
                            <input
                              required
                              ref={input => {
                                this.fname = input;
                              }}
                              type="text"
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-sm-6">
                            <span className="label">Last name</span>
                            <input
                              required
                              ref={input => {
                                this.lname = input;
                              }}
                              type="text"
                              className="form-text"
                            />
                          </div>
                        </div>
                        <div className="form-element">
                          <span className="label">E-mail Address</span>
                          <input
                            required
                            ref={input => {
                              this.email = input;
                            }}
                            type="email"
                            className="form-text"
                          />
                        </div>

                        <div className="form-element phone">
                          <span className="label">
                            Phone Number
                                                    </span>
                          <input
                            required
                            ref={input => {
                              this.phone = input;
                            }}
                            min="9"
                            type="number"
                            className="form-text"
                          />
                        </div>

                        <div className="form-element">
                          <span className="label">Password</span>
                          <input
                            required
                            ref={input => {
                              this.password = input;
                            }}
                            type="password"
                            className="form-text"
                          />
                        </div>
                        <div className="form-element">
                          <span className="label">
                            Company
                            <small
                              style={{
                                color: "red"
                              }}
                            >
                              (optional)
                          </small>
                          </span>
                          <div className="form-select long">
                            <select
                              onChange={this.handleSelectChange.bind(
                                this
                              )}
                            >
                              <option>
                                none
                              </option>
                              {allBusinesses && allBusinesses.map(
                                (each, i) => {
                                  return (
                                    <option
                                      key={
                                        i
                                      }
                                    >
                                      {
                                        each.corporate_name
                                      }
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form-element">
                          <input
                            type="radio"
                            name="gender"
                            value="male"
                            defaultChecked
                          />
                          <span
                            style={{
                              marginRight: "20px"
                            }}
                          >
                            Male
                          </span>
                          <input
                            type="radio"
                            name="gender"
                            value="female"
                          />
                          <span>Female</span>
                        </div>
                        <div className="form-element">
                          <span className="label">
                            Date of Birth
                            {/* {dateError && (
                              <small
                                style={{
                                  color: "red"
                                }}
                              >
                                incorrect format
                              </small>
                            )} */}
                          </span>
                          <input
                            type="date"
                            placeholder="DD/MM/YYYY"
                            onChange={this.onDateChange.bind(this)}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element">
                          <input
                            type="submit"
                            // disabled={!selectedDate || dateError}
                            className="bvtton"
                            value={loadingData ? "Adding User..." : "Add User"}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="module-footer" />
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

AddUser.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  setDashboardMessage: PropTypes.func
};

AddUser.defaultProps = {
  user: null,
  location: {
    state: {}
  },
  setDashboardMessage: () => { }
};

AddUser.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};
