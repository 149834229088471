import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';
import { updateOrder } from '../../models/orders';
import { formatMoney } from '../../libs/utils';

import '../../styles/spinner.css';

export default class OntripOrdersDetails extends Component {
  constructor() {
    super();
    this.state = {
      order: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentWillMount() {
    const order = this.props.location.state.order || this.props.order;
    this.setState({
      order,
      loadingData: false,
      loadingSuccessFull: true,
    });
  }


  updateOrder(e) {
    e.preventDefault();
    const order = this.state.order || this.props.order || this.props.location.state.order;
    const data = {
      status: this.status.value,
    };

    if (!data.status) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    updateOrder(order.id, data).then(() => {
      this.props.setDashboardMessage('success', 'Request updated succesfully');
      this.setState({
        order,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((err) => {
      console.error('[Order Details] Error updating charter', data, err.stack);
      this.props.setDashboardMessage('error', 'Sorry an error occured while updating the order');
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  render() {
    const user = this.props.user;
    const order = this.state.order || this.props.location.state.order;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trip-orders/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!order) {
      return (
        <Redirect to={{
          pathname: '/trip-orders',
        }}
        />
      );
    }

    return (
      <div>
        <div className="user-dash-wrapper">
          <div className="sub-page-header col-sm-12">
            <h2>{`${user.fname}'s Order Details`} - {order.order_number}</h2>
          </div>
          <div className="page-usability-back col-sm-12">
            <button className="bvtton small grey"
              onClick={() => this.context.router.history.goBack()}
            >
              Go Back
                        </button>
          </div>
          <div className="clearfix" />

          <div className="col-md-4">
            <div className={`admin-user-summary ${this.state.loading && 'loading'}`}>
              {this.state.loading && <div className="spinner" />}
              <div className="user-picture">
                <div className="image css-img-object"
                  style={{ backgroundImage: `url(${user.avatar || '/assets/img/default.png'})` }}
                />
              </div>
              <h3>{order.user.fname} {order.user.lname}</h3>
              <div className="user-data flex flex-row">
                <div>
                  {order.user.phone}
                  <span>Phone Number</span>
                </div>
                <div>
                  {order.user.email}
                  <span>E-mail Address</span>
                </div>
                <div className="">
                  <div><Moment format="MMM Do, YYYY">{user.created_at}</Moment></div>
                  <span>MEMBER SINCE</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 pull-right">
          <div className="module">
            <div className="module-wrapper">
              <div className="module-content module-form-wrapper">
                <div className="row">
                  <div className="form-sub-header">
                    <h4>Order Items</h4>
                  </div>
                  <div className="col-md-12">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th width="35%">Item</th>
                          <th>Qty</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.items.map(currentOrder => (
                          <tr key={currentOrder.product.id}>
                            <td>
                              {currentOrder.product.name}
                            </td>
                            <td>
                              {formatMoney(currentOrder.quantity)}
                            </td>
                            <td>
                              &#x20a6;{formatMoney(currentOrder.price)}
                            </td>
                            <td>
                              &#x20a6;{formatMoney(currentOrder.total)}
                            </td>
                          </tr>
                        ))}
                        <tr key="grand">
                          <td>
                            <strong>Grand Total</strong>
                          </td>
                          <td>
                            &#x20a6;{formatMoney(order.grand_total)}
                          </td>
                          <td>
                            .
                          </td>
                          <td>
                            .
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div className="form-wrapper">
                  {(!this.state.loadingData &&
                    this.state.loadingSuccessFull &&
                    order.status === 'New') &&
                    <form onSubmit={e => this.updateOrder(e)}>
                      <div className="row">

                        <div className="form-sub-header">
                          <h4>Admin Feedback</h4>
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">Set Status</span>
                          <div className="form-select">
                            <select ref={(input) => { this.status = input; }} >
                              <option value="Fulfilled">Fulfilled</option>
                              <option value="Cancelled">Cancelled</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="form-element">
                        <input
                          disabled={(this.state.loadingData && !this.state.loadingSuccessFull)}
                          type="submit"
                          className="bvtton"
                          value="Update Order"
                        />
                      </div>
                    </form>
                  }
                  {(!this.state.loadingData &&
                    this.state.loadingSuccessFull && order.status !== 'New') &&
                    <div className="row">
                      <div className="form-sub-header">
                        <h4>Admin Feedback</h4>
                      </div>
                      <div className="form-element col-md-6">
                        <span className="label">Order Status</span>
                        <div className="form-select">
                          {order.status}
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="module-footer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OntripOrdersDetails.propTypes = {
  user: PropTypes.object,
  order: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

OntripOrdersDetails.defaultProps = {
  user: null,
  order: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

OntripOrdersDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
