import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { updateRoute, getLuggageConfig, processRouteDirection } from '../../models/routes';
import { getAllBusStops } from "../../models/busStops";
import cacheClient from '../../libs/cacheClient';
import { scrollToEl } from '../../libs/utils';
import {getAllBusinesses} from '../../models/business';
import constants from "../../constants";
import '../../styles/spinner.css';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

export default function EditRoute({ location, setDashboardMessage, user, history }) {
  const [meta, setMeta] = useState({ loading: false, error: false, success: false });
  const [busStops, setBusStops] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [publicChecked, setPublicChecked] = useState(false);
  const [privateChecked, setPrivateChecked] = useState(false);
  const route = (location.state && location.state.route);
  const [currentOrgID, setCurrentOrgID] = useState(route.corporate_id);
  const [futureRoute, setFutureRoute] = useState(route.is_future_route);
  const [luggageConfig, setLuggageConfig] = useState(false);
  const [luggageConfigData, setLuggageConfigData] = useState(false);
  const [selectedLuggageConfig, setSelectedLuggageConfig] = useState(false);
  const [isPreview, setIsPreview] = useState(true);
  const [startCordinates, setStartCordinates] = useState(null);
  const [endCordinates, setEndCordinates] = useState(null);
  const [overviewPolyline, setOverviewPolyline] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [mapAtRest, setMapAtRest] = useState(true)
  
  //pick up
  const [startingValue, setStartingValue] = useState(null);
  const [startingLocation, setStartingLocation] = useState(route.pickup);

  //finat stop
  const [finalValue, setFinalValue] = useState(null);
  const [finalLocation, setFinalLocation] = useState(route.destination);

  if(startingValue) {
    geocodeByAddress(startingValue.label)
    .then(results => getLatLng(results[0]))
    .then(() => {
      setStartingLocation(startingValue.label);
    });
  }

  if(finalValue) {
    geocodeByAddress(finalValue.label)
    .then(results => getLatLng(results[0]))
    .then(() => {
      setFinalLocation(finalValue.label);
    });
  }



  useEffect(() => {
    //get bus-stops
    getAllBusStops()
      .then(async(data) => {
        let luggageConfigData = await getLuggageConfig();
        setBusStops(data);
        setLuggageConfigData(luggageConfigData.data)
      })
      .catch(e => {
        console.error("[Bus stop List] Error fetching bus stop data", e);
      })

      //get corporates
      async function getCorporates() {
        let businesses = await getAllBusinesses();
        setBusinesses(businesses.data)
      }

      getCorporates()
      checkedValue();
  }, [])

  let corporateArray = [];
  businesses.map((each, key) => {
    if(each.id != route.corporate_id) {
      corporateArray.push(
        <option value={each.id} key={key}>{each.corporate_name}</option>
      )    
    }
  })

  const handleDropdownChange = (e) => {
    const organization_id = e.target.value;
    setCurrentOrgID(organization_id)
  }


  const getCorporateNameByID = (data, corporate_id) => {
    let corporate_name;
    data.map((each) => {
      if(each.id == corporate_id) {
        corporate_name = each.corporate_name
      }
    })
    return corporate_name;
  }
  const corporateName = getCorporateNameByID(businesses, route.corporate_id);


  const prepareRoutePreview = async (e) => {
    e.preventDefault();
    setMapLoading(true);
    if(!startingLocation || !finalLocation) {
      setDashboardMessage('error', 'Starting and ending location required');
      scrollToEl('label-header');
      setMapLoading(false);
      return false;
    }

    await processRouteDirection({
      startPoint: startingLocation, 
      endPoint: finalLocation
    })
    .then((resp) => {
      if(resp && resp.data) {
        if(resp.data.routes && resp.data.routes.length && resp.data.routes[0].hasOwnProperty('overview_polyline')) {
          const overview_polyline  = resp.data.routes[0].overview_polyline.points;
          const { end_location, start_location } = resp.data.routes[0].legs[0];
          setStartCordinates(`${start_location.lat}, ${start_location.lng}`);
          setEndCordinates(`${end_location.lat}, ${end_location.lng}`);
          setOverviewPolyline(overview_polyline);
          setMapLoading(false);
          setIsPreview(false);
          setMapAtRest(false);
        }
      } else {
        throw new Error(
          'invalid location selected'
        )
      }
    })
    .catch((Err) => {
      console.log(Err, "An error occured while processing direction");
      setDashboardMessage('error', 'An error occured while processing direction');
      setMapLoading(false);
      return false;    
    })
  }


  // if we have a user session we redirect away from sign up
  if (!user || !user.id) {
    return (
      <Redirect to={{
        push: true,
        rtURL: '/trips/details',
        rtProps: this.props.location.state,
        pathname: '/login',
      }}
      />
    );
  }


  const checkedValue = () => {
    switch(route.visibility) {
      case "public":
        setPublicChecked(true)
        break;
      case "private":
        setPrivateChecked(true)
        break;
    }
  }

  const getVisibility = () => {
    let visibility;
    if(privateChecked) {
      visibility = constants.PRIVATE;
    }
    else {
      visibility = constants.PUBLIC
    }
    return visibility;
  }


  if (!route) {
    return (<Redirect to={{ pathname: '/trips/routes/' }} />);
  }

  const schema = Yup.object().shape({
    total_seats: Yup.string().required('Total seats is required'),
    destination: Yup.string().required('Destination is required'),
    pickup: Yup.string().required('Pickup location is required'),
  });

  return (
    <div className="user-dash-wrapper">
      <div className="sub-page-header col-sm-12" id="label-header">
        <h2>Edit Route</h2>
        <div className="right-options" />
      </div>

      <div className="page-usability-back col-sm-12">
        <button className="bvtton small grey" onClick={() => history.goBack()}>
          Go Back
        </button>
      </div>

      <div className="clearfix" />

      <div className="col-md-12 col-lg-12">
        <div className="row">
          <div className="col-sm-12">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper _row_div">
                <div className="module-content module-form-wrapper section_">
                  <div className="form-wrapper">
                    <Formik
                      initialValues={{
                        pickup: route.pickup,
                        destination: route.destination,
                        day_of_week: 'MON - FRI',
                        total_seats: route.total_seats,
                        is_future_route: futureRoute,
                        route_code: route.route_code
                      }}
                      validationSchema={schema}
                      onSubmit={(values, actions) => {
                        setMeta({ loading: true });
                        const visibility = getVisibility();

                        if(privateChecked && !currentOrgID) {
                          setDashboardMessage('error', 'Please select an organization');
                          setMeta({ error: true, loading: false });
                          return false;
                        }
                        
                        updateRoute(route.id, {
                          visibility: visibility,
                          corporate_id: currentOrgID,
                          pickup: startingLocation,
                          destination: finalLocation,
                          total_seats: values.total_seats,
                          day_of_week: 'MON - FRI',
                          is_future_route: futureRoute,
                          luggage_config_id: selectedLuggageConfig ? selectedLuggageConfig : null,
                          route_code: values.route_code
                        }).then((res) => {
                          setDashboardMessage('success', 'Route updated successfully');

                          cacheClient.remove('/v1/routes');

                          setMeta({ success: true, loading: false });

                          return <Redirect to={{ state: { route: res }, pathname: '/trips/routes/details/' }} />;
                        }).catch((err) => {
                          if(err.hasOwnProperty('error') && err.error.code === 'RouteSeatsError') {
                            setDashboardMessage('error', 'Route cannot be updated as the number of people currently booked is greater than the incoming route total seat');
                          }
                          setDashboardMessage('error', 'Sorry an error occured while updating route');
                          console.error('[Edit Route] Error updating route information', err.stack);
                          setMeta({ error: true, loading: false });
                        });
                      }}
                    >
                      {props => {
                        const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } = props;

                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="form-sub-header">
                                <h4>Route Information</h4>
                                <span>Information the origin and destination of a ride.</span>
                              </div>
                              <div className="form-element col-sm-12">
                                <span className="label">Starting Location</span>
                                <div>
                                  <GooglePlacesAutocomplete
                                    apiKey="AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE"
                                    selectProps={{
                                      startingValue,
                                      onChange: setStartingValue,
                                      isClearable:true,
                                      placeholder: route.pickup
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-element col-sm-12">
                                <span className="label">Final stop</span>
                                <GooglePlacesAutocomplete
                                  apiKey="AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE"
                                  selectProps={{
                                    finalValue,
                                    onChange: setFinalValue,
                                    isClearable:true,
                                    placeholder: route.destination
                                  }}
                                />
                              </div>

                              <div className="form-element col-md-12">
                                <span className="label">
                                  Total Number Seats Available for this route
                              </span>
                                <input
                                  type="number"
                                  name="total_seats"
                                  value={values.total_seats}
                                  onChange={handleChange}
                                  className="form-text"
                                  onBlur={handleBlur}
                                />
                                {errors['total_seats'] && touched['total_seats'] && <p style={{ color: 'red' }}>{errors['total_seats']}</p>}
                              </div>
                              <div className="form-element col-md-12">
                                <span className="label">
                                  Enter Route Code
                                </span>
                                <input
                                  type="text"
                                  required
                                  minlength="2"
                                  maxlength="5"
                                  name="route_code"
                                  value={values.route_code}
                                  onChange={handleChange}
                                  className="form-text"
                                  onBlur={handleBlur}
                                  placeholder="Enter route code. Max of 5 letters"
                                />
                                {errors['total_seats'] && touched['total_seats'] && <p style={{ color: 'red' }}>{errors['total_seats']}</p>}
                              </div>
                              <div className="clearfix" />
                            </div>
                            <div className="future_route">
                                <label className="future_route_label">Set as future route</label>
                                <input 
                                  type="checkbox" 
                                  name="is_future_route"
                                  onChange={(e) => {
                                    setFutureRoute(!futureRoute);
                                  }}
                                  checked={futureRoute}
                                />
                            </div>
                            <div className="checbbox_sec">
                            <label className="future_route_label">Set route visibility</label>
                              <label
                                className="check_label"
                                htmlFor="public"
                              >
                                <div class="flex_label">
                                  <input
                                      className="form-check-input check_box"
                                      type="checkbox"
                                      name="public"
                                      onChange={(e) => {
                                        setPublicChecked(true);
                                        setPrivateChecked(false);
                                        } 
                                      }
                                    checked={(privateChecked) ?  false : true}
                                  />
                                  <p className="p">Public</p>
                                </div>
                              </label>
                              <label
                                className="check_label"
                                htmlFor="private"
                              >
                                <div class="flex_label">
                                  <input
                                      className="form-check-input check_box"
                                      type="checkbox"
                                      name="private"
                                      onChange={(e) => {
                                        setPrivateChecked(true);
                                        setPublicChecked(false);
                                      } 
                                    }
                                    checked={(publicChecked) ? false : true}
                                  />
                                  <p className="p">Private</p>
                                </div>
                              </label>
                            </div>
                            <div>
                              {
                                (privateChecked) &&
                                <div className="corporates_div">
                                  <label htmlFor="corporates" name="total_seats">Select Organization</label>
                                  <select 
                                    name="corporates" 
                                    className="select_corporate"
                                    onChange={handleDropdownChange}
                                  >
                                    <option value={route.corporate_id} key=""> {
                                      (corporateName) ? corporateName
                                      : "Select Organization"}
                                    </option>
                                    {corporateArray}
                                  </select>                                  
                                </div>
                              }
                            </div>
                            <div className="future_route_">
                              <div>
                                <br/>
                                <label className="future_route_label">Should Support Luggage</label>
                                <input
                                  type="checkbox" 
                                  name="should_support_luggage"
                                  onChange={(e) => {
                                    setLuggageConfig(!luggageConfig);
                                  }}
                                  checked={luggageConfig}
                                />
                              </div>
                              {
                                luggageConfig && 
                                <div
                                  className="luggage_config"
                                >
                                  <label className="future_route_label">Luggage Configuration</label>
                                  <table id="luggage-config">
                                    <tr>
                                      <th>Fee Type</th>
                                      <th>Amount</th>
                                      <th>Max Quantity</th>              
                                    </tr>
                                    {
                                      Array.isArray(luggageConfigData) && luggageConfigData.map((info, key) => (
                                        <tr
                                         key={key}
                                        >
                                          <td
                                            key={key}
                                          >
                                            {info.fee_type}
                                          </td>
                                          <td
                                            key={key}
                                          >{info.amount}
                                          </td>
                                          <td
                                            key={key}
                                          >
                                            <input
                                              type="checkbox" 
                                              name={`luggage_config_entry`}
                                              onChange={(e) => {
                                                setSelectedLuggageConfig(info.id);
                                              }}
                                              key={key}
                                            /> 
                                          </td>                                    
                                        </tr>
                                      ))
                                    }
                                  </table>
                                </div>
                              }
                            </div>
                            <div className="form-element">
                              {
                                !isPreview 
                                ? (
                                  <input
                                    type="submit"
                                    className="bvtton"
                                    style={{width:'100%', marginTop: '10%', marginBottom: '5%', cursor: 'pointer'}}
                                    value={meta.loading ? 'Updating Route...' : 'Update Route'}
                                  />
                                )
                                : (
                                  <input
                                    type="button"
                                    className="bvtton"
                                    style={{width:'100%', marginTop: '10%', marginBottom: '5%', cursor: 'pointer'}}
                                    value={(mapLoading) ? 'Loading' : 'Preview'}
                                    onClick={(e) => prepareRoutePreview(e)}
                                  />
                                )
                              }
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
                <div>
                  <div className="map_sec">
                  {
                    mapAtRest ? (
                      <div
                        style={{height: '100%', width: '100%'}}
                      >
                        <p
                          style={{
                            margin: 'auto', 
                            marginTop: '50%', 
                            textAlign:'center',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            color: (mapLoading) ? 'orange' : 'unset'
                          }}
                        >
                          {(!mapLoading) ? "Select start and end location to preview Map" : "Loading..."}
                        </p>
                      </div>
                    )
                    : (
                      <div className="map-image">
                        <img
                            src={
                                `https://maps.googleapis.com/maps/api/staticmap?&maptype=roadmap&markers=color:green%7Clabel:A%7C${startCordinates}&markers=color:red%7Clabel:B%7C${endCordinates}&&path=weight:3%7Ccolor:blue%7Cenc:${overviewPolyline}&size=500x1000&key=AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE`} // eslint-disable-line
                            alt="map"
                        />
                      </div>
                    )
                  }
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EditRoute.propTypes = {
  user: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

EditRoute.defaultProps = {
  route: null,
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

EditRoute.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
