import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import { updateRoutePickup } from '../../models/routes';

const schema = Yup.object().shape({
  location: Yup.string().required('Pickup location is required'),
});

export default class EditPickup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      busStops: []
    };
  }

  render() {
    return (
      <div className="module">
        <div className="module-header" />
        <div className="module-wrapper">
          <div className="module-content module-form-wrapper">
            <div className="form-wrapper">
              <Formik
                initialValues={{
                  location: this.props.pickup.location,
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {                  
                  const res = this.props.busStops.find(item => item.location == values.location);
                  const route = this.props.route;
                  const pickup = this.props.pickup;
                  const onUpdate = this.props.onUpdate;
                  const setDashboardMessage = this.props.setDashboardMessage;

                  this.setState({ error: false });
                  updateRoutePickup(route.id, pickup.id, {
                    location: values.location,
                    location_description: res.location_description,
                  }).then((data) => {
                    onUpdate(data);
                    setDashboardMessage('success', 'Pickup point has been updated');
                  }).catch((err) => {
                    console.error('[Edit Pickup point] An error occurred while editing pickup point', err.stack);
                    setDashboardMessage('error', 'Sorry we could not update this pickup point');
                  });
                }}
              >
                {props => {
                  const { handleSubmit, values, errors, touched, handleBlur, setFieldValue } = props;

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-element col-sm-12">
                          <span className="label">Location</span>
                          <Select
                            value={{ label: values.location, value: values.location }}
                            onChange={val => setFieldValue('location', val.value)}
                            options={this.props.busStops.map(item => ({ label: item.location, value: item.location }))}
                            required
                            name="location"
                            onBlur={handleBlur}
                          />
                          {errors['location'] && touched['location'] && <p style={{ color: 'red' }}>{errors['location']}</p>}
                        </div>
                      </div>

                      <div className="row"><br />
                        <div className="form-element col-sm-12">
                          <input
                            type="submit"
                            className="bvtton"
                            value={
                              this.state.loadingData ? 'Updating Pickup...' : 'Edit Pickup'
                            }
                          />
                        </div><br />
                      </div>
                    </form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditPickup.propTypes = {
  route: PropTypes.object,
  pickup: PropTypes.object,
  onUpdate: PropTypes.func,
  setDashboardMessage: PropTypes.func,
};

EditPickup.defaultProps = {
  route: null,
  pickup: null,
  onUpdate: () => { },
  setDashboardMessage: () => { },
};
