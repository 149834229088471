import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';

import RoutesMap from '../../components/routeMap';
import { getRoute, getRouteDestination, createRouteDropOff, deleteRoutePickup } from '../../models/routes';
import { getAllBusStops } from "../../models/busStops";
import EditDepartureTime from '../../components/routes/editDepartureTime';
import EditPickup from '../../components/routes/editPickup';
import DatePicker from '../../components/datepicker';

import '../../styles/spinner.css';

const schema = Yup.object().shape({
  fare: Yup.string().required('Fare is required'),
  location: Yup.string().required('Drop off location is required'),
});

export default class PickUpDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: null,
      pickup: null,
      itinerary: null,
      destinations: null,
      loadingData: false,
      loadingSuccessFull: true,
      showEditDepartureTime: false,
      showEditPickupPoint: false,
      busStops: []
    };
  }

  componentWillMount() {
    const route = this.state.route ||
      this.props.route || (this.props.location.state && this.props.location.state.route) || null;
    const pickup = this.state.pickup ||
      this.props.pickup || (this.props.location.state && this.props.location.state.pickup) || null;
    this.setState({ route, pickup });
  }

  componentDidMount() {
    const { pickup, route } = this.state;
    getRouteDestination(route.id, pickup.id, true)
      .then((data) => {
        this.setState({
          destinations: data.destinations,
          itinerary: data.itineraries && data.itineraries[0],
        });
      }).catch((err) => {
        this.setState({
          loadingData: false,
        });
        console.error('[Route details]', err.stack);
      });

    getAllBusStops()
      .then(data => {
        this.setState({ busStops: data })
      })
      .catch(e => {
        console.error("[Bus stop List] Error fetching bus stop data", e);
      })
  }

  onUpdateRoute() {
    getRoute(this.state.route.id, true).then(route => this.setState({ route }));
  }

  onUpdateRoutePickup(pickup) {
    if (pickup.id === this.state.pickup.id) {
      this.setState({ pickup });
    }
  }

  onUpdateDepartureTime(itinerary) {
    if (itinerary.id === this.state.itinerary.id) {
      this.setState({ itinerary });
    }
  }

  deleteRoutePickup(e) {
    e.preventDefault();
    const pickup = this.state.pickup;

    if (!window.confirm('Are you sure you want to delete this pickup point?')) return false;

    deleteRoutePickup(pickup.id).then(() => {
      this.context.router.history.goBack();
      this.props.setDashboardMessage('success', 'Pickup point has been been deleted');
    }).catch((err) => {
      console.error('[Pickup Details] Error deleteing route', pickup.id, err.stack);
      this.props.setDashboardMessage('error', 'Sorry we could not remmove this Pickup point');
    });
  }

  render() {
    const user = this.props.user;
    const { pickup, route, itinerary } = this.state;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!(route && pickup)) {
      return (
        <Redirect to={{
          pathname: '/trips/routes/',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{this.state.showEditPickupPoint ? 'Edit Pickup Point' : 'Pickup Point Details'}</h2>
          <div className="right-options" />
          <div className="page-usability-back col-sm-12 row">
            <button className="bvtton small grey"
              onClick={() => this.context.router.history.goBack()}
            >
              Go Back
            </button>
          </div>
        </div>

        <div className="col-md-6">
          <div className="admin-user-summary">
            <div className="form-sub-header">
              <h4>Pick Up Point Details</h4>
            </div>
            <div className="user-data flex flex-row">
              <div className="">
                {pickup.location}
                <span className="route-description">({pickup.location_description})</span>
              </div>
            </div>
            <div className="form-sub-header">
              <h4>Add Drop off location</h4>
            </div>

            <div className="form-wrapper">
              <Formik
                initialValues={{ location: '', fare: '' }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  const res = this.state.busStops.find(item => item.location == values.location);
                  const routeData = {
                    location: values.location,
                    location_description: res.location_description,
                    fare: values.fare,
                  };

                  if (this.pickup_time) {
                    const time = this.pickup_time.split(' ');
                    routeData.itinerary = [
                      {
                        departure_time: time[0],
                        time_of_day: time[1],
                      },
                    ];
                  }

                  this.setState({ loadingData: true });
                  const pickUpId = this.state.pickup.id;
                  const routeId = this.state.route.id;
                  createRouteDropOff(routeId, pickUpId, routeData).then(() => {
                    this.props.setDashboardMessage('success', 'Route drop off created successfully');
                    this.setState({
                      loadingData: false,
                      loadingSuccessFull: true,
                    });
                    setTimeout(() => window.location.reload(1), 1000);
                  }).catch((err) => {
                    console.error('[Create Route Pickup] Error creating route', routeData, err.stack);
                    this.props.setDashboardMessage('error', 'Sorry an error occurred while creating the route');
                    this.setState({ loadingData: false, loadingSuccessFull: false });
                  });
                }}
              >
                {props => {
                  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } = props;

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-element col-sm-12">
                          <span className="label">Location</span>
                          <Select
                            value={{ label: values.location, value: values.location }}
                            onChange={val => setFieldValue('location', val.value)}
                            options={this.state.busStops.map(item => ({ label: item.location, value: item.location }))}
                            required
                            name="location"
                            onBlur={handleBlur}
                          />
                          {errors['location'] && touched['location'] && <p style={{ color: 'red' }}>{errors['location']}</p>}
                        </div>

                        <div className="form-element col-sm-12"><br />
                          <span className="label">Fare</span>
                          <input
                            type="number"
                            required
                            name="fare"
                            value={values.fare}
                            onChange={handleChange}
                            className="form-text"
                            onBlur={handleBlur}
                          />
                          {errors['fare'] && touched['fare'] && <p style={{ color: 'red' }}>{errors['fare']}</p>}
                        </div>

                        {(this.state.destinations && this.state.destinations.length === 0 &&

                          <div className="form-element col-sm-12 col-md-12"><br />
                            <div className="form-element">
                              <span className="label">Pickup Time</span>
                              <DatePicker
                                type="time"
                                required
                                onUpdate={(data) => { this.pickup_time = data; }}
                                placeholder=""
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row"><br />
                        <div className="form-element col-sm-12">
                          <input
                            type="submit"
                            className="bvtton"
                            value={
                              this.state.loadingData ? 'Adding Drop Off...' : 'Add Drop Off'
                            }
                          />
                        </div><br />
                      </div>
                    </form>
                  )
                }}
              </Formik>

            </div><br /><br />

            <div className="form-sub-header">
              <h4>Drop Off Points</h4>
            </div>
            {(this.state.destinations && this.state.destinations.length > 0 &&
              <ul style={{
                listStyle: 'none',
                margin: 0,
                padding: 0,
              }}
              >
                {this.state.destinations.map(destination => (
                  <li className="single-route" key={destination.id}>
                    <Link to={{
                      state: { pickup, route, destination },
                      pathname: '/trips/routes/pickup/destination/details',
                    }}
                    >
                      {destination.location}
                      <span className="route-description">
                        ({destination.location_description})
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="admin-account-actions">
            <div className="_header">
              <h3>Pickup Actions</h3>
            </div>
            <ul>
              {itinerary &&
                <li>
                  <Link to=""
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        showEditDepartureTime: true,
                        showEditPickupPoint: false,
                      });
                    }}
                  >
                    Edit Departure Time
                  <i className="fa fa-angle-right" />
                  </Link>
                </li>
              }
              <li>
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showEditDepartureTime: false,
                      showEditPickupPoint: true,
                    });
                  }}
                >Edit Pickup Point
                  <i className="fa fa-angle-right" />
                </Link>
              </li>
              <li>
                <Link to=""
                  onClick={e => this.deleteRoutePickup(e)}>Delete Pickup Point
                  <i className="fa fa-angle-right" />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-5 col-md-offset-1">
          {this.state.showEditDepartureTime &&
            <EditDepartureTime
              pickup={pickup}
              itinerary={itinerary}
              onUpdate={data => this.onUpdateDepartureTime(data)}
              setDashboardMessage={this.props.setDashboardMessage}
            />
          }
          {this.state.showEditPickupPoint &&
            <EditPickup
              route={route}
              pickup={pickup}
              onUpdate={data => this.onUpdateRoutePickup(data)}
              setDashboardMessage={this.props.setDashboardMessage}
              busStops={this.state.busStops}
            />
          }
          {!(this.state.showEditDepartureTime ||
            this.state.showEditPickupPoint) &&
            <div className="map-image" style={{ border: 'none' }}>
              <RoutesMap
                polyline={route.overview_polyline.points}
                pickup_coordinate={route.pickup_coordinate}
                destination_coordinate={route.destination_coordinate}
                size="413x340"
              />
            </div>
          }
        </div>
      </div>
    );
  }
}


PickUpDetails.propTypes = {
  user: PropTypes.object,
  route: PropTypes.object,
  pickup: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

PickUpDetails.defaultProps = {
  route: null,
  user: null,
  pickup: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

PickUpDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
