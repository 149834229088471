import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { createPromotion } from '../../models/promotions';
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";
import '../../styles/spinner.css';

export default class AddPromotion extends Component {
  constructor() {
    super();
    this.state = {
      expires_at: null,
      loadingData: false,
      loadingSuccessFull: true,
    };
  }

  /**
   * Creates an admin user
   * @param {Event} e
   */
  createPromotion(e) {
    e.preventDefault();
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return;
    }
    if (!this.reward_kind.value || !this.reward_amount.value) {
      return this.props.setDashboardMessage('error', 'Reward Information is required');
    }

    const promoData = {
      owner_type: "admin",
      owner_id: user.id,
      customer_benefit: {
        reward_kind: this.reward_kind.value,
        reward_amount: parseInt(this.reward_amount.value),
        customer_constraint: 'none',
      },
      max_number_of_uses: parseInt(this.max_number_of_uses.value),
      name: this.name.value,
      description: this.description.value,
      
    };

    if(this.state.expires_at) {
      promoData['expires_at'] = this.state.expires_at;
    }

    if(this.promo_code && this.promo_code.value) {
      promoData["promo_code"] = this.promo_code.value;
    }

    if(this.customer_constraint && this.customer_constraint.value) {
      promoData.customer_benefit['customer_constraint'] = this.customer_constraint.value;
    }

    this.setState({ loadingData: true });
    createPromotion(promoData).then(() => {
      this.props.setDashboardMessage('success', 'Promotion created successfully');
      this.setState({
        loadingData: false,
        loadingSuccessFull: true,
      });
      this.createForm.reset();
    }).catch((err) => {
      let message = 'Sorry an error occurred while creating the admin';
      if (err.body && (err.body.code === 'BadRequest' || err.body.code === 'ItemAlreadyExist')) {
        message = err.body.message;
      }
      this.props.setDashboardMessage('error', message);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }


  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/promotions/new',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>New Admin Promotion</h2>
          <div className="right-options" />
        </div>

        <div className="clearfix" />

        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="module">
                <div className="module-header" />
                <div className="module-wrapper">
                  <div className="module-content module-form-wrapper">
                    <div className="form-wrapper">
                      <form
                        onSubmit={e => this.createPromotion(e)}
                        ref={(form) => { this.createForm = form; }}
                      >
                        <div className="row">
                          <div className="form-element col-sm-6">
                            <span className="label">Name</span>
                            <input
                              required ref={(input) => { this.name = input; }} type="text"
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-sm-6">
                            <span className="label">Description</span>
                            <input
                              required ref={(input) => { this.description = input; }} type="text"
                              className="form-text"
                            />
                          </div>
                        </div>
                        <div className="form-element">
                          <span className="label">Max Number of Uses (Use Zero for unlimited)</span>
                          <input
                            required ref={(input) => { this.max_number_of_uses = input; }} type="number"
                            className="form-text"
                          />
                        </div>

                        <div className="form-element">
                          <span className="label">Reward Kind</span>
                          <select
                            required ref={(input) => { this.reward_kind = input; }}
                            className="form-text"
                          >
                            <option value="wallet_credit">Credit Customer's Wallet</option>
                            <option value="flat_rate_discount_on_trips">Discount Customer's Trip by an Amount</option>
                            <option value="percentage_discount_on_trips">Discount Customer's Trip by a Percentage</option>
                          </select>
                        </div>

                        <div className="form-element">
                          <span className="label">Reward Amount</span>
                          <input
                            required ref={(input) => { this.reward_amount = input; }}
                            type="number"
                            className="form-text"
                          />
                        </div>

                        <div className="row">

                        <div className="form-element col-sm-4">
                          <span className="label">Expiration Date (Leave blank to never expire)</span>
                          <DatePicker
                            selected={this.state.expires_at}
                            onChange={date => this.setState({expires_at: date})}
                            showTimeSelect
                            isClearable
                            dateFormat="yyyy-MM-dd HH:mm:ss"
                            className="form-text"
                          />
                        </div>


                          <div className="form-element col-sm-4">
                            
                            <span className="label">Promo Code</span>
                            <input
                              placeholder="(Optional)"
                              required ref={(input) => { this.promo_code = input; }} type="text"
                              className="form-text"
                            />
                          </div>

                          <div className="form-element col-sm-4">
                            
                            <span className="label">Set Customer Constrain</span>
                            <select
                            required ref={(input) => { this.customer_constraint = input; }}
                            className="form-text"
                          >
                            <option value="none">None</option>
                            <option value="new_customer">Applies to only new customers</option>
                          </select>
                          </div>
                         
                        </div>

                        

                        <div className="form-element">
                          <input
                            type="submit"
                            className="bvtton"
                            value={
                              this.state.loadingData ? 'Createing Promotion...' : 'Create Promotion'
                            }
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="module-footer" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

AddPromotion.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AddPromotion.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AddPromotion.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
