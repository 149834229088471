import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { createBusiness } from '../../models/business';
import '../../styles/dashboard.css';

export default class AddBusiness extends Component {
  constructor() {
    super();
    this.state = {
      users: null,
      searchQuery: '',
      loadingData: false,
      loadingSuccessFull: true,
    };
  }

  createBusiness(e) {
    e.preventDefault();
    if (!this.fname.value ||
      !this.lname.value ||
      !this.person_email.value ||
      !this.person_phone.value ||
      !this.email.value ||
      !this.password.value ||
      !this.corporate_name.value ||
      !this.corporate_phone.value ||
      !this.corporate_address.value ||
      !this.industry.value) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    const data = {
      fname: this.fname.value,
      lname: this.lname.value,
      person_email: this.person_email.value,
      person_phone: this.person_phone.value,
      email: this.email.value,
      password: this.password.value,
      corporate_name: this.corporate_name.value,
      corporate_phone: this.corporate_phone.value,
      corporate_address: this.corporate_address.value,
      industry: this.industry.value,
    };

    this.setState({ loadingData: true });
    createBusiness(data).then(() => {
      this.props.setDashboardMessage('success', 'Business created succesfully');
      this.setState({
        loadingData: false,
        loadingSuccessFull: true,
      });
      this.createForm.reset();
    }).catch((err) => {
      console.error('[Create Business] Error creating business', data, err.stack);
      this.props.setDashboardMessage('error', 'Sorry an error occured while creating the business');
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/business/add',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Add Business</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <a href="/business" className="bvtton small grey">Go Back</a>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="row">

            <div className="col-sm-12">
              <div className="module">
                <div className="module-header" />
                <div className="module-wrapper">
                  <div className="module-content module-form-wrapper">
                    <div className="form-wrapper">
                      <form
                        onSubmit={e => this.createBusiness(e)}
                        ref={(form) => { this.createForm = form; }}
                      >
                        <div className="row">

                          <div className="form-sub-header">
                            <h4>About the Business</h4>
                            <span>Information about the organization.</span>
                          </div>

                          <div className="form-element col-sm-12">
                            <span className="label">Business Name</span>
                            <input
                              ref={(input) => { this.corporate_name = input; }}
                              type="text"
                              required
                              className="form-text"
                            />
                          </div>

                          <div className="form-element col-md-6">
                            <span className="label">Official Phone</span>
                            <input
                              ref={(input) => { this.corporate_phone = input; }}
                              type="text"
                              required
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-md-6">
                            <span className="label">Official E-mail Address</span>
                            <input
                              ref={(input) => { this.email = input; }}
                              type="email"
                              required
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-md-6">
                            <span className="label">Password</span>
                            <input
                              ref={(input) => { this.password = input; }}
                              type="password"
                              required
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-md-6">
                            <span className="label">Sector/Industry</span>
                            <div className="form-select">
                              <select
                                name=""
                                id=""
                                ref={(input) => { this.industry = input; }}
                              >
                                <option value="Government">
                                  Government
                                                                </option>
                                <option value="Telecommunication">
                                  Telecommunication
                                                                </option>
                                <option value="Legal services">
                                  Legal services
                                                                </option>
                                <option value="Construction">
                                  Construction
                                                                </option>
                                <option value="Food processing">
                                  Food processing
                                                                </option>
                                <option value="Consulting">
                                  Consulting
                                                                </option>
                                <option value="Gambling">
                                  Gambling
                                                                </option>
                                <option value="Retail sales">
                                  Retail sales
                                                                </option>
                                <option value="Franchising">
                                  Franchising
                                                                </option>
                                <option value="Real estate">
                                  Real estate
                                                                </option>
                                <option value="Education">
                                  Education
                                                                </option>
                                <option value="Pharmaceuticals">
                                  Pharmaceuticals
                                                                </option>
                                <option value="Hospitality/Tourism">
                                  Hospitality/Tourism
                                                                </option>
                                <option value="Mass media">
                                  Mass media
                                                                </option>
                                <option value="Healthcare/hospitals">
                                  Healthcare/hospitals
                                                                </option>
                                <option value="Public health">
                                  Public health
                                                                </option>
                                <option value="Information technology">
                                  Information technology
                                                                </option>
                                <option value="Waste disposal">
                                  Waste disposal
                                                                </option>
                                <option value="Banking">
                                  Banking
                                                                </option>
                                <option value="Insurance">
                                  Insurance
                                                                </option>
                                <option value="Financial services">
                                  Financial services
                                                                </option>
                                <option value="FMCG">
                                  FMCG
                                                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="form-element col-md-12">
                            <span className="label">Official Address</span>
                            <textarea
                              ref={(input) => { this.corporate_address = input; }}
                              type="text"
                              required
                              className="form-text"
                            />
                          </div>

                          <div className="clearfix" />
                          <div className="form-sub-header">
                            <h4>About the Administrator</h4>
                            <span>
                              Information about the key personnel that
                                                            will manage the company account.</span>
                          </div>

                          <div className="form-element col-md-6">
                            <span className="label">First Name</span>
                            <input
                              type="text"
                              required
                              ref={(input) => { this.fname = input; }}
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-md-6">
                            <span className="label">Last Name</span>
                            <input
                              ref={(input) => { this.lname = input; }}
                              type="text"
                              required
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-md-6">
                            <span className="label">Phone Number</span>
                            <input
                              ref={(input) => { this.person_phone = input; }}
                              type="text"
                              required
                              className="form-text"
                            />
                          </div>
                          <div className="form-element col-md-6">
                            <span className="label">E-mail Address</span>
                            <input
                              ref={(input) => { this.person_email = input; }}
                              type="email"
                              required
                              className="form-text"
                            />
                            <span className="hint">
                              An email will be sent to this address
                              with instructions to set a password.
                                                        </span>
                          </div>

                        </div>

                        <div className="form-element">
                          <input
                            type="submit"
                            className="bvtton"
                            value={this.state.loadingData ?
                              'Adding Business...' : 'Add Business '
                            }
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="module-footer" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}


AddBusiness.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AddBusiness.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AddBusiness.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
