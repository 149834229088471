import AddBusiness from './add';
import BusinessDetails from './details';
import BusinessInsights from './insights';
import CorporateInterest from './corporate_interest';
export {
  AddBusiness,
  BusinessDetails,
  BusinessInsights,
  CorporateInterest
};

export default BusinessInsights;
