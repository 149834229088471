import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import { updateRouteDestination } from '../../models/routes';
import { getAllBusStops } from "../../models/busStops";

const schema = Yup.object().shape({
  fare: Yup.string().required('Fare is required'),
  location: Yup.string().required('Drop off location is required'),
});

export default class EditDestination extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      busStops: []
    };
  }

  componentDidMount() {
    getAllBusStops()
      .then(data => { this.setState({ busStops: data }) })
      .catch(e => {
        console.error("[Bus stop List] Error fetching bus stop data", e);
      })
  }

  render() {
    return (
      <div className="module">
        <div className="module-header" />
        <div className="module-wrapper">
          <div className="module-content module-form-wrapper">
            <div className="form-wrapper">
              <Formik
                initialValues={{
                  location: this.props.destination.location,
                  fare: this.props.destination.fare
                }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                  const route = this.props.route;
                  const pickup = this.props.pickup;
                  const destination = this.props.destination;
                  const onUpdate = this.props.onUpdate;
                  const setDashboardMessage = this.props.setDashboardMessage;

                  const res = this.state.busStops.find(item => item.location == values.location);

                  this.setState({ error: false });
                  updateRouteDestination(route.id, pickup.id, destination.id, {
                    location: values.location,
                    location_description: res.location_description,
                    fare: values.fare,
                  })
                    .then((data) => {
                      onUpdate(data);
                      setDashboardMessage('success', 'Destination has been updated');
                    })
                    .catch((err) => {
                      const message = (err.body && err.body.message) ||
                        'Sorry an error occurred while creating the vehicle';
                      this.props.setDashboardMessage('error', message);
                      console.error('[Edit Destination] An error occurred while editing destination', err.stack);
                      setDashboardMessage('error', message);
                    });
                }}
              >
                {props => {
                  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } = props;

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-element col-sm-12">
                          <span className="label">Location</span>
                          <Select
                            value={{ label: values.location, value: values.location }}
                            onChange={val => setFieldValue('location', val.value)}
                            options={this.state.busStops.map(item => ({ label: item.location, value: item.location }))}
                            required
                            name="location"
                            onBlur={handleBlur}
                          />
                          {errors['location'] && touched['location'] && <p style={{ color: 'red' }}>{errors['location']}</p>}
                        </div>

                        <div className="form-element col-sm-12"><br />
                          <span className="label">Fare</span>
                          <input
                            type="number"
                            min="1"
                            required
                            className="form-text"
                            name="fare"
                            value={values.fare}
                            onChange={handleChange}
                          />
                          {errors['fare'] && touched['fare'] && <p style={{ color: 'red' }}>{errors['fare']}</p>}
                        </div>
                      </div>

                      <div className="row">
                        <br />

                        <div className="form-element col-sm-12">
                          <input
                            type="submit"
                            className="bvtton"
                            value={
                              this.state.loadingData ? 'Updating Destination...' : 'Edit Destination'
                            }
                          />
                        </div><br />
                      </div>
                    </form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditDestination.propTypes = {
  route: PropTypes.object,
  pickup: PropTypes.object,
  destination: PropTypes.object,
  onUpdate: PropTypes.func,
  setDashboardMessage: PropTypes.func,
};

EditDestination.defaultProps = {
  route: null,
  pickup: null,
  destination: null,
  onUpdate: () => { },
  setDashboardMessage: () => { },
};
