import ManageAdmin from './manage';
import AdminDetails from './details';
import AddAdmin from './new';

export {
  ManageAdmin,
  AdminDetails,
  AddAdmin,
};

export default ManageAdmin;
