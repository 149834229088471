import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { getTransactionGraphData } from '../../models/transactions';
import { usersGraph } from '../../models/users';
import { initLineChart, initBarChart } from '../../libs/utils';

import UserList from '../../components/userList';
import '../../styles/dashboard.css';

export default class UserInsights extends Component {
  constructor() {
    super();
    this.state = {
      users: 0,
    };
  }

  componentDidMount() {
    usersGraph().then((data) => {
      const userData = [];

      data.forEach((datum) => {
        userData.push([datum.month, datum.total]);
      });

      const Users30Day = [{
        data: userData,
        label: 'Users',
      }];

      initBarChart('.linechart.user-transit-rides', Users30Day);
    });

    getTransactionGraphData().then((data) => {
      const transactionData = [];

      data.forEach((datum) => {
        const amount = datum.amount;
        const date = parseInt(moment(datum.created_at).format('X'), 10);
        if (datum.type === 'credit') {
          transactionData.push([date, amount]);
        }
      });

      const lineTransaction30Day = [
        {
          data: transactionData,
          label: 'Wallet Funding',
        },
      ];

      initLineChart('.linechart.average-wallet-size', lineTransaction30Day, '&#x20A6;');
    });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/users',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">

        <div className="col-md-12">
          <div className="chart-wrapper">
            <h3>User Growth (30 days)</h3>
            <div className="chart linechart user-transit-rides" />
          </div>
        </div>

        <div className="col-md-6">
          <div className="chart-wrapper">
            <h3>Top 5 Recent Users</h3>
            <UserList nameOnly summary />
          </div>
        </div>

        <div className="col-md-6">
          <div className="chart-wrapper">
            <h3>Wallet Funding <span>Wallet funding by users in the last 30 days</span></h3>
            <div className="chart linechart average-wallet-size" />
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}


UserInsights.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

UserInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
