import storage from 'localforage';

/**
 * API wrapper around localforage as a cache mechanism
 */
class CacheClient {
  constructor() {
    this.config = {
      cacheDuration: 600, // 1o mins
    };

    // By default mock out the storage interface
    // TODO set up an inmemory store as a fallback
    this.db = {
      getItem: () => Promise.resolve(null),
      setItem: () => Promise.resolve(null),
      removeItem: () => Promise.resolve(null),
    };

    storage.ready().then(() => {
      this.db = storage;
    }).catch((e) => {
      console.log('[Cache Client] error could not set up database', e); // `No available storage method found.`
    });
  }

  /**
   * Return an item from cache
   * @param key
   * @returns {Request|Promise.<TResult>|*}
   */
  get(key) {
    return this.db.getItem(key)
      .then((record) => {
        if (record) {
          if (!record.ts || (new Date()).getTime() < parseInt(record.ts, 10)) {
            // If there is no expiry timestamp, or if the expiry time hasn't been reached yet
            return record.value;
          }

          // If time has passed, remove the record from the cache
          return this.db.removeItem(key);
        }

        return record;
      });
  }

  /**
   * Adds an item to cache
   * @param key
   * @param value
   * @param ttl
   * @returns {*}
   */
  set(key, value, ttl) {
    const cacheTTL = ttl || this.config.cacheDuration;

    const record = {
      value,
      // Multiply ttl by 1000 to convert it to milliseconds
      ts: (new Date()).getTime() + parseInt(cacheTTL * 1000, 10),
    };

    return this.db.setItem(key, record);
  }

  /**
   * Removes an item from cache
   * @param key
   * @returns {*}
   */
  remove(key) {
    // If time has passed, remove the record from the cache
    return this.db.removeItem(key);
  }
}


export default new CacheClient();
