import axios from 'axios';
import createBrowserHistory from 'history/createBrowserHistory';
import storage from 'localforage';

import configuration from '../config';

const api = configuration.api;

const history = createBrowserHistory();

axios.interceptors.request.use(async (config) => {
  const authTokenData = await storage.getItem('session');
  const configCopy = config;

  configCopy.headers.Authorization = authTokenData ? `JWT ${authTokenData.token.token}` : null;

  return configCopy;
}, (error) => Promise.reject(error));


const handleResponse = ({ data }) => data

const handleError = ({ response }) => {
  try {
    console.log(response);
    const { data, status } = response;

    if (status === 401) {
      storage.removeItem('session').then(() => {
        history.push('/login')
        window.location.reload()
      })
    }
    return Promise.reject({ error: data });
  } catch (ex) {
    return Promise.reject({ error: ex });
  }
};

export const get = (url, params = {}) => axios.get(`${api}${url}`, { params }).then(handleResponse).catch(handleError)

export const post = (url, payload, param = null) => axios.post(`${api}${url}`, payload, param).then(handleResponse).catch(handleError)

export const patch = (url, payload) => axios.patch(`${api}${url}`, payload).then(handleResponse).catch(handleError)

export const del = (url, data) => axios.delete(`${api}${url}`, { data }).then(handleResponse).catch(handleError)
