/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import createHistory from 'history/createBrowserHistory';

import { getUserTrips } from '../models/users';
import { getAllTrips } from '../models/trips';
import { getDriverTrips } from '../models/drivers';

import '../styles/spinner.css';

export default class RideHistory extends Component {
    constructor() {
        super();
        this.state = {
            userTrips: null,
            driverTrips: null,
            allTrips: null,
            loadingData: true,
            loadingSuccessFull: false,
            loadingDriver: false,
            loadingUser: false,
            loadingAll: false,
        };

        this.history = createHistory();
        this.fetchAllTrips = this.fetchAllTrips.bind(this);
        this.fetchUsersTrips = this.fetchUsersTrips.bind(this);
        this.fetchDriverTrips = this.fetchDriverTrips.bind(this);
    }

    componentDidMount() {
        const user = this.props.user;
        const driver = this.props.driver;
        const scope = this.props.scope || null;
        if (driver) return this.fetchDriverTrips(driver);
        if (user) return this.fetchUsersTrips(user, scope);
        return this.fetchAllTrips();
    }

    componentWillReceiveProps(nextProps) {
        const filter = nextProps.filter || null;
        if (this.props.filter === filter) return false;
        if (!filter) return this.fetchAllTrips();
        if (filter.from && filter.to) {
            this.fetchAllTrips(filter);
        }
    }

    fetchUsersTrips(user, scope) {
        getUserTrips(user.id, scope).then((data) => {
            this.setState({
                userTrips: data,
                loadingData: false,
                loadingSuccessFull: true,
                loadingUser: true,
                loadingDriver: false,
                loadingAll: false,
            });
        }).catch((e) => {
            console.error('[Ride History] Error fetching trips for user', user.id, e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    fetchDriverTrips(driver) {
        getDriverTrips(driver.id).then((data) => {
            this.setState({
                driverTrips: data.data.trips,
                loadingData: false,
                loadingSuccessFull: true,
                loadingDriver: true,
                loadingUser: false,
                loadingAll: false,
            });
        }).catch((e) => {
            console.error('[Ride History] Error fetching trips for driver', driver.id, e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    fetchAllTrips(range) {
        this.setState({
            loadingData: true,
            loadingSuccessFull: false,
        });
        getAllTrips(false, false, range).then((trips) => {
            this.setState({
                allTrips: trips.data || trips,
                loadingData: false,
                loadingSuccessFull: true,
                loadingAll: true,
                loadingDriver: false,
                loadingUser: false,
            });
        }).catch((e) => {
            console.error('[Ride History] Error fetching data', e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    render() {
        let user = this.props.user || null;
        if (this.state.loadingDriver) {
            user = this.props.driver || null;
        }
        const isUser = user && user.id;
        let trips = this.state.userTrips || null;
        if (this.state.loadingDriver) {
            trips = this.state.driverTrips || null;
        } else if (this.state.loadingAll) {
            trips = this.state.allTrips || null;
        }

        //sort trips
        if(trips && trips.length) {
            trips.sort( (a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }

        return (
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                <div className="module-content has-table has-users">
                    <div className="module-table">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>{(this.props.scope) ? "Route" : "From"}</th>
                                    <th>{(this.props.scope) ? "PickUp" : "To"}</th>
                                    <th>{(this.props.scope) ? "Name" : "Distance"}</th>
                                    <th>{(this.props.scope) ? "Email" : "Duration"}</th>
                                    <th className="align-right">{(this.props.scope) ? "Destination" : "Fare"}</th>
                                </tr>
                            </thead>
                            {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                            <tbody>
                                <tr className="ride-data" style={{ display: 'table-row' }}>
                                    <td colSpan="6">
                                        <div className="ride-data-row">
                                            <div>
                                            Loading ride history...
                                                <div className="spinner" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                            <tbody>
                                <tr className="ride-data" style={{ display: 'table-row' }}>
                                    <td colSpan="6">
                                        <div className="ride-data-row">
                                            <div>
                                            Sorry you do not have any trip.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            }
                            {(!this.state.loadingData && this.state.loadingUser) &&
                            <tbody>
                                {(trips && trips.length > 0) && trips.map(trip => (
                                    <tr key={trip.id} className="clickable"
                                        onClick={() => this.context.router.history.push('/trips/details', { trip })}
                                    >
                                        <td><Moment format="h:mm A">{trip.created_at}</Moment>
                                            <br />
                                            <Moment format="MMM Do YY">{(!this.props.scope) 
                                                ? trip.created_at 
                                                : trip.trip_start_date}
                                            </Moment>
                                        </td>
                                        {
                                            (!this.props.scope) ?
                                                <td>{trip.route.pickup} ({trip.pickup_point.location})</td>
                                            : <td>{trip.pickup}</td>
                                        }
                                        { (!this.props.scope) ?
                                            <td>{trip.route.destination} ({trip.destination.location})</td>
                                            : <td>{trip.pickup}</td>
                                        }
                                        <td>{(!this.props.scope) ? trip.route.distance : trip.lname+" "+trip.fname}</td>
                                        <td>{(!this.props.scope) ? trip.route.duration : trip.email}</td>
                                        {
                                            (!this.props.scope) ?
                                            <td className="align-right money" >&#x20A6; {trip.destination.fare}</td>
                                            :
                                            <td>{trip.destination}</td>
                                        }
                                        
                                    </tr>
                                ))}
                            </tbody>
                            }

                            {(!this.state.loadingData && this.state.loadingDriver) &&
                            <tbody>
                                {(trips && trips.length > 0) && trips.map(trip => (
                                    <tr key={trip.id} className="clickable"
                                        onClick={() => this.context.router.history.push('/trips/details', { trip })}
                                    >
                                        <td><Moment format="h:mm A">{trip.created_at}</Moment>
                                            <br /><Moment format="MMM Do YY">{trip.created_at}</Moment>
                                        </td>
                                        <td>{trip.pickup}</td>
                                        <td>{trip.destination}</td>
                                        <td>{trip.distance}</td>
                                        <td>{trip.duration}</td>
                                        <td className="align-right money">Multiple Drop Off</td>
                                    </tr>
                                ))}
                            </tbody>
                            }

                            {(!this.state.loadingData && this.state.loadingAll) &&
                            <tbody>
                                {(trips && trips.length > 0) && trips.map(trip => (
                                    <tr key={trip.id} className="clickable"
                                        onClick={() => this.context.router.history.push('/trips/details', { trip })}
                                    >
                                        <td><Moment format="h:mm A">{trip.created_at}</Moment>
                                            <br /><Moment format="MMM Do YY">{trip.created_at}</Moment>
                                        </td>
                                        <td>{trip.route.pickup}</td>
                                        <td>{trip.route.destination}</td>
                                        <td>{trip.route.distance}</td>
                                        <td>{trip.route.duration}</td>
                                        <td className="align-right money">Multiple Drop Off</td>
                                    </tr>
                                ))}
                            </tbody>
                            }

                        </table>
                    </div>
                </div>
                {isUser &&
                <div className="module-footer">
                    <div className="pagination">
                        <nav>
                            <ul>
                                <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                                <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                }
            </div>
        );
    }
}


RideHistory.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
    }),
    filter: PropTypes.object,
    driver: PropTypes.shape({
        id: PropTypes.number,
    }),
};

RideHistory.defaultProps = {
    user: null,
    driver: null,
    filter: null,
};
RideHistory.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired,
        }).isRequired,
        staticContext: PropTypes.object,
    }).isRequired,
};
