import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { getAllServices, searchService } from '../../models/ontrip';
import { formatMoney } from '../../libs/utils';

import '../../styles/dashboard.css';

export default class OntripServicesInsights extends Component {
  constructor() {
    super();
    this.state = {
      services: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    this.loadAllServices();
  }

  componentWillReceiveProps() {
    this.searchTerm.value = null;
    this.loadAllServices();
  }

  loadAllServices() {
    getAllServices()
      .then((services) => {
        this.setState({
          services,
          loadingData: false,
          loadingSuccessFull: true,
        });
      }).catch((err) => {
        console.error('[OnTripService Insights] Error fetching service data', err, err.stack);
        this.setState({
          loadingData: false,
          loadingSuccessFull: false,
        });
        this.props.setDashboardMessage('error', 'Sorry could not fetch service information');
      });
  }

  findService(e) {
    e.preventDefault();
    const query = this.searchTerm.value;

    if (!query) return this.loadAllServices();

    this.setState({
      loadingData: true,
      loadingSuccessFull: false,
    });

    searchService(query)
      .then((data) => {
        this.setState({
          services: { data },
          loadingData: false,
          loadingSuccessFull: true,
        });
      }).catch((err) => {
        console.error('[OnTripService Insights] Error serching for service', query, err, err.stack);
        this.setState({
          loadingData: false,
          loadingSuccessFull: false,
        });
        this.props.setDashboardMessage('error', 'Sorry could not find that service');
      });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trip-services',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Manage On-trip Services</h2>
          <div className="right-options">
            <div className="search">
              <form onSubmit={e => this.findService(e)}>
                <div className="form-element">
                  <input
                    ref={(query) => { this.searchTerm = query; }}
                    type="text"
                    className="form-text"
                    placeholder="Enter search term"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-wrapper">
              <div className="module-content has-table has-users">
                <div className="module-table">
                  <table className="">
                    <thead>
                      <tr>
                        <th width="35%">Item</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="7">
                            <div className="ride-data-row">
                              <div>
                                Loading service data...
                                                                <div className="spinner" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="7">
                            <div className="ride-data-row">
                              <div>
                                Sorry we could not load any service data.
                                                            </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                      <tbody>
                        {this.state.services.data.map(service => (
                          <tr className="clickable"
                            key={service.id}
                            onClick={() =>
                              this.context.router.history.push('/trip-services/details',
                                { service })}
                          >
                            <td>
                              <div className="user-table-display">
                                <div className="image css-img-object"
                                  style={{
                                    backgroundImage:
                                      `url(${service.image})`
                                  }}
                                />
                                <h3>{service.name}</h3>
                              </div>
                            </td>
                            <td>{(parseInt(service.status, 10) === 1) ?
                              'Active' : 'Non-active'}</td>
                            <td>&#x20A6;{formatMoney(service.price)}</td>
                            <td>{formatMoney(service.quantity)}</td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OntripServicesInsights.propTypes = {
  user: PropTypes.object,
  setDashboardMessage: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

OntripServicesInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

OntripServicesInsights.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
