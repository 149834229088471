
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import { capitalizeString } from '../../libs/utils'
import PromotionsList from '../../components/promotionsList'

import '../../styles/dashboard.css'

export default class ManagePromoCodes extends Component {
  constructor() {
    super()
    this.state = {
      users: null,
      searchQuery: '',
    }

    this.findPromotion = this.findPromotion.bind(this)
  }

  componentWillReceiveProps() {
    this.setState({ searchQuery: this.state.searchQuery })
  }

  findPromotion(e) {
    e.preventDefault()
    const query = this.search.value
    if (query.length >= 3 || query.length === 0) {
      this.setState({ searchQuery: query })
    } else {
      this.props.setDashboardMessage('info', 'Search query length must be at least 3 characters long')
    }
  }

  render() {
    const user = this.props.user
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: `/${this.props.type}/manage`,
            rtProps: this.props.location.state,
            pathname: '/login',
          }}
        />
      )
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Manage {capitalizeString(this.props.type)}</h2>
          <div className="right-options">
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />
            <PromotionsList
              query={this.state.searchQuery}
              setDashboardMessage={this.props.setDashboardMessage}
              type={this.props.type}
            />
          </div>
        </div>
      </div>
    )
  }
}

ManagePromoCodes.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
}

ManagePromoCodes.defaultProps = {
  user: null,
  type: 'promotions',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
}
