import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { createService } from '../../models/ontrip';

import '../../styles/spinner.css';

export default class AddOntripService extends Component {
  constructor() {
    super();
    this.state = {
      service: null,
      loading: false,
    };
  }

  createService(e) {
    e.preventDefault();
    const serviceData = {
      name: this.name.value,
      price: this.price.value,
      status: parseInt(this.status.value, 10),
      quantity: this.quantity.value,
      image: this.image.value ? this.image.value : 'NULL',
    };
    const requiredFields = ['name', 'price', 'status', 'quantity'];

    this.setState({ loading: true });

    requiredFields.forEach((field) => {
      if (!serviceData[field]) {
        this.props.setDashboardMessage('error', 'Some required fields are missing');
        return false;
      }
    });

    createService(serviceData)
      .then(() => {
        this.setState({
          loading: false,
        });
        this.createForm.reset();
        this.props.setDashboardMessage('success', 'Service created.');
      }).catch((err) => {
        this.setState({ loading: false });
        this.props.setDashboardMessage('error', 'Sorry we could not creating service information');
        console.error('[Add OnTripService]', 'Error creating service', serviceData, err, err.stack);
      });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trip-services/add',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>New On-trip Service</h2>
        </div>

        <div className="col-sm-12">
          <div className="module">
            <div className="module-header" />
            <div className="module-wrapper">
              <div className="module-content module-form-wrapper">
                <div className="form-wrapper">
                  <form
                    ref={(form) => { this.createForm = form; }}
                    onSubmit={e => this.createService(e)}
                  >
                    <div className="row">
                      <div className="form-element col-sm-12">
                        <span className="label">Name</span>
                        <input
                          ref={(input) => { this.name = input; }}
                          type="text"
                          className="form-text"
                          required
                        />
                      </div>
                      <div className="form-element col-sm-6">
                        <span className="label">Price</span>
                        <input
                          ref={(input) => { this.price = input; }}
                          type="number"
                          className="form-text"
                          required
                        />
                      </div>
                      <div className="form-element col-md-6">
                        <span className="label">Status</span>
                        <div className="form-select">
                          <select required ref={(input) => { this.status = input; }}>
                            <option value="1">Active</option>
                            <option value="0">Disabled</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-element col-sm-6">
                        <span className="label">Quantity</span>
                        <input
                          ref={(input) => { this.quantity = input; }}
                          type="number"
                          className="form-text"
                          required
                        />
                      </div>
                      <div className="form-element col-sm-6">
                        <span className="label">Add Product/Service image</span>
                        <input
                          type="file"
                          accept=".png, .jpeg, .jpg"
                          className="form-text"
                          ref={(input) => { this.image = input; }}
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                    <div className="form-element">
                      <input type="submit" className="bvtton"
                        value={`${this.state.loading ? 'Creating' : 'Create'} Product/Service`}
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div className="module-footer" />
            </div>
          </div>

        </div>

      </div>
    );
  }
}


AddOntripService.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AddOntripService.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AddOntripService.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
