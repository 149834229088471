import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import '../../styles/spinner.css';

export default class NotFound extends Component {
  constructor() {
    super();
    this.state = {
      success: false,
      error: false
    };
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: `/${this.props.type}/manage`,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="notFound">
          <div className="messageDiv">
            <div className="message">
              <u>
                <h2 className="__text">
                  Page Not Found
                </h2>
                <a
                  href="/"
                >
                  take me home
                </a>
              </u>
            </div>
          </div>
          <div className="messageDivRight">
            <img src="/assets/img/trial-charter.png" alt="banner image" className="banner_img_"/>
          </div>
        </div>
      </div>
    );
  }
}


NotFound.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

NotFound.defaultProps = {
  user: null,
  type: 'users',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
