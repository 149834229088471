import React from 'react'
import PropTypes from 'prop-types'

export default class SearchBox extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      query: '',
      isFocused: false,
      suggestions: [],
    }
  }

  static defaultProps = {
    shouldSuggest: false,
    loadSuggestion: () => { },
    filterSuggestions: suggestions => suggestions,
    onSubmit: () => { },
    maxNoOfSuggestions: 5,
  }

  componentDidMount() {
    if (this.props.shouldSuggest) {
      this.props
        .loadSuggestion()
        .then(suggestions => this.setState({ suggestions }))
        .catch(e => console.error('error loading suggestions', e.stack))
    }
  }

  handleChange = e => this.setState({ query: e.target.value })

  handleSubmit = e => {
    e.preventDefault()
    this.props.onSubmit(this.state.query)
  }

  render() {
    const suggestions = this.props
      .filterSuggestions(this.state.suggestions, this.state.query)

    return (
      <div
        style={{ position: 'relative' }}
        onFocus={() => this.setState({ isFocused: true })}
        onBlur={() =>
          setTimeout(() => this.setState({ isFocused: false }), 500)
        }
      >
        <form onSubmit={this.handleSubmit} className="virtualize-box">
          <i className="fa fa-search virtualize-search-icon" />
          <input
            type="text"
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            value={this.state.query}
            className="virtualize-input"
          />
          {this.props.shouldSuggest &&
            (this.state.isFocused && (
              <div className="virtualize-suggest-box">
                {this.props.renderSuggestion(suggestions)}
              </div>
            ))}
          {this.props.shouldSuggest && (
            <div
              style={{
                position: 'absolute',
                right: '10px',
                top: '11px',
              }}
            >
              <i
                className="fa fa-caret-down"
                style={{ fontSize: '15px' }}
              />
            </div>
          )}
        </form>
      </div>
    )
  }
}

SearchBox.propTypes = {
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  shouldSuggest: PropTypes.bool,
  maxNoOfSuggestions: PropTypes.number,
  loadSuggestion: PropTypes.func,
  renderSuggestion: PropTypes.func,
  filterSuggestions: PropTypes.func,
}
