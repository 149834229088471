import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import VehicleList from '../../components/vehicleList';
import '../../styles/dashboard.css';

export default class FleetInsights extends Component {
  constructor() {
    super();
    this.state = {
      fleet: null,
      searchQuery: '',
      searchDone: false,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentWillReceiveProps() {
    this.setState({ searchQuery: this.state.searchQuery });
  }

  findVehicle(e) {
    e.preventDefault();
    const query = this.search.value;
    if ((query.length >= 3) || (query.length === 0)) {
      this.setState({ searchQuery: query });
    } else {
      this.props.setDashboardMessage('info', 'Search query length must be at least 3 characters long');
    }
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/fleet',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Manage Fleet</h2>
          <div className="right-options">
            <div className="search">
              <form onSubmit={(e) => { this.findVehicle(e); }}>
                <div className="form-element">
                  <input
                    ref={(input) => { this.search = input; }}
                    type="text"
                    className="form-text"
                    placeholder="Enter search term"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />
            <VehicleList
              query={this.state.searchQuery}
              setDashboardMessage={this.props.setDashboardMessage}
            />
          </div>
        </div>

      </div>
    );
  }
}

FleetInsights.propTypes = {
  user: PropTypes.object,
  setDashboardMessage: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

FleetInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

FleetInsights.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
