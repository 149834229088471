import { get, patch } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getWallet = (id, noCache) => {
  const fetchWallet = get(`/v1/wallet/${id}`)
    .then((wallet) => {
      cacheClient.set(`/v1/wallet/${id}`, wallet);
      return wallet;
    });

  if (noCache) return fetchWallet;

  return cacheClient.get(`/v1/wallet/${id}`)
    .catch((err) => {
      console.error('[Wallet model] Error fetching wallet from cache', id, err.stack);
      return fetchWallet;
    })
    .then((data) => {
      if (data) return data;
      return fetchWallet;
    });
};

export const fundWallet = (user, id, amount, desc, source) =>
  patch(`/v1/wallet/${id}/user/${user}`, { title: desc, amount, type: 'credit', source: source });

export const debitWallet = (user, id, amount, desc, source) =>
  patch(`/v1/wallet/${id}/user/${user}`, { title: desc, amount, type: 'debit', source: source});


