import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import RideHistory from '../../components/rideHistory';
import { tripGraph } from '../../models/trips';
import { initLineChart } from '../../libs/utils';
import DatePicker from '../../components/datepicker';

import '../../styles/dashboard.css';

export default class TripInsights extends Component {
  constructor() {
    super();
    this.state = {
      users: 0,
      filter: null,
    };
  }

  componentDidMount() {
    tripGraph().then((data) => {
      const tripsData = [];
      data.forEach((datum) => {
        const total = datum.total;
        const date = parseInt(moment(datum.created_at).format('X'), 10);
        tripsData.push([date, total]);
      });

      const lineTrips30Day = [{
        data: tripsData,
        label: 'Rides',
      }];
      initLineChart('.linechart.user-transit', lineTrips30Day, 'Rides: ');
    });
  }

  componentWillReceiveProps() {
    this.setState({ filter: null });
  }

  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;
    if (from && to) this.setState({ filter: { from, to } });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/users',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="clearfix" />

        <div className="col-md-12">
          <div className="chart-wrapper">
            <h3>Trips <span>Trips by Users</span></h3>
            <div className="chart linechart user-transit" />
          </div>
        </div>
        <div className="clearfix" />
        <div className="sub-page-header col-sm-12">
          <h2>All Trips</h2>
        </div>

        <div className="col-md-12 col-lg-12">

          <div className="module focus green">
            <div className="module-pre-header-filter">
              <form onSubmit={e => this.applyFilters(e)}>
                <div className="flex flex-wrap inline-form-wrapper">
                  <div className="title">
                    <h3>Filter by Date</h3>
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.startDate = data; }}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.endDate = data; }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="form-element">
                    <button type="submit">Filter</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="module-header" />
            <RideHistory filter={this.state.filter} />
          </div>
        </div>

      </div>
    );
  }
}


TripInsights.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

TripInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
