import AddDriver from './new';
import DriverDetails from './Details';
import ManageDrivers from './manage';

export {
  AddDriver,
  DriverDetails,
  ManageDrivers
};

export default DriverDetails;
