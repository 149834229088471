import { del, get, post, patch } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getAllDrivers = () => {
  const fetchAllDrivers = get('/v1/drivers')
    .then((trips) => {
      cacheClient.set('/v1/drivers', trips);
      return trips;
    });

  return cacheClient.get('/v1/drivers')
    .catch((err) => {
      console.error('[Drivers model] Error fetching drivers from cache', err.stack);
      return fetchAllDrivers;
    })
    .then((data) => {
      if (data) return fetchAllDrivers;
      return fetchAllDrivers;
    });
};
//getDriverRoutes
export const getDriverRoutes = (id) => {
  const fetchRoutes = get(`/v1/drivers/${id}/routes`)
    .then((routes) => {
      cacheClient.set(`/v1/drivers/${id}/routes`, routes.routes);
      return routes.routes;
    });

  return cacheClient.get(`/v1/drivers/${id}/routes`)
    .catch((err) => {
      console.error('[Drivers model] Error fetching user routes from cache', err.stack);
      return fetchRoutes;
    })
    .then((data) => {
      if (data) return data;
      return fetchRoutes;
    });
};

export const getRoutes = async ({id}) => {
  console.log("getRoutes", id);
  return get(`/v1/routes/${id}/drivers`)
    .then(async(res) => {
      return res.data
    })
    .catch((error)=> {
      return error;
    })
}

export const getDriverTrips = (id) => {
  const fetchTrips = get(`/v1/drivers/${id}/trips`)
    .then((trips) => {
      cacheClient.set(`/v1/drivers/${id}/trips`, trips);
      return trips;
    });

  return cacheClient.get(`/v1/drivers/${id}/trips`)
    .catch((err) => {
      console.error('[Drivers model] Error fetching user trips from cache', err.stack);
      return fetchTrips;
    })
    .then((data) => {
      if (data) return data;
      return fetchTrips;
    });
};

export const driverTripsGraph = (id) => {
  const fetchDriverTripsGraph = get(`/v1/drivers/${id}/trips/graph`)
    .then((data) => {
      cacheClient.set(`/v1/drivers/${id}/trips/graph`, data);
      return data;
    });

  return cacheClient.get(`/v1/drivers/${id}/trips/graph`)
    .catch((err) => {
      console.error('[Driver model] Error fetching driver trips graph from cache', err.stack);
      return fetchDriverTripsGraph;
    })
    .then((data) => {
      if (data) return data;
      return fetchDriverTripsGraph;
    });
};

export const searchDrivers = name => post('/v1/drivers/search', { name });

export const updateDriver = (id, data) => patch(`/v1/drivers/${id}`, data);

export const assignRoute = (id, data) => post(`/v1/drivers/${id}/routes`, data);

export const startTripForRoute = (id, data) => post(`/v1/trips/`, Object.assign({driver_id: id},data));

export const unassignRoute = (id, data) => del(`/v1/drivers/${id}/routes`, data);

export const deleteDriver = id => del(`/v1/drivers/${id}`);

export const createDriver = data => post('/v1/drivers/', data);

export const getDriverDetail = (id) => get(`/v1/drivers/${id}`);

export const getDriverActiveTrips = (id) => get(`/v1/drivers/${id}/active-trips`);

export const endDriverTrip = (data, id) => patch(`/v1/trips/${id}`, data)