import { get, post, patch } from '../libs/requestClient';

export const getAllBusinesses = () => get('/v1/corporates', {limit: 50});

export const getBusinessGraph = () => get('/v1/corporates/graph');

export const getBusinessTripsGraph = id => get(`/v1/corporates/${id}/staff/graph`);

export const getBusinessStaff = id => get(`/v1/corporates/${id}/staff`);

export const updateBusiness = (id, data) => patch(`/v1/corporates/${id}`, data);

export const findBusiness = name => post('/v1/corporates/search', { name });

export const createBusiness = data => post('/v1/corporates/', data);

export const uploadFile = (file, options) => post('/v1/corporates/user_upload', file, options);

export const getBusinessInterest = () => get(`/v1/corporates/interest/list`);

export const approveBusinessInterest = (id) => post(`/v1/corporates/${id}/interest/approve`, {})

export const getAllPartners = () => get(`/v1/partner`);
