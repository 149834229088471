import { del, get, post, patch } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getAllBusStops = () => {
  const fetchAllBusStops = get('/v1/bus-stops')
    .then((trips) => {
      console.log(trips);

      cacheClient.set('/v1/bus-stops', trips);
      return trips;
    });

  return cacheClient.get('/v1/bus-stops')
    .catch((err) => {
      console.error('[Bus stop model] Error fetching bustops from cache', err.stack);
      return fetchAllBusStops;
    })
    .then((data) => {
      if (data) return fetchAllBusStops;
      return fetchAllBusStops;
    });
};

export const updateBusStop = (id, data) => patch(`/v1/bus-stops/${id}`, data);

export const deleteBusStop = id => del(`/v1/bus-stops/${id}`);

export const createBusStop = data => post('/v1/bus-stops/', data);

export const mergeBusStop = data => post('/v1/bus-stops/merge', data);
