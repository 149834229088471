import { del, get, post, patch } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';
import constants from "../constants"

export const getAllRoutes = () => {
  const fetchAllRoutes = get('/v1/routes?status=all')
    .then((routes) => {
      cacheClient.set('/v1/routes?status=all', routes);
      return routes;
    });

  return cacheClient.get('/v1/routes?status=all')
    .catch((err) => {
      console.error('[Route model] Error fetching routes from cache', err.stack);
      return fetchAllRoutes;
    })
    .then((data) => {
      if (data) return data;
      return fetchAllRoutes;
    });
};

export const getPickUpPoints = (id, noCache) => {
  const fetchRoutePickUp = get(`/v1/routes/${id}/pickups`)
    .then((route) => {
      cacheClient.set(`/v1/routes/${id}/pickups`, route);
      return route;
    });

  if (noCache) return fetchRoutePickUp;

  return cacheClient.get(`/v1/routes/${id}/pickups`)
    .catch((err) => {
      console.error('[Route model] Error fetching route from cache', id, err.stack);
      return fetchRoutePickUp;
    })
    .then((data) => {
      if (data) return data;
      return fetchRoutePickUp;
    });
};

export const getRoutePassengers = (id, noCache) => {
  const fetchRoutePassengers = get(`/v1/routes/${id}/users`)
    .then((passengers) => {
      cacheClient.set(`/v1/routes/${id}/users`, passengers);
      console.log('PASSENGERS::', passengers);
      return passengers;
    });

  if (noCache) return fetchRoutePassengers;

  return cacheClient.get(`/v1/routes/${id}/users`)
    .catch((err) => {
      console.error('[Route model] Error fetching passengers from cache', id, err.stack);
      return fetchRoutePassengers;
    })
    .then((data) => {
      if (data) return data;
      return fetchRoutePassengers;
    });
};

export const getRouteDestination = (id, pickUpId, noCache) => {
  const fetchRoutePickUp = get(`/v1/routes/${id}/pickups/${pickUpId}/destinations`)
    .then((route) => {
      cacheClient.set(`/v1/routes/${id}/pickups/${pickUpId}/destinations`, route);
      return route;
    });

  if (noCache) return fetchRoutePickUp;

  return cacheClient.get(`/v1/routes/${id}/pickups/${pickUpId}/destinations`)
    .catch((err) => {
      console.error('[Route model] Error fetching route from cache', id, err.stack);
      return fetchRoutePickUp;
    })
    .then((data) => {
      if (data) return data;
      return fetchRoutePickUp;
    });
};

export const getRoute = (id, noCache) => {
  const fetchRoute = get(`/v1/routes/${id}`)
    .then((route) => {
      cacheClient.set(`/v1/routes/${id}`, route);
      return route;
    });

  if (noCache) return fetchRoute;

  return cacheClient.get(`/v1/routes/${id}`)
    .catch((err) => {
      console.error('[Route model] Error fetching route from cache', id, err.stack);
      return fetchRoute;
    })
    .then((data) => {
      if (data) return data;
      return fetchRoute;
    });
};

export const getSuggestions = () => get('/v1/suggest');

export const getFutureRoutes = () => get('/v1/future_routes');

export const getLuggageConfig = () => get('/v1/routes/luggage/get');

export const searchRoutes = (search, lean = false) => post('/v1/routes/search', { search,  lean});

export const updateRoute = (id, data) => patch(`/v1/routes/${id}`, data);

export const createItinerary = (id, data) => post(`/v1/routes/${id}/itinerary`, data);

export const updateItinerary = (itineraryId, pickupId, data) =>
  patch(`/v1/routes/pickup/${pickupId}/itinerary/${itineraryId}`, data);

export const createRoutePickup = (id, data) => post(`/v1/routes/${id}/pickups`, data);

export const updateRoutePickup = (id, pickupId, data) => patch(`/v1/routes/${id}/pickups/${pickupId}`, data);

export const updateRouteDestination = (id, pickupId, destinationId, data) =>
  patch(`/v1/routes/${id}/pickups/${pickupId}/destinations/${destinationId}`, data);

export const updateRouteSeats = (id, data) => patch(`/v1/routes/${id}/seats`, data);

export const createRouteDropOff = (id, pickUpId, data) =>
  post(`/v1/routes/${id}/pickups/${pickUpId}/destinations`, data);

export const createRoute = async (data) => {
  if((!data.private || !data.private) && (!data.public || !data.public.length)) {
    return {status: false, message: "Please set route visibility"};
  }

  if((data.private && data.private.includes("on"))) {
    data.visibility = constants.PRIVATE;
  }else {
    data.visibility = constants.PUBLIC;
  }
  return post('/v1/routes', data);
};

export const deleteRoute = id => del(`/v1/routes/${id}`);

export const deleteRouteDestination = (id, pickup_id, destination_id) => del(`/v1/routes/${id}/pickups/${pickup_id}/destinations/${destination_id}`);

export const deleteRoutePickup = id => del(`/v1/routes/${id}/pickups`);

export const getRouteDays = async (id) => {
  return get(`/v1/routes/routedays/${id}`)
    .then(async(res) => {
      return res
    })
    .catch((error)=> {
      return error;
    })
}

export const assignRoute = (id, data) => post(`/v1/routes/${id}/routes`, data);
export const unassignRoute = (id, data) => del(`/v1/routes/${id}/routes`, data);
export const getBookings = (id, data, corporate_id) => post(`/v1/routes/${id}/bookings/${corporate_id}`, data);
export const getBookingReport = (id, data) => get(`/v1/routes/${id}/bookings/start_date/${data.start_date}/end_date/${data.end_date}`)
export const getCorporateBookingReport = (id, month, route_id) => get(`/v1/corporates/${id}/bookings/route/${route_id}/duration/${month}`);
export const processRouteDirection = (data) => post(`/v1/routes/direction`, data);
