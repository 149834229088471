import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import ActiveTripsTable from '../../components/ActiveTripsTable';
import DatePicker from '../../components/datepicker';

import '../../styles/dashboard.css';

export default class ActiveTrips extends Component {
  constructor() {
    super();
    this.state = {
      users: 0,
      filter: null,
    };
  }

  componentDidMount() {
    
  }

  componentWillReceiveProps() {
    this.setState({ filter: null });
  }

  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;
    if (from && to) this.setState({ filter: { from, to } });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/users',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="clearfix" />
        <div className="sub-page-header col-sm-12">
          <h2>Active Trips for Today</h2>
        </div>

        <div className="col-md-12 col-lg-12">

          <div className="module focus green">
            <div className="module-header" />
            <ActiveTripsTable filter={this.state.filter} setDashboardMessage={this.props.setDashboardMessage} />
          </div>
        </div>

      </div>
    );
  }
}


ActiveTrips.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

ActiveTrips.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
