import AddOntripService from './add';
import OntripServiceDetails from './details';
import OntripServicesInsights from './insights';

export {
  AddOntripService,
  OntripServiceDetails,
  OntripServicesInsights,
};

export default OntripServicesInsights;
