import React from 'react';
import PropTypes from 'prop-types';


export default class DatePicker extends React.Component {
    constructor() {
        super();
        this.state = { value: null, type: null };
    }

    componentDidMount() {
        this.setupDatepicker(this.props.type);
    }

    componentDidUpdate() {
        this.setupDatepicker(this.props.type);
    }

    setupDatepicker(type) {
        // cache this so we can reference it inside the datepicker
        const comp = this;
        const el = this.datepicker;

        if (type === 'time') {
            window.jQuery(el).pickatime({
                closeOnSelect: true,
                interval: 5,
                onSet: () => {
                    // you can use any of the pickadate options here
                    const val = comp.datepicker.value;
                    comp.setState({ value: val });
                    this.props.onUpdate(val);
                },
            });
        } else {
            window.jQuery(el).pickadate({
                format: 'yyyy-mm-dd',
                formatSubmit: 'yyyy-mm-dd',
                selectMonths: true,
                selectYears: 5,
                closeOnSelect: true,
                onSet: () => {
                    // you can use any of the pickadate options here
                    const val = comp.datepicker.value;
                    comp.setState({ value: val });
                    this.props.onUpdate(val);
                },
            });
        }
    }

    render() {
        return (
            <input
                type="date"
                ref={(input) => { this.datepicker = input; }}
                required={this.props.required}
                className="form-text timepicker"
                defaultValue={this.state.value}
                placeholder={this.props.placeholder}
            />
        );
    }
}

DatePicker.propTypes = {
    required: PropTypes.bool,
    type: PropTypes.string,
    onUpdate: PropTypes.func,
    placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
    required: false,
    type: 'time',
    placeholder: '',
    onUpdate: () => {},
};
