import React from "react";
import PropTypes from "prop-types";
import Table from "@ashvin27/react-datatable";

export default function Datatable({ data, columns, title, showButtons }) {
  const config = {
    page_size: 20,
    length_menu: [10, 20, 50],
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    show_first: true,
    filename: title,
    no_data_text: 'No data available!',
    sort: { column: "id", order: "desc" },
    show_last: true,
    button: {
      csv: true,
      excel: false,
      print: true
    }
  };

  if (!showButtons) {
    delete config.button;
  }

  return (
    <div style={{ width: '100%' }}>
      <Table
        records={data}
        columns={columns}
        config={config}
      />
    </div>
  );
}

Datatable.propTypes = {
  type: PropTypes.string,
  query: PropTypes.string,
  nameOnly: PropTypes.bool,
  summary: PropTypes.bool,
  setDashboardMessage: PropTypes.func
};

Datatable.defaultProps = {
  type: "users",
  query: "",
  nameOnly: false,
  summary: false,
  setDashboardMessage: () => { }
};

