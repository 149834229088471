import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, NavLink} from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Datatable from '../../components/datatable';
import { getAllBusStops, mergeBusStop, deleteBusStop } from "../../models/busStops";
import '../../styles/dashboard.css';

export default function BusStops(props) {
  const [busStops, setBusStops] = useState([]);
  const [selectedBusStops, setSelectedBusStops] = useState([]);
  const [meta, setMeta] = useState({
    loading: false,
    error: false,
    success: false,
  });

  useEffect(() => {
    getAllBusStops()
      .then(data => {
        setBusStops(data);
      })
      .catch(e => {
        console.error("[Bus stop List] Error fetching bus stop data", e);
        setMeta({ error: true });
      })
  }, [])

  const user = props.user;

  // if we have a user session we redirect away from sign up
  if (!user || !user.id) {
    return (
      <Redirect to={{
        push: true,
        rtURL: `/${this.props.type}/manage`,
        rtProps: this.props.location.state,
        pathname: '/login',
      }}
      />
    );
  }

  const btn_labels = [
    'select',
    'delete'
  ]

  const columns = [
    {
      key: "location",
      text: "Location",
      sortable: true,
    },
    {
      key: "location_description",
      text: "Location Description",
      sortable: true,
    },
    {
      key: 'action',
      text: 'Action',
      align: 'left',
      sortable: false,
      cell: record => {
        return (
          <div>
            {Array.isArray(btn_labels) && btn_labels.map((info, key) => (
                  <button
                    key={key}
                    type="button"
                    className={(info == 'select') 
                      ? "btn btn-primary btns"
                      : "btn btn-danger btns"
                    }
                    style={{ textAlign: 'center' }}
                    onClick={async (e) => {
                      const res = selectedBusStops.find(x => x.id === record.id);
                      
                      if((info == "select")) {
                        if (res) {
                          alert('You have selected this bus stop already');
                        } else {
                          setSelectedBusStops([...selectedBusStops, record]);
                        }
                      }
                      else {
                        const action = window.confirm("Do you really want to delete bus-stop?");
                        if(action) {
                          await deleteBusStop(record.id);
                          alert('Bus-stop deleted successfully');
                          return window.location.assign("/bus-stops");
                        }
                      }
                    }}
                  >
                    {info}
                  </button>
                ))
              }

              <NavLink to={{pathname:`/bus-stops/update/`, state: {data: record}}}>
                <button
                  type="button"
                  className="btn btn-warning btns"
                  style={{ textAlign: 'center' }}
                >
                  Update
                </button>
              </NavLink>
          </div>
        );
      },
    }
  ]

  const schema = Yup.object().shape({
    location: Yup.string().required('Location is required'),
    description: Yup.string().required('Location description is required'),
  });

  return (
    <div className="user-dash-wrapper">

      <div className="sub-page-header col-sm-12">
        <h2>Manage Bustops</h2>
      </div>

      <Formik
        initialValues={{ location: '', description: '' }}
        validationSchema={schema}
        onSubmit={async (values, action) => {
          try {
            if (selectedBusStops.length < 2) {
              alert('Please select at least two bus stops to merge');
              return;
            }

            setMeta({ loading: true });

            const ids = selectedBusStops.map(item => item.id);

            await mergeBusStop({
              ids,
              location: values.location,
              location_description: values.description,
            });

            setMeta({ loading: false });
            props.setDashboardMessage("success", "All selected bus stops have been merged successfully");

            setSelectedBusStops([]);
            action.resetForm();
            // at this point refetch the bustops from the server
            // getAllBusStops(); , this didnt work, reload the broswer 
            setTimeout(() => {
              window.location.reload();
            }, 3000)
          } catch (ex) {
            props.setDashboardMessage("error", `failed to merge bus stops`);
            setMeta({ loading: false, error: 'Error in meging bus stops, please try again soon' });
          }
        }}
      >
        {props => {
          const { values, handleChange, handleSubmit, errors, touched } = props;

          return (
            <form onSubmit={handleSubmit}>
              <div className="row" style={{ margin: 10 }}>
                <div className="col-md-4">
                  <div className="row" style={{ marginBottom: 10 }}>
                    <div className="form-element">
                      <input
                        required
                        name="location"
                        type="text"
                        className="form-text"
                        value={values.location}
                        onChange={handleChange}
                        placeholder="Location"
                      />
                      {errors['location'] && touched['location'] && <p style={{ color: 'red' }}>{errors['location']}</p>}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-element">
                      <textarea
                        required
                        name="description"
                        type="text"
                        className="form-text"
                        value={values.description}
                        onChange={handleChange}
                        placeholder="Location description"
                        rows={6}
                      />
                      {errors['description'] && touched['description'] && <p style={{ color: 'red' }}>{errors['description']}</p>}
                    </div>
                  </div>

                  <button
                    className="select-bvtton--active"
                    disabled={meta.loading}
                    type="submit"
                  >
                    {meta.loading ? 'Merging...' : 'Merge'}
                  </button>
                </div>

                <div className="col-md-8">
                  <div style={{ margin: 10, display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                    {selectedBusStops.map(item => (
                      <div style={{ margin: 5, backgroundColor: 'antiquewhite', padding: 10 }} key={item.id}>
                        <p style={{ display: 'inline', marginRight: '10px' }}>{item.location}</p>

                        <span onClick={() => {
                          const selected = selectedBusStops.filter(each => each.id != item.id);
                          setSelectedBusStops(selected);
                        }}
                        >
                          x
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </form>
          )
        }}
      </Formik>

      <div className="col-md-12 col-lg-12">
        <div className="module focus green">
          <div className="module-header" />
            <Datatable
              title="Bus Stops"
              data={busStops}
              columns={columns}
            />
        </div>
      </div>
    </div>
  );
}


BusStops.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

BusStops.defaultProps = {
  user: null,
  type: 'bustops',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
