import React, { Fragment, useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';

import Datatable from '../../components/datatable';
import { getAllUsers, NotifyUsers } from "../../models/users";
import "./transfers.css";
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { createPromotion } from '../../models/promotions';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import '../../styles/spinner.css';
export default function Notify(props) {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [meta, setMeta] = useState({
        loading: false,
        error: false,
        success: false,
        expires_at: null,
        notifyAll: false,
        loadingData: false,
        loadingSuccessFull: true,
    });

    useEffect(() => {
        getAllUsers()
            .then(users => {
                setUsers(users);
            })
            .catch(ex => {
                console.log(ex);
            });
    }, [])

    const columns = [
        {
            key: "email",
            text: "Email",
            sortable: true,
        },
        {
            key: "phone",
            text: "Phone",
            sortable: true,
        },
        {
            key: 'action',
            text: 'Action',
            width: 50,
            align: 'left',
            sortable: false,
            cell: record => {
                return (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{ textAlign: 'center' }}
                        onClick={() => {
                            const res = selectedUsers.find(x => x.id === record.id);

                            if (res) {
                                alert('You have selected this user already');
                            } else {
                                setSelectedUsers([...selectedUsers, record]);
                            }
                        }}
                    >
                        Select
                    </button>
                );
            },
        }
    ];

    const schema = Yup.object().shape({
        name: Yup.string().required('Promotion name is required'),
        reward_kind: Yup.string().required('Promotion reward kind is required'),
        reward_amount: Yup.number().required('Promotion reward amount is required'),
    });

    let onFormikSubmitted = async (values, action) => {
        try {
            setMeta({ loading: true });

            if (selectedUsers.length < 1 && !values.notify_all) {
                setMeta({ loading: false });
                alert('Please select at least one user');
                return;
            }

            let user_ids = [];
            if (!values.notify_all) {
                user_ids = selectedUsers.map(item => {
                    return { owner_type: 'customer', owner_id: item.id };
                });
            }
            const payload = Object.assign({
                customer_benefit: {
                    reward_amount: values.reward_amount,
                    reward_kind: values.reward_kind,
                    customer_constraint: 'none',
                }
            }, values, {
                owners: user_ids,
            });


            if (payload.hasOwnProperty('promo_code') && !payload.promo_code) {
                delete payload.promo_code;
            }

            if (values.notify_all) {
                delete payload.owners;
                payload.owner_type = 'all'
                payload.owner_id = 1;
            }

            if (values.customer_constraint && values.customer_constraint != "") {
                payload.customer_benefit['customer_constraint'] = values.customer_constraint;
            }


            if (values.owner_reward_kind && values.owner_reward_kind != "") {
                payload.owner_benefit = {
                    reward_amount: values.owner_reward_amount,
                    reward_kind: values.owner_reward_kind,
                }
            }

            await createPromotion(payload);

            setMeta({ loading: false });
            props.setDashboardMessage("success", "Promotion successfully generated for all selected users");
            setSelectedUsers([]);
            action.resetForm();
        } catch (ex) {
            props.setDashboardMessage("error", `failed to notify users`);
            setMeta({ loading: false, error: 'Error in sending push notification, please try again soon' });
        }
    };

    return (
        <Fragment>
            <div className="user-dash-wrapper">
                <div className="sub-page-header col-sm-12">
                    <h2>Make Promo Code for Users</h2>
                </div>

                {meta.error && (
                    <div className="col-md-12 col-lg-12">
                        <div className="module">
                            <div className="module-header">
                                <div className="module-wrapper">
                                    <div className="module-content">
                                        <h3 style={{ color: "red" }}>
                                            failed to generate promo code
                      </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Formik
                    initialValues={{
                        customer_constraint: 'none',
                        promo_code: '',
                        name: '',
                        description: '',
                        max_number_of_uses: 0,
                        reward_amount: 0,
                        reward_kind: 'wallet_credit',
                        owner_reward_amount: 0,
                        owner_reward_kind: '',
                        reward_owner: false,
                        notify_all: false,
                    }}
                    validationSchema={schema}
                    onSubmit={onFormikSubmitted}
                >
                    {props => {
                        const { values, handleChange, handleSubmit, errors, touched } = props;
                        return (

                            <div className="form-wrapper">
                                <form
                                    onSubmit={handleSubmit}
                                >
                                    <div className="row">
                                        <div className="form-element col-sm-6">
                                            <span className="label">Name</span>
                                            <input
                                                required
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                type="text"
                                                className="form-text"
                                            />
                                        </div>
                                        <div className="form-element col-sm-6">
                                            <span className="label">Description</span>
                                            <input
                                                required
                                                name="description"
                                                value={values.description}
                                                onChange={handleChange}
                                                type="text"
                                                className="form-text"
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">

                                        <div className="col-sm-6 form-element">
                                            <span className="label">Max Number of Uses (Use Zero for unlimited)</span>
                                            <input
                                                required

                                                name="max_number_of_uses"
                                                value={values.max_number_of_uses}
                                                onChange={handleChange}
                                                type="number"
                                                className="form-text"
                                            />
                                        </div>

                                        <div className="form-element col-sm-6">
                                            <span className="label">Expiration Date (Leave blank to never expire)</span>
                                            <DatePicker
                                                selected={meta.expires_at}
                                                onChange={date => setMeta({ expires_at: date })}
                                                showTimeSelect
                                                isClearable
                                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                                className="form-text"
                                            />
                                        </div>


                                        {/* <div className="form-element col-sm-4">

                                            <span className="label">Promo Code</span>
                                            <input
                                                placeholder="(Optional)"
                                                name="promo_code"
                                                value={values.promo_code}
                                                onChange={handleChange}
                                                type="text"
                                                className="form-text"
                                            />
                                        </div> */}

                                    </div>

                                    <div className="row mt-3">

                                        <div className="col-sm-4 form-element">
                                            <span className="label">Customer Reward Kind</span>
                                            <select
                                                required

                                                name="reward_kind"
                                                value={values.reward_kind}
                                                onChange={handleChange}
                                                className="form-text"
                                            >
                                                <option selected value="wallet_credit">Credit Customer's Wallet</option>
                                                <option value="flat_rate_discount_on_trips">Discount Customer's Trip by an Amount</option>
                                                <option value="percentage_discount_on_trips">Discount Customer's Trip by a Percentage</option>
                                            </select>
                                        </div>

                                        <div className=" col-sm-4 form-element">
                                            <span className="label">Customer Reward Amount</span>
                                            <input
                                                required
                                                name="reward_amount"
                                                value={values.reward_amount}
                                                onChange={handleChange}
                                                type="number"
                                                className="form-text"
                                            />
                                        </div>


                                        <div className="form-element col-sm-4">

                                            <span className="label">Set Customer Constrain</span>
                                            <select
                                                name="customer_constraint"
                                                value={values.customer_constraint}
                                                onChange={handleChange}
                                                className="form-text"
                                            >
                                                <option selected value="none">None</option>
                                                <option value="new_customer">Applies to only new customers</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="row mt-3">

                                        <div className="col-sm-4 form-element">
                                            <span className="label">Owner Reward Kind</span>
                                            <select
                                                name="owner_reward_kind"
                                                value={values.owner_reward_kind}
                                                onChange={handleChange}
                                                className="form-text"
                                            >
                                                <option selected value="">None</option>
                                                <option value="wallet_credit">Credit Owner's Wallet</option>
                                                <option value="flat_rate_discount_on_trips">Discount Owner's Trip by an Amount</option>
                                                <option value="percentage_discount_on_trips">Discount Owner's Trip by a Percentage</option>
                                            </select>
                                        </div>

                                        <div className="col-sm-4 form-element">
                                            <span className="label">Owner Reward Amount</span>
                                            <input
                                                name="owner_reward_amount"
                                                value={values.owner_reward_amount}
                                                onChange={handleChange}
                                                type="number"
                                                className="form-text"
                                            />
                                        </div>

                                    </div>

                                    <div className="row mt-3">

                                        <div className="col-sm-4 form-element">
                                            <label className="label" for="notify_all">
                                                Generate for all users
                                                <input
                                                    id="notify_all"
                                                    name="notify_all"
                                                    type="checkbox"
                                                    value={values.notify_all}
                                                    onChange={handleChange}
                                                    className="form-text"
                                                />
                                            </label>

                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <div className="col-sm-12">
                                            <div className="form-element">
                                                <button
                                                    className="bvtton select-bvtton--active"
                                                    disabled={meta.loading}
                                                    type="submit"
                                                    style={{ margin: 0 }}
                                                >
                                                    {meta.loading ? 'Creating Promotion...' : 'Create Promotion'}
                                                </button>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-8">
                                            <div style={{ margin: 10, display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                                                {selectedUsers.map(item => (
                                                    <div style={{ margin: 5, backgroundColor: 'antiquewhite', padding: 10 }} key={item.id}>
                                                        <p style={{ display: 'inline', marginRight: '10px' }}>{item.email}</p>
                                                        <span onClick={() => {
                                                            const selected = selectedUsers.filter(each => each.id != item.id);
                                                            setSelectedUsers(selected);
                                                        }}>
                                                            x
                                    </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        )
                    }}
                </Formik>

                <div className="col-md-12 col-lg-12">
                    <div className="module focus green">
                        <div className="module-header" />
                        <Datatable
                            title="Users"
                            data={users}
                            columns={columns}
                            showButtons={false}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

