import React from 'react'
import PropType from 'prop-types'
import { generateUID } from '../libs/uid'
import { linkUserToCard } from '../models/nfc'
import Modal from 'react-modal'

function StatusModal({ open, onClose, status, message }) {
    const customStyles = {
        content: {
            top: '20%',
            left: '30%',
            right: 'auto',
            bottom: 'auto',
            height: '40vh',
            borderRadius: '5px',
            width: '40%',
            // marginRight: '-50%',
            // transform: 'translate(-50%, -50%)',
            minWidth: '350px',
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-around',
            position: 'relative',
            flexDirection: 'column',
        },
        overlay: {
            backgroundColor: '#212b34cf',
            zIndex: 10,
        },
    }

    return (
        <Modal
            isOpen={open}
            //   onAfterOpen={this.afterOpenModal}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="status-modal-content">
                <img
                    src={`/assets/img/${
                        status === 'success' ? 'success.svg' : 'error.svg'
                    }`}
                />
                <p>
                    {status === 'success'
                        ? 'Linked Successfully'
                        : 'Link Failed'}
                    {status !== 'success' && (
                        <p className={'caption  caption-secondary'}>
                            Please try again
                        </p>
                    )}
                </p>
                {status !== 'success' && <p className={'caption'}>{message}</p>}
            </div>
            <button className="status-modal-button" onClick={onClose}>
                close
            </button>
        </Modal>
    )
}

function User({ user }) {
    if (!user) {
        return (
            <div className={`col-md-5`}>
                <div
                    className="module-wrapper card"
                    style={{ height: '300px' }}
                >
                    <p>Select a user from the Dropdown</p>
                </div>
            </div>
        )
    }

    return (
        <div className={`col-md-5`}>
            <div className="module-wrapper card" style={{ height: '300px' }}>
                <div
                    style={{ width: '70px', height: '70px' }}
                    className="round-image"
                >
                    <img
                        src={user.avatar || '/assets/img/default.png'}
                        alt="avatar"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </div>
                <div
                    className="module-header"
                    style={{ marginBottom: '15px', marginTop: '10px' }}
                >
                    <h3>{`${user.fname} ${user.lname}`}</h3>
                </div>
                <div className="text-segment">
                    <p className="card-text">{user.phone}</p>
                    <span className="card-caption">PHONE NUMBER</span>
                </div>
                <div className="text-segment">
                    <p className="card-text">{user.email}</p>
                    <span className="card-caption">EMAIL ADDRESS</span>
                </div>
            </div>
        </div>
    )
}

function NFCCard({ tagId, error }) {
    return (
        <div className={`col-md-5`}>
            <div className="card module-wrapper" style={{ height: '300px' }}>
                <img src="/assets/img/nfc.svg" alt="card" />
                <div
                    className="module-header"
                    style={{ marginBottom: '15px', marginTop: '10px' }}
                >
                    <h3>
                        {tagId
                            ? `Card ${tagId}`
                            : 'Please Scan Card to Link the card with a user'}
                    </h3>
                </div>
                <div className="text-segment">
                    <p className="card-text">Unlinked</p>
                    <span className="card-caption">STATUS</span>
                </div>
            </div>
        </div>
    )
}

export default class CardLink extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            nfcId: null,
            nfcError: null,
            linking: false,
            linkingError: null,
            statusModalIsOpen: false,
        }
    }

    componentDidMount() {
        this.readNfc()
    }

    writeToNfc = content => {
        navigator.nfc
            .push({
                records: [{ data: content }],
            })
            .then(() => {
                console.log('Tag successfully written.', content)
                this.setState({ nfcId: content })
            })
            .catch(error => {
                this.setState({ nfcError: error })
                console.log('Could not write to tag. Error: ', error)
            })
    }

    readNfc = () => {
        if ('nfc' in navigator) {
            navigator.nfc
                .watch(
                    message => {
                        console.log(
                            'NFC message received ' + '%O',
                            message //message.records[0].data()
                        )
                        if (message.records.length < 1) {
                            this.writeToNfc(generateUID())
                        } else if (message.records[0].recordType === 'empty') {
                            this.writeToNfc(generateUID())
                        } else {
                            const nfcId =
                                typeof message.records[0].data === 'function'
                                    ? message.records[0].data()
                                    : message.records[0].data
                            this.setState({ nfcId })
                        }
                    },
                    { mode: 'any' }
                )
                .then(() => console.log('Added a watch.'))
                .catch(err => {
                    this.setState({ nfcError: err.name })
                    console.log('Adding watch failed: ' + err.name)
                })
        } else {
            alert('NFC API not supported.')
        }
    }

    handleUserCardLinking = () => {
        this.setState({ linking: true, linkingError: null })
        linkUserToCard({
            user_id: this.props.user.id,
            nfc_id: this.state.nfcId,
        })
            .then(res => {
                this.setState({
                    linking: false,
                    statusModalIsOpen: true,
                    nfcId: null,
                })
                this.props.clearUser()
                console.log('res on link', res)
            })
            .catch(res => {
                this.setState({
                    linking: false,
                    linkingError: res.error.message,
                    statusModalIsOpen: true,
                })
                console.log('error', res.error.message)
            })
    }

    render() {
        return (
            <div style={{ padding: '20px 0px' }} className="row">
                <User user={this.props.user} />
                <div
                    className={'col-md-2 justify-center align-center '}
                    style={{ height: '300px' }}
                >
                    <img src="/assets/img/link.svg" alt="link" />
                </div>
                <NFCCard tagId={this.state.nfcId} error={this.state.nfcError} />
                <div className="justify-center" style={{ padding: '35px' }}>
                    <button
                        role="button"
                        className="bvtton"
                        disabled={
                            !this.state.nfcId ||
                            !this.props.user ||
                            this.state.linking
                        }
                        onClick={this.handleUserCardLinking}
                    >
                        {this.state.linking ? 'Linking ...' : 'Link User'}
                    </button>
                </div>
                <StatusModal
                    open={this.state.statusModalIsOpen}
                    onClose={() => this.setState({ statusModalIsOpen: false })}
                    status={this.state.linkingError ? 'error' : 'success'}
                    message={this.state.linkingError}
                />
            </div>
        )
    }
}

CardLink.propTypes = {
    user: PropType.object,
}

CardLink.defaultProps = {
    user: null,
}
