/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import createHistory from 'history/createBrowserHistory';

import { getUserWallet } from '../models/users';
import { getAllTransactions } from '../models/transactions';
import { formatMoney } from '../libs/utils';
import '../styles/spinner.css';

export default class TransactionHistory extends Component {
  constructor() {
    super();

    this.state = {
      transactions: [],
      loadingData: true,
      loadingSuccessFull: false,
    };

    this.history = createHistory();
    this.fetchUsersTransactions = this.fetchUsersTransactions.bind(this);
  }

  componentWillMount() {
    this.setState({ filter: this.props.filter });
  }

  componentDidMount() {
    const user = this.props.user;
    if (user) return this.fetchUsersTransactions(user);
    this.fetchAllTransactions();
  }

  componentWillReceiveProps(nextProps) {
    const filter = nextProps.filter || null;
    if (this.props.filter === filter) return false;
    if (!filter) return this.fetchAllTransactions();
    if (filter.from && filter.to) {
      this.fetchAllTransactions(filter);
    }
  }

  fetchUsersTransactions(user) {
    getUserWallet(user.id).then((data) => {
      this.setState({
        transactions: this.props.summary ? data.walletHistory.slice(0, 6) : data.walletHistory,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((e) => {
      console.error('[Transaction History] Error fetching data for user', user.id, e.stack);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  fetchAllTransactions(range) {
    this.setState({
      loadingData: true,
      loadingSuccessFull: false,
    });

    getAllTransactions(false, false, range).then((data) => {
      const transactions = data.data || data;
      console.log(transactions);

      this.setState({
        transactions: this.props.summary ? transactions.slice(0, 5) : transactions,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((e) => {
      console.error('[Transaction History] Error fetching all Transactions', e.stack);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  render() {
    const user = this.props.user || null;
    const isUser = user && user.id;

    return (
      <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
        <div className="module-content has-table has-users">
          <div className="module-table">
            <table className="">
              <thead>
                <tr>
                  <th>Transaction Date</th>
                  <th>Description</th>
                  {!isUser && <th>User</th>}
                  <th className="align-right">Amount</th>
                </tr>
              </thead>
              {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                <tbody>
                  <tr className="ride-data" style={{ display: 'table-row' }}>
                    <td colSpan="5">
                      <div className="ride-data-row">
                        <div>
                          Loading transaction history...
                                                    <div className="spinner" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              }
              {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                <tbody>
                  <tr className="ride-data" style={{ display: 'table-row' }}>
                    <td colSpan="5">
                      <div className="ride-data-row">
                        <div>
                          Sorry we could not load transaction history.
                                                </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              }
              {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                <tbody>
                  {this.state.transactions.map(transaction => (
                    <tr key={transaction.id} className="clickable"
                      onClick={() => {
                        if (isUser) transaction.user = user;
                        this.context.router.history.push('/transactions/details',
                          { transaction });
                      }
                      }
                    >
                      <td><Moment format="h:mm A">{transaction.created_at}</Moment>
                        <br /><Moment format="MMM Do YY">{transaction.created_at}</Moment>
                      </td>
                      <td>{transaction.title}</td>
                      {!isUser && <td>{transaction.user.fname} {transaction.user.lname}</td>}
                      <td className={`align-right money ${transaction.type}`}>
                        &#x20A6;{formatMoney(transaction.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              }

            </table>
          </div>
        </div>
        {isUser &&
          <div className="module-footer">
            <div className="pagination">
              <nav>
                <ul>
                  <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                  <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                </ul>
              </nav>
            </div>
          </div>
        }
      </div>
    );
  }
}

TransactionHistory.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
  }),
  filter: PropTypes.object,
  summary: PropTypes.bool,
};

TransactionHistory.defaultProps = {
  user: null,
  filter: null,
  summary: false,
};

TransactionHistory.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
