const LIVE_API = 'https://api.shuttlers.ng';
const DEV_API = 'https://api.qa.shuttlers.africa';
const localhost = 'http://localhost:8080';

let apiEnvironments = {
  "production": LIVE_API,
  "staging": DEV_API,
  "development": localhost
};


const config = {
  api: apiEnvironments[process.env.NODE_ENV] || LIVE_API,
  // changing this temporatily
  request: {
    timeout: 60000,
    retry: 2,
  },
}

if (process.env.REACT_APP_API_URL) {
  config.api = process.env.REACT_APP_API_URL
}

export default config
