
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';

import RoutesMap from '../../components/routeMap';
import { getTrip } from '../../models/trips';
import '../../styles/spinner.css';

export default class TripDetails extends Component {
  constructor() {
    super();
    this.state = {
      trip: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    const trip = this.props.location.state.trip;

    getTrip(trip.id)
      .then((data) => {
        console.log('Trips are', trip);

        this.setState({
          trip: data,
          loadingData: false,
          loadingSuccessFull: true,
        });
      }).catch((err) => {
        this.setState({
          loadingData: false,
          loadingSuccessFull: true,
        });
        this.props.setDashboardMessage('error', 'Sorry we could not load trip details');
        console.error('[Trip Details] Error fetching trip details', trip.id, err.stack);
      });
  }


  render() {
    const user = this.props.user;
    const trip = this.props.location.state.trip;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!trip) {
      return (
        <Redirect to={{
          pathname: '/trips',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Trip Details</h2>
          <div className="right-options" />
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>

        <div className="col-md-7">
          <div className="module">
            <div className="module-header">
              <h3>Trip Details</h3>
            </div>
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
              {this.state.loadingData && <div className="spinner" />}
              {this.state.loadingData &&
                <div className="ride-pre-head"><p>Please wait wihile we fetch trip data...</p></div>
              }
              {(this.state.trip && !this.state.loadingData) &&
                <div>
                  <div className="ride-pre-head">
                    <div className="col-sm-12">
                      <div className="ride-date">
                        <span />
                        <Moment format="MMMM Do, YYYY">{this.state.trip.created_at}</Moment>
                      </div>
                    </div>
                    <div className="the-driver col-sm-12">
                      <div className="user-table-display">
                        <div className="image css-img-object"
                          style={{ backgroundImage: `url(${this.state.trip.driver.avatar || '/assets/img/default.png'})` }} //eslint-disable-line
                        />
                        <h3>
                          <a href="" tabIndex="-1"
                            onClick={(e) => {
                              e.preventDefault();
                              this.context.router.history.push('/drivers/details',
                                { userDetails: this.state.trip.driver });
                            }
                            }
                          >
                            {this.state.trip.driver.fname} {this.state.trip.driver.lname}
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="head">
                    <div className="route-info">
                      <div className="col-md-6">
                        <div className="issa-route">
                          <span className="indicator indicator-v2 from">From</span>
                          {this.state.trip.route.pickup}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="issa-route">
                          <span className="indicator indicator-v2 to">To</span>
                          {this.state.trip.route.destination}
                        </div>
                      </div>

                      <div className="clearfix" />
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>
          <div className="module focus green">
            <div className="module-header">
              <h3>Passenger Manifest</h3>
            </div>
            {(this.state.trip && !this.state.loadingData) &&
              <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                <div className="module-content has-table has-users">
                  <div className="module-table">
                    {this.state.loadingData && <div className="spinner" />}
                    <table className="">
                      <thead>
                        <tr>
                          <th>Passenger</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.trip.users.map(person => (
                          <tr key={person.id} className="clickable"
                            onClick={() =>
                              this.context.router.history.push('/users/details',
                                { userDetails: person })}
                          >
                            <td>
                              <div className="user-table-display">
                                <div className="image css-img-object"
                                  style={{
                                    backgroundImage: `url(${person.avatar || '/assets/img/default.png'})`, // eslint-disable-line
                                  }}
                                />
                                <h3>{person.fname} {person.lname}</h3>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>

        <div className="col-sm-5">
          <div className="module">
            <div className="module-header">
              <h3>Route Map</h3>
            </div>
            {(this.state.trip && !this.state.loadingData) &&
              <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                <div className="module-content">
                  {this.state.loadingData && <div className="spinner" />}
                  <div className="module-ride-information-image">
                    <div className="pre-head">
                      <div className="map-image">
                        <RoutesMap
                          polyline={JSON.parse(this.state.trip.route.overview_polyline).points}
                          pickup_coordinate={this.state.trip.route.pickup_coordinate}
                          destination_coordinate={this.state.trip.route.destination_coordinate}
                          size="413x340"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

      </div>
    );
  }
}

TripDetails.propTypes = {
  user: PropTypes.object,
  trip: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

TripDetails.defaultProps = {
  user: null,
  trip: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

TripDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
