import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const Map = withGoogleMap(() =>
    (
        <GoogleMap defaultZoom={11} defaultCenter={{ lat: 6.465422, lng: 3.406448 }}>
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
        </GoogleMap>
    ));

Map.propTypes = {
    loadingElement: PropTypes.string,
    googleMapURL: PropTypes.string,
};

Map.defaultProps = {
    loadingElement: '',
    googleMapURL: '',
};
export default Map;
