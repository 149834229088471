import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../datepicker';

import { updateItinerary } from '../../models/routes';

export default class EditDepartureTime extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedTime: null,
            itinerary: null,
            error: false,
        };
    }

    updateItinerary(e) {
        e.preventDefault();

        const { pickup, itinerary, onUpdate } = this.props;
        const setDashboardMessage = this.props.setDashboardMessage;

        if (!this.state.selectedTime) {
            this.setState({ error: true });
            return false;
        }

        this.setState({ error: false });
        const departureTime = this.state.selectedTime.split(' ');
        updateItinerary(itinerary.id, pickup.id, {
            departure_time: departureTime[0],
            time_of_day: departureTime[1],
        }).then((data) => {
            onUpdate(data);
            setDashboardMessage('success', 'Departure time has been updated');
        }).catch((err) => {
            console.error('[Edit Departure Time] An error occurred while adding departure time', err.stack);
            setDashboardMessage('error', 'Sorry we could not edit departure for this route');
        });
    }

    render() {
        const itinerary = this.props.itinerary;
        const departure = `${itinerary.departure_time} ${itinerary.time_of_day}`;
        return (
            <div className="module">
                <div className="module-header" />
                <div className="module-wrapper">
                    <div className="module-content module-form-wrapper">
                        <div className="form-wrapper">
                            <form onSubmit={e => this.updateItinerary(e)}>
                                <div className="row">
                                    <div className="form-sub-header">
                                        <h4>Edit Departure Time</h4>
                                        <span>Edit departure time</span>
                                    </div>

                                    <div className="form-element col-md-12">
                                        <span className="label">Departure Time</span>
                                        <DatePicker
                                            type="time"
                                            placeholder={departure}
                                            onUpdate={data => this.setState({ selectedTime: data })}
                                        />
                                        {this.state.error &&
                                            <small style={{ color: '#E1354F' }}>Please select a departure time</small>
                                        }
                                    </div>

                                    <div className="clearfix" />

                                </div>
                                <div className="form-element">
                                    <input type="submit" className="bvtton" value="Edit Time" />
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="module-footer" />
                </div>
            </div>
        );
    }
}

EditDepartureTime.propTypes = {
    pickup: PropTypes.object,
    itinerary: PropTypes.object,
    onUpdate: PropTypes.func,
    setDashboardMessage: PropTypes.func,
};

EditDepartureTime.defaultProps = {
    pickup: null,
    itinerary: null,
    onUpdate: () => {},
    setDashboardMessage: () => {},
};
