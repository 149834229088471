import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import AddVehicle from '../../components/addVehicle';
import '../../styles/dashboard.css';

export default class VehicleRegistration extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/fleet/new',

          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Vehicle Registration</h2>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="row">

            <div className="col-sm-12">
              <div className="module">
                <div className="module-header" />
                <AddVehicle setDashboardMessage={this.props.setDashboardMessage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VehicleRegistration.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

VehicleRegistration.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
