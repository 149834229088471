import { get, post, patch } from "../libs/requestClient";
import cacheClient from "../libs/cacheClient";

export const getAllUsers = () => {
  const fetchAllUsers = get("/v1/users").then(users => {
    cacheClient.set("/v1/users", users);
    return users;
  });

  return cacheClient
    .get("/v1/users")
    .catch(err => {
      console.error("[User model] Error fetching users from cache", err.stack);
      return fetchAllUsers;
    })
    .then(data => {
      if (data) return data;
      return fetchAllUsers;
    });
};

export const getUserByCorporate = async (id) => {
  return await get(`/v1/users/corporate/${id}`)
    .then((res) => {
      return res;
    })
    .catch((E) => {
      console.log(E);
      return null;
    })
}

export const getAllLinkUsers = () => {
  const fetchAllLinkedUsers = get("/v1/nfc/linked-users").then(users => {
    cacheClient.set("/v1/users", users);
    return users;
  });

  return cacheClient
    .get("/v1/nfc/linked-users")
    .catch(err => {
      console.error("[User model] Error fetching users from cache", err.stack);
      return fetchAllLinkedUsers;
    })
    .then(data => {
      if (data) return data;
      return fetchAllLinkedUsers;
    });
};


export const searchUsers = user => post("/v1/users/search", { user });

export const getUserWallet = id => {
  const fetchWallet = get(`/v1/users/${id}/wallet`).then(wallet => {
    cacheClient.set(`/v1/users/${id}/wallet`, wallet);
    return wallet;
  });

  return cacheClient
    .get(`/v1/users/${id}/wallet`)
    .catch(err => {
      console.error(
        "[User model] Error fetching user wallet from cache",
        err.stack
      );
      return fetchWallet;
    })
    .then(data => {
      if (data) return data;
      return fetchWallet;
    });
};

export const getUserTrips = (id, scope) => {
  return cacheClient
    .get(`/v1/users/${id}/trips`)
    .catch(err => {
      console.error(
        "[User model] Error fetching user trips from cache",
        err.stack
      );
      return get(`/v1/users/${id}/trips`, {scope: scope}).then(trips => {
        cacheClient.set(`/v1/users/${id}/trips`, trips);
        return trips;
      });
    })
    .then(data => {
      if (data) return data;
      return get(`/v1/users/${id}/trips`, {scope: scope}).then(trips => {
        cacheClient.set(`/v1/users/${id}/trips`, trips);
        return trips;
      });
    });
};

export const getUserRoutes = id => {
  const fetchRoutes = get(`/v1/users/${id}/routes`).then(routes => {
    cacheClient.set(`/v1/users/${id}/routes`, routes);
    return routes;
  });

  return cacheClient
    .get(`/v1/users/${id}/routes`)
    .catch(err => {
      console.error(
        "[User model] Error fetching user routes from cache",
        err.stack
      );
      return fetchRoutes;
    })
    .then(data => {
      if (data) return data;
      return fetchRoutes;
    });
};

export const updateUser = (id, data) => patch(`/v1/users/${id}`, data);
export const getUser = id => get(`/v1/users/${id}`);
export const createUser = data => post("/v1/users", data);

export const usersGraph = () => {
  const fetchAllUsers = get("/v1/users/graph").then(users => {
    cacheClient.set("/v1/users/graph", users);
    return users;
  });

  return cacheClient
    .get("/v1/users/graph")
    .catch(err => {
      console.error(
        "[User model] Error fetching user graph from cache",
        err.stack
      );
      return fetchAllUsers;
    })
    .then(data => {
      if (data) return data;
      return fetchAllUsers;
    });
};

export const userTripsGraph = id => {
  const fetchUserTripsGraph = get(`/v1/users/${id}/trips/graph`).then(data => {
    cacheClient.set(`/v1/users/${id}/trips/graph`, data);
    return data;
  });

  return cacheClient
    .get(`/v1/users/${id}/trips/graph`)
    .catch(err => {
      console.error(
        "[User model] Error fetching user trips graph from cache",
        err.stack
      );
      return fetchUserTripsGraph;
    })
    .then(data => {
      if (data) return data;
      return fetchUserTripsGraph;
    });
};

export const updateUserProfilePicture = (user_id, blob) => patch(`/v1/user/avatar/${user_id}`, { avatar: blob })

export const BulkDebitUsers = payload => patch("/v1/wallet/batch/debit", payload);

export const BulkCreditUsers = payload => patch("/v1/wallet/batch/credit", payload);

export const NotifyUsers = payload => post("/v1/notifications/push", payload);
