import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { getAllBusStops } from "../../models/busStops";
import { createRoute, processRouteDirection } from '../../models/routes';
import '../../styles/spinner.css';
import {getAllBusinesses} from '../../models/business';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
const polyline = require('@mapbox/polyline');

export default function AddRoute({ user, location, setDashboardMessage }) {
  const [busStops, setBusStops] = useState([]);
  const [meta, setMeta] = useState({ loading: false, error: false, success: false });
  const [publicChecked, setPublicChecked] = useState(false);
  const [privateChecked, setPrivateChecked] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [currentOrgID, setCurrentOrgID] = useState(null);
  const [futureRoute, setFutureRoute] = useState(0);
  const [isPreview, setIsPreview] = useState(true);
  const [startCordinates, setStartCordinates] = useState(null);
  const [endCordinates, setEndCordinates] = useState(null);
  const [overviewPolyline, setOverviewPolyline] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [mapAtRest, setMapAtRest] = useState(true);
  const [geoJSON, setGeoJSON] = useState(true);
  const [bounds, setBounds] = useState(null);
  const [payload, setPayload] = useState({});

  //pick up
  const [startingValue, setStartingValue] = useState(null);
  const [startingLocation, setStartingLocation] = useState(null);

  //finat stop
  const [finalValue, setFinalValue] = useState(null);
  const [finalLocation, setFinalLocation] = useState(null);

  useEffect(() => {
    getAllBusStops()
      .then(data => {
        setBusStops(data);
      })
      .catch(e => {
        console.error("[Bus stop List] Error fetching bus stop data", e);
      })

      //get corporates
      async function getCorporates() {
        let businesses = await getAllBusinesses();
        setBusinesses(businesses.data)
      }
      getCorporates()
  }, [])

  const handleDropdownChange = (e) => {
    const organization_id = e.target.value;
    setCurrentOrgID(organization_id)
  }

  let corporateArray = [];
  businesses.map((each, key) => {
      corporateArray.push(
        <option value={each.id} key={key}>{each.corporate_name}</option>
      )    
  })

  if(startingValue) {
    geocodeByAddress(startingValue.label)
    .then(results => getLatLng(results[0]))
    .then(() => {
      setStartingLocation(startingValue.label);
    });
  }

  if(finalValue) {
    geocodeByAddress(finalValue.label)
    .then(results => getLatLng(results[0]))
    .then(() => {
      setFinalLocation(finalValue.label);
    });
  }


  const prepareRoutePreview = async (e) => {
    e.preventDefault();
    setMapLoading(true);
    if(!startingLocation || !finalLocation) {
      setDashboardMessage('error', 'Starting and ending location required');
      setMapLoading(false);
      return false;
    }

    await processRouteDirection({
      startPoint: startingLocation, 
      endPoint: finalLocation
    })
    .then((resp) => {
      if(resp && resp.data) {
        if(resp.data.routes && resp.data.routes[0].hasOwnProperty('overview_polyline')) {
          const overview_polyline  = resp.data.routes[0].overview_polyline.points;
          const { end_location, start_location } = resp.data.routes[0].legs[0];
          const bounds = resp.data.routes[0].bounds
          const requestData = {
            duration_value: resp.data.routes[0].legs[0].duration_in_traffic ? resp.data.routes[0].legs[0].duration_in_traffic.value : resp.data.routes[0].legs[0].duration.value,
            distance_value: resp.data.routes[0].legs[0].distance.value,
            duration: resp.data.routes[0].legs[0].duration.text,
            distance: resp.data.routes[0].legs[0].distance.text,
            start_location: resp.data.routes[0].legs[0].start_location,
            end_location: resp.data.routes[0].legs[0].end_location
          }
          setGeoJSON(polyline.toGeoJSON(overview_polyline));
          setBounds(bounds);
          setStartCordinates(`${start_location.lat}, ${start_location.lng}`);
          setEndCordinates(`${end_location.lat}, ${end_location.lng}`);
          setOverviewPolyline(overview_polyline);
          setPayload(requestData);
          setMapLoading(false);
          setIsPreview(false);
          setMapAtRest(false);
        }
      } else {
        throw new Error(
          'invalid location selected'
        )
      }
    })
    .catch((Err) => {
      console.log(Err, "An error occured while processing direction");
      setDashboardMessage('error', 'An error occured while processing direction');
      setMapLoading(false);
      return false;
    })
  }

  // if we have a user session we redirect away from sign up
  if (!user || !user.id) {
    return (
      <Redirect to={{
        push: true,
        rtURL: '/trips/details',
        rtProps: location.state,
        pathname: '/login',
      }}
      />
    );
  }

  const schema = Yup.object().shape({
    total_seats: Yup.string().required('Total seats is required'),
  });
  return (
    <div className="user-dash-wrapper">
      <div className="sub-page-header col-sm-12">
        <h2>New Route</h2>
        <div className="right-options" />
      </div>

      <div className="clearfix" />

      <div className="col-md-12 col-lg-12">
        <div className="row">
          <div className="col-sm-12">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper _row_div">
                <div className="module-content module-form-wrapper section_">
                  <div className="form-wrapper">
                    <Formik
                      initialValues={
                        { 
                          day_of_week: 'MON - FRI', 
                          total_seats: '',
                          corporate_id: null,
                          is_future_route: null
                        }
                      }
                      validationSchema={schema}
                      onSubmit={(values, actions) => {
                        setMeta({ loading: true });
                        if(privateChecked && !currentOrgID) {
                          setDashboardMessage('error', 'Please select an organization');
                          setMeta({ error: true, loading: false });
                          return false;
                        }

                        createRoute({
                          ...values,
                          pickup: startingLocation,
                          destination: finalLocation,                       
                          corporate_id: currentOrgID,
                          is_future_route: futureRoute,
                          route_preview: geoJSON,
                          bounds: bounds,
                          ...payload
                        })
                        .then(async(res) => {
                          if(res.hasOwnProperty("status") && !res.status) {
                            console.error('[Create Route] Error creating route', values, res.message);
                            setDashboardMessage('error', res.message);
                            setMeta({ loading: false, error: true });
                            return false;
                          }
                          setDashboardMessage('success', 'Route created successfully');
                          setMeta({ loading: false, success: true });

                          setTimeout(() => window.location.assign('/trips/routes/'), 1000);
                          actions.resetForm()
                        }).catch((err) => {
                          let message;
                          if(err.error.hasOwnProperty('code') && err.error.code == "RouteAlreadyExist") {
                            message = "Route already exists";
                          }
                          else {
                            message = "Sorry an error occurred while creating the route";
                          }
                          console.error('[Create Route] Error creating route', values, err.stack);
                          setDashboardMessage('error', message);
                          setMeta({ loading: false, error: true });
                        });
                      }}
                    >
                      {props => {
                        const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } = props;

                        return (
                          <form onSubmit={handleSubmit}>
                            <div className="row">

                              <div className="form-sub-header">
                                <h4>Route Information</h4>
                                <span>Information the origin and destination of a ride.</span>
                              </div>

                              <div className="form-element col-sm-12">
                                <span className="label">Starting Location</span>
                                <div>
                                  <GooglePlacesAutocomplete
                                    apiKey="AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE"
                                    selectProps={{
                                      startingValue,
                                      onChange: setStartingValue,
                                      isClearable:true
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-element col-sm-12">
                                <span className="label">Final stop</span>
                                <GooglePlacesAutocomplete
                                  apiKey="AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE"
                                  selectProps={{
                                    finalValue,
                                    onChange: setFinalValue,
                                    isClearable:true
                                  }}
                                />
                              </div>

                              <div className="form-element col-md-12">
                                <span className="label">
                                  Total Number Seats Available for this route
                              </span>
                                <input
                                  type="number"
                                  required
                                  name="total_seats"
                                  value={values.total_seats}
                                  onChange={handleChange}
                                  className="form-text"
                                  onBlur={handleBlur}
                                />
                                {errors['total_seats'] && touched['total_seats'] && <p style={{ color: 'red' }}>{errors['total_seats']}</p>}
                              </div>
                              <div className="form-element col-md-12">
                                <span className="label">
                                  Enter Route Code
                              </span>
                                <input
                                  type="text"
                                  required
                                  minlength="2"
                                  maxlength="5"
                                  name="route_code"
                                  value={values.route_code}
                                  onChange={handleChange}
                                  className="form-text"
                                  onBlur={handleBlur}
                                  placeholder="Enter route code. Max of 5 letters"
                                />
                                {errors['total_seats'] && touched['total_seats'] && <p style={{ color: 'red' }}>{errors['total_seats']}</p>}
                              </div>
                              <div className="clearfix" />
                            </div>
                            <div className="checbbox_sec">
                              <div className="future_route">
                                <label className="future_route_label">Set as future route</label>
                                <input 
                                  type="checkbox" 
                                  name="is_future_route"
                                  onChange={(e) => {
                                    setFutureRoute(!futureRoute);
                                  }}
                                />
                              </div>
                              <span className="main_label">Set route visibility</span>
                              <label
                                className="check_label"
                                htmlFor="public"
                              >
                                <div class="flex_label">
                                  <input
                                    className="form-check-input check_box"
                                    type="checkbox"
                                    name="public"
                                    onChange={(e) => {
                                      setPublicChecked(true);
                                      setPrivateChecked(false);
                                      handleChange(e);
                                    }}
                                    checked={(privateChecked || (!publicChecked && !privateChecked))
                                        ?  false 
                                        : true
                                      }
                                    />
                                  <p className="p">Public</p>
                                </div>
                              </label>
                              <label
                                className="check_label"
                                htmlFor="private"
                              >
                                <div class="flex_label">
                                  <input
                                    className="form-check-input check_box"
                                    type="checkbox"
                                    name="private"
                                    onChange={(e) => {
                                      setPrivateChecked(true);
                                      setPublicChecked(false);
                                      handleChange(e)
                                    }}
                                    checked={(publicChecked || (!publicChecked && !privateChecked))
                                       ? false 
                                       : true
                                      }
                                  />
                                  <p className="p">Private</p>
                                </div>
                              </label>
                              {
                                (privateChecked) &&
                                <div className="corporates_div">
                                  <label for="corporates" name="total_seats">Select Organization</label>
                                  <select 
                                    name="corporates" 
                                    className="select_corporate"
                                    onChange={handleDropdownChange}
                                  >
                                    <option value="" key="">Select Organization</option>
                                    {corporateArray}
                                  </select>                                  
                                </div>
                              }
                            </div>
                            <div className="form-element">
                              {
                                !isPreview 
                                ? (
                                  <input
                                    type="submit"
                                    className="bvtton"
                                    style={{width:'100%', marginTop: '10%', marginBottom: '5%', cursor: 'pointer'}}
                                    value={meta.loading ? 'Creating Route...' : 'Create Route'}
                                  />
                                )
                                : (
                                  <input
                                    type="button"
                                    className="bvtton"
                                    style={{width:'100%', marginTop: '10%', marginBottom: '5%', cursor: 'pointer'}}
                                    value={(mapLoading) ? 'Loading' : 'Preview'}
                                    onClick={(e) => prepareRoutePreview(e)}
                                  />
                                )
                              }
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  </div>
                </div>
                <div>
                  <div className="map_sec">
                  {
                    mapAtRest ? (
                      <div
                        style={{height: '100%', width: '100%'}}
                      >
                        <p
                          style={{
                            margin: 'auto', 
                            marginTop: '50%', 
                            textAlign:'center',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            color: (mapLoading) ? 'orange' : 'unset'
                          }}
                        >
                          {(!mapLoading) ? "Select start and end location to preview Map" : "Loading..."}
                        </p>
                      </div>
                    )
                    : (
                      <div className="map-image">
                        <img
                            src={
                                `https://maps.googleapis.com/maps/api/staticmap?&maptype=roadmap&markers=color:green%7Clabel:A%7C${startCordinates}&markers=color:red%7Clabel:B%7C${endCordinates}&&path=weight:3%7Ccolor:blue%7Cenc:${overviewPolyline}&size=500x1000&key=AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE`} // eslint-disable-line
                            alt="map"
                        />
                      </div>
                    )
                  }
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div >
  );
}



AddRoute.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AddRoute.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AddRoute.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
