/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { post } from '../../libs/requestClient';
import { scrollToEl } from '../../libs/utils';

export default class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      resetMessage: '',
      resetSuccess: false,
    };
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  componentDidMount() {
    this.props.setLayout('login'); // reset page layout
    const documentHeight = $(window).innerHeight();
    const headerHeight = $('header').outerHeight();

    if (headerHeight > 0) {
      $('.right').css('min-height', `${documentHeight}px`);
    }
  }

  componentWillUnmount() {
    this.props.setLayout('default'); // set page layout
  }

  /**
   * Sets the message and type
   * @param {string} resetMessage - the message to display
   * @param {boolean} resetSuccess - true for success message, false for error message
   */
  setMessage(resetMessage, resetSuccess) {
    this.setState({ resetMessage, resetSuccess });
    scrollToEl('form-message');
  }

  /**
   * Sends password reset link to valid email
   * @param {Event} e - the event that triggered the reset
   */
  forgotPassword(e) {
    e.preventDefault();

    const requestData = {
      type: 'staff',
      email: this.email.value,
    };

    this.setState({ resetMessage: '', resetSuccess: false });
    if (!this.email.value) {
      return this.setMessage('Your email is required.', false);
    }
    if (!this.yes.checked) {
      return this.setMessage('Accept password change to proceed.', false);
    }

    this.setState({ loading: true });

    post('/v1/password', requestData)
      .then(() => {
        this.setMessage('Please check your email. We just sent a link to reset your password.', true);
        this.createForm.reset();
      })
      .catch((err) => {
        console.error('Error in resetting your password', err);
        if (err.body && err.body.message) {
          return this.setMessage(err.body.message.toUpperCase().replace('_', ' '), false);
        }
        this.setMessage('Sorry we could not reset your password. Please try again', false);
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const user = this.props.user;
    const rtURL = this.props.location.rtURL || '/';
    const rtProps = this.props.location.rtProps || {};

    // if we have a user session we redirect away from login
    if (user && user.id) {
      return (
        <Redirect to={{
          push: true,
          state: rtProps,
          pathname: rtURL,

        }}
        />
      );
    }
    return (
      <section className="nopadding split-screen">
        <div className="container-fluid full-height">
          <div className="row">
            <div className="col-sm-6 col-md-5  col-lg-4 left">
              <div className="split-form-wrapper">
                <div className="wrapper-header">
                  <div className="back-to-site">
                    <Link to="" onClick={this.props.history.goBack} className="bvtton small grey">
                      <i className="fa fa-long-arrow-left" />Back
                                        </Link>
                  </div>
                  <div className="logo">
                    <Link to="/"><img src="/assets/img/logo.png" alt="" /></Link>
                  </div>
                  <div className="caption">
                    <h2>So you forgot password</h2>
                    <p>
                      {'Don\'t worry, it happens to everyone.'}<br /><br />
                      {`Simply enter the e-mail address associated with your account
                                             and we'll send you an e-mail with further instructions.`}
                    </p>
                  </div>
                </div>
                <div className="wrapper-body">
                  <div className="form-wrapper" id="form-message">
                    {this.state.resetMessage &&
                      <div className={this.state.resetSuccess ? 'success' : 'error'}>
                        {this.state.resetMessage}
                      </div>
                    }
                    <form
                      onSubmit={e => this.forgotPassword(e)}
                      ref={(form) => { this.createForm = form; }}
                    >
                      <div className="form-element">
                        <span className="label">E-mail Address</span>
                        <input
                          type="email"
                          className="form-text"
                          required
                          ref={(input) => { this.email = input; }}
                        />
                      </div>
                      <div className="form-element">
                        <input
                          type="checkbox"
                          value="1"
                          ref={(input) => { this.yes = input; }}
                          id="remember"
                        />
                        <label htmlFor="remember"> Yes I want to reset this password</label>
                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Reset Password" />
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-sm-6  col-md-7 col-lg-8 right  hide-on-mobile css-img-object"
              style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1495264442745-e8edc9e7949b?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=)' }} // eslint-disable-line
            />

          </div>
        </div>
      </section>
    );
  }
}

ForgotPassword.propTypes = {
  user: PropTypes.object,
  setLayout: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  location: PropTypes.shape({
    rtProps: PropTypes.object,
    rtURL: PropTypes.string,
  }),
};

ForgotPassword.defaultProps = {
  user: null,
  setLayout: () => { },
  history: {
    goBack: () => { },
  },
  location: {
    rtProps: {},
    rtURL: '/',
  },
};
