import React, { Fragment, useState, useEffect } from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';

import Datatable from '../../components/datatable';
import { getAllUsers, NotifyUsers, getUserByCorporate } from "../../models/users";
import { getAllBusinesses } from '../../models/business';
import "./transfers.css";
import '../../styles/spinner.css'

export default function Notify(props) {
  const [users, setUsers] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [notifyAll, setNotifyAll] = useState(false);
  const [isFlash, setIsFlash] = useState(false);
  const [meta, setMeta] = useState({
    loading: false,
    error: false,
    success: false,
  });

  useEffect(() => {
    getAllUsers()
      .then(users => {
        setUsers(users);
      })
      .then(async () => {
        try {
          let businesses = await getAllBusinesses();
          businesses = businesses.data.filter((x) => x.active === '1')
          setCorporates(businesses)
        }
        catch(e) {
          throw e;
        } 
      })
      .catch(ex => {
        console.log(ex);
      });
  }, [])

  const handleFileChange = (e) => {
    if(e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpeg') {
      return window.alert('only images of type jpeg and png are allowed')
    }
    const reader = new FileReader();
    reader.addEventListener("load", fileLoadedEvent => {
      const image = fileLoadedEvent.target.result;
      setSelectedFile(image)
    })
    reader.readAsDataURL(e.target.files[0]);
    setFileName(e.target.files[0].name);
  }

  const columns = [
    {
      key: "email",
      text: "Email",
      sortable: true,
    },
    {
      key: "phone",
      text: "Phone",
      sortable: true,
    },
    {
      key: 'action',
      text: 'Action',
      width: 50,
      align: 'left',
      sortable: false,
      cell: record => {
        return (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            style={{ textAlign: 'center' }}
            onClick={() => {
              const res = selectedUsers.find(x => x.id === record.id);

              if (res) {
                alert('You have selected this user already');
              } else {
                setSelectedUsers([...selectedUsers, record]);
              }
            }}
          >
            Select
          </button>
        );
      },
    }
  ];

  const getUsersByCompany = async (corporate_id) => {
    if(corporate_id) {
      getUserByCorporate(corporate_id)
        .then((data) => {
          setUsers(data);
        })
        .catch(ex => {
          props.setDashboardMessage("error", 'an error occured while filtering users, please try again');
          console.log(ex);
        });
    }
  }

  const corporateArray = [];
  if(corporates.length) {
    corporates.map((each, key) => {
      corporateArray.push(
        <option value={each.id} key={key}>{each.corporate_name}</option>
      )    
    })
  }

  const handleDropdownChange = (e) => {
    let organization_id;
    if(e.target.value !== "external") {
      organization_id = e.target.value;
    }
    else {
      organization_id = null
    }
    setCurrentBusiness(organization_id)
  }

  const applyCompanyFilters = async (corporate_id) => {
    if(corporate_id) {
      await getUsersByCompany(corporate_id);
    }
    else {
      getAllUsers()
        .then(users => {
          users = users.filter((x) => (!x.corporate_id && x.active === '1'))
          setUsers(users);
        })
        .catch((E) => {
          console.log(E, "could not get users");
          return false;
        })
    }
  } 

  const schema = Yup.object().shape({
    title: Yup.string().required('Notification title is required'),
    description: Yup.string().required('Notification description is required'),
  });

  const getUserIds = (users) => {
    return users.map((_) => _.id)
  }

  return (
    <Fragment>
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Notify Users</h2>
          <div className="side_filters">
            <p className="filters__">Filter By Company :</p>
            <div className="corporates_div">
              <select 
                name="corporates" 
                className="select_corporate"
                onChange={ (e) => handleDropdownChange(e)}
              >
                <option value="" key="" > Select Company </option>
                <option value="external">External Users</option>
                {corporateArray}
              </select>                                  
            </div>
            <button className="filter_btn, __filter_btn"
              onClick={(e) => {
                e.preventDefault();
                applyCompanyFilters(currentBusiness);
              }}
            >Apply</button>
          </div>
        </div>

        {meta.error && (
          <div className="col-md-12 col-lg-12">
            <div className="module">
              <div className="module-header">
                <div className="module-wrapper">
                  <div className="module-content">
                    <h3 style={{ color: "red" }}>
                      Notify action failed
                      </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Formik
          initialValues={{ title: '', description: '', notifyAll: false }}
          validationSchema={schema}
          onSubmit={async (values, action) => {
            try {
              if(isFlash && !selectedFile) {
                alert('Splash screen image is required');
                setMeta({ loading: false });
                return
              }

              setMeta({ loading: true });
              if (values.notifyAll === true) {
                const ids = getUserIds(users)
                let payload = {
                  all: true,
                  user_ids: [1],
                  title: values.title,
                  body: values.description,
                  filtered_ids: ids
                };

                if(selectedFile) {
                  payload = {...payload, image: selectedFile, type: 'flash'}
                }
                await NotifyUsers(payload);

                setMeta({ loading: false });
                props.setDashboardMessage("success", "All users were successfully notified");
                setSelectedUsers([]);
                action.resetForm();
                return;
              }

              if (selectedUsers.length < 1 && values.notifyAll === false) {
                alert('Please select at least one user');
                setMeta({ loading: false });
                return;
              }

              const user_ids = selectedUsers.map(item => item.id);
              const payload = {
                user_ids,
                title: values.title,
                body: values.description,
              };

              if(selectedFile) {
                payload = {...payload, image: selectedFile, type: 'flash'}
              }

              await NotifyUsers(payload);

              setMeta({ loading: false });
              props.setDashboardMessage("success", "All selected users were successfully notified");
              setSelectedUsers([]);
              action.resetForm();
            } catch (ex) {
              props.setDashboardMessage("error", `failed to notify users`);
              setMeta({ loading: false, error: 'Error in sending push notification, please try again soon' });
            }
          }}
        >
          {props => {
            const { values, handleChange, handleSubmit, errors, touched } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="row" style={{ margin: 10 }}>
                  <div className="col-md-4 formSec">
                    <div className="row" style={{ marginBottom: 10 }}>
                      <div className="form-element">
                        <input
                          required
                          name="title"
                          type="text"
                          className="form-text"
                          value={values.title}
                          onChange={handleChange}
                          placeholder="Notification title"
                        />
                        {errors['title'] && touched['title'] && <p style={{ color: 'red' }}>errors[key]</p>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-element">
                        <textarea
                          required
                          name="description"
                          type="text"
                          className="form-text"
                          value={values.description}
                          onChange={handleChange}
                          placeholder="Notification description"
                          rows={6}
                        />
                        {errors['description'] && touched['description'] && <p style={{ color: 'red' }}>errors[key]</p>}
                      </div>
                    </div>

                    <div className="row" style={{ marginBottom: 15 }}>
                      <div className="col-md-1" style={{ padding: 0 }}>
                        <div className="form-element">
                          <input
                            name="notifyAll"
                            type="checkbox"
                            className="form-text"
                            value={values.notifyAll}
                            onChange={(e) => {
                              setNotifyAll(!notifyAll)
                              handleChange(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-9 flashCheck" style={{ padding: 0 }}>
                        Notify All
                      </div>
                      
                      <div className="col-md-1" style={{ padding: 0 }}>
                        <div className="form-element">
                          <input
                            name="isFlash"
                            type="checkbox"
                            className="form-text"
                            onChange={(e) => {
                              setIsFlash(!isFlash)
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-9 flashCheck" style={{ padding: 0 }}>
                        Is Flash Notification
                      </div>
                      { isFlash && (
                        <div>
                          <div className="col-md-9 fileSection" >
                            <div className="imgDivUpload">
                              <label>Add Splash screen Image</label>
                            </div>
                            <label 
                              className="file-select"
                            >
                              <div 
                                className="select-button"
                              >
                                <div className="fileBtn">Select File</div>
                              </div>
                              <input type="file" onChange={handleFileChange}/>
                            </label>
                          </div>
                          <div>
                            {
                              fileName && (
                                <div className="fileNameSec">
                                  <b>Selected File</b>: <span style={{color: "red"}}>{fileName}</span>
                                </div>
                              )
                            }
                          </div>
                        </div>
                        )
                      }
                    </div>

                    <button
                      className="select-bvtton--active main_btn_notify"
                      disabled={meta.loading}
                      type="submit"
                      style={{ margin: 0 }}
                    >
                      {meta.loading ? 'Notifying...' : 'Notify'}
                    </button>
                  </div>

                  <div className="col-md-8">
                    <div style={{ margin: 10, display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
                      {selectedUsers.map(item => (
                        <div style={{ margin: 5, backgroundColor: 'antiquewhite', padding: 10 }} key={item.id}>
                          <p style={{ display: 'inline', marginRight: '10px' }}>{item.email}</p>
                          <span onClick={() => {
                            const selected = selectedUsers.filter(each => each.id != item.id);
                            setSelectedUsers(selected);
                          }}>
                            x
                        </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />
            <Datatable
              title="Users"
              data={users}
              columns={columns}
              showButtons={false}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

