import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { getActiveTrips } from '../../models/trips';
import Map from '../../components/map';
import '../../styles/spinner.css';

export default class TrackFleet extends Component {
  constructor() {
    super();
    this.state = {
      trips: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    this.activeTrips();
  }

  activeTrips() {
    getActiveTrips().then((trips) => {
      this.setState({
        trips,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((e) => {
      console.error('[Fleet List] Error fetching vehicles data', e.stack);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/fleet',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Active Trips</h2>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="fleet-live-tracker">
            <div id="map">
              <Map
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE&v=3.exp&libraries=geometry,drawing,places" // eslint-disable-line
                containerElement={<div style={{ height: '600px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">

            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`} >
              <div className="module-content has-table">
                <div className="module-table">
                  <table className="responsive">
                    <thead>
                      <tr>
                        <th>Driver</th>
                        <th>Phone</th>
                        <th>Route</th>
                        <th>Vehicle</th>
                        <th>Registration Number</th>
                        <th>Started Trips</th>
                      </tr>
                    </thead>
                    {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="8">
                            <div className="ride-data-row">
                              <div>
                                Loading vehicles data...
                                                                <div className="spinner" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="8">
                            <div className="ride-data-row">
                              <div>
                                Sorry we could not load any vehicle data.
                                                            </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                      <tbody>
                        {this.state.trips.map(trip => (
                          <tr key={trip.id} className="clickable"
                            onClick={() =>
                              this.context.router.history.push('/fleet/tracking',
                                { trip })}
                          >
                            <td>{trip.driver.fname} {trip.driver.lname}.</td>
                            <td>{trip.driver.phone}</td>
                            <td>{trip.route.pickup} &mdash; {trip.route.destination}</td>
                            <td>
                              {trip.driver.vehicle.name} {trip.driver.vehicle.brand}
                                                            ({trip.driver.vehicle.type})
                                                        </td>
                            <td>{trip.driver.vehicle.registration_number}</td>
                            <td>{trip.start_trip}</td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


TrackFleet.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

TrackFleet.defaultProps = {
  user: null,
  location: {
    state: {},
  },
};

TrackFleet.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
