
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import { getAllBusinesses, getBusinessGraph, findBusiness } from '../../models/business';
import { initBarChart } from '../../libs/utils';

import '../../styles/dashboard.css';

export default class BusinessInsights extends Component {
  constructor() {
    super();
    this.state = {
      businesses: null,
    };
  }

  componentDidMount() {
    Promise.all([getAllBusinesses(), getBusinessGraph()])
      .then((data) => {
        this.setState({
          businesses: data[0],
        });
        this.plotGraph(data[1]);
      }).catch((err) => {
        console.error('[Business Insights] Error fetching business data', err, err.stack);
        this.props.setDashboardMessage('error', 'Sorry could not fetch businesses information');
      });
  }

  featchAllBusinesses() {
    getAllBusinesses()
      .then((businesses) => {
        this.setState({ businesses });
      }).catch((err) => {
        console.error('[Business Insights] Error fetching business data', err, err.stack);
        this.props.setDashboardMessage('error', 'Sorry could not fetch businesses information');
      });
  }

  plotGraph(data) {
    const businessData = [];
    data.forEach((datum) => {
      businessData.push([datum.month, datum.total]);
    });

    const business30Day = [{
      data: businessData,
      label: 'Business',
    }];

    initBarChart('.linechart.user-transit-rides', business30Day);
  }

  findBusiness(e) {
    e.preventDefault();

    const query = this.query.value;

    // if (!query) return false;
    if (query === '') return this.featchAllBusinesses();

    findBusiness(query).then((businesses) => {
      this.setState({ businesses: { data: businesses } });
    }).catch((err) => {
      console.error('[Business Insights] Error fetching business data', err, err.stack);
      this.props.setDashboardMessage('error', 'Sorry could not fetch businesses information');
    });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/business',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="col-md-12">
          <div className="chart-wrapper">
            <h3>Business Growth (12 Month)</h3>
            <div className="chart linechart user-transit-rides" />
          </div>
        </div>

        <div className="clearfix" />
        <div className="sub-page-header col-sm-12">
          <h2>Manage Businesses</h2>
          <div className="right-options">
            <div className="search">
              <form onSubmit={e => this.findBusiness(e)}>
                <div className="form-element">
                  <input
                    ref={(input) => { this.query = input; }}
                    type="text"
                    className="form-text"
                    placeholder="Enter search term"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />
            <div className="module-wrapper">
              <div className="module-content has-table has-users">
                <div className="module-table">
                  <table className="">
                    <thead>
                      <tr>
                        <th width="35%">Name</th>
                        <th>Contact E-mail</th>
                        <th>Phone</th>
                        <th>Staff Size</th>
                        <th className="align-right">Date Joined</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.businesses && this.state.businesses.data.map(business => (
                        <tr key={business.id} className="clickable"
                          onClick={() =>
                            this.context.router.history.push('/business/details',
                              { businessDetails: business })}
                        >
                          <td>
                            <div className="user-table-display">
                              <div className="image css-img-object"
                                style={{
                                  backgroundImage:
                                    'url(/assets/img/default-business.png)',
                                }}
                              />
                              <h3>{business.corporate_name}</h3>
                            </div>
                          </td>
                          <td>{business.email}</td>
                          <td>{business.corporate_phone}</td>
                          <td>{business.industry}</td>
                          <td className="align-right"
                            title={`Last Active: ${business.created_at}`}
                          >
                            <Moment format="MMM Do YY">{business.created_at}</Moment>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

BusinessInsights.propTypes = {
  user: PropTypes.object,
  setDashboardMessage: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

BusinessInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

BusinessInsights.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
