import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import { getFutureRoutes } from '../../models/routes';
import DatePicker from '../../components/datepicker';

import '../../styles/dashboard.css';

export default class FutureRoute extends Component {
  constructor() {
    super();
    this.state = {
      data: 0,
      filter: null,
      loadingData: true,
      loadingSuccessFull: false,
      futureRoutes: [],
      data_clone: []
    };
  }

  componentDidMount() {
    getFutureRoutes().then((response) => {
      this.setState({
        data: response.data || response,
        loadingData: false,
        loadingSuccessFull: true,
      });
      let pickupArray = [];
      for(let i = 0; i < response.data.length; i++) {
        pickupArray.push(response.data[i]['route'].pickup);
      }
      pickupArray = Array.from(new Set([...pickupArray]));
      this.setState({futureRoutes: pickupArray, data_clone: response.data})
    }).catch((e) => {
      console.error('[Suggestion] Error fetching data', e.stack);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  handleRowClick(e, info) {
    e.preventDefault();
    this.applyFilters(e, info)
  }

  applyFilters(e, route = null) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;

    if (from && to) {
      this.setState({ filter: { from, to } });
      let dataArray = this.state.data_clone;
      let filteredData = dataArray.filter(data => 
        data.created_at.split(" ")[0] >= from && data.created_at.split(" ")[0] <= to
      );

      if(route) {
        filteredData = filteredData.filter(data => data.route.pickup === route)
      }
      this.setState({data: filteredData});
      return true;
    }    
    else if(route) {
      let dataArray = this.state.data_clone;
      let filteredData = dataArray.filter(data => data.route.pickup === route)
      this.setState({data: filteredData});
      return true;
    }
    else {
      window.alert("Please indicate start and end date in the query");
      return false;
    }
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/suggestion',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="clearfix" />
        <div className="sub-page-header col-sm-12">
          <h2>Future Routes</h2>
        </div>
        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-pre-header-filter">
              <form onSubmit={e => this.applyFilters(e)}>
                <div className="flex flex-wrap inline-form-wrapper">
                  <div className="title">
                    <h3>Filter by Date</h3>
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.startDate = data; }}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.endDate = data; }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="form-element">
                    <button type="submit">Filter</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="module-header" />
                <div className="admin-account-actions">
                    <div className="_header">
                        <h3>Select Future Route To Filter</h3>
                    </div>
                    <ul>
                        {
                            Array.isArray(this.state.futureRoutes) && this.state.futureRoutes.map((info, key) => (
                              <li key={key}>
                                <a href="" onClick={(e) => (this.handleRowClick(e, info))}  key={key} value={info}>{info}</a>
                              </li>
                            ))
                        }
                    </ul>
                </div>
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
              <div className="module-content has-table has-users">
                <div className="module-table">
                  <table className="">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Route</th>
                        <th>Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="6">
                            <div className="ride-data-row">
                              <div>
                                Loading Future routes...
                                                                <div className="spinner" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="6">
                            <div className="ride-data-row">
                              <div>
                                Sorry we could not load Future routes.
                                                            </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                      <tbody>
                        {this.state.data.map(info => (
                          <tr key={info.id} className="clickable">
                            <td><Moment format="h:mm A">{info.created_at}</Moment>
                              <br />
                              <Moment format="MMM Do YY">{info.created_at}</Moment>
                            </td>
                            <td>{info.route.pickup}</td>
                            <td>{info.name}</td>
                            <td>{info.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    }

                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

FutureRoute.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

FutureRoute.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
