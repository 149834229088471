/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getWallet, debitWallet } from '../models/wallet';
import { formatMoney } from '../libs/utils';

import '../styles/spinner.css';

export default class DebitWallet extends Component {
    constructor() {
        super();
        this.state = {
            wallet: null,
            loadingData: true,
            loadingSuccessFull: false,
            currentSource: 'main_balance'
        };

        this.fetchWallet = this.fetchWallet.bind(this);
        this.debitWallet = this.debitWallet.bind(this);
    }

    componentDidMount() {
        this.fetchWallet();
    }
    
    handleDropdownChange = (e) => {
        const source = e.target.value;
        if(source) {
            this.setState({currentSource: source});
        }
    }
      
    fetchWallet() {
        const walletID = this.props.wallet;
        getWallet(walletID).then((data) => {
            this.setState({
                wallet: data,
                loadingData: false,
                loadingSuccessFull: true,
            });
        }).catch((e) => {
            console.error('[Top up wallet] Error fetching user wallet', e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    debitWallet(evt) {
        evt.preventDefault();
        const walletID = this.props.wallet;
        const desc = this.desc.value;
        const amount = parseInt(this.amount.value, 10) > 0 ? this.amount.value : null;

        if (!desc || !amount) return this.props.setDashboardMessage('error', 'Please povide an amount and description');

        this.setState({ loadingData: true });
        debitWallet(this.state.wallet.user_id, walletID, amount, desc, this.state.currentSource)
            .then(() => {
                this.fetchWallet();
                this.props.setDashboardMessage('success', `Debit of ${amount} was successful`);
                this.props.onWalletDebited();
            })
            .catch((e) => {
                console.error('[Top up wallet] Error debitting user wallet', e.stack);
                this.props.setDashboardMessage('error', 'An error occured while debitting user\'s wallet');
                this.setState({ loadingData: false });
            });
    }

    render() {
        return (
            <div>
                <div className="module focus green col-sm-8 row">
                    <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                        <div className="module-content">
                            <div className="big-number">
                                {this.state.loadingData && <div className="spinner" />}
                                {this.state.wallet ?
                                    <div>&#x20A6;{formatMoney(this.state.wallet.amount)}</div> : 'Unavaliable'
                                }
                                <span>Wallet Balance</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix" />

                <div className="module">

                    <div className="module-wrapper">
                        <div className="funding-option">
                            <div className="funding-option-header"
                                style={{
                                    color: 'red',
                                    fontWeight: 'bold',
                                }}
                            >
                                <h2><i className="fa fa-money" />Debit money from this wallet</h2>
                                <p>You can now debit money to this wallet</p>
                            </div>

                            <div className="funding-option-form">
                                {
                                    (this.props.user.role !== 'admin' && this.props.user.role !== 'super_admin') ?
                                        <div className="bg_white">
                                            <p className="bg_text">You're not allowed to perform this operation</p>
                                            <p style={{textAlign: "center"}}>Kindly speak with the admin</p>
                                        </div>         
                                    :
                                    <form onSubmit={e => this.debitWallet(e)}>
                                    <div className="form-element">
                                        <span className="label">Enter debit amount</span>
                                        <input
                                            ref={(input) => { this.amount = input; }}
                                            type="number"
                                            required
                                            className="form-text card-top-up numbers-only money-format"
                                            placeholder="Eg. 10000" defaultValue="0" maxLength="6"
                                        />
                                    </div>
                                    <div className="form-element">
                                        <span className="label">Enter debit Description</span>
                                        <input
                                            ref={(input) => { this.desc = input; }}
                                            type="text"
                                            required
                                            className="form-text"
                                            placeholder="Payment for trip"
                                        />
                                    </div>
                                    <div className="form-element _row_div">
                                    <span className="label">Select Account to Debit</span>
                                        <select 
                                            className="_wallet_option"
                                            onChange={(e) => this.handleDropdownChange(e)}
                                        >
                                            <option 
                                                value="main_balance"                       
                                                ref={(input) => { this.source = input; }}
                                            >main balance</option>
                                            {
                                                this.props.isCorporate && 
                                                <option 
                                                    value="credit_account"
                                                    ref={(input) => { this.source = input; }}
                                                >
                                                    company balance
                                                </option>
                                            }
                                        </select>
                                    </div>
                                    <div className="form-element">
                                        <input
                                            type="submit"
                                            value="Debit" className="bvtton in-module card-top-up"
                                        />
                                    </div>
                                </form>
                                }
                            </div>

                        </div>

                        <div className="module-footer" />
                    </div>

                </div>
            </div>
        );
    }
}


DebitWallet.propTypes = {
    wallet: PropTypes.number.isRequired,
    onWalletDebited: PropTypes.func,
    setDashboardMessage: PropTypes.func,
};

DebitWallet.defaultProps = {
    wallet: null,
    onWalletDebited: () => {},
    setDashboardMessage: () => {},
};
