import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import storage from 'localforage';
import { post } from '../../libs/requestClient';
import Spinner from '../../components/spinner';
import { Error, Success } from '../../components/banners';

export default class LoginPage extends Component {
  constructor() {
    super();

    this.state = {
      error: false,
      loading: false,
      success: false,
    };

    this.performLogin = this.performLogin.bind(this);
  }

  componentDidMount() {
    this.props.setLayout('login'); // reset page layout
    const documentHeight = window.jQuery(window).innerHeight();
    const headerHeight = window.jQuery('header').outerHeight();

    if (headerHeight > 0) {
      window.jQuery('.right').css('min-height', `${documentHeight}px`);
    }
  }

  componentWillUnmount() {
    this.props.setLayout('default'); // set page layout
  }

  resetState = () => {
    this.setState({ error: false, success: false, loading: false })
  }

  /**
   * Handles login form submit and performs login
   * @param e
   */
  performLogin(e) {
    e.preventDefault();

    const loginData = {
      type: 'staff',
      email: this.email.value,
      password: this.password.value,
    };

    this.setState({ loading: true, error: false, success: false });

    post('/v1/login', loginData).then((user) => {
      this.setState({ loading: false, success: true });

      setTimeout(() => {
        storage.setItem('session', user).then(() => {
          this.props.setUserSession(user);
        });
      }, 2000);
    }).catch((err) => {
      const defaultError = 'An error occurred during login, please try after some time';
      console.log(err);

      if (err.error) {
        this.setState({
          loading: false,
          error: (err.error.code === 'ItemNotFound') ? 'Email or Password is incorrect' : defaultError,
        });
      } else {
        this.setState({
          loading: false,
          error: defaultError,
        });
      }
    });
  }

  render() {
    const user = this.props.user;
    const rtURL = this.props.location.rtURL || '/';
    const rtProps = this.props.location.rtProps || {};
    const { error, loading, success } = this.state;

    // if we have a user session we redirect away from login
    if (user && user.id) {
      return (
        <Redirect to={{
          push: true,
          state: rtProps,
          pathname: rtURL,
        }}
        />
      );
    }

    return (
      <section className="nopadding split-screen">
        <div className="container-fluid full-height">
          <div className="row">
            <div className="col-sm-6 col-md-5  col-lg-4 left">
              <div className="split-form-wrapper">
                <div className="wrapper-header">
                  <div className="logo">
                    <Link to="/"><img src="/assets/img/logo.png" alt="" /></Link>
                  </div>
                  <div className="caption">
                    <h2>Log In to your Account</h2>
                  </div>
                </div>
                <div className="wrapper-body">
                  <div className="form-wrapper">
                    {error && <Error message={error} reset={this.resetState} />}
                    {success && <Success
                      message="Login successful, redirecting you to your dashboard ..."
                      reset={this.resetState} />
                    }

                    <form onSubmit={this.performLogin}>
                      <div className="form-element">
                        <span className="label">E-mail Address</span>
                        <input
                          ref={(input) => { this.email = input; }} type="email" required
                          className="form-text"
                        />
                      </div>
                      <div className="form-element">
                        <span className="label">Password</span>
                        <input
                          ref={(input) => { this.password = input; }} type="password" required
                          className="form-text"
                        />
                      </div>
                      <div className="form-element">
                        <input type="checkbox" name="remember" value="1" id="remember" />
                        <label htmlFor="remember"> Remember me on this computer</label>
                      </div>

                      <div className="form-element">
                        <button
                          className="bvtton"
                          type="submit"
                          disabled={loading}
                          style={{ width: '130px' }}
                        >
                          {loading ? <Spinner /> : "Login"}
                        </button>

                        <span className="helper-link inline">
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-sm-6  col-md-7 col-lg-8 right  hide-on-mobile css-img-object"
              style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1483202277372-226db5c0ae2d?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=)' }} // eslint-disable-line
            />

          </div>
        </div>
      </section>
    );
  }
}

LoginPage.propTypes = {
  user: PropTypes.object,
  setLayout: PropTypes.func,
  location: PropTypes.shape({
    rtProps: PropTypes.object,
    rtURL: PropTypes.string,
  }),
  setUserSession: PropTypes.func,
};

LoginPage.defaultProps = {
  user: null,
  setLayout: () => { },
  setUserSession: () => { },
  location: {
    rtProps: {},
    rtURL: '/',
  },
};
