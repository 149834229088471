/**
 * Created by constance.okoghenun on 14/09/2017.
 */
import React, { Component } from "react";
import "../../styles/dashboard.css";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ExportToCsv } from 'export-to-csv';
import {getRoute, } from '../../models/routes';


export default class RouteBookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: props.location.state.data ? props.location.state.data : null,
        _date: props.location.state.date,
        users: null,
        route_name: null
    };
  }

  componentDidMount() {
    if(this.state.data.length === 0) return false;
    console.log(this.state.data);
    getRoute(this.state.data[0].route_id, false).then((route) => {
        this.setState({
          route_name: route.pickup
        });
    }).catch((e) => {
        console.error('[Booking Details], Error fetching route data', e.stack);
    });
  }

  handleDownload(e) {
    let csvData = this.state.data.map(x => {
      return {
        amount: x.amount,
        pickup: x.pickup.location,
        destination: x.destination.location,
        email: x.user.email,
        name: x.user.fname + " " + x.user.lname,
        start_date: moment(x.start_date).format("YYYY-MM-DD"),
        end_date: moment(x.end_date).format("YYYY-MM-DD")
      }
    });

    const csvParams = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      showTitle: true,
      title: `Bookings for ${this.state.route_name} on ${this.state._date}`,
      useTextFile: false,
      useBom: true,
      headers: ['Amount', 'Pickup', 'Destination', 'Email', 'Name', 'Start Date', 'End Date']
    };
    const csvExporter = new ExportToCsv(csvParams);
    csvExporter.generateCsv(csvData);
    window.alert('Download complete');
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: "/users/manage",
            rtProps: this.props.location.state,
            pathname: "/login"
          }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Route Bookings</h2>
          <input class="bvtton _download" onClick={(e) => this.handleDownload(e)} value="Download" type="submit"/>
        </div>
        <div className="module passenger-list" id="target_table">
            <div className="module-wrapper false">
                <div className="module-content has-table has-users">
                    <div className="module-table">
                    <table>
                        <thead>
                        <tr>
                            <th>Users</th>
                            <th>Pickups</th>
                            <th>Destinations</th>
                            <th>Email</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                               !this.state.data.length &&
                               <center><h4>There are no users on this day...</h4></center>                  
                            } 
                            {
                            Array.isArray(this.state.data) &&  this.state.data.map((info, key) => (
                                <tr key={key}>
                                    <td>
                                        <div className="user-table-display">
                                        <div className="image css-img-object"
                                            style={{ backgroundImage: `url(${info.user.avatar || '/assets/img/default.png'})` }} // eslint-disable-line
                                        />
                                        <h3>{`${info.user.fname} ${info.user.lname}`}</h3>
                                        </div>
                                    </td>
                                    <td>{ info.pickupRouteBusStop ? info.pickupRouteBusStop.name : info.pickup.location}</td>
                                    <td>{ info.destinationRouteBusStop ? info.destinationRouteBusStop.name :  info.destination.location}</td>
                                    <td>{info.user.email}</td>
                                    <td>{JSON.stringify(new Date(info.start_date ? info.start_date : info.userRoute.start_date)).split("T")[0]}</td>
                                    <td>{JSON.stringify(new Date(info.end_date ? info.end_date : info.userRoute.end_date)).split("T")[0]}</td>
                                </tr> 
                            ))
                            }
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

RouteBookings.propTypes = {
    user: PropTypes.object,
    route: PropTypes.object,
    location: PropTypes.shape({
      state: PropTypes.object,
    }),
    setDashboardMessage: PropTypes.func,
};
  