/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { post } from '../../libs/requestClient';
import { scrollToEl } from '../../libs/utils';

export default class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      resetMessage: '',
      resetSuccess: false,
    };
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    this.props.setLayout('login'); // reset page layout
    const documentHeight = $(window).innerHeight();
    const headerHeight = $('header').outerHeight();

    if (headerHeight > 0) {
      $('.right').css('min-height', `${documentHeight}px`);
    }
  }

  componentWillUnmount() {
    this.props.setLayout('default'); // set page layout
  }

  /**
   * Sets the message and type
   * @param {string} resetMessage - the message to display
   * @param {boolean} resetSuccess - true for success message, false for error message
   */
  setMessage(resetMessage, resetSuccess) {
    this.setState({ resetMessage, resetSuccess });
    scrollToEl('form-message');
  }

  /**
   * Resets the user's password given the correct payload
   * @param {Event} e - the event that triggered the reset
   */
  resetPassword(e) {
    e.preventDefault();
    const password = this.password.value;
    const confPassword = this.confirm_password.value;
    const { match: { params } } = this.props;

    const requestData = {
      type: 'staff',
      password,
      role: 'role',
      confirm_password: confPassword,
      token: params.token,
    };

    this.setState({ resetMessage: '', resetSuccess: false });
    if (!this.password.value || !this.confirm_password.value) {
      return this.setMessage('Some fields are missing.', false);
    }

    if (password !== confPassword) {
      return this.setMessage('Your passwords don\'t match.', false);
    }

    this.setState({ loading: true });

    post('/v1/password/change', requestData)
      .then(() => {
        this.setMessage('Your password rest was successful, we\'ll redirect you to login shortly', true);
        this.createForm.reset();
        setTimeout(() => this.context.router.history.push('/login'), 3000);
      })
      .catch((err) => {
        if (err.body && err.body.message) {
          return this.setMessage(err.body.message.toUpperCase().replace('_', ' '), false);
        }
        this.setMessage('Sorry we could not reset your password. Please try again', false);
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const user = this.props.user;
    const rtURL = this.props.location.rtURL || '/';
    const rtProps = this.props.location.rtProps || {};

    // if we have a user session we redirect away from login
    if (user && user.id) {
      return (
        <Redirect to={{
          push: true,
          state: rtProps,
          pathname: rtURL,

        }}
        />
      );
    }

    return (
      <section className="nopadding split-screen">
        <div className="container-fluid full-height">
          <div className="row">
            <div className="col-sm-6 col-md-5  col-lg-4 left">
              <div className="split-form-wrapper">
                <div className="wrapper-header">
                  <div className="logo">
                    <Link to="/"><img src="/assets/img/logo.png" alt="" /></Link>
                  </div>
                  <div className="caption">
                    <h2>Reset your password</h2>
                    <p>Enter your desired password in the form below and click Reset password.</p>
                  </div>
                </div>
                <div className="wrapper-body">
                  <div className="form-wrapper" id="form-message">
                    {this.state.resetMessage &&
                      <div className={this.state.resetSuccess ? 'success' : 'error'}>
                        {this.state.resetMessage}
                      </div>
                    }
                    <form
                      onSubmit={e => this.resetPassword(e)}
                      ref={(form) => { this.createForm = form; }}
                    >
                      <div className="form-element">
                        <span className="label">Password</span>
                        <input
                          type="password"
                          className="form-text"
                          required
                          ref={(input) => { this.password = input; }}
                        />
                      </div>
                      <div className="form-element">
                        <span className="label">Confirm Password</span>
                        <input
                          type="password"
                          className="form-text"
                          required
                          ref={(input) => { this.confirm_password = input; }}
                        />
                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Reset Password" />
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-sm-6  col-md-7 col-lg-8 right  hide-on-mobile css-img-object"
              style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1495264442745-e8edc9e7949b?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=)' }} // eslint-disable-line
            />

          </div>
        </div>
      </section>
    );
  }
}

ResetPassword.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  setLayout: PropTypes.func,
  location: PropTypes.shape({
    rtProps: PropTypes.object,
    rtURL: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {
  user: null,
  match: { params: {} },
  setLayout: () => { },
  history: {
    goBack: () => { },
  },
  location: {
    rtProps: {},
    rtURL: '/',
  },
};

ResetPassword.contextTypes = {
  router: PropTypes.object,
};
