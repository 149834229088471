/**
 * Created by constance.okoghenun on 10/10/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import createHistory from 'history/createBrowserHistory';

import { getUserTrips } from '../models/users';
import { getActiveTrips, getUpcomingTrips } from '../models/trips';
import { startTripForRoute } from '../models/drivers';

import '../styles/spinner.css';
import moment from 'moment';

const dayFormat = 'YYYY-MM-DD'

export default class UpcomingTripsTable extends Component {
    constructor() {
        super();
        this.state = {
            allTrips: null,
            loadingData: true,
            loadingSuccessFull: false,
            loadingAll: false,
        };

        this.history = createHistory();
        this.fetchActiveTrips = this.fetchUpcomingTrips.bind(this);
    }

    componentDidMount() {
        return this.fetchUpcomingTrips();
    }

    fetchUpcomingTrips() {
        this.setState({
            loadingData: true,
            loadingSuccessFull: false,
        });
        getUpcomingTrips().then((trips) => {
            
            const today = moment().format(dayFormat);
            trips = trips.data || trips;
            console.log("Here it is", trips)
            this.setState({
                allTrips: trips,
                loadingData: false,
                loadingSuccessFull: true,
                loadingAll: true,
            });
        }).catch((e) => {
            console.error('[Upcoming Trips] Error fetching data', e.stack);
            this.setState({ loadingData: false, loadingSuccessFull: false });
        });
    }

    startUpcomingTrip = async (trip) => {
        const { setDashboardMessage } = this.props;
        
        if(window.confirm('Are you sure you want to start this trip')) {
            try {
                const time = trip.route.itineraries[0].trip_time;

                const tripTimeMinus10Minutes = moment(`${trip.trip_date} ${time}`).subtract(10, 'minutes');
               
                //Check if current time is greater than (trip_time - 10 minutes)
                if(!moment().isAfter(tripTimeMinus10Minutes)){
                    return window.alert("You can only start a trip between 10 minutes before start time or after")
                }

                const data = { route_id: trip.route.id, pickup_coordinate: trip.route.pickup_coordinate, };

                await startTripForRoute(trip.route.driver.id, data);

                setDashboardMessage('success', 'Trip started successfully');

                const upcomingTrips = this.state.allTrips;

                upcomingTrips.forEach((t, index) => {
                    if(t.id === trip.id) {
                        upcomingTrips.splice(index, 1);  
                    }
                }); 

                this.setState({allTrips: upcomingTrips});
                
            } catch (err) {
                console.log('Error::', err);
                const message = err?.error?.message ?? 'Error starting trip';
                setDashboardMessage('error', message);
            }
        }
    }

    render() {
        let trips = this.state.allTrips || null;
        if (this.state.loadingAll) {
            trips = this.state.allTrips || null;
        }

        return (
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
                <div className="module-content has-table has-users">
                    <div className="module-table">
                        <table className="">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Duration</th>
                                    <th>Driver's Name</th>
                                    <th>Trip Date</th>
                                    <th>Passengers</th>
                                    <th className="align-right">Action</th>
                                </tr>
                            </thead>
                            {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                                <tbody>
                                    <tr className="ride-data" style={{ display: 'table-row' }}>
                                        <td colSpan="6">
                                            <div className="ride-data-row">
                                                <div>
                                                    Loading Upcoming Trip...
                                                <div className="spinner" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                            {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                                <tbody>
                                    <tr className="ride-data" style={{ display: 'table-row' }}>
                                        <td colSpan="6">
                                            <div className="ride-data-row">
                                                <div>
                                                    Sorry you do not have any trip.
                                            </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            }

                            {(!this.state.loadingData && this.state.loadingAll) &&
                                <tbody>
                                    {(trips && trips.length > 0) && trips.map(trip => {
                                        const time = trip.route.itineraries?.[0]?.trip_time;
                                        return (
                                            <tr key={trip.id}>
                                                <td>
                                                    <Moment format="h:mm A">{trip.created_at}</Moment>
                                                    <br /><Moment format="MMM Do YY">{trip.created_at}</Moment>
                                                </td>
                                                <td>{trip.route.pickup}</td>
                                                <td>{trip.route.destination}</td>
                                                <td>{trip.route.duration}</td>
                                                <td>{trip.route.driver.fname}{" "}{trip.route.driver.lname}</td>
                                                <td>
                                                    <Moment format="MMM Do YY - h:mm A">{`${trip.trip_date} ${time}`}</Moment>
                                                </td>
                                                <td>{trip.passengers_count}</td>
                                                <td className="align-right money">
                                                    <button className="btn btn-success" onClick={() => this.startUpcomingTrip(trip)}>
                                                        Start Trip <i className="fa fa-check" />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            }
                           
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}


UpcomingTripsTable.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number,
    }),
    setDashboardMessage: PropTypes.func,
};

UpcomingTripsTable.defaultProps = {
    user: null,
    setDashboardMessage: () => { },
};
UpcomingTripsTable.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired,
        }).isRequired,
        staticContext: PropTypes.object,
    }).isRequired,
};
