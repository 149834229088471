/**
 * Created by constance.okoghenun on 14/09/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { createVehicle, updateVehicle } from '../models/fleet';

export default class AddVehicle extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const vehicleData = {
            brand: this.brand.value,
            name: this.name.value,
            type: this.type.value,
            seats: this.seats.value,
            registration_number: this.registration_number.value,
            amenities: this.amenities.value,
            code: this.busCode.value
        };

        const requiredFields = ['brand', 'name', 'type', 'seats', 'registration_number', 'amenities', 'code'];

        this.setState({ loading: true });

        requiredFields.forEach((field) => {
            if (!vehicleData[field]) {
                this.props.setDashboardMessage('error', 'Some required fields are missing');
                return false;
            }
        });

        if (vehicleData.seats < 1) {
            this.props.setDashboardMessage('error', 'Vehicle capacity must be a positive number.');
            return false;
        }

        if (this.props.vehicle) {
            updateVehicle(this.props.vehicle.id, vehicleData)
                .then((data) => {
                    this.setState({ loading: false });
                    this.createForm.reset();
                    this.props.setDashboardMessage('success', 'Vehicle Updated');
                    this.props.onUpdate(data);
                }).catch((err) => {
                    this.setState({ loading: false });
                    console.error('[Add Vehicle] An error occurred while updating vehicle', vehicleData, err, err.stack);
                });
        } else {
            createVehicle(vehicleData)
                .then(() => {
                    this.createForm.reset();
                    this.props.setDashboardMessage('success', 'Vehicle Created');
                }).catch((err) => {
                    if(err.error && err.error.code === 'ItemAlreadyExist') {
                        return this.props.setDashboardMessage('error', 'Vehicle already exists');
                    }
                    const message = (err.body && err.body.message) ||
                        'Sorry an error occurred while creating the vehicle';
                    this.props.setDashboardMessage('error', message);
                    console.error('[Add Vehicle] An error occurred while creating a vehicle',
                        vehicleData, err);
                }).finally(() => {
                    this.setState({ loading: false });
                });
        }
    }

    render() {
        const vehicle = this.props.vehicle || {};
        return (
            <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                    <div className="form-wrapper">
                        <form ref={(form) => { this.createForm = form; }}
                            onSubmit={e => this.handleSubmit(e)}
                        >
                            <div className="row">
                                <div className="form-element col-md-12">
                                    <span className="label">Vehicle Brand</span>
                                    <input
                                        ref={(input) => { this.brand = input; }}
                                        type="text"
                                        required
                                        defaultValue={vehicle.brand}
                                        className="form-text"
                                    />
                                </div>
                                <div className="form-element col-md-12">
                                    <span className="label">Vehicle Name</span>
                                    <input
                                        ref={(input) => { this.name = input; }}
                                        type="text"
                                        required
                                        defaultValue={vehicle.name}
                                        className="form-text"
                                    />
                                </div>
                                <div className="form-element col-md-12">
                                    <span className="label">Vehicle Plate Number</span>
                                    <input
                                        ref={(input) => { this.registration_number = input; }}
                                        type="text"
                                        required
                                        defaultValue={vehicle.registration_number}
                                        className="form-text"
                                    />
                                </div>
                                <div className="form-element col-md-6">
                                    <span className="label">Vehicle Capacity</span>
                                    <input
                                        ref={(input) => { this.seats = input; }}
                                        type="number"
                                        min="1"
                                        required
                                        defaultValue={vehicle.seats}
                                        className="form-text"
                                    />
                                </div>
                                <div className="form-element col-md-6">
                                    <span className="label">Vehicle Type</span>
                                    <div className="form-select long">
                                        <select
                                            ref={(input) => { this.type = input; }}
                                            required
                                            defaultValue={vehicle.type}
                                        >
                                            <option value="">Select type...</option>
                                            <option value="Bus">Bus</option>
                                            <option value="Sedan">Sedan</option>
                                            <option value="Bike">Bike</option>
                                            <option value="Bicycle">Bicycle</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-element col-md-6">
                                    <span className="label">Vehicle Code</span>
                                    <input
                                        ref={(input) => { this.busCode = input; }}
                                        type="text"
                                        min="1"
                                        max="6"
                                        required
                                        defaultValue={vehicle.code}
                                        className="form-text"
                                    />
                                </div>

                                <div className="form-element col-md-12">
                                    <span className="label">Vehicle Amenities</span>
                                    <input
                                        ref={(input) => { this.amenities = input; }}
                                        type="text"
                                        required
                                        defaultValue={vehicle.amenities}
                                        className="form-text"
                                    />
                                </div>
                            </div>

                            {vehicle.id ?
                                <div className="form-element">
                                    <input type="submit" className="bvtton"
                                        value={this.state.loading ? 'Updating Vehicle...' : 'Update Vehicle'}
                                    />
                                </div>
                                :
                                <div className="form-element">
                                    <input type="submit" className="bvtton"
                                        value={this.state.loading ? 'Adding Vehicle...' : 'Add Vehicle'}
                                    />
                                </div>
                            }

                        </form>
                    </div>
                </div>
                <div className="module-footer" />
            </div>
        );
    }
}


AddVehicle.propTypes = {
    vehicle: PropTypes.object,
    onUpdate: PropTypes.func,
    setDashboardMessage: PropTypes.func,
};

AddVehicle.defaultProps = {
    vehicle: null,
    onUpdate: () => {},
    setDashboardMessage: () => {},
};
