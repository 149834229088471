
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Redirect } from 'react-router-dom';

import '../../styles/spinner.css';

export default class TransactionDetails extends Component {
  constructor() {
    super();
    this.state = {
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  render() {
    const user = this.props.user;
    const transaction = this.props.location.state.transaction;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!transaction) {
      return (
        <Redirect to={{
          pathname: '/transactions',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Transaction Details</h2>
          <div className="right-options" />
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>

        <div className="col-md-7">
          <div className="module">
            <div className="module-header">
              <h3>Transaction Details</h3>
            </div>
            <div className="module-wrapper">
              <div className="module-content">
                <div className="t-d">
                  <div className="t-d-header flex flex-row space-between">
                    <div>
                      <div className="reciept-id">
                        {transaction.reference}
                        <span>Transaction id</span>
                      </div>
                      <div className="type">
                        <span className={transaction.type}>{transaction.type}</span>
                      </div>
                    </div>
                    <div className="date-time">
                      <Moment format="MMM Do YY">{transaction.created_at}</Moment>
                      <span>at <Moment format="h:mm A">{transaction.created_at}</Moment></span>
                    </div>
                  </div>
                  <div className="the-user">
                    <div className="user-table-display">
                      <div className="image css-img-object"
                        style={{
                          backgroundImage:
                            `url(${transaction.user.avatar || '/assets/img/default.png'})`,
                        }}
                      />
                      <h3>
                        <a href="" tabIndex="-1"
                          onClick={(e) => {
                            e.preventDefault();
                            this.context.router.history.push('/users/details',
                              { userDetails: transaction.user });
                          }
                          }
                        >{transaction.user.fname} {transaction.user.lname}
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="clearfix" />

                  <div className="t-d-details flex flex-row space-between">
                    <div className="t-d-amount credit">
                      &#x20A6;{transaction.amount}
                    </div>
                    <div className="description">
                      {transaction.title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TransactionDetails.propTypes = {
  user: PropTypes.object,
  transaction: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

TransactionDetails.defaultProps = {
  user: null,
  transaction: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

TransactionDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
