/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { patch } from '../../libs/requestClient';
import { scrollToEl } from '../../libs/utils';
import { fetchAllStaff } from '../../models/staff'

export default class ChangePassword extends Component {
  constructor() {
    super();

    this.state = {
      resetMessage: '',
      resetSuccess: false,
      token: null,
      id: null
    };
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    this.props.setLayout('login'); // reset page layout
    const documentHeight = $(window).innerHeight();
    const headerHeight = $('header').outerHeight();
    const { match: { params } } = this.props;
    this.setState({token: params.token})

    let code = params.token
    if(!code) {
        window.location.href = "/login"
    }
    code = code.split("-");
    let staffIndex = code[code.length - 1]
    this.setState({id: staffIndex})
        
    if (headerHeight > 0) {
      $('.right').css('min-height', `${documentHeight}px`);
    }
  }

  /**
   * Sets the message and type
   * @param {string} resetMessage - the message to display
   * @param {boolean} resetSuccess - true for success message, false for error message
   */
  setMessage(resetMessage, resetSuccess) {
    this.setState({ resetMessage, resetSuccess });
    scrollToEl('form-message');
  }

  /**
   * Sends password reset link to valid email
   * @param {Event} e - the event that triggered the reset
   */
  changePassword(e) {
    e.preventDefault();

    const requestData = {
      password: this.password.value,
      id: this.state.id,
      token: this.state.token,
      type: 'staff'
    };
    
    this.setState({ resetMessage: '', resetSuccess: false });
    if (!this.password.value) {
      return this.setMessage('some fields are missing', false);
    }
    if (!this.confirmPassword.value) {
        return this.setMessage('some fields are missing', false);
    }

    if(this.confirmPassword.value !== this.password.value) {
        return this.setMessage('passwords do not match', false);
    }

    if(this.password.value.length < 8) {
        return this.setMessage('passwords must be more than 8 characters long', false);
    }

    this.setState({ loading: true });
    patch('/v1/staff', requestData)
      .then((resp) => {
          console.log(resp, "resprespresp")
        this.setMessage('Password updated successfully', true);
        this.createForm.reset();
        window.location.href = "/login"
      })
      .catch((err) => {
        console.error('Error in updating your password', err);
        if (err.body && err.body.message) {
          return this.setMessage(err.body.message.toUpperCase().replace('_', ' '), false);
        }
        this.setMessage('Sorry we could not update your password. Please try again', false);
      })
  }

  render() {
    // if we have a user session we redirect away from login
    return (
      <section className="nopadding split-screen">
        <div className="container-fluid full-height">
          <div className="row">
            <div className="col-sm-6 col-md-5  col-lg-4 left">
              <div className="split-form-wrapper">
                <div className="wrapper-header">
                  <div className="back-to-site">
                  </div>
                  <div className="logo">
                    <Link to="/"><img src="/assets/img/logo.png" alt="" /></Link>
                  </div>
                  <div className="caption">
                    <h2>Change password</h2>
                  </div>
                </div>
                <div className="wrapper-body">
                  <div className="form-wrapper" id="form-message">
                    {this.state.resetMessage &&
                      <div className={this.state.resetSuccess ? 'success' : 'error'}>
                        {this.state.resetMessage}
                      </div>
                    }
                    <form
                      onSubmit={e => this.changePassword(e)}
                      ref={(form) => { this.createForm = form; }}
                    >
                      <div className="form-element">
                        <span className="label">Password</span>
                        <input
                          type="password"
                          className="form-text"
                          required
                          ref={(input) => { this.password = input; }}
                        />
                        <br></br>
                        <span className="label">Confirm Password</span>
                        <input
                          type="password"
                          className="form-text"
                          required
                          ref={(input) => { this.confirmPassword = input; }}
                        />
                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Change Password" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6  col-md-7 col-lg-8 right  hide-on-mobile css-img-object"
              style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1495264442745-e8edc9e7949b?dpr=2&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=)' }} // eslint-disable-line
            />

          </div>
        </div>
      </section>
    );
  }
}
