
import { get } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getAllTransactions = (offset, limit, range, count = null) => {
  let url = (offset && limit) ? `/v1/transactions?offset=${offset}&limit=${limit}&` : '/v1/transactions?';
  if (range) url += `from=${range.from}&to=${range.to}`;
  if(count) count = { all: true };
  return get(url, count)
    .then((transactions) => {
      cacheClient.set(url, transactions);
      return transactions;
    });

  // return cacheClient.get(url)
  //   .catch((err) => {
  //     console.error('[Transactions model] Error fetching transactions from cache', err.stack);
  //     return fetchTransactions;
  //   })
  //   .then((data) => {
  //     if (data) return data;
  //     return fetchTransactions;
  //   });
};

export const getTransaction = (id) => {
  const fetchTransaction = get(`/v1/transactions/${id}`)
    .then((transaction) => {

      cacheClient.set(`/v1/transactions/${id}`, transaction);
      return transaction;
    });

  return cacheClient.get(`/v1/transactions/${id}`)
    .catch((err) => {
      console.error('[Transactions model] Error fetching transaction from cache', id, err.stack);
      return fetchTransaction;
    })
    .then((data) => {
      if (data) return data;
      return fetchTransaction;
    });
};

export const getTransactionGraphData = () => {
  const fetchTransaction = get('/v1/transactions/graph')
    .then((transaction) => {
      cacheClient.set('/v1/transactions/graph', transaction);
      return transaction;
    });

  return cacheClient.get('/v1/transactions/graph')
    .catch((err) => {
      console.error('[Transactions model] Error fetching transaction graph from cache', err.stack);
      return fetchTransaction;
    })
    .then((data) => {
      if (data) return data;
      return fetchTransaction;
    });
};
