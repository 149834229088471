import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { getAllOrders, searchOrder } from '../../models/orders';
import { formatMoney } from '../../libs/utils';

import '../../styles/dashboard.css';

export default class OntripOrdersInsights extends Component {
  constructor() {
    super();
    this.state = {
      orders: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    this.loadAllOrders();
  }

  loadAllOrders() {
    getAllOrders()
      .then((orders) => {
        this.setState({
          orders,
          loadingData: false,
          loadingSuccessFull: true,
        });
      }).catch((err) => {
        console.error('[OnTrip Orders Insights] Error fetching orders data', err, err.stack);
        this.setState({
          loadingData: false,
          loadingSuccessFull: false,
        });
        this.props.setDashboardMessage('error', 'Sorry could not fetch orders information');
      });
  }

  findOrder(e) {
    e.preventDefault();
    const query = this.searchTerm.value;

    if (!query) return this.loadAllOrders();

    this.setState({
      loadingData: true,
      loadingSuccessFull: false,
    });

    searchOrder(query)
      .then((data) => {
        this.setState({
          orders: { data },
          loadingData: false,
          loadingSuccessFull: true,
        });
      }).catch((err) => {
        console.error('[OnTrip Orders Insights] Error serching for orders', query, err, err.stack);
        this.setState({
          loadingData: false,
          loadingSuccessFull: false,
        });
        this.props.setDashboardMessage('error', 'Sorry could not find that orders');
      });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/orders',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Manage On-trip Orders</h2>
          <div className="right-options">
            <div className="search">
              <form onSubmit={e => this.findOrder(e)}>
                <div className="form-element">
                  <input
                    ref={(query) => { this.searchTerm = query; }}
                    type="text"
                    className="form-text"
                    placeholder="Enter order number"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-wrapper">
              <div className="module-content has-table has-users">
                <div className="module-table">
                  <table className="">
                    <thead>
                      <tr>
                        <th width="35%">Order Number</th>
                        <th>Customer</th>
                        <th>Total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="7">
                            <div className="ride-data-row">
                              <div>
                                Loading service data...
                                                                <div className="spinner" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="7">
                            <div className="ride-data-row">
                              <div>
                                Sorry we could not load any service data.
                                                            </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                      <tbody>
                        {this.state.orders.data.map(order => (
                          <tr key={order.id} className="clickable"
                            onClick={() =>
                              this.context.router.history.push('/trip-orders/details',
                                { order })}
                          >
                            <td>{order.order_number}</td>
                            <td>{order.user.fname} {order.user.lname}</td>
                            <td>&#x20A6;{formatMoney(order.grand_total)}</td>
                            <td>{order.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OntripOrdersInsights.propTypes = {
  user: PropTypes.object,
  setDashboardMessage: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

OntripOrdersInsights.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

OntripOrdersInsights.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
