import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import PromotionUsageList from '../../components/promotionUsageList'
import { endPromotionById } from '../../models/promotions';
import '../../styles/spinner.css';

const moment = require("moment");

export default class PromoCodeDetails extends Component {
  constructor() {
    super();
    this.state = {
      promotion: null,
      listUsage: false,
      loadingData: false,
      loadingSuccessful: true,
    };
  }

  componentWillMount() {
    const locationAdmin = this.props.location.state ? this.props.location.state.promotion : null;
    const promotion = locationAdmin || this.props.promotion;
    this.setState({ promotion });
  }

  endPromotion() {
    const promotion = this.state.promotion;

    if (!window.confirm('Are you sure you want to end this Promotion?')) return false; //eslint-disable-line

    this.setState({
      loading: true,
    });

    endPromotionById(promotion.id).then(() => {
      this.context.router.history.goBack();
      this.props.setDashboardMessage('success', 'Promotion ended');
    }).catch((e) => {
      this.setState({
        loading: false,
      });
      this.props.setDashboardMessage('error', 'Sorry an error occured while ending promotion');
      console.error('[Admin Details] Error ending promotion ', promotion.id, e.stack);
    });
  }

  render() {
    const user = this.props.user;
    const promotionDetails = this.state.promotion;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/promotions/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!promotionDetails) {
      return (
        <Redirect to={{
          pathname: '/promotions',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{`${promotionDetails.promo_code}'s Details`}</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>
        <div className="clearfix" />

        <div className="col-md-4">
          <div className={`admin-user-summary ${this.state.loading && 'loading'}`}>
            {this.state.loading && <div className="spinner" />}
            <h3>{promotionDetails.promo_code}</h3>
            <div className="user-data flex flex-row">
              <div>
                {promotionDetails.description}
              </div>
              <div>
                <span>{ !promotionDetails.has_promotion_expired
                              ? "Active"
                              : "Inactive"
                              }</span>
              </div>
              <div>
                {promotionDetails.expires_at ? moment(promotionDetails.expires_at).format('YYYY-MM-DD') : 'Never'}
                <span>Expires At</span>
              </div>

              <div>
                {promotionDetails.max_number_of_uses ? promotionDetails.max_number_of_uses : 'Unlimited'}
                <span>Maximum number of uses</span>
              </div>

              <div>
                {promotionDetails.number_of_usages ? promotionDetails.number_of_usages : 0}
                <span>Number of usages</span>
              </div>

              <div>
                {promotionDetails.customer_benefit && promotionDetails.customer_benefit.customer_constraint ? promotionDetails.customer_benefit.customer_constraint : 'None'}
                <span>Customer Constraint</span>
              </div>
            </div>
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Account Actions</h3>
            </div>
            <ul>
              
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({ listUsage: true });
                }}
                >
                  List Usage <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => { e.preventDefault(); this.endPromotion(); }}>
                  End Promotion <i className="fa fa-delete" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {this.state.listUsage &&
          <div className="col-md-8">
            <div className="module">
              <div className="module-header">
                <h3>Promo Code Usages</h3>
              </div>
              <PromotionUsageList 
                    promotionId={promotionDetails.id}
                  />
            </div>
          </div>
        }
      </div>
    );
  }
}

PromoCodeDetails.propTypes = {
  user: PropTypes.object,
  promotion: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

PromoCodeDetails.defaultProps = {
  user: null,
  promotion: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

PromoCodeDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
