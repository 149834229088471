import React, { Component } from 'react';

export function Error(props) {
  const { message, reset } = props;

  return (
    <div className="error">
      <div
        onClick={reset}
        className="fa fa-close"
        style={{ float: "right", padding: '5px' }}
      />
      {message}
    </div>
  );
}


export function Success(props) {
  const { message, reset } = props;

  return (
    <div className="success">
      <div
        onClick={reset}
        className="fa fa-close"
        style={{ float: "right", padding: '5px' }}
      />
      {message}
    </div>
  );
}
