import { get, post, patch, del } from "../libs/requestClient";
import cacheClient from "../libs/cacheClient";

export const getAllPromotions = () => {
    const routeUri = "/v1/promo-codes";
    return get(routeUri);
};

export const getPromoCodeUsages = (promotionId) => {
    const routeUri = `/v1/promo-codes/${promotionId}/usages`;
  const fetchAllPromotions = get(routeUri).then(users => {
    cacheClient.set(routeUri, users);
    return users;
  });

  return cacheClient
    .get(routeUri)
    .catch(err => {
      console.error("[User model] Error fetching promotions from cache", err.stack);
      return fetchAllPromotions;
    })
    .then(data => {
      if (data) return data;
      return fetchAllPromotions;
    });
};


export const endPromotionById = (promotionId) => {
    const routeUri = `/v1/promo-codes/${promotionId}`;
    return del(routeUri)
      .catch(err => {
        console.error("[User model] Error fetching promotions from cache", err.stack);
        return false;
      })
      .then(data => {
        if (data) return data;
        return true;
      });
}

export const createPromotion = (payload) => post('/v1/promo-codes', payload);