import ManageUsers from './manage';
import UserDetails from './details';
import UserInsights from './insights';
import AddUser from './new';
import Transfers from './Transfers';
import Notify from './Notify';
import UserRouteBookings from './bookings'
import ExportData from './exportData';


export {
  UserRouteBookings,
  ManageUsers,
  UserDetails,
  UserInsights,
  AddUser,
  Transfers,
  Notify,
  ExportData
};

export default UserInsights;
