/**
 * Created by tewshi on 10/4/2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import createHistory from 'history/createBrowserHistory';

import { getAllVehicles, searchVehicles } from '../models/fleet';
import '../styles/spinner.css';

export default class VehicleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fleet: null,
      searchTerm: '',
      loadingData: true,
      loadingSuccessful: false,
    };

    this.history = createHistory();
    this.fetchAllVehicles = this.fetchAllVehicles.bind(this);
  }

  /**
   * Component lifecycle method, called by React when the component mounts
   */
  componentDidMount() {
    const { query } = this.props;
    if (query.length >= 3) return this.executeSearch(query);
    this.fetchAllVehicles();
  }

  componentWillReceiveProps(nextProps) {
    const query = nextProps.query || '';
    if (this.state.searchTerm === query) return false;
    if (query === '') {
      return this.fetchAllVehicles();
    }
    if (query.length >= 3) {
      this.executeSearch(query);
    }
  }

  /**
   * Method used in initializing the state on component mount.
   */
  fetchAllVehicles() {
    this.setState({
      loadingData: true,
      loadingSuccessful: false,
    });
    getAllVehicles().then((data) => {
      this.setState({
        fleet: this.props.summary ? data.slice(0, 6) : data,
        loadingSuccessful: true,
        searchTerm: '',
      });
    }).catch((e) => {
      console.error('[Driver List] Error fetching driver data', e.stack);
      this.setState({ loadingSuccessful: false });
    }).finally(() => {
      this.setState({ loadingData: false });
    });
  }

  executeSearch(query) {
    this.setState({
      searchTerm: query,
      loadingData: true,
      loadingSuccessful: false,
    });

    this.props.setDashboardMessage(); // reset the timeout and dashboard message if there exists any.

    searchVehicles(query)
      .then((fleet) => {
        // If no users reset state
        if (!Array.isArray(fleet) || fleet.length < 1) {
          return this.setState({ loadingSuccessful: false });
        }

        this.setState({
          fleet: fleet,
          loadingSuccessful: true,
        });
      })
      .catch((err) => {
        this.setState({
          loadingSuccessful: false,
        });
        let message = 'Sorry we could not find that vehicle';
        if (err.body && (err.body.code === 'BadRequest')) {
          message = err.body.message;
        }
        this.props.setDashboardMessage('error', message);
      }).finally(() => {
        this.setState({ loadingData: false });
      });
  }

  render() {
    const isSummary = this.props.summary;
    const nameOnly = this.props.nameOnly;
    return (
      <div className="module-wrapper">
        <div className="module-content has-table has-users">
          <div className="module-table">
            <table className="">
              {!nameOnly &&
                <thead>
                  <tr>
                    <th width="35%">Vehicle</th>
                    <th>Plate Number</th>
                    {!isSummary && <th>Capacity</th>}
                    {!isSummary && <th>Type</th>}
                    {!isSummary && <th className="align-right">Vehicle Amenities</th>}
                    <th className="align-right">Driver</th>
                    <th className="align-right">Date Added</th>
                  </tr>
                </thead>
              }
              {(this.state.loadingData && !this.state.loadingSuccessful) &&
                <tbody>
                  <tr className="ride-data" style={{ display: 'table-row' }}>
                    <td colSpan="7">
                      <div className="ride-data-row">
                        <div>
                          Loading fleet data...
                                                <div className="spinner" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              }
              {(!this.state.loadingData && !this.state.loadingSuccessful) &&
                <tbody>
                  <tr className="ride-data" style={{ display: 'table-row' }}>
                    <td colSpan="7">
                      <div className="ride-data-row">
                        <div>
                          Sorry we could not load any fleet data.
                                            </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              }
              {(!this.state.loadingData && this.state.loadingSuccessful) &&
                <tbody>
                  {this.state.fleet.map(vehicle => (
                    <tr key={vehicle.id} className="clickable"
                      onClick={() =>
                        this.context.router.history.push('/fleet/details',
                          { vehicle })}
                    >
                      <td>
                        <div className="user-table-display">
                          <a target="_blank"
                            href={'https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=512x512&chl=' + encodeURIComponent('https://shuttlers.ng/#/vehicles/' + vehicle.id)}>
                            <img src={'https://chart.googleapis.com/chart?cht=qr&choe=UTF-8&chs=80x80&chl=' + encodeURIComponent('https://shuttlers.ng/#/vehicles/' + vehicle.id)} alt={'QR Code Vehicle ' + vehicle.id} />
                          </a>
                          <div
                            className="image css-img-object"
                            style={{
                              backgroundImage:
                                'url(/assets/img/default-bus.png)',
                            }}
                          />
                          <h3>{vehicle.brand} {vehicle.name}</h3>
                        </div>
                      </td>
                      {!nameOnly && <td>{vehicle.registration_number}</td>}
                      {!nameOnly && !isSummary && <td>{vehicle.seats} Seater</td>}
                      {!nameOnly && !isSummary && <td>{vehicle.type}</td>}
                      {!nameOnly && !isSummary &&
                        <td className="align-right">{vehicle.amenities}</td>
                      }
                      {!nameOnly && !isSummary &&
                        <td className="align-right">{vehicle.hasOwnProperty('driver') && vehicle.driver.fname && vehicle.driver.lname 
                          ? vehicle.driver.fname + ' ' + vehicle.driver.lname 
                          : 'N/A'}
                        </td>
                      }
                      {!nameOnly &&
                        <td className="align-right">
                          <Moment format="MM-DD-YYYY">{vehicle.created_at}</Moment>
                        </td>
                      }
                    </tr>
                  ))}
                </tbody>
              }
            </table>
          </div>
        </div>
        <div className="module-footer">
          <div className="pagination">
            <nav>
              <ul>
                <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                <li><a href=""><i className="fa fa-arrow-right" /></a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

VehicleList.propTypes = {
  query: PropTypes.string,
  nameOnly: PropTypes.bool,
  summary: PropTypes.bool,
  setDashboardMessage: PropTypes.func,
};

VehicleList.defaultProps = {
  query: '',
  nameOnly: false,
  summary: false,
  setDashboardMessage: () => { },
};
VehicleList.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
