/**
 * Created by tewshi on 9/4/2018.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createHistory from 'history/createBrowserHistory';

import '../styles/spinner.css';
import { get } from '../libs/requestClient';

export default class Pagination extends Component {
    constructor() {
        super();
        this.state = {
            loadingData: false,
            loadingSuccessful: false,
            first: true,
            last: false,
            total: 0,
            limit: 10,
            offset: 0,
            previous: 0,
            current: 1,
            next: 2,
            url: null,
        };

        this.history = createHistory();
    }

    /**
     * Lifecycle hook, called with the props from the parent component
     */
    componentWillReceiveProps() {
        const { url, limit } = this.props.options;
        this.setState({ url, limit }, this.onPagination);
    }

    /**
     * This fetches data when the component is loaded for the first time.
     */
    onPagination() {
        if (this.state.loadingData) return;
        const { url, limit, offset } = this.state;
        if (offset < 0) return;
        this.setState({
            loadingData: true,
        });
        get(`${url}?limit=${limit}&offset=0`).then((data) => {
            this.setState({
                loadingSuccessful: true,
                total: data.total,
            });
            this.props.callback({}, data);
        }).catch((e) => {
            console.error('[Pagination] Error fetching data', e.stack);
            this.setState({ loadingSuccessful: false });
            this.props.callback({ loadingData: false, loadingSuccessful: false }, null);
        }).finally(() => {
            this.setState({
                loadingData: false,
                last: this.state.total === 0 || Math.ceil(this.state.total / this.state.limit) === this.state.current,
            });
        });
    }

    /**
     * Fetches the required data, previous page or next page.
     * @param evt the event that triggered this function call, if any, especially from button clicks.
     * @param {boolean} nextPage - When true, advance to next page, else go to previous page.
     */
    fetchData(evt, nextPage) {
        if (evt) {
            evt.preventDefault();
        }
        if (this.state.loadingData) return;
        const { url, limit, offset, previous, current, next } = this.state;
        if (this.state.first && !nextPage) {
            return;
        }
        if (this.state.last && nextPage) {
            return;
        }
        if (offset < 0) return;
        this.setState({
            loadingData: true,
        });

        const newOffset = nextPage ? offset + limit : offset - limit;
        get(`${url}?limit=${limit}&offset=${newOffset}`).then((data) => {
            if (nextPage) {
                this.setState({
                    previous: current,
                    current: next,
                    next: next + 1,
                });
            } else {
                this.setState({
                    previous: previous - 1,
                    current: previous,
                    next: current,
                });
            }
            this.setState({
                loadingSuccessful: true,
                total: data.total,
                offset: newOffset,
            });
            this.props.callback({}, data);
        }).catch((e) => {
            console.error('[Pagination] Error fetching data', e.stack);
            this.setState({ loadingSuccessful: false });
            this.props.callback({ loadingData: false, loadingSuccessful: false }, null);
        }).finally(() => {
            this.setState({
                loadingData: false,
                first: this.state.current === 1,
                last: this.state.total === 0 || Math.ceil(this.state.total / this.state.limit) === this.state.current,
            });
        });
    }

    render() {
        return (
            <div className="pagination">
                <nav>
                    {this.state.total > 0 &&
                    <span className={'mr-3'}>
                        {`Page ${this.state.current} of ${Math.ceil(this.state.total / this.state.limit)}`}
                    </span>
                    }
                    <ul>
                        {this.state.first ?
                            <li>
                                <span className={'first'}>
                                    <i className="fa fa-arrow-left" />
                                </span>
                            </li>
                            :
                            <li>
                                <a href="" onClick={e => this.fetchData(e, false)}>
                                    <i className="fa fa-arrow-left" />
                                </a>
                            </li>
                        }
                        {this.state.last ?
                            <li>
                                <span className={'last'}>
                                    <i className="fa fa-arrow-right" />
                                </span>
                            </li>
                            :
                            <li>
                                <a href="" onClick={e => this.fetchData(e, true)}>
                                    <i className="fa fa-arrow-right" />
                                </a>
                            </li>
                        }
                    </ul>
                </nav>
            </div>
        );
    }
}


Pagination.propTypes = {
    options: PropTypes.object,
    callback: PropTypes.func,
};

Pagination.defaultProps = {
    options: null,
    callback: () => {},
};

Pagination.contextTypes = {
    router: PropTypes.shape({
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
            replace: PropTypes.func.isRequired,
        }).isRequired,
        staticContext: PropTypes.object,
    }).isRequired,
};
