"use strict";

import React, { useState, Fragment } from 'react';
import '../../styles/nfc.css'
import {updateBusStop, } from '../../models/busStops'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const UpdateBusStops = (props) => {
    const [value, setValue] = useState(null);
    const [location, setLocation] = useState(null);
    const [location_description, setDescription] = useState(null);
    const [latitude, setLat] = useState(null);
    const [longitude, setLong] = useState(null);

    const handleSubmit = async (e) => {
      e.preventDefault();
      const payload = {
        location: location || props.location.state.data.location,
        location_description: location_description || props.location.state.data.location_description,
        latitude,
        longitude
      }
      const id = props.location.state.data.id
      try {
        await updateBusStop(id, payload);
        return window.alert("Busstop updated successfully")
      }
      catch(e) {
        return window.alert("An error occured while updating busstop, please try again later")
      }
    }

    const myChangeHandler = (event) => {
      let value = event.target.value;
      setDescription(value)
    }

    if(value) {
      geocodeByAddress(value.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocation(value.label);
        setLat(lat)
        setLong(lng)
      });
    }
    
    return (
      <Fragment>
        <div className="_main_wrap">
          <p className="label_">Update Bus-Stop</p>
          <form 
              action="/bus-stops" 
              className="update_bus_stop"
              onSubmit={(event) => this.submitForm(event)}    
          >
            <div className="busstopForm">
              Search Location
              <GooglePlacesAutocomplete
                apiKey="AIzaSyDuyXr09QxMQAgEKt25kyPbhw7MJ-acubE"
                selectProps={{
                  value,
                  onChange: setValue,
                }}
              />
            </div>
            Enter Description
            <input 
              type="text" 
              placeholder={props.location.state.data.location} 
              className="loc_desc" 
              onChange={(e) => myChangeHandler(e)}
              name="location_description"    
            />
            <button
              type="submit"
              className= "btn btn-primary btn-sm update_btn"
              style={{ textAlign: 'center' }}
              onClick={(e) => handleSubmit(e)}
            >
                update
            </button>
          </form>
        </div>
      </Fragment>

    )
};

export default UpdateBusStops;
