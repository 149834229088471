const chartColors = {
  purple: 'rgb(92, 45, 160)',
  indigo: 'rgb(92, 106, 196)',
  blue: 'rgb(0, 122, 206)',
  green: 'rgb(80, 184, 60)',
  yellow: 'rgb(238, 194, 0)',
  orange: 'rgb(244, 147, 66)',
  red: 'rgb(191, 7, 17)',
};

/**
 * Returns the denature time for an itinerary item
 * @param itineraries
 * @param id
 * @returns {String}
 */
export const getItineraryTime = (itineraries, id) => itineraries.reduce((acc, val) => {
  if (acc) return acc;

  if (val && val.id && val.id === id) {
    return `${val.departure_time}${val.time_of_day}`;
  }

  return '';
}, null);

/**
 * Formats money value
 * @param value
 * @returns {string}
 */
export const formatMoney = (value) => {
  if (!value) return '';

  const moneyValue = value.toString().split('.');
  moneyValue[0] = moneyValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return moneyValue.join('.');
};

/**
 * Capitalize a string
 * @param {*} string
 */
export const capitalizeString = string => string.charAt(0).toUpperCase() + string.slice(1);

export const initLineChart = (cl, chartdata, tiplabel) => {
  if (!window.jQuery(cl).length) return false;

  window.jQuery.plot(window.jQuery(cl), chartdata, {
    series: {
      lines: {
        show: true,
        lineWidth: 2,
        fill: true,
        fillColor: {
          colors: [{
            opacity: 0.35,
          }, {
            opacity: 0.35,
          }],
        },
      },
      points: {
        show: true,
      },
      shadowSize: 0,
    },
    legend: {
      show: true,
    },
    grid: {
      margin: {
        left: -8,
        right: -8,
        top: 0,
        bottom: 0,
      },
      show: false,
      labelMargin: 15,
      axisMargin: 500,
      hoverable: true,
      clickable: true,
      tickColor: 'rgba(0,0,0,0.15)',
      borderWidth: 0,
    },

    colors: [chartColors.purple, chartColors.blue, chartColors.green, chartColors.yellow],
    xaxis: {
      ticks: 12,
      tickDecimals: 0,
      tickLength: 0,
    },
    yaxis: {
      show: false,
      autoscaleMargin: 0.5,
      ticks: 4,
      tickDecimals: 0,
    },
  });

  function showTooltip(x, y, contents, color) {
    window.jQuery(`<div id="tooltip">${formatMoney(contents)}</div>`).css({
      position: 'absolute',
      display: 'none',
      padding: '5px 10px',
      'font-size': '12px',
      'font-weight': '500',
      top: y + 5,
      left: x + 5,
      'border-width': '2px',
      'border-style': 'solid',
      'border-color': color,
      color: '#fff',
      'background-color': color,
      opacity: 0.90,
    }).appendTo('body').fadeIn(200);
  }

  // Figure out which point is being hovered over and display that data
  window.jQuery(cl).bind('plothover', (event, pos, item) => {
    let previousPoint;
    if (item) {
      if (previousPoint !== item.dataIndex) {
        previousPoint = item.dataIndex;

        window.jQuery('#tooltip').remove();

        // This defines x and y as variables that will display the
        // x and y values of the point being hovered over. If you'd like to display
        // decimal places, try this code: var x = item.datapoint[0].toFixed(2);
        // Be aware that this will affect all tooltips for all charts on this page.

        // var x = item.datapoint[0];
        const y = item.datapoint[1];

        // This is where you can control what shows up in your tooltip.
        // "item.series.label" will return the name of your dataset.
        const label = `${tiplabel} ${y}`;

        // showTooltip is a function that takes three comma-separated arguments.
        // The first two arguments will help position the tooltip near the point.
        // The third is our label, defined above, and the fourth returns the color of the chart.
        showTooltip(item.pageX, item.pageY, label, item.series.color);
      }
    } else { // Once we're no longer hovering over the point, remove the tooltip
      window.jQuery('#tooltip').remove();
      previousPoint = null;
    }
  });
};

export const initPieChart = (cl, chartdata) => {
  if (!window.jQuery(cl).length) return false;

  const color1 = 'rgb(92, 45, 160)';
  const color2 = 'rgb(0, 122, 206)';
  const color3 = 'rgb(80, 184, 60)';
  const color4 = 'rgb(244, 147, 66)';


  window.jQuery.plot(cl, chartdata, {
    series: {
      pie: {
        show: true,
        innerRadius: 0.35,
        shadow: {
          top: 5,
          left: 15,
          alpha: 0.3,
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        label: {
          show: true,
          formatter: (label, series) => `
                        <div style="font-size:12px;text-align:center;padding:2px;color:#777;">
                            <span style="display:block; font-weight:600;">
                                ${Math.round(series.percent.toFixed(2))}%
                            </span>
                            ${label}
                        </div>`,
          threshold: 0.1,
        },
        highlight: {
          opacity: 0.08,
        },
      },
    },
    grid: {
      hoverable: true,
      clickable: true,
    },
    colors: [color1, color2, color3, color4],
    legend: {
      show: false,
    },
  });
};

export const initBarChart = (cl, chartdata) => {
  if (!window.jQuery(cl).length) return false;

  window.jQuery.plot(cl, chartdata, {
    series: {
      bars: {
        show: true,
        barWidth: 0.6,
        align: 'center',
      },
    },
    grid: {
      margin: {
        left: 0,
        right: 0,
        top: 20,
        botttom: 40,
      },
      labelMargin: 15,
      axisMargin: 200,
      hoverable: true,
      clickable: true,
      tickColor: 'rgba(0,0,0,.040)',
      borderWidth: 0,
      borderColor: 'rgba(0,0,0,0.15)',
    },
    label: {
      show: true,
      formatter: label => `
                <div style="font-size:12px;text-align:center;padding:2px;color:#333;">
                    ${label}
                </div>`,
    },
    colors: [chartColors.purple, chartColors.blue],
    xaxis: {
      mode: 'categories',
      tickLength: 0,
    },
    yaxis: {
      ticks: 4,
      tickDecimals: 0,
    },
  });
};

/**
 * Scrolls to an element, given the element's id
 * @param id - the id of the element (without #) to scroll to.
 * @param {'auto'|'instant'|'smooth'} [behavior=smooth] behavior - the transition animation
 */
export const scrollToEl = (id, behavior = 'smooth') => {
  try {
    document.querySelector(`#${id}`).scrollIntoView({ behavior });
  } catch (ex) {
    console.log(ex.message);
  }
};

export default {
  formatMoney,
  getItineraryTime,
  scrollToEl,
};
