/**
 * Created by constance.okoghenun on 14/09/2017.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import RouteList from "../../components/routeList";
import "../../styles/dashboard.css";

export default class ManageRoutes extends Component {
  constructor() {
    super();
    this.state = {
      users: null,
      searchQuery: "",
      filter: ""
    };

    this.findRoute = this.findRoute.bind(this);
  }

  findRoute(e) {
    e.preventDefault();
    const query = this.search.value;

    if (query.trim().length >= 1) {
      this.setState({
        searchQuery: query
      });
    } else {
      this.setState({ searchQuery: "", filter: "" });
    }
  }

  filter(e) {
    this.setState({ ...this.state, filter: e.target.value });
  }

  reset() {
    this.active.checked = false;
    this.inactive.checked = false;
    this.setState({ filter: "" });
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: "/users/manage",
            rtProps: this.props.location.state,
            pathname: "/login"
          }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Manage Routes</h2>

          <div className="right-options">
            <div className="search">
              <form
                onSubmit={e => {
                  this.findRoute(e);
                }}
                ref={el => (this.form = el)}
              >
                <div className="form-element">
                  <input
                    ref={input => {
                      this.search = input;
                    }}
                    type="text"
                    className="form-text"
                    placeholder="Enter search term"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="form-element">
              <label htmlFor="active">
                <input
                  type="radio"
                  onChange={this.filter.bind(this)}
                  ref={el => this.active = el}
                  name="active"
                  value="1"
                />
                Active
              </label>

              <label
                htmlFor="inactive"
                style={{ marginLeft: "10px" }}
              >
                <input
                  type="radio"
                  onChange={this.filter.bind(this)}
                  ref={el => this.inactive = el}
                  name="active"
                  value="0"
                />
                Inactive
              </label>

              <button className="btn btn-small btn-default" style={{ marginLeft: '20px' }} onClick={this.reset.bind(this)}>
                RESET
              </button>
            </div>
            <div className="module-header" />

            <RouteList
              query={this.state.searchQuery}
              filter={this.state.filter}
              reset={this.reset.bind(this)}
              setDashboardMessage={this.props.setDashboardMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}

ManageRoutes.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  setDashboardMessage: PropTypes.func
};

ManageRoutes.defaultProps = {
  user: null,
  location: {
    state: {}
  },
  setDashboardMessage: () => { }
};
