import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { getAllDrivers } from '../../models/drivers';
import { updateCharter } from '../../models/charter';
import '../../styles/spinner.css';

export default class CharterDetails extends Component {
  constructor() {
    super();
    this.state = {
      charter: null,
      drivers: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    const charter = this.props.charter || this.props.location.state.charter;

    getAllDrivers().then((drivers) => {
      this.setState({
        charter,
        drivers,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((e) => {
      console.error('[Charter Details], Error fetching driver data', e.stack);
      this.props.setDashboardMessage('error', 'Sorry we could not fetch driver information');
      this.setState({
        loadingData: false,
        loadingSuccessFull: true,
      });
    });
  }

  updateCharter(e) {
    e.preventDefault();
    const charter = this.state.charter || this.props.charter || this.props.location.state.charter;
    const data = {
      driver_id: this.driver.value,
      fare: this.fare.value,
      status: this.status.value,
    };

    if (!data.driver_id || !data.fare || !data.status) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    updateCharter(charter.id, data).then(() => {
      this.props.setDashboardMessage('success', 'Request updated succesfully');
      this.setState({
        charter,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((err) => {
      console.error('[Charter Details] Error updating charter', data, err.stack);
      this.props.setDashboardMessage('error', 'Sorry an error occured while updating the charter request');
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  render() {
    const user = this.props.user;
    const charter = this.state.charter || this.props.charter || this.props.location.state.charter;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/chaters/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Charter Request Details</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
                    </button>
        </div>

        <div className="clearfix" />

        <div className="col-md-7">
          <div className={`admin-user-summary ${this.state.loadingData && 'loading'}`}>
            {(this.state.loadingData && !this.state.loadingSuccessFull) &&
              <div className="spinner" />
            }
            <div className="form-sub-header">
              <h4>Customer Details</h4>
            </div>

            <div className="user-data flex flex-row">
              <div className="">
                {charter.fname} {charter.lname}
                <span>Full Name</span>
              </div>
              <div className="dual">
                {charter.email}
                <span>E-mail Address</span>
              </div>
              <div className="dual">
                {charter.phone}
                <span>Phone Number</span>
              </div>
              <div className="">
                {charter.how_did_you_hear}
                <span>How did you hear about Shuttlers</span>
              </div>
              <div className="">
                {charter.vehicles}
                <span>Vehicle</span>
              </div>

            </div>
            <div className="form-sub-header">
              <h4>Pickup Details</h4>
            </div>
            <div className="user-data flex flex-row">
              <div className="">
                {charter.pickup_address}
                <span>Pickup Address</span>
              </div>
              <div className="dual">
                {charter.pickup_date}
                <span>Pickup Date</span>
              </div>
              <div className="dual">
                {charter.pickup_time}
                <span>Pickup Time</span>
              </div>
              <div className="">
                {charter.dropoff_address}
                <span>Drop Off Address</span>
              </div>

            </div>

            {charter.return_address &&
              <div>
                <div className="form-sub-header">
                  <h4>Return Trip</h4>
                </div>
                <div className="user-data flex flex-row">
                  <div className="">
                    {charter.return_address}
                    <span>Return Address</span>
                  </div>
                  <div className="dual">
                    {charter.return_time}
                    <span>Return Time</span>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>

        <div className="col-md-5">
          <div className="module">
            <div className="module-wrapper">
              <div className="module-content module-form-wrapper">
                <div className="form-wrapper">
                  {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                    <div>
                      <div className="spinner" />
                      <div className="form-sub-header">
                        <h4>Admin Feedback</h4>
                      </div>
                    </div>
                  }

                  {(!this.state.loadingData &&
                    this.state.loadingSuccessFull && charter.status !== 'Completed') &&
                    <form onSubmit={e => this.updateCharter(e)}>
                      <div className="row">

                        <div className="form-sub-header">
                          <h4>Admin Feedback</h4>
                        </div>
                        <div className="form-element col-md-6">
                          <span className="label">Set Status</span>
                          <div className="form-select">
                            <select ref={(input) => { this.status = input; }} >
                              <option value="Accepted">Accepted</option>
                              <option value="Completed">Completed</option>
                              <option value="Cancelled">Cancelled</option>
                            </select>
                          </div>
                        </div>

                        <div className="form-element col-md-6">
                          <span className="label">Assign Driver</span>
                          <div className="form-select">
                            <select ref={(input) => { this.driver = input; }} id="">
                              {this.state.drivers.map(driver => (
                                <option
                                  key={`driver ${driver.id}`}
                                  value={driver.id}
                                >{driver.fname} {driver.lname}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="form-element col-md-6">
                          <span className="label">Set Fare</span>
                          <input
                            type="text"
                            ref={(input) => { this.fare = input; }}
                            placeholder="2000"
                            defaultValue={charter.fare}
                            className="form-text"
                          />
                        </div>
                      </div>

                      <div className="form-element">
                        <input
                          disabled={(this.state.loadingData && !this.state.loadingSuccessFull)}
                          type="submit"
                          className="bvtton"
                          value="Update Request"
                        />
                      </div>
                    </form>
                  }

                  {(!this.state.loadingData &&
                    this.state.loadingSuccessFull && charter.status !== 'New') &&
                    <div className="row">
                      <div className="form-sub-header">
                        <h4>Admin Feedback</h4>
                      </div>
                      <div className="form-element col-md-6">
                        <span className="label">Charter Status</span>
                        <div className="form-select">
                          {charter.status}
                        </div>
                      </div>

                      <div className="form-element col-md-6">
                        <span className="label">Assigned Driver</span>
                        <div className="form-select">
                          {charter.driver.fname} {charter.driver.lname}
                        </div>
                      </div>

                      <div className="form-element col-md-6">
                        <span className="label">Charter Fare</span>
                        {charter.fare}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="module-footer" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


CharterDetails.propTypes = {
  user: PropTypes.object,
  charter: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

CharterDetails.defaultProps = {
  user: null,
  charter: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

CharterDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
