import React, { PureComponent } from 'react'

import SearchBox from '../components/virtualize-search'
import CardLink from '../components/cardlink'
import Datatable from '../components/datatable'
import { getAllUsers, getAllLinkUsers } from '../models/users'

import '../styles/nfc.css'

export default class NFC extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      users: [],
      selectedUser: null,
    }
  }

  componentDidMount() {
    this.fetchLinkedUsers()
  }

  fetchLinkedUsers = () => {
    getAllLinkUsers()
      .then(users => this.setState({ isLoading: false, users }))
      .catch(e => this.setState({ isLoading: false }))
  }

  setSelectedUser = selectedUser => this.setState({ selectedUser })

  filterUserSuggestions = (suggestions, query) => {
    return suggestions.filter(item => {
      let username = `${item.fname} ${item.lname}`
      const matchWithUsername = username
        .toLowerCase()
        .includes(query.toLowerCase())
      const matchWithEmail = item.email
        .toLowerCase()
        .includes(query.toLowerCase())

      if (matchWithUsername || matchWithEmail) {
        return true
      }

      return false
    })
  }

  renderSuggestion = suggestions =>
    suggestions.map((user, index) => (
      <div
        key={index}
        className="suggest-list-item"
        onClick={() => this.setSelectedUser(user)}
      >
        <p>{`${user.fname} ${user.lname}`}</p>
        <span>{user.email}</span>
      </div>
    ))

  render() {
    const columns = [
      {
        key: 'index',
        text: 'S/N',
        sortable: true,
        cell: (_, index) => {
          return index
        },
      },
      {
        key: 'fname',
        text: 'First Name',
        sortable: true,
      },
      {
        key: 'lname',
        text: 'Last Name',
        sortable: true,
      },
      {
        key: 'email',
        text: 'Email',
        // sortable: true,
      },
      {
        key: 'phone',
        text: 'Phone',
        // sortable: true,
      },
      {
        key: 'nfc_id',
        text: 'NFC Card ID',
        sortable: false,
      },
    ]

    return (
      <div className="user-dash-wrapper">
        <div className="row">
          <div className="col-md-4">
            <SearchBox
              placeholder="Search Unlinked User"
              shouldSuggest
              loadSuggestion={getAllUsers}
              renderSuggestion={this.renderSuggestion}
              filterSuggestions={this.filterUserSuggestions}
            />
          </div>
        </div>
        <CardLink
          user={this.state.selectedUser}
          clearUser={() => this.setState({ selectedUser: null })}
        />
        <div className="col-md-12 col-lg-12">
          <h3>Linked User</h3>
        </div>
        {/* <div className="col-md-12 col-lg-12"> */}
        {/* <div className="module focus green"> */}
        {/* <div className="module-header" /> */}
        <Datatable
          title="Users"
          data={this.state.users}
          columns={columns}
          {...this.props}
        />
        {/* </div> */}
        {/* </div> */}
      </div>
    )
  }
}
