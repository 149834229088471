import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import { getSuggestions } from '../../models/routes';
import DatePicker from '../../components/datepicker';

import '../../styles/dashboard.css';

export default class SuggestedRoutes extends Component {
  constructor() {
    super();
    this.state = {
      suggestions: 0,
      filter: null,
      loadingData: true,
      loadingSuccessFull: false,
    };
  }

  componentDidMount() {
    getSuggestions().then((suggestions) => {
      this.setState({
        suggestions: suggestions.data || suggestions,
        loadingData: false,
        loadingSuccessFull: true,
      });
    }).catch((e) => {
      console.error('[Suggestion] Error fetching data', e.stack);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }

  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;
    if (from && to) this.setState({ filter: { from, to } }) 
    else {
      window.alert("Please indicate start and end date in the query");
      return false;
    }
    const filteredData = this.state.suggestions.filter(data => 
        data.created_at.split(" ")[0] >= from && data.created_at.split(" ")[0] <= to
    );
    this.setState({suggestions: filteredData})
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/suggestion',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="clearfix" />
        <div className="sub-page-header col-sm-12">
          <h2>Suggested Routes</h2>
        </div>

        <div className="col-md-12 col-lg-12">

          <div className="module focus green">
            <div className="module-pre-header-filter">
              <form onSubmit={e => this.applyFilters(e)}>
                <div className="flex flex-wrap inline-form-wrapper">
                  <div className="title">
                    <h3>Filter by Date</h3>
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.startDate = data; }}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="form-element">
                    <DatePicker
                      type="date"
                      onUpdate={(data) => { this.endDate = data; }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="form-element">
                    <button type="submit">Filter</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="module-header" />
            <div className={`module-wrapper ${this.state.loadingData && 'loading'}`}>
              <div className="module-content has-table has-users">
                <div className="module-table">
                  <table className="">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>From</th>
                        <th>To</th>
                        <th className="align-right">Departure time</th>
                      </tr>
                    </thead>
                    {(this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="6">
                            <div className="ride-data-row">
                              <div>
                                Loading transaction history...
                                                                <div className="spinner" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && !this.state.loadingSuccessFull) &&
                      <tbody>
                        <tr className="ride-data" style={{ display: 'table-row' }}>
                          <td colSpan="6">
                            <div className="ride-data-row">
                              <div>
                                Sorry we could not load transaction history.
                                                            </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                    {(!this.state.loadingData && this.state.loadingSuccessFull) &&
                      <tbody>
                        {this.state.suggestions.map(suggestion => (
                          <tr key={suggestion.id} className="clickable">
                            <td><Moment format="h:mm A">{suggestion.created_at}</Moment>
                              <br />
                              <Moment format="MMM Do YY">{suggestion.created_at}</Moment>
                            </td>
                            <td>{suggestion.pickup}</td>
                            <td>{suggestion.destination}</td>
                            <td className="align-right money">
                              {suggestion.departure_time} {suggestion.time_of_day}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    }

                  </table>
                </div>
              </div>
              <div className="module-footer">
                <div className="pagination">
                  <nav>
                    <ul>
                      <li><a href=""><i className="fa fa-arrow-left" /></a></li>
                      <li><a href=""><i className="fa fa-arrow-right" /></a></li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

SuggestedRoutes.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

SuggestedRoutes.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
