import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Moment from 'react-moment';

import DriverTrips from '../../components/driverTrips';
import AddVehicle from '../../components/addVehicle';
import { getAllVehicles } from '../../models/fleet';
import { getAllRoutes } from '../../models/routes';
import { getVehicle } from '../../models/vehicles';
import { getDriverTrips, updateDriver, deleteDriver,startTripForRoute, driverTripsGraph, assignRoute, unassignRoute, getDriverRoutes, getDriverActiveTrips, endDriverTrip } from '../../models/drivers';
import { initBarChart } from '../../libs/utils';
import moment from 'moment'
import '../../styles/spinner.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default class DriverDetails extends Component {
  constructor() {
    super();
    this.state = {
      trips: null,
      vehicles: [],
      routes: [],
      driverRoutes: [],
      driverVehicle: null,
      loading: true,
      userDetails: null,
      assignVehicle: false,
      assignRoute: false,
      startTrip: false,
      endTrip: false,
      activeTrips: [],
      unassignRoute: false,
      editDriver: false,
      changePassword: false,
      value: null,
      updateBus: false
    };
  }

  componentDidMount() {
    const userDetails = this.state.userDetails ||
      (this.props.location.state && this.props.location.state.userDetails);
    const driverTrips = getDriverTrips(userDetails.id);
    const driverRoutes = getDriverRoutes(userDetails.id);
    const activeTrips = getDriverActiveTrips(userDetails.id);
    const driverTripsGrph = driverTripsGraph(userDetails.id);
    const allVehicles = getAllVehicles();
    const allRoutes = getAllRoutes();

    Promise.resolve(driverRoutes)
    .then((driverRoutes) => {
      this.setState({
        driverRoutes,
      });
    }).catch((err) => {
      console.error('[Driver Details] Error fetching driverRoutes for driver', userDetails.id, err.stack);
    }).finally(() => {
      this.setState({
        loading: false,
        userDetails,
      });
    });


    Promise.resolve(driverTrips)
      .then((trips) => {
        this.setState({
          trips,
        });
      }).catch((err) => {
        console.error('[Driver Details] Error fetching trips for driver', userDetails.id, err.stack);
      }).finally(() => {
        this.setState({
          loading: false,
          userDetails,
        });
      });

    Promise.resolve(driverTripsGrph)
      .then((data) => {
        this.plotGraph(data);
      }).catch((err) => {
        console.error('[Driver Details] Error fetching trips graph for driver', userDetails.id, err.stack);
      }).finally(() => {
        this.setState({
          loading: false,
        });
      });

    if (userDetails.vehicle_id) {
      const driverVehicleData = getVehicle(userDetails.vehicle_id);

      Promise.resolve(driverVehicleData)
        .then((driverVehicle) => {
          this.setState({
            driverVehicle,
          });
        }).catch((err) => {
          console.error('[Driver Details] Error fetching vehicle for driver', userDetails.id, err.stack);
        }).finally(() => {
          this.setState({
            loading: false,
          });
        });
    }

    Promise.resolve(allVehicles)
      .then((vehicles) => {
        this.setState({
          vehicles,
        });
      }).catch((err) => {
        console.error('[Driver Details] Error fetching all vehicles', userDetails.id, err.stack);
      }).finally(() => {
        this.setState({
          loading: false,
        });
      });

    Promise.resolve(activeTrips)
      .then((trips) => {
        trips = trips.filter((x) => {
          let start = moment(x.start_trip).format('YYYY-MM-DD');
          let today = moment().format('YYYY-MM-DD');
          return start >= today
        })

        this.setState({
          activeTrips: trips
        })
      })
      .catch((err) => {
        console.log("could not get active trips  ", err)
      })

    Promise.resolve(allRoutes)
      .then((routes) => {
        this.setState({
          routes,
        });
      }).catch((err) => {
        console.error('[Driver Details] Error fetching all routes', userDetails.id, err.stack);
      }).finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  updateDriverBus(e) {
    this.setState({
      updateBus: true
    })
  }

  plotGraph(data) {
    const driverData = [];
    data.forEach((datum) => {
      driverData.push([datum.month, datum.total]);
    });

    const driver30Day = [{
      data: driverData,
      label: 'Rides',
    }];

    initBarChart('.linechart.user-transit-rides', driver30Day);
  }

  suspendDriver() {
    const driver = this.props.location.state.userDetails;
    const data = { active: 0 };

    this.setState({
      loading: true,
    });
    updateDriver(driver.id, data).then((userDetails) => {
      this.setState({
        userDetails,
      });
      this.props.setDashboardMessage('success', 'Driver account suspended');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while suspending driver');
      console.error('[Driver Details] Error suspending driver ', driver.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  unsuspendDriver() {
    const driver = this.props.location.state.userDetails;
    const data = { active: 1 };

    this.setState({
      loading: true,
    });
    updateDriver(driver.id, data).then((userDetails) => {
      this.setState({
        userDetails,
      });
      this.props.setDashboardMessage('success', 'Driver account unsuspended');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while unsuspending driver');
      console.error('[Driver Details] Error unsuspending driver ', driver.id, e.stack);
    })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  editDriver(evt) {
    evt.preventDefault();
    const driver = this.state.userDetails;

    if (!this.fname.value || !this.lname.value || !this.email.value || !this.phone.value) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    const data = {
      fname: this.fname.value,
      lname: this.lname.value,
      email: this.email.value,
      phone: this.phone.value,
    };

    this.setState({
      loading: true,
    });
    updateDriver(driver.id, data).then((userDetails) => {
      this.setState({
        editDriver: false,
        userDetails,
      });
      this.props.setDashboardMessage('success', 'Driver account updated');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while updating driver details');
      console.error('[Driver Details] Error updating driver', driver.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  async endDriverTrip(e) {
    e.preventDefault();
    try {
      let endTrip = await endDriverTrip({
        destination_coordinate: this.state.activeTrips.length 
          ? this.state.activeTrips[0].route.destination_coordinate 
          : "6.5135466666661115, 3.3668908333333337"
      }, e.target.value)
  
      if(endTrip) {
        this.props.setDashboardMessage('success', 'Trip ended successfully');
      }
    }
    catch(error) {
      console.log(error, "Error while ending trip")
      return this.props.setDashboardMessage('error', 'An error occured while ending trip');
    }
  }

  changePassword(evt) {
    evt.preventDefault();
    const driver = this.state.userDetails;
    const password = this.password.value;
    const password2 = this.password2.value;

    if ((password && !password2) || (!password && password2)) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    } else if ((password && password2) && (password.length < 4)) {
      return this.props.setDashboardMessage('error', 'Password length should be at least 4 characters long');
    } else if (password && (password !== password2)) {
      return this.props.setDashboardMessage('error', 'Password confirmation does not match');
    }

    const data = { password };

    this.setState({
      loading: true,
    });
    updateDriver(driver.id, data).then((userDetails) => {
      this.setState({
        changePassword: false,
        userDetails,
      });
      this.props.setDashboardMessage('success', 'Driver account updated');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while changing driver password');
      console.error('[Driver Details] Error changing driver password', driver.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  assignVehicle(evt) {
    evt.preventDefault();
    const driver = this.props.location.state.userDetails;
    const data = { vehicle_id: this.selectedVehicle.value };

    this.setState({
      loading: true,
    });
    updateDriver(driver.id, data).then((userDetails) => {
      this.setState({
        assignVehicle: false,
        userDetails,
      });
      this.props.setDashboardMessage('success', 'Vehicle assignment successful');
    }).catch((e) => {
      if (e.error.code === 'ItemAlreadyExist') {
        this.props.setDashboardMessage('error', 'This bus has already been assigned');
        return false;
      }
      this.props.setDashboardMessage('error', 'Sorry an error occurred while assigning a vehicle');
      console.error('[Driver Details] Error assigning vehicle to driver ', driver.id, data, e, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  assignRoute(evt) {
    evt.preventDefault();
    const driver = this.props.location.state.userDetails;
    const data = { route_id: this.state.value.id };

    this.setState({
      loading: true,
    });
    assignRoute(driver.id, data).then((userDetails) => {
      this.setState({
        assignRoute: false,
        userDetails,
      });
      this.props.setDashboardMessage('success', 'Route assignment successful');
    }).catch(({ error }) => {
      this.props.setDashboardMessage('error', error.message);
      console.error('[Driver Details] Error assigning route to driver ', driver.id, data);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }



  startTripForRoute(evt) {
    evt.preventDefault();
    const driver = this.props.location.state.userDetails;

    const _entries = this.state.driverRoutes.filter((v) => this.selectedRoute.value == v.id);

    if(!_entries.length) {
      return;
    }

    const routeModel = _entries[0];

    console.log(routeModel);
  
    const data = { route_id: routeModel.id,pickup_coordinate: routeModel.pickup_coordinate, };

    this.setState({
      loading: true,
    });
    startTripForRoute(driver.id, data).then((userDetails) => {
      this.setState({
        startTrip: false,
      });
      this.props.setDashboardMessage('success', 'Route Trip Started successful');
    }).catch(({ error }) => {
      this.props.setDashboardMessage('error', error.message);
      console.error('[Driver Details] Error starting route  trip for driver ', driver.id, data);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  unassignRoute(evt) {
    evt.preventDefault();
    const driver = this.props.location.state.userDetails;
    const data = { route_id: this.selectedRoute.value };

    if (!window.confirm('Are you sure you want to unassign this route?')) return false; //eslint-disable-line

    this.setState({
      loading: true,
    });

    unassignRoute(driver.id, data).then((userDetails) => {
      this.setState({
        unassignRoute: false,
        userDetails,
      });
      this.context.router.history.goBack();
      this.props.setDashboardMessage('success', 'Route successfully unassigned');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while unassigning route');
      console.error('[Driver Details] Error deleting driver ', driver.id, data);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  deleteDriver() {
    const driver = this.props.location.state.userDetails;

    if (!window.confirm('Are you sure you want to delete this driver?')) return false; //eslint-disable-line

    this.setState({
      loading: true,
    });

    deleteDriver(driver.id).then(() => {
      this.context.router.history.goBack();
      this.props.setDashboardMessage('success', 'Driver account deleted');
    }).catch((e) => {
      this.props.setDashboardMessage('error', 'Sorry an error occurred while deleting driver');
      console.error('[Driver Details] Error deleting driver ', driver.id, e.stack);
    }).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const user = this.props.user;
    const userDetails = this.state.userDetails ||
      (this.props.location.state && this.props.location.state.userDetails);
    // if we have a user session we redirect away from sign up
    const vehicle = this.state.driverVehicle;
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/driver/details',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!userDetails) {
      return (
        <Redirect to={{
          pathname: '/drivers',
        }}
        />
      );
    }
    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{`${userDetails.fname}'s Details`}</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
          </button>
        </div>
        <div className="clearfix" />

        <div className="col-md-4">
          <div className={`admin-user-summary ${this.state.loading && 'loading'}`}>
            {this.state.loading && <div className="spinner" />}
            <div className="user-picture">
              <div className="image css-img-object"
                style={{ backgroundImage: `url(${userDetails.avatar || '/assets/img/default.png'})` }}
              />
            </div>
            <h3>{userDetails.fname} {userDetails.lname}</h3>
            <div className="user-data flex flex-row">
              <div>
                {userDetails.phone}
                <span>Phone Number</span>
              </div>
              <div>
                {userDetails.email}
                <span>E-mail Address</span>
              </div>
              <div>
                {userDetails.vehicle_id ? 'Yes' : 'No'} - {vehicle ?
                  `${vehicle.brand} ${vehicle.name} (${vehicle.registration_number})` :
                  'None'}
                <span>BUS ASSIGNED</span>
              </div>
            </div>
            <div className="bi-stat">
              <div className="user-rides-count">
                {this.state.trips ? `${this.state.trips.total}` : '0'}
                <span>rides completed</span>
              </div>
              <div className="">
                <div><Moment format="MMM Do, YYYY">{userDetails.created_at}</Moment></div>
                <span>MEMBER SINCE</span>
              </div>
            </div>
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Account Actions</h3>
            </div>
            <ul>
              <li>
                <a href=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      assignVehicle: true,
                      startTrip: false,
                      assignRoute: false,
                      unassignRoute: false,
                      editDriver: false,
                      changePassword: false,
                      endTrip: false,
                    });
                  }}
                >
                  Assign Bus <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    assignRoute: true,
                    startTrip: false,
                    unassignRoute: false,
                    assignVehicle: false,
                    editDriver: false,
                    changePassword: false,
                    endTrip: false,
                  });
                }}
                >
                  Assign Route <i className="fa fa-angle-right" />
                </a>
              </li>

              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    startTrip: true,
                    assignRoute: false,
                    unassignRoute: false,
                    assignVehicle: false,
                    editDriver: false,
                    changePassword: false,
                    endTrip: false,
                  });
                }}
                >
                  Start Trip <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    endTrip: true,
                    startTrip: false,
                    assignRoute: false,
                    unassignRoute: false,
                    assignVehicle: false,
                    editDriver: false,
                    changePassword: false,
                  });
                }}
                >
                  End Trip <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    endTrip: false,
                    unassignRoute: true,
                    assignRoute: false,
                    startTrip: false,
                    assignVehicle: false,
                    editDriver: false,
                    changePassword: false,
                  });
                }}
                >
                  Unassign Route <i className="fa fa-angle-right" />
                </a>
              </li>
              {userDetails.active === '0' ?
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.unsuspendDriver(); }}>
                    Unsuspend Driver <i className="fa fa-angle-right" />
                  </a>
                </li>
                :
                <li>
                  <a href="" onClick={(e) => { e.preventDefault(); this.suspendDriver(); }}>
                    Suspend Driver <i className="fa fa-angle-right" />
                  </a>
                </li>
              }
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    editDriver: true,
                    assignRoute: false,
                    unassignRoute: false,
                    assignVehicle: false,
                    startTrip: false,
                    changePassword: false,
                    endTrip: false,
                  });
                }}
                >
                  Edit Driver <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    changePassword: true,
                    editDriver: false,
                    assignRoute: false,
                    unassignRoute: false,
                    startTrip: false,
                    assignVehicle: false,
                    endTrip: false,
                  });
                }}
                >
                  Change Password <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href=""
                  onClick={(e) => { e.preventDefault(); this.deleteDriver(); }}
                  style={{
                    color: 'red'
                  }}>
                  Delete Driver <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a href=""
                  onClick={(e) => { e.preventDefault(); this.updateDriverBus(); }}
                >
                  Edit Bus <i className="fa fa-angle-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {this.state.assignVehicle &&
          <div className="col-md-5">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.assignVehicle(e)}>
                      <div className="row">
                        <div className="form-sub-header">
                          <h4>Assign Vehicle</h4>
                          <span>Assign a vehicle to this driver</span>
                        </div>

                        <div className="form-element col-md-12">
                          <span className="label">Vehicles</span>
                          <div className="form-select long">
                            <select
                              ref={(input) => { this.selectedVehicle = input; }}
                              defaultValue={userDetails.vehicle_id}
                            >
                              {this.state.vehicles.map(car => (
                                <option
                                  value={car.id}
                                  key={car.id}
                                >
                                  {car.seats} Seater - {' '}
                                  {car.brand} {car.name} ({car.registration_number})
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="clearfix" />

                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Assign Vehicle" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {
          this.state.updateBus && (
            <div className="col-md-6">
              <div className="module">
                  <div className="module-content module-form-wrapper">
                    <AddVehicle
                      setDashboardMessage={this.props.setDashboardMessage}
                      vehicle={vehicle}
                      onUpdate={v => { }}
                    />
                  </div>
              </div>
            </div>
          )
        }

        {this.state.assignRoute &&
          <div className="col-md-5">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.assignRoute(e)}>
                      <div className="row">
                        <div className="form-sub-header">
                          <h4>Assign Route</h4>
                          <span>Assign a route to this driver</span>
                        </div>
                        <div className="form-element col-md-12">
                          <div>
                            <Autocomplete
                              options={this.state.routes}
                              getOptionLabel={(option) => `${option.pickup} - ${ option.destination}`}
                              value={this.state.value}
                              onChange={(event, newValue) => {
                                this.setState({value: newValue});
                              }}
                              style={{
                                width: '98%',
                                margin: 'auto',
                                height: '50px',
                                marginBottom: 25,
                                fontSize: '25',
                              }}
                              renderInput={(params) => 
                                <TextField 
                                  {...params} 
                                  label="select or search route" 
                                  margin="normal" 
                                  InputLabelProps={{
                                    style: {
                                      fontSize: 15,
                                      paddingTop: '-20px',
                                      paddingBottom: 20
                                    }
                                  }}
                              />
                            }
                            />
                          </div>
                        </div>

                        <div className="clearfix" />

                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Assign Route" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {this.state.endTrip && 
          <div className="col-md-6 tripModule">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.startTripForRoute(e)}>
                      <div className="row single-route">
                        <h4 className="trip_label">
                          All Active Trips For This Driver
                        </h4>
                        {this.state.activeTrips.length &&
                          this.state.activeTrips.map((info, key) => (
                          <div className="routeList_" >
                            <div className="direction" key={key}>
                              <div key={key}>
                                <span className="indicator from" key={key}/>{" "}
                                {info.route.pickup}
                              </div>
                              <div key={key}>
                                <span className="indicator to" key={key}/>{" "}
                                {info.route.destination}
                              </div>
                            </div>
                            <button
                             className="endActionBtn"
                             key={key}
                             onClick={(e) => this.endDriverTrip(e)}
                             value={info.id}
                            >
                              End Trip
                            </button>
                          </div>
                          ))
                        }
                        {
                        !this.state.activeTrips.length && 
                          <div>
                            There are no active trips for this driver  
                          </div>
                       }
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {this.state.startTrip &&
          <div className="col-md-5">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.startTripForRoute(e)}>
                      <div className="row">
                        <div className="form-sub-header">
                          <h4>Start Trip For Route</h4>
                          <span>Choose a route from the driver's routes to start the trip</span>
                        </div>

                        <div className="form-element col-md-12">
                          <span className="label">Routes</span>
                          <div className="form-select long">
                            <select ref={(input) => { this.selectedRoute = input; }} id="">
                              {this.state.driverRoutes.map(route => (
                                <option value={route.id} key={route.id}>
                                  {route.pickup} - {' '} {route.destination}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="clearfix" />

                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Start Trip" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {this.state.unassignRoute &&
          <div className="col-md-5">
            <div className="module">
              <div className="module-header" />
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.unassignRoute(e)}>
                      <div className="row">
                        <div className="form-sub-header">
                          <h4>Unassign Route</h4>
                          <span>Unassign this drivers routes</span>
                        </div>

                        <div className="form-element col-md-12">
                          <span className="label">Routes</span>
                          <div className="form-select long">
                            <select ref={(input) => { this.selectedRoute = input; }} id="">
                              {this.state.driverRoutes.map(route => (
                                <option value={route.id} key={route.id}>
                                  {route.pickup} - {' '} {route.destination}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="clearfix" />

                      </div>
                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Unassign Route" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {this.state.editDriver &&
          <div className="col-md-8">
            <div className="module">
              <div className="module-header">
                <h3>Edit Driver</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.editDriver(e)}>
                      <div className="row">
                        <div className="form-element col-sm-6">
                          <span className="label">First Name</span>
                          <input
                            ref={(input) => { this.fname = input; }}
                            type="text"
                            required
                            defaultValue={userDetails.fname}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-sm-6">
                          <span className="label">Last Name</span>
                          <input
                            ref={(input) => { this.lname = input; }}
                            type="text"
                            required
                            defaultValue={userDetails.lname}
                            className="form-text"
                          />
                        </div>
                      </div>
                      <div className="form-element">
                        <span className="label">E-mail Address</span>
                        <input
                          ref={(input) => { this.email = input; }}
                          type="email"
                          required
                          defaultValue={userDetails.email}
                          className="form-text"
                        />
                      </div>

                      <div className="form-element phone">
                        <span className="label">Phone Number</span>
                        <input
                          ref={(input) => { this.phone = input; }}
                          type="number"
                          required
                          defaultValue={userDetails.phone}
                          className="form-text"
                        />
                      </div>

                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Update Driver" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {this.state.changePassword &&
          <div className="col-md-8">
            <div className="module">
              <div className="module-header">
                <h3>Change Driver Password</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.changePassword(e)}>
                      <div className="row">
                        <div className="form-element col-sm-6">
                          <span className="label">New Password</span>
                          <input
                            ref={(input) => { this.password = input; }}
                            type="password"
                            required
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-sm-6">
                          <span className="label">Password Confirmation</span>
                          <input
                            ref={(input) => { this.password2 = input; }}
                            type="password"
                            required
                            className="form-text"
                          />
                        </div>
                      </div>

                      <div className="form-element">
                        <input type="submit" className="bvtton" value="Change Password" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        }

        {!this.state.assignVehicle && !this.state.assignRoute &&
          !this.state.editDriver && !this.state.changePassword &&
          <div className="col-md-8">
            <div className="col-md-12">
              <div className="chart-wrapper no-margin-top">
                <h3>Rides by Driver</h3>
                <div className="chart linechart user-transit-rides" />
              </div>
            </div>

            <div className="col-sm-6 hide">
              <div className="chart-wrapper no-margin-top">
                <h3>Transit Time Distribution (Morning, Evening)</h3>
                <div className="chart piechart user-transit-time" />
              </div>
            </div>
            <div className="col-sm-6 hide">
              <div className="module admin-focus">
                <div className="focus-number">
                  <span>Passengers Transported</span>
                </div>
              </div>
            </div>

            <div className="clearfix" />

          </div>
        }
        <div className="col-sm-12">
          <div className="module">
            <div className="module-header">
              <h3>Ride History</h3>
            </div>
            {userDetails &&
              <DriverTrips driver={userDetails} />
            }
          </div>
        </div>
      </div>
    );
  }
}


DriverDetails.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

DriverDetails.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

DriverDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
