
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';

import RoutesMap from '../../components/routeMap';
import EditDestination from '../../components/routes/editDestination';
import {deleteRouteDestination, } from '../../models/routes';

import '../../styles/spinner.css';

export default class DestinationDetails extends Component {
  constructor() {
    super();
    this.state = {
      route: null,
      pickup: null,
      destination: null,
      loadingData: false,
      loadingSuccessFull: true,
      showEditDestination: false,
    };
  }

  deleteDestination(e) {
    e.preventDefault();
    deleteRouteDestination(this.state.route.id, this.state.pickup.id, this.state.destination.id)
      .then(() => {
        return window.alert("Successfully deleted route pickup destination");
      })
      .catch((error) => {
        console.log("an error occured while deleting route pick_up destination : ", error)
      })
  }

  componentWillMount() {
    const route = this.state.route ||
      this.props.route || (this.props.location.state && this.props.location.state.route) || null;
    const pickup = this.state.pickup ||
      this.props.pickup || (this.props.location.state && this.props.location.state.pickup) || null;
    const destination = this.state.destination ||
      this.props.destination || (this.props.location.state && this.props.location.state.destination) || null;

    this.setState({ route, pickup, destination });
  }

  onUpdateDestination(destination) {
    if (destination.id === this.state.destination.id) {
      this.setState({ destination, showEditDestination: false });
    }
  }

  render() {
    const user = this.props.user;
    const { destination, pickup, route } = this.state;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/trips/routes',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    if (!(route && pickup && destination)) {
      return (
        <Redirect to={{
          pathname: '/trips/routes',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{this.state.showEditDestination ? 'Edit Destination' : 'Destination Details'}</h2>
          <div className="right-options" />
          <div className="page-usability-back col-sm-12 row">
            <button className="bvtton small grey"
              onClick={() => this.context.router.history.goBack()}
            >
              Go Back
            </button>
          </div>
        </div>

        <div className="col-md-6">
          <div className="admin-user-summary">
            <div className="form-sub-header">
              <h4>Destination Details</h4>
            </div>
            <div className="user-data flex flex-row">
              <div className="">
                Location: {destination.location}
                <span className="route-description">
                  Description: {destination.location_description}
                </span>
                <span className="route-description">Fare: &#x20A6;{destination.fare}</span>
              </div>
            </div>
          </div>

          <div className="admin-account-actions">
            <div className="_header">
              <h3>Destination Actions</h3>
            </div>
            <ul>
              <li>
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showEditDestination: true,
                    });
                  }}
                >Edit Destination
                <i className="fa fa-angle-right" />
                </Link>
              </li>
              <li>
                <Link
                  to=""
                  onClick={(e) => {
                    if (window.confirm("Do you really want to delete this destination?")) { 
                      return this.deleteDestination(e)
                    }
                    else {
                      e.preventDefault()
                    }
                  }}
                >Delete Destination
                <i className="fa fa-angle-right" />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-md-5 col-md-offset-1">
          {this.state.showEditDestination &&
            <EditDestination
              route={route}
              pickup={pickup}
              destination={destination}
              onUpdate={data => this.onUpdateDestination(data)}
              setDashboardMessage={this.props.setDashboardMessage}
            />
          }
          {!this.state.showEditDestination &&
            <div className="map-image" style={{ border: 'none' }}>
              <RoutesMap
                polyline={route.overview_polyline.points}
                pickup_coordinate={route.pickup_coordinate}
                destination_coordinate={route.destination_coordinate}
                size="413x340"
              />
            </div>
          }
        </div>
      </div>
    );
  }
}


DestinationDetails.propTypes = {
  user: PropTypes.object,
  route: PropTypes.object,
  pickup: PropTypes.object,
  destination: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

DestinationDetails.defaultProps = {
  route: null,
  user: null,
  pickup: null,
  destination: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

DestinationDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
