import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect, NavLink } from "react-router-dom";

import RideHistory from "../../components/rideHistory";
import TopUpWallet from "../../components/topUpWallet";
import DebitWallet from "../../components/debitWallet";
import DatePicker from '../../components/datepicker'
import TransactionHistory from "../../components/transactionHistory";
import { getAllBusinesses } from "../../models/business";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import {
  getUserTrips,
  getUserWallet,
  updateUser,
  userTripsGraph,
  updateUserProfilePicture,
  getUser
} from "../../models/users";
import {
  getAllRoutes,
  getPickUpPoints,
  getRouteDestination
} from "../../models/routes";
import { formatMoney, initBarChart, scrollToEl } from "../../libs/utils";
import { post } from "../../libs/requestClient";

import "../../styles/spinner.css";
import moment from "moment";
import "./new.css";
export default class UserDetails extends Component {
  constructor() {
    super();
    this.state = {
      creditBalance: null,
      walletAmount: null,
      trips: null,
      loading: true,
      wallet: null,
      route: null,
      routes: [],
      pickupPoints: [],
      destinations: [],
      profilePictureSelected: false,
      profilePictureUploading: false,
      allBusinesses: [],
      fundWallet: false,
      debitWallet: false,
      editUser: false,
      changePassword: false,
      setRoute: false,
      changeProfilePicture: false,
      profileUri: null,
      viewTransactions: true,
      userDetails: null,
      pickupId: null,
      destinationId: null,
      itineraryId: null,
      fare: 0,
      selectedDate: null,
      dateChanged: false,
      dateError: false,
      companySelected: false,
      company: null,
      schedule: {
        monday: 0,
        tuesday: 0,
        wednesday: 0,
        thursday: 0,
        friday: 0,
        saturday: 0,
        sunday: 0
      },
      isSubscription: false,
      dayWithIds: [{
        sunday: 1,
        monday: 2,
        tuesday: 3,
        wednesday: 4,
        thursday: 5,
        friday: 6,
        saturday: 7
      }],
      chosenDays: [],
      start_date: null,
      trip_weeks: 0,
      recurring: 0,
      hasLuggage: false,
      luggageQuantity: 0,
      routeLabel: null,
      selectedRoute: 'Select or search for routes',
      value: null,
      paymentSource: null
    };

    this.handleRouteChange = this.handleRouteChange.bind(this);
    this.handlePickupChange = this.handlePickupChange.bind(this);
    this.handleDestinationChange = this.handleDestinationChange.bind(this);
    this.handleScheduleChange = this.handleScheduleChange.bind(this);
    this.addRoute = this.addRoute.bind(this);
  }
  componentDidMount() {
    const userDetails = this.props.location.state
      ? this.props.location.state.userDetails
      : null;
    if (!userDetails) return;
    const userWallet = getUserWallet(userDetails.id);
    const tripsGraph = userTripsGraph(userDetails.id);
    const userTrips = getUserTrips(userDetails.id);
    this.setState({
      creditBalance: userDetails.wallet.credit_amount,
      walletAmount: userDetails.wallet.amount
    })

    Promise.resolve(userWallet)
      .then(data => {
        this.setState({
          wallet: data.wallet
        });
      })
      .catch(err => {
        console.error(
          "[User Details] Error fetching user wallet for user",
          userDetails.id,
          err
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
          userDetails
        });
      });

    Promise.resolve(tripsGraph)
      .then(data => {
        this.plotGraph(data);
      })
      .catch(err => {
        console.error(
          "[User Details] Error fetching user trips graph for user",
          userDetails.id,
          err
        );
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });

    Promise.resolve(userTrips)
      .then(trips => {
        this.setState({
          trips
        });
      })
      .catch(err => {
        console.error(
          "[User Details] Error fetching user trips for user",
          userDetails.id,
          err
        );
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
    getAllBusinesses()
      .then(resp => {
        this.setState({ ...this.state, allBusinesses: resp.data });
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Error occurred while trying to fetch companies"
        );
      });
    if (userDetails.dob && userDetails.dob !== "") {
      this.setState({
        ...this.state,
        selectedDate: moment(userDetails.dob).format("DD/MM/YYYY")
      });
    }

    getUser(userDetails.id).then(
      resp => {
        this.setState({ ...this.state, userDetails: resp })
      }
    )
  }

  //   get the users data
  reload() {
    const userDetails = this.state.userDetails
    if (userDetails) {
      getUser(userDetails.id).then(
        resp => {
          this.setState({ ...this.state, userDetails: resp })
        }
      )
    }
  }
  plotGraph(data) {
    const userData = [];
    data.forEach(datum => {
      userData.push([datum.month, datum.total]);
    });

    const Users30Day = [
      {
        data: userData,
        label: "Users"
      }
    ];

    initBarChart(".linechart.user-transit-rides", Users30Day);
  }

  handleSubsripitionToggle() {
    this.setState({
      isSubscription: !this.state.isSubscription
    })
  }

  /**
   * Reset the UI elements, show and scroll to the transactions component
   */
  showTransactions() {
    this.setState(
      {
        fundWallet: false,
        viewTransactions: true,
        debitWallet: false,
        changePassword: false,
        editUser: false,
        setRoute: false
      },
      () => {
        scrollToEl("view-transactions");
      }
    );
  }

  /**
   * Reset the UI elements, show and scroll to the fund wallet component
   */
  showFundWallet() {
    this.setState(
      {
        fundWallet: true,
        viewTransactions: false,
        debitWallet: false,
        changePassword: false,
        editUser: false,
        setRoute: false
      },
      () => {
        scrollToEl("top-up-wallet");
      }
    );
  }

  /**
   * Reset the UI elements, show and scroll to the debit wallet component
   */
  showDebitWallet() {
    this.setState(
      {
        fundWallet: false,
        viewTransactions: false,
        debitWallet: true,
        changePassword: false,
        editUser: false,
        setRoute: false
      },
      () => {
        scrollToEl("debit-wallet");
      }
    );
  }

  /**
   * Reset the UI elements, show and scroll to the edit user component
   */
  showEditUser() {
    this.setState(
      {
        fundWallet: false,
        viewTransactions: false,
        debitWallet: false,
        editUser: true,
        changePassword: false,
        setRoute: false
      },
      () => {
        scrollToEl("edit-user");
      }
    );
  }

  /**
   * Reset the UI elements, show and scroll to the change password component
   */
  showChangePassword() {
    this.setState(
      {
        fundWallet: false,
        viewTransactions: false,
        debitWallet: false,
        editUser: false,
        changePassword: true,
        setRoute: false
      },
      () => {
        scrollToEl("change-password");
      }
    );
  }

  /**
   * Reset the UI elements, show and scroll to the set user route component
   */
  showSetRoute() {
    this.setState(
      {
        fundWallet: false,
        viewTransactions: false,
        debitWallet: false,
        changePassword: false,
        editUser: false,
        setRoute: true
      },
      () => {
        getAllRoutes()
          .then(routes => this.setState({ routes }))
          .catch(() => { });
        scrollToEl("set-route");
      }
    );
  }

  /**
   * Deactivates a user
   */
  suspendUser() {
    const user = this.props.location.state.userDetails;
    const data = { active: 0 };

    this.setState({
      loading: true
    });
    updateUser(user.id, data)
      .then(userDetails => {
        this.setState({
          userDetails
        });
        this.props.setDashboardMessage("success", "User account suspended");
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Sorry an error occurred while suspending user"
        );
        console.error(
          "[User Details] Error suspending user ",
          user.id,
          e.stack
        );
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  /**
   * Activates a user
   */
  unSuspendUser() {
    const user = this.props.location.state.userDetails;
    const data = { active: 1 };

    this.setState({
      loading: true
    });
    updateUser(user.id, data)
      .then(userDetails => {
        this.setState({
          userDetails
        });
        this.props.setDashboardMessage("success", "User account unsuspended");
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Sorry an error occurred while unsuspending user"
        );
        console.error(
          "[User Details] Error unsuspending user ",
          user.id,
          e.stack
        );
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  /**
   * Updates a user's details
   * @param {Event} evt - the event that triggers the update
   */
  editUser(evt) {
    evt.preventDefault();
    const user = this.state.userDetails;
    if (
      !this.fname.value ||
      !this.lname.value ||
      !this.email.value ||
      !this.phone.value ||
      this.form.gender.value == "" ||
      // !this.state.selectedDate ||
      (this.state.companySelected && !this.state.company)
    ) {
      this.props.setDashboardMessage("error", "Some fields are missing");
      return;
    }

    let data = {
      fname: this.fname.value,
      lname: this.lname.value,
      email: this.email.value,
      phone: this.phone.value,
      gender: this.form.gender.value,
      corporate_id: null,
    };

    if (
      this.state.selectedDate &&
      !this.state.dateError &&
      this.state.dateChanged
    ) {
      data = Object.assign({}, data, {
        dob: moment(this.state.selectedDate, "DD/MM/YYYY").toISOString()
      });
    }
    if (this.state.companySelected && this.state.company !== "none") {
      data = Object.assign({}, data, {
        corporate_id: this.state.company
      });
    } else {
      data = Object.assign({}, data, {
        corporate_id: null
      });
    }

    this.setState({
      loading: true
    });

    console.log('Data being submitted', data);

    updateUser(user.id, data)
      .then(userDetails => {
        this.setState({
          userDetails
        });
        this.showTransactions();
        this.props.setDashboardMessage("success", "User account updated");
        this.reload();
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Sorry an error occurred while updating user details"
        );
        console.error("[User Details] Error updating user", user.id, e.stack);
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  /**
   * Updates a user's password
   * @param {Event} evt - the event that triggers the update
   */
  changePassword(evt) {
    evt.preventDefault();
    const user = this.state.userDetails;
    const password = this.password.value;
    const password2 = this.password2.value;

    if ((password && !password2) || (!password && password2)) {
      return this.props.setDashboardMessage("error", "Some fields are missing");
    } else if (password && password2 && password.length < 4) {
      return this.props.setDashboardMessage(
        "error",
        "Password length should be at least 4 characters long"
      );
    } else if (password && password !== password2) {
      return this.props.setDashboardMessage(
        "error",
        "Password confirmation does not match"
      );
    }

    const data = { password };

    this.setState({
      loading: true
    });
    updateUser(user.id, data)
      .then(userDetails => {
        this.setState({
          userDetails
        });
        this.showTransactions();
        this.props.setDashboardMessage("success", "User account updated");
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Sorry an error occurred while changing user password"
        );
        console.error(
          "[User Details] Error changing user password",
          user.id,
          e.stack
        );
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  /**
   * Fetches all pickups for the selected route.
   */
  fetchPickups() {
    const route = this.state.route || null;
    if (route) {
      getPickUpPoints(route.id, true)
        .then(data => {
          this.setState({
            pickupPoints: data.pickups
          });
        })
        .catch(err => {
          console.warn("Error fetching route's pickup points", err);
        });
    }
  }

  /**
   * Fetches all destinations for the selected pickup.
   */
  fetchDestinations() {
    const route = this.state.route || null;
    const pickupId = this.state.pickupId;
    if (route && pickupId) {
      getRouteDestination(route.id, pickupId, true)
        .then(data => {
          this.setState({
            destinations: data.destinations,
            itineraryId: data.itineraries[0].id
          });
        })
        .catch(err => {
          console.warn("Error fetching pickup's destinations", err);
        });
    }
  }

  /**
   * Resets the pickups and destinations based on selected route.
   * @param {Event} event
   */
  handleRouteChange(newValue) {
    const routeId = newValue.id;
    const routeData = this.state.routes.find(
      route => parseInt(route.id, 10) === parseInt(routeId, 10)
    );

    if (routeId && routeData.id) {
      this.setState({ route: routeData, destinations: [] }, () => {
        this.fetchPickups();
      });
    } else {
      this.setState({
        route: null,
        routeId: null,
        pickupPoints: [],
        pickupId: null,
        destinations: [],
        destinationId: null,
        itineraryId: null,
        fare: 0
      });
    }
  }

  /**
   * Resets the destinations based on selected pickup.
   * @param {Event} event
   */
  handlePickupChange(event) {
    const pickupId = event.target.value;
    this.setState({ pickupId }, () => {
      if (pickupId) {
        this.fetchDestinations();
      } else {
        this.setState({
          pickupId: null,
          destinations: [],
          destinationId: null,
          itineraryId: null,
          fare: 0
        });
      }
    });
  }

  /**
   * Resets the fare, and itinerary based on selected destination.
   * @param {Event} event
   */
  handleDestinationChange(event) {
    const destinationId = event.target.value;
    this.setState({ destinationId });
    const destinationData = this.state.destinations.find(
      destination =>
        parseInt(destination.id, 10) === parseInt(destinationId, 10)
    );
    if (destinationId && destinationData.id) {
      this.setState({ fare: destinationData.fare });
    } else {
      this.setState({
        destinationId: null,
        itineraryId: null,
        fare: 0
      });
    }
  }

  getDayID(day) {
    return this.state.dayWithIds.map((x) => {
      if(x[day]) {
        return x[day]
      }
      else {
        console.log(false)
      }
    })[0]
  }

  computeEndDate() {
    if (this.state.isSubscription && this.state.trip_weeks) {
      let _date = moment(this.state.start_date)
        .add(this.state.trip_weeks, "weeks")
        return _date.subtract(1, "days").format("YYYY-MM-DD");
    }
    return this.state.start_date;
  }


  handleWeekRadio(e) {
    const weeks = e.target.value;
    const schedule = this.state.schedule;
    let dayIds = [];

    for (const x in schedule) {
      if(schedule[x]) {
        let day = this.getDayID(x);
        if(day) {
          dayIds.push(day)
        }
      }
    }

    const destinationData = this.state.destinations.find(
      destination =>
        parseInt(destination.id, 10) === parseInt(this.state.destinationId, 10)
    );
    let fare = destinationData.fare;
    let cost = parseInt(dayIds.length * weeks) * fare;

    this.setState({
      trip_weeks: e.target.value,
      fare: cost
    })
  }

  withLuggageToggle(e) {
    return this.setState({
      hasLuggage: !this.state.hasLuggage
    })
  }


  setLuggageQuantity(e) {
    return this.setState({
      luggageQuantity: e.target.value
    })
  }

  /**
   * Sets the selected schedule.
   * @param {Event} event
   */
  handleScheduleChange(event) {
    const target = event.target;
    const value = target.checked ? 1 : 0;
    const name = target.name;

    this.setState(prevState => {
      const schedule = prevState.schedule;
      schedule[name] = value;
      return { schedule };
    });
  }

  /**
   * Add the route for the selected user.
   */
  addRoute() {
    const { route, pickupId, destinationId, itineraryId } = this.state;
    const schedule = this.state.schedule;

    let dayIds = [];
    for (const x in schedule) {
      if(schedule[x]) {
        let day = this.getDayID(x);
        if(day) {
          dayIds.push(day)
        }
      }
    }

    if(!dayIds.length) {
      dayIds.push(
        moment(this.state.start_date).day()+1
      )
    }

    if (!route) {
      this.props.setDashboardMessage("warning", "Please select a route.");
      return;
    }

    if (!pickupId) {
      this.props.setDashboardMessage(
        "warning",
        "Please select a Pickup location from the list."
      );
      return;
    }

    if (!destinationId) {
      this.props.setDashboardMessage(
        "warning",
        "Please select a Destination location from the list."
      );
      return;
    }

    const postData = {
      route_id: route.id,
      itinerary_id: itineraryId,
      pickup_id: pickupId,
      destination_id: destinationId,
      days_ids: dayIds,
      meta: JSON.stringify(route),
      start_date: this.state.start_date,
      end_date: this.computeEndDate(),
      recurring: this.state.isSubscription ? 1 : 0,
    };

    if(this.state.hasLuggage) {
      postData.luggage_quantity = this.state.hasLuggage ? this.state.luggageQuantity : 0
    }

    if(this.state.paymentSource === "" || !this.state.paymentSource) {
      scrollToEl("set-route");
      this.props.setDashboardMessage(
        "warning",
        "Please select a payment source for booking."
      );
      return;
    }

    postData.payment_source = this.state.paymentSource;
    const pickupItineraryRequest = post(
      `/v1/users/${this.state.userDetails.id}/routes`,
      postData
    );

    Promise.resolve(pickupItineraryRequest)
      .then((res) => {
        this.props.setDashboardMessage("success", "Route set successfully");
        this.setState({
          setRoute: false,
          route: null,
          routes: [],
          routeId: null,
          pickupPoints: [],
          pickupId: null,
          destinations: [],
          destinationId: null,
          itineraryId: null,
          fare: 0,
          schedule: {}
        });
      })
      .catch(err => {
        if (err.error && (err.error.code === "UserAlreadyOnRoute" || err.error.code === "DUPLICATE_BOOKING")) {
          this.props.setDashboardMessage(
            "info",
            "This user is already on this route."
          );
        } else if (err.error && err.error.code === "WalletInsufficientFunds") {
          const errorMessage =
            "This user does not have enough money in wallet.";
          this.props.setDashboardMessage(
            "warning",
            errorMessage,
            err.error.code
          );
        } else if (err.error.code === "ItemAlreadyExist") {
          const errorMessage = "This user is already on this route.";
          this.props.setDashboardMessage("info", errorMessage, err.data.code);
        } else {
          this.props.setDashboardMessage(
            "error",
            "Sorry we could not set the route, please try after sometime."
          );
        }
      })
      .finally(() => {
        this.setState({ itinerary: null });
      });
  }

  /**
   * Renders the component for adding user routes.
   * @returns {*}
   */
  renderAddRoute() {
    const { route, routes, pickupPoints, destinations } = this.state;

    return (
      <div className="col-md-8" id={"set-route"}>
        <div className="module">
          <div className="module-header">
            <h3>Add route to this user.</h3>
          </div>
          <div className="module-wrapper">
            <div className="module-content no-padding">
              <div className="module-route-information">
                <div className="head">
                  <div className="row">
                    <div className="route-info">
                      <div>
                        <Autocomplete
                          options={routes}
                          getOptionLabel={(option) => `From ${option.pickup} to ${ option.destination}`}
                          value={this.state.value}
                          onChange={(event, newValue) => {
                            this.setState({value: newValue});
                            this.handleRouteChange(newValue);
                          }}
                          style={{
                            width: '95%',
                            margin: 'auto',
                            height: '50px',
                            marginBottom: 20,
                            fontSize: '25',
                          }}
                          renderInput={(params) => 
                            <TextField 
                              {...params} 
                              label="select or search route" 
                              margin="normal" 
                              InputLabelProps={{
                                style: {
                                  fontSize: 15,
                                  paddingTop: '-20px',
                                  paddingBottom: 20
                                }
                              }}
                          />
                        }
                        />
                      </div>
                      <div className="clearfix" />

                      {route && (
                        <div className="col-md-6">
                          <div className="issa-route confirmation-padding">
                            <span className="indicator indicator-v2 from">
                              From
                            </span>
                            {route.pickup}
                          </div>
                        </div>
                      )}

                      {route && (
                        <div className="col-md-6">
                          <div className="issa-route confirmation-padding">
                            <span className="indicator indicator-v2 to">
                              To
                            </span>
                            {route.destination}
                          </div>
                        </div>
                      )}

                      <div className="clearfix" />
                      <div className="form-element">
                        <div className="confirmation-element col-md-6">
                          <span className="label">Select Pickup Point</span>
                          <div className="form-select long">
                            <select
                              name="pickup_id"
                              onChange={this.handlePickupChange}
                            >
                              <option value="">Select Pickup point</option>
                              {pickupPoints.map(pickup => (
                                <option value={pickup.id} key={pickup.id}>
                                  {pickup.location}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="confirmation-element col-md-6">
                          <span className="label">Select Drop off Point</span>
                          <div className="form-select long">
                            <select
                              name="destination_id"
                              onChange={this.handleDestinationChange}
                            >
                              <option value="">Select Destination</option>
                              {destinations.map(destination => (
                                <option
                                  value={destination.id}
                                  key={destination.id}
                                >
                                  {destination.location}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="confirmation-element form-check">
                        <div className="col-md-12">
                        <span className="label">Choose Date</span>
                        <div className="form-element date-container">
                          <DatePicker
                            type="date"
                            onUpdate={(data) => { this.state.start_date = data; }}
                            placeholder="Start Date"
                          />
                        </div>
                          <div className="daysSection">
                            <span className="label">Is Subscription?</span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="is_subscription"
                              onChange={(e) => this.handleSubsripitionToggle(e)}
                            />
                          </div>
                          {
                            this.state.isSubscription &&
                            <div>
                              <span className="label">Select Pickup Days</span>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="sunday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="sunday"
                                    value="1"
                                    defaultChecked={this.state.schedule.sunday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Sunday
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="monday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="monday"
                                    value="2"
                                    defaultChecked={this.state.schedule.monday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Monday
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="tuesday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="tuesday"
                                    value="3"
                                    defaultChecked={this.state.schedule.tuesday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Tuesday
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="wednesday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="4"
                                    name="wednesday"
                                    defaultChecked={this.state.schedule.wednesday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Wednesday
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="thursday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="thursday"
                                    value="5"
                                    defaultChecked={this.state.schedule.thursday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Thursday
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="friday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="friday"
                                    value="6"
                                    defaultChecked={this.state.schedule.friday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Friday
                                </label>
                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor="saturday"
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="saturday"
                                    value="7"
                                    defaultChecked={this.state.schedule.saturday}
                                    onClick={this.handleScheduleChange}
                                  />
                                  &nbsp;Saturday
                                </label>
                              </div>
                              <div className="trip-weeks">
                                <span className="label">Select Trip Weeks</span>
                                <div className="form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="weeks"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="weeks"
                                      onClick={(e) => this.handleWeekRadio(e)}
                                      value="1"
                                    />
                                    &nbsp;1 week
                                  </label>
                                </div>
                                <div className="form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="weeks"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="weeks"
                                      onClick={(e) => this.handleWeekRadio(e)}
                                      value="2"
                                    />
                                    &nbsp;2 weeks
                                  </label>
                                </div>
                                <div className="form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="weeks"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="weeks"
                                      onClick={(e) => this.handleWeekRadio(e)}
                                      value="3"
                                    />
                                    &nbsp;3 weeks
                                  </label>
                                </div>
                                <div className="form-check">
                                  <label
                                    className="form-check-label"
                                    htmlFor="weeks"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="weeks"
                                      value="4"
                                      onClick={(e) => this.handleWeekRadio(e)}
                                    />
                                    &nbsp;4 weeks
                                  </label>
                                </div>
                              </div>
                            </div>
                          }
                          <div className="daysSection">
                            <span className="label">With Luggage?</span>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="with_luggage"
                              onChange={(e) => this.withLuggageToggle(e)}
                              // defaultChecked={this.state.schedule.sunday}
                              // onClick={this.handleScheduleChange}
                            />
                          </div>
                          <div className="paymentSource">
                            <span className="label">Select Payment Source</span>
                              <select
                                name="payment_source"
                                onChange={(e) => {
                                  this.setState({
                                    paymentSource: e.target.value
                                  })
                                }}
                                className="selectSource"
                              >
                                <option value="">Select Payment Source</option>
                                <option value="credit_balance">Company wallet</option>
                                <option value="main_balance">Personal wallet</option>
                              </select>
                          </div>
                          <div className="daysSection sidL">
                            {
                              this.state.hasLuggage && 
                              <div className="daysSection">
                                <label className="luggageLabel">Luggage Quantity</label>
                                <input
                                  type="number"
                                  min="1"
                                  max="10"
                                  className="luggageInput"
                                  onChange={(e) => this.setLuggageQuantity(e)}
                                />
                              </div>
                            }
                          </div>
                        </div>
                      </div>

                      {this.state.destinationId && (
                        <div className="confirmation-element price col-md-12">
                          <span className="label">Price</span>
                          &#x20A6;
                          <span>{this.state.fare}</span>
                        </div>
                      )}

                      {route && (
                        <div className="confirmation-element col-md-6">
                          <span className="label">Duration</span>
                          {route.duration}
                        </div>
                      )}
                      <div className="clearfix" />

                      <div className="ride-action">
                        <span>
                          <button onClick={this.addRoute} className="bvtton">
                            Confirm and Book Ride
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="module-footer" />
          </div>
        </div>
      </div>
    );
  }
  onDateChange(e) {
    let date = new RegExp(
      "^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.]((19|20)\\d\\d)$"
    );

    if (date.test(e.target.value)) {
      this.setState({
        ...this.state,
        selectedDate: e.target.value,
        dateChanged: true,
        dateError: false
      });
    } else {
      this.setState({
        ...this.state,
        selectedDate: null,
        dateError: true
      });
    }
  }

  profilePicture() {
    this.setState({
      ...this.state,
      changeProfilePicture: true
    });
  }
  handleUploadChange(event) {
    const fileReader = new FileReader();
    fileReader.onload = e => {
      this.thumbnail.src = e.target.result;
      this.thumbnail.style.display = "block";
      this.setState({ ...this.state, profileUri: e.target.result });
    };
    fileReader.onerror = e => { };
    fileReader.readAsDataURL(event.target.files[0]);
    this.setState({ ...this.state, profilePictureSelected: true });
  }

  uploadImage() {
    const user = this.state.userDetails;
    this.setState({ ...this.state, profilePictureUploading: true });
    updateUserProfilePicture(user.id, this.state.profileUri)
      .then(resp => {
        this.props.setDashboardMessage("success", "Profile Picture Updated");
        this.reload()
        this.setState({
          ...this.state,
          changeProfilePicture: false,
          profilePicture: false,
          profileUri: null,
          profilePictureUploading: false
        });
      })
      .catch(e => {
        this.props.setDashboardMessage(
          "error",
          "Sorry an error occurred while updating the profile picture"
        );
        this.setState({ ...this.state, profilePictureUploading: false });
      });
  }
  profilePictureComponent() {
    return (
      <div className="col-md-8" id={"edit-user"}>
        <div className="module">
          <div className="module-header">
            <h3>Update Profile </h3>
            <div className="module-wrapper">
              <div className="module-content module-form-wrapper">
                <form>
                  <div className="form-element">
                    <label
                      style={{
                        width: "100%",
                        border: "2px dashed rgba(0 , 0 , 0 , .1)",
                        textAlign: "center",
                        padding: "30px 30px",
                        borderRadius: "5px",
                        fontSize: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <img
                        ref={el => (this.thumbnail = el)}
                        style={{
                          display: "none",
                          maxHeight: "300px",
                          maxWidth: "300px",
                          height: "auto",
                          width: "auto"
                        }}
                      />
                      {this.state.profileUri == null && "Click To Select Image"}
                      <input
                        type="file"
                        onChange={this.handleUploadChange.bind(this)}
                        accept="image/*"
                        ref={el => (this.upload = el)}
                        style={{ display: "none" }}
                      />
                    </label>
                    <input
                      onClick={this.uploadImage.bind(this)}
                      disabled={
                        !this.state.profilePictureSelected ||
                        this.state.profilePictureUploading
                      }
                      type="button"
                      className="bvtton"
                      value={
                        this.state.profilePictureUploading
                          ? "Uploading"
                          : "Upload"
                      }
                    />
                  </div>
                  <div className="form-element" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  handleSelectChange(e) {
    try {
      if (e.target.value !== "none") {
        let company = this.state.allBusinesses.find(
          each => each.corporate_name == e.target.value
        ).id;
        this.setState({
          ...this.state,
          companySelected: true,
          company: company
        });
      } else {
        this.setState({
          ...this.state,
          companySelected: false,
          company: null
        });
      }
    } catch (ex) {
      return 'N/A'
    }

  }
  resolveCompany(id) {
    console.log(id);
    try {
      return this.state.allBusinesses.find(each => each.id === id).corporate_name
    } catch (ex) {
      return 'N/A';
    }
  }
  render() {
    const user = this.props.user;
    const userDetails =
      this.state.userDetails ||
      (this.props.location.state
        ? this.props.location.state.userDetails
        : null);
    // if we have a user session we redirect away from sign up

    if (!user || !user.id) {
      return (
        <Redirect
          to={{
            push: true,
            rtURL: "/users/details",
            rtProps: this.props.location.state,
            pathname: "/login"
          }}
        />
      );
    }

    if (!userDetails) {
      return (
        <Redirect
          to={{
            pathname: "/users"
          }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>{`${userDetails.fname}'s Details`}</h2>
        </div>
        <div className="page-usability-back col-sm-12">
          <button
            className="bvtton small grey"
            onClick={() => this.context.router.history.goBack()}
          >
            Go Back
          </button>
        </div>
        <div className="clearfix" />

        <div className="col-md-12">
          <div className="chart-wrapper">
            <h3>Rides by User</h3>
            <div className="chart linechart user-transit-rides" />
          </div>
        </div>
        <div className="clearfix" />

        <div className="col-md-4">
          <div
            className={`admin-user-summary ${this.state.loading && "loading"}`}
          >
            {this.state.loading && <div className="spinner" />}
            <div className="user-picture">
              {/* <img src={userDetails.avatar} className="image css-img-object" alt=""/> */}
              <div
                className="image css-img-object"
                style={{
                  backgroundImage: `url(
                                        ${
                    userDetails.avatar
                      ? userDetails.avatar
                      : "/assets/img/default.png"
                    })`
                }}
              />
            </div>
            <h3>
              {userDetails.fname} {userDetails.lname}
            </h3>
            <div className="user-data flex flex-row">
              <div>
                {userDetails.phone}
                <span>Phone Number</span>
              </div>
              <div>
                {userDetails.email}
                <span>E-mail Address</span>
              </div>
              <div>
                {userDetails.dob ? moment(userDetails.dob).format("DD/MM/YYYY") : 'N/A'}
                <span>D.O.B</span>
              </div>
              <div>
                {userDetails.corporate_id ? this.resolveCompany(userDetails.corporate_id) : 'N/A'}
                <span>Company</span>
              </div>
              <div>
                {userDetails.car_owner === 1 ? "YES" : "NO"}
                <span>Car Owner</span>
              </div>
              <div>
                {userDetails.gender ? userDetails.gender : "N/A"}
                <span>Gender</span>
              </div>

            </div>
            <div className="bi-stat">
              <div className="user-rides-count">
                {this.state.trips ? `${this.state.trips.length}` : "0"}
                <span>rides completed</span>
              </div>
              <div className="user-account-balance">
                {this.state.wallet ? (
                  <div>
                    &#x20A6;
                    {formatMoney(this.state.walletAmount)}
                  </div>
                ) : (
                    "Unavaliable"
                  )}
                <span>account balance</span>
              </div>
            </div>
            {
              userDetails.corporate_id && 
              <div className="bi-stat">
                <div className="user-account-balance">
                  {this.state.wallet ? (
                    <div>
                      &#x20A6;
                      {formatMoney(this.state.creditBalance)}
                    </div>
                  ) : (
                      "Unavaliable"
                    )}
                  <span>company balance</span>
                </div>
              </div>
            }
          </div>
          <div className="admin-account-actions">
            <div className="_header">
              <h3>Account Actions</h3>
            </div>
            <ul>
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showTransactions();
                  }}
                >
                  User Transactions <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showFundWallet();
                  }}
                >
                  Top-up User Wallet <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showDebitWallet();
                  }}
                  style={{
                    color: "red",
                    fontWeight: "bold"
                  }}
                >
                  Debit User Wallet <i className="fa fa-angle-right" />
                </a>
              </li>
              {userDetails.active === "0" ? (
                <li>
                  <a
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      this.unSuspendUser();
                    }}
                  >
                    Unsuspend User <i className="fa fa-angle-right" />
                  </a>
                </li>
              ) : (
                  <li>
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        this.suspendUser();
                      }}
                    >
                      Suspend User <i className="fa fa-angle-right" />
                    </a>
                  </li>
                )}
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showEditUser();
                  }}
                >
                  Edit User <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showSetRoute();
                  }}
                >
                  Add User Route <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.showChangePassword();
                  }}
                >
                  Change Password <i className="fa fa-angle-right" />
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.profilePicture();
                  }}
                >
                  Add Profile Picture <i className="fa fa-angle-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        {this.state.wallet && this.state.fundWallet && (
          <div className="col-md-8" id={"top-up-wallet"}>
            <TopUpWallet
              wallet={this.state.wallet.id}
              onWalletFunded={() => this.showTransactions()}
              setDashboardMessage={this.props.setDashboardMessage}
              isCorporate={userDetails.corporate_id}
              user={user}
            />
          </div>
        )}

        {this.state.wallet && this.state.debitWallet && (
          <div className="col-md-8" id={"debit-wallet"}>
            <DebitWallet
              wallet={this.state.wallet.id}
              onWalletDebited={() => this.showTransactions()}
              setDashboardMessage={this.props.setDashboardMessage}
              isCorporate={userDetails.corporate_id}
              user={user}
            />
          </div>
        )}

        {this.state.changeProfilePicture && this.profilePictureComponent()}

        {this.state.editUser && (
          <div className="col-md-8" id={"edit-user"}>
            <div className="module">
              <div className="module-header">
                <h3>Edit User</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form
                      onSubmit={e => this.editUser(e)}
                      ref={form => (this.form = form)}
                    >
                      <div className="row">
                        <div className="form-element col-sm-6">
                          <span className="label">First Name</span>
                          <input
                            ref={input => {
                              this.fname = input;
                            }}
                            type="text"
                            required
                            defaultValue={userDetails.fname}
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-sm-6">
                          <span className="label">Last Name</span>
                          <input
                            ref={input => {
                              this.lname = input;
                            }}
                            type="text"
                            required
                            defaultValue={userDetails.lname}
                            className="form-text"
                          />
                        </div>
                      </div>
                      <div className="form-element">
                        <span className="label">E-mail Address</span>
                        <input
                          ref={input => {
                            this.email = input;
                          }}
                          type="email"
                          required
                          defaultValue={userDetails.email}
                          className="form-text"
                        />
                      </div>

                      <div className="form-element phone">
                        <span className="label">Phone Number</span>
                        <input
                          ref={input => {
                            this.phone = input;
                          }}
                          type="number"
                          required
                          defaultValue={userDetails.phone}
                          className="form-text"
                        />
                      </div>
                      <div className="form-element">
                        <span className="label">
                          Company <small>(optional)</small>
                        </span>
                        <div className="form-select long">
                          <select defaultValue={this.state.allBusinesses && userDetails.corporate_id && this.resolveCompany(userDetails.corporate_id)} onChange={this.handleSelectChange.bind(this)}>
                            <option>none</option>
                            {this.state.allBusinesses &&
                              this.state.allBusinesses.map((each, i) => {
                                return (
                                  <option key={i}>{each.corporate_name}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-element">
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          defaultChecked={userDetails.gender == "male"}
                        />
                        <span>Male</span>
                        <input
                          style={{
                            marginLeft: "10px"
                          }}
                          type="radio"
                          name="gender"
                          defaultChecked={userDetails.gender == "female"}
                          value="female"
                        />
                        <span>Female</span>
                      </div>
                      <div className="form-element">
                        <span className="label">
                          {" "}
                          Date of Birth{" "}
                          <small
                            style={{
                              color: "red"
                            }}
                          >
                            (optional)
                          </small>
                          {this.state.dateError && (
                            <small
                              style={{
                                color: "red"
                              }}
                            >
                              {" "}
                              incorrect format
                            </small>
                          )}
                        </span>
                        <input
                          type="text"
                          defaultValue={
                            this.state.selectedDate
                              ? moment(userDetails.dob).format("DD/MM/YYYY")
                              : ""
                          }
                          placeholder="DD/MM/YYYY"
                          onChange={this.onDateChange.bind(this)}
                          className="form-text"
                        />
                      </div>
                      <div className="form-element">
                        <input
                          type="submit"
                          className="bvtton"
                          disabled={this.state.loading}
                          value={this.state.loading ? "Updating..." : "Update User"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        )}

        {this.state.setRoute && this.renderAddRoute()}

        {this.state.changePassword && (
          <div className="col-md-8" id={"change-password"}>
            <div className="module">
              <div className="module-header">
                <h3>Change User Password</h3>
              </div>
              <div className="module-wrapper">
                <div className="module-content module-form-wrapper">
                  <div className="form-wrapper">
                    <form onSubmit={e => this.changePassword(e)}>
                      <div className="row">
                        <div className="form-element col-sm-6">
                          <span className="label">New Password</span>
                          <input
                            ref={input => {
                              this.password = input;
                            }}
                            type="password"
                            required
                            className="form-text"
                          />
                        </div>
                        <div className="form-element col-sm-6">
                          <span className="label">Password Confirmation</span>
                          <input
                            ref={input => {
                              this.password2 = input;
                            }}
                            type="password"
                            required
                            className="form-text"
                          />
                        </div>
                      </div>

                      <div className="form-element">
                        <input
                          type="submit"
                          className="bvtton"
                          value="Change Password"
                        />
                      </div>
                    </form>
                  </div>
                </div>
                <div className="module-footer" />
              </div>
            </div>
          </div>
        )}

        {this.state.viewTransactions && (
          <div className="col-md-8" id={"view-transactions"}>
            <div className="clearfix" />

            <div className="module focus green">
              <div className="module-header">
                <h3>{`${userDetails.fname}'s`} Transactions</h3>
              </div>
              <TransactionHistory user={userDetails} />
            </div>
          </div>
        )}

        <div className="col-md-6 userRoutesLayout">
          <div className="clearfix" />
          <div className="module focus green">
            <div className="module-header">
            </div>
            <h3>{`${userDetails.fname}'s`} Bookings</h3>
            <div className="admin-account-actions">
              <ul>
                  <li>
                    <a>
                      <NavLink to={{pathname:`/users/user-bookings`, state: {data: {userId: userDetails.id, bookingType: 'Active'}}}}>
                        Active Booking <i className="fa fa-angle-right" />
                      </NavLink>
                    </a>
                  </li>
                  <li>
                    <a>
                      <NavLink to={{pathname:`/users/user-bookings`, state: {data: {userId: userDetails.id, bookingType: 'Past'}}}}>
                        Past Booking <i className="fa fa-angle-right" />
                      </NavLink>
                    </a>
                  </li>
              </ul>
            </div>
            {/* <UserRoutes user={userDetails} /> */}
          </div>
        </div>

        <div className="col-sm-12">
          <div className="module">
            <div className="module-header">
              <h3>Ride History</h3>
            </div>
            <RideHistory user={userDetails} />
          </div>
        </div>
      </div>
    );
  }
}

UserDetails.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  setDashboardMessage: PropTypes.func
};

UserDetails.defaultProps = {
  user: null,
  location: {
    state: {}
  },
  setDashboardMessage: () => { }
};

UserDetails.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired
    }).isRequired,
    staticContext: PropTypes.object
  }).isRequired
};
