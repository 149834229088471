import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import Datatable from '../../components/datatable'
import {getBusinessInterest, approveBusinessInterest } from '../../models/business'

import '../../styles/dashboard.css';

export default class CorporateInterest extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      success: false,
      error: false
    };
  }

  componentDidMount() {
    getBusinessInterest()
      .then(data => {
       this.setState({ data, success: true });
      })
      .catch(e => {
        console.error("[Business List] Error Corporate data", e);
        this.setState({ error: true });
      })
  }

  handleRowClick = (e, record) => {
    e.preventDefault();
    if(window.confirm("Are you sure you want to approve this business?")) {
            return approveBusinessInterest(record.id)
                .then(() => {
                    window.alert(`Successfully Approved Business ${record.corporate_name}`)
                    window.location.reload()
                })
                .catch((error) => {
                    console.error("[Business Approve] Error Corporate interest", error);
                    this.setState({ error: true });
                    window.location.reload()
                })
    }
    else {
        return;
    }
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: `/${this.props.type}/manage`,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    const { data } = this.state;

    const columns = [
      {
        key: "fname",
        text: "First Name",
        sortable: true,
      },
      {
        key: "lname",
        text: "Last Name",
        sortable: true,
      },
      {
        key: "corporate_address",
        text: "Company Address",
        sortable: true
      },
      {
        key: "email",
        text: "Email",
        sortable: true,
      },
      {
        key: "corporate_phone",
        text: "Phone",
        sortable: true,
      },
      {
        key: "corporate_name",
        text: "Company Name",
        sortable: true,
      },
      {
        key: "num_staff_members",
        text: "Members",
        sortable: true
      },
      {
        key: "desired_start_date",
        text: "Desired start date",
        sortable: true,
        cell: record => moment(record.created_at).format('DD-MM-YYYY')
      },
      {
        key: "resumption_time",
        text: "Resumption time",
        sortable: true,
        cell: record => moment(record.resumption_time, ["HH.mm"]).format("hh:mm a")
      },
      {
        key: "closing_time",
        text: "Closing time",
        sortable: true,
        cell: record => moment(record.closing_time, ["HH.mm"]).format("hh:mm a")
      },
      {
        key: "package_type",
        text: "Pakage Type",
        sortable: true
      },
      {
        key: "status",
        text: "Status",
        sortable: true,
      },
      {
        key: 'action',
        text: 'Action',
        width: 100,
        align: 'left',
        sortable: false,
        cell: record => {

          return (
              <div className="btn_stack">
                <button
                    type="button"
                    id = "approve"
                    className="btn btn-primary btn-sm check_btn"
                    onClick={(e) => this.handleRowClick(e, record)}
                    style={{ textAlign: 'center' }}
                    disabled={record.status === 'approved' ? true : false}
                >
                    Approve
                </button>
              </div>

          );
        },
      }
    ]

    return (
      <div className="user-dash-wrapper">

        <div className="sub-page-header col-sm-12">
          <h2>Manage Business Interests</h2>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />

            <Datatable
              title="Users"
              data={data}
              columns={columns}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}


CorporateInterest.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

CorporateInterest.defaultProps = {
  user: null,
  type: 'users',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
