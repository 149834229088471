import React, {useState} from 'react';
import SideBar from './sideBar';
import '../styles/dashboard.css';
import '../styles/login.css';
import '../styles/style.css';

const Burger = () => {
    const [open, setOpen] = useState(false);

    return (
        <>      
            <div className="admin-sidebar row">
                <div className="burger" onClick={(e) => setOpen(!open)}>
                    <div className={(open) ? "child_1": "burger_line"}></div>
                    <div className={(open) ? "child_2": "burger_line"}></div>
                    <div className={(open) ? "child_3": "burger_line"}></div>
                </div>
                {
                    open &&       
                        <SideBar/>
                }
            </div>
        </>
    )
}

export default Burger;