import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import Datatable from '../../components/datatable'
import { getAllUsers } from "../../models/users";
import DatePicker from '../../components/datepicker'
import '../../styles/dashboard.css';

export default class ManageUsers extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      success: false,
      error: false,
      count: null
    };
  }

  applyFilters(e) {
    e.preventDefault();

    const from = this.startDate;
    const to = this.endDate;
    if (from && to) this.setState({ filter: { from, to } }) 
    else {
      window.alert("Please indicate start and end date in the query");
      return false;
    }

    const filteredData = this.state.data.filter(data => 
        data.created_at.split(" ")[0] >= from && data.created_at.split(" ")[0] <= to
    );

    this.setState({
      data: filteredData,
      count: filteredData.length
    })
  }


  componentDidMount() {
    getAllUsers()
      .then(data => {

        this.setState({ data, success: true });
      })
      .catch(e => {
        console.error("[User List] Error fetching user data", e);
        this.setState({ error: true });
      })
  }

  handleRowClick = (e, record) => {
    const isAdmin = this.props.type === "admins";
    const isSummary = this.props.summary;
    const nameOnly = this.props.nameOnly;
    console.log(this.props);


    this.props.history.push(
      '/users/details',
      isAdmin
        ? {
          adminDetails: record
        }
        : {
          userDetails: record
        }
    );
  }

  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: `/${this.props.type}/manage`,
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    const { data } = this.state;

    const columns = [
      {
        key: "fname",
        text: "First Name",
        sortable: true,
      },
      {
        key: "lname",
        text: "Last Name",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        sortable: true,
      },
      {
        key: "phone",
        text: "Phone",
        sortable: true,
      },
      {
        key: "car_owner",
        text: "Car Owner",
        sortable: true
      },
      {
        key: "dob",
        text: "DOB",
        sortable: true,
        cell: (record) => {
          if(record.dob) {
            return record.dob.split(" ")[0]
          } else {
            return 'N/A'
          }
        }
      },
      {
        key: "created_at",
        text: "Date Joined",
        sortable: true,
        cell: record => moment(record.created_at).format('DD-MM-YYYY')
      },
      {
        key: "wallet.amount",
        text: "Wallet balance",
        sortable: true,
        cell: record => record.wallet.amount
      },
      {
        key: "wallet.credit_amount",
        text: "Company balance",
        sortable: true,
        cell: record => record.wallet.credit_amount
      },
      {
        key: "avatar",
        text: "Avatar",
        width: 70,
        sortable: false,
        cell: record => {
          return (
            <img
              className="image css-img-object"
              src={
                record.avatar
                  ? record.avatar
                  : "/assets/img/default.png"
              }
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                marginRight: "10px"
              }}
            />
          );
        }
      },
      {
        key: "active",
        width: 70,
        text: "Status",
        sortable: true,
        align: "left",
        cell: record => {
          return (
            <Fragment>
              {record.active == 1 ?
                "Active" : "InActive"
              }
            </Fragment>
          );
        }
      },
      {
        key: 'action',
        text: 'Action',
        width: 50,
        align: 'left',
        sortable: false,
        cell: record => {
          return (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => this.handleRowClick(e, record)}
              style={{ textAlign: 'center' }}
            >
              <i className="fa fa-edit" />
            </button>
          );
        },
      }
    ]

    return (
      <div className="user-dash-wrapper">
        <div className="module-pre-header-filter">
          <form onSubmit={e => this.applyFilters(e)}>
            <div className="flex flex-wrap inline-form-wrapper">
              <div className="title">
                <h3>Filter Users by Date</h3>
              </div>
              <div className="form-element">
                <DatePicker
                  type="date"
                  onUpdate={(data) => { this.startDate = data; }}
                  placeholder="Start Date"
                />
              </div>
              <div className="form-element">
                <DatePicker
                  type="date"
                  onUpdate={(data) => { this.endDate = data; }}
                  placeholder="End Date"
                />
              </div>
              <div className="form-element">
                <button type="submit">Filter</button>
              </div>
            </div>
          </form>
          {
            this.state.count &&
            <div className="userCount">
              <p>COUNT: {this.state.count}</p>
            </div>
          }
        </div>
        <div className="sub-page-header col-sm-12">
          <h2>Manage Users</h2>
        </div>

        <div className="col-md-12 col-lg-12">
          <div className="module focus green">
            <div className="module-header" />

            <Datatable
              title="Users"
              data={data}
              columns={columns}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}


ManageUsers.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

ManageUsers.defaultProps = {
  user: null,
  type: 'users',
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};
