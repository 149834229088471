import TripInsights from './insights';
import TripDetails from './details';
import ActiveTrips from './active';

export {
  TripDetails,
  TripInsights,
  ActiveTrips
};

export default TripInsights;
