import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { createAdmin } from '../../models/admin';

import '../../styles/spinner.css';

export default class AddAdmin extends Component {
  constructor() {
    super();
    this.state = {
      loadingData: false,
      loadingSuccessFull: true,
    };
  }

  /**
   * Creates an admin user
   * @param {Event} e
   */
  createAdmin(e) {
    e.preventDefault();
    if (!this.fname.value || !this.lname.value || !this.phone.value || !this.email.value || !this.password.value) {
      return this.props.setDashboardMessage('error', 'Some fields are missing');
    }

    const adminData = {
      fname: this.fname.value,
      lname: this.lname.value,
      phone: this.phone.value,
      email: this.email.value,
      password: this.password.value,
    };

    this.setState({ loadingData: true });
    createAdmin(adminData).then(() => {
      this.props.setDashboardMessage('success', 'Admin created successfully');
      this.setState({
        loadingData: false,
        loadingSuccessFull: true,
      });
      this.createForm.reset();
    }).catch((err) => {
      let message = 'Sorry an error occurred while creating the admin';
      if (err.body && (err.body.code === 'BadRequest' || err.body.code === 'ItemAlreadyExist')) {
        message = err.body.message;
      }
      this.props.setDashboardMessage('error', message);
      this.setState({ loadingData: false, loadingSuccessFull: false });
    });
  }


  render() {
    const user = this.props.user;
    // if we have a user session we redirect away from sign up
    if (!user || !user.id) {
      return (
        <Redirect to={{
          push: true,
          rtURL: '/admins/new',
          rtProps: this.props.location.state,
          pathname: '/login',
        }}
        />
      );
    }

    return (
      <div className="user-dash-wrapper">
        <div className="sub-page-header col-sm-12">
          <h2>Add a new admin</h2>
          <div className="right-options" />
        </div>

        <div className="clearfix" />

        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="module">
                <div className="module-header" />
                <div className="module-wrapper">
                  {
                    (user.role !== 'admin' && user.role !== 'super_admin') ?
                        <div className="bg_white">
                          <p className="bg_text">You're not allowed to view this page</p>
                          <p style={{textAlign: "center"}}>Kindly speak with the admin</p>
                        </div>
                      : 
                      <div className="module-content module-form-wrapper">
                      <div className="form-wrapper">
                        <form
                          onSubmit={e => this.createAdmin(e)}
                          ref={(form) => { this.createForm = form; }}
                        >
                          <div className="row">
                            <div className="form-element col-sm-6">
                              <span className="label">First Name</span>
                              <input
                                required ref={(input) => { this.fname = input; }} type="text"
                                className="form-text"
                              />
                            </div>
                            <div className="form-element col-sm-6">
                              <span className="label">Last Name</span>
                              <input
                                required ref={(input) => { this.lname = input; }} type="text"
                                className="form-text"
                              />
                            </div>
                          </div>
                          <div className="form-element">
                            <span className="label">E-mail Address</span>
                            <input
                              required ref={(input) => { this.email = input; }} type="email"
                              className="form-text"
                            />
                          </div>
  
                          <div className="form-element phone">
                            <span className="label">Phone Number</span>
                            <input
                              required ref={(input) => { this.phone = input; }} min="9"
                              type="number" className="form-text"
                            />
                          </div>
  
                          <div className="form-element">
                            <span className="label">Password</span>
                            <input
                              required ref={(input) => { this.password = input; }}
                              type="password"
                              className="form-text"
                            />
                          </div>
                          <div className="form-element">
                            <input
                              type="submit"
                              className="bvtton"
                              value={
                                this.state.loadingData ? 'Adding Admin...' : 'Add Admin'
                              }
                            />
                          </div>
                        </form>
                      </div>
                    </div>  
                  }
                  <div className="module-footer" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

AddAdmin.propTypes = {
  user: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
  setDashboardMessage: PropTypes.func,
};

AddAdmin.defaultProps = {
  user: null,
  location: {
    state: {},
  },
  setDashboardMessage: () => { },
};

AddAdmin.contextTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    staticContext: PropTypes.object,
  }).isRequired,
};
