import { get, post, patch } from '../libs/requestClient';
import cacheClient from '../libs/cacheClient';

export const getAllCharters = (offset, limit, range) => {
  let url = (offset && limit) ? `/v1/charters?offset=${offset}&limit=${limit}&` : '/v1/charters?';

  if (range) url += `from=${range.from}&to=${range.to}`;
  const fetchAllCharters = get(url)
    .then((charters) => {
      cacheClient.set(url, charters);
      return charters;
    });

  return cacheClient.get(url)
    .catch((err) => {
      console.error('[Charter model] Error fetching charters from cache', err.stack);
      return fetchAllCharters;
    })
    .then((data) => {
      if (data) return data;
      return fetchAllCharters;
    });
};

export const getCharters = (offset, limit) => getAllCharters(offset, limit);
export const findCharter = query => post('/v1/charters/search', { search_term: query });
export const updateCharter = (id, data) => patch(`/v1/charters/${id}`, data);
